import React, { FunctionComponent, useMemo } from 'react';
import { ContainerProps } from '../types';
import { useMutation } from '@apollo/client';
import { useSessionStatus } from 'document-viewer/src/lib/hooks/useSessionStatus';
import Component from './Component';
import { SUBMIT_KBA_INFO } from './gql';

const Container: FunctionComponent<ContainerProps> = ({ participantId, onSuccess }) => {
  const { value: { participants } } = useSessionStatus();
  const participant = useMemo(() => participants.find(({ id }) => id === participantId), [participants, participantId]);

  const [submitAnswers, { loading }] = useMutation(SUBMIT_KBA_INFO, {
    context: { service: 'enl-core-gql' },
    onCompleted: onSuccess,
  });

  return (
    <Component
      participant={participant}
      submitting={loading}
      onSubmit={(kbaInfo) => submitAnswers({
        variables: { participantId, kbaInfo }
      })}
      idvErrored
    />
  );
};

export default Container;
