import React, { useEffect, createContext } from 'react';
// import useAuth from 'document-viewer/src/lib/hooks/useAuth';
import { AxiosInstance } from 'axios';
import axios from 'axios';

const axiosInstance = axios.create();

const AxiosContext = createContext<AxiosInstance>(null);

export function AxiosProvider({ children }) {
  // const { currenAuthenticationId } = useAuth();
  // console.log(currenAuthenticationId);
  useEffect(() => {
    const interceptorId = axiosInstance.interceptors.request.use(async (config) => {
      // let token = currenAuthenticationId;
      // console.log(token);
      // if (token) {
      // const  tokenData = parseJwt(token);
      // const isExpired = tokenData.exp < Date.now() / 1000;

      // if (token && isExpired) {
      const { organization } = (window as any).store?.getState()?.auth || {};
      //   try {
      //     token = await (window as any).auth0.getTokenSilently({
      //       audience: auth0Config.audience,
      //       scope: auth0Config.scope,
      //       organization,
      //     });
      //   } catch (error) {
      //     await (window as any).auth0.loginWithRedirect({
      //       redirect_uri: `${window.location.origin}/callback?organization=${organization}`,
      //       organization,
      //     });
      //   }
      // }
      // return {
      //   ...config,
      //   headers: {
      //     ...config.headers,
      //     common: {
      //       ...config.headers.common,
      //       // authorization: `Bearer ${token}`
      //     }
      //   }
      // }
      // }
      return config;
    });
    return () => axiosInstance.interceptors.request.eject(interceptorId);
  }, [])


  return (
    <AxiosContext.Provider value={axiosInstance as AxiosInstance}>
      { children}
    </AxiosContext.Provider >
  )
}


export default AxiosContext;
