import React, { useReducer, useContext } from 'react';
import { useEffectOnce } from 'react-use';
import { useAppState } from '../AppState';
import { createServer, Server, ServerProps } from './server';

export const ServerCtx = React.createContext<Server | null>(null);

const reducer = (state: any = null, action: any) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

export interface ServerProviderProps {
  firebase: any;
  config: any;
  children: React.ReactNode;
}

// Provider
export function ServerProvider({ firebase, config, children }: ServerProviderProps) {
  const appState = useAppState();
  const [server, dispatch] = useReducer(reducer, null);


  useEffectOnce(() => {
    const importServer = async () => {
      if (server) {
        dispatch({ type: 'set', payload: server });

        return server;
      }

      const _server = await createServer({ firebase, ...config });

      // @ts-expect-error
      window.server = _server;

      dispatch({ type: 'set', payload: _server });

      return _server;
    };

    if (!server && firebase) {
      importServer()
        .then((server) => {
          server.getLoadedDocs()
            .then(appState.setLoadedDocs);
        });
    }

    // return () => {
    //   if (server) {
    //     // return server.markAsDisconnected()
    //   }
    // };
  });


  return (
    <ServerCtx.Provider value={server}>
      {server && children}
    </ServerCtx.Provider>
  );
}


// hook
export const useServer = () => {
  const server = useContext(ServerCtx);

  if (!server) {
    throw new Error('useServer must be called within <ServerProvider/> context');
  }

  return server;
};



// HOC
export const withServerProvider = (useConfig: any) => (Component: any) => function WithServerProvider({ firebase, ...props }: ServerProps) {
  const conf = useConfig(props);


  return (
    <ServerProvider
      firebase={conf.firebase}
      config={conf}
    >
      <Component {...props} />
    </ServerProvider>
  );
};

export const withUseServer = (Component: any) => (props: any) => {
  const server = useServer();

  return (
    <Component
      {...props}
      server={server}
    />
  );
};

