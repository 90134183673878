import { createSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { IdvState } from 'document-viewer/src/slices/idv';

import {
  selector,
  kbaStart,
  kbaReloadParticipant,
  getKbaQuestions,
  submitAnswers,
  submitCredAnalysis,
} from 'document-viewer/src/slices/idv';

// selectors
const loadingSel = createSelector(selector, (state: IdvState) => state.loading);
const questionsSel = createSelector(
  selector,
  (state: IdvState) => state.questions
);
const questionIdSel = createSelector(selector, (state: IdvState) => state.id);
const participantSel = createSelector(
  selector,
  (state: IdvState) => state.idvParticipant
);

export function useIdv() {
  const dispatch = useDispatch();
  const loading = useSelector(loadingSel);
  const questions = useSelector(questionsSel);
  const questionId = useSelector(questionIdSel);
  const idvParticipant = useSelector(participantSel);

  return {
    loading,
    questions,
    questionId,
    idvParticipant,
    kbaReloadParticipant: (participantId) =>
      dispatch(kbaReloadParticipant(participantId)),
    kbaStart: (transactionId, participantId, payload) =>
      dispatch(kbaStart(transactionId, participantId, payload)),
    getKbaQuestions: (participantId: string) =>
      dispatch(getKbaQuestions(participantId)),
    submitAnswers: (id: string, answers, isChallengeQuestion?: boolean, isAbruptlyClosed?: boolean) =>
      dispatch(submitAnswers(id, answers, isChallengeQuestion, isAbruptlyClosed)),
    submitCredAnalysis: (id: string, identityAccessKey: string, hasFailed: boolean = false) =>
      dispatch(submitCredAnalysis(id, identityAccessKey, hasFailed)),
  };
}
