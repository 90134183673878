import * as R from 'ramda';
import _ from 'lodash';
import registerTool from '../initializers/registerTool';
import { injectTool } from '../initializers/injectors';
import registerHeaderGroup from '../initializers/registerHeaderGroup';
import createStampAnnot from '../annots/stamp';
import { stampToolIcon } from '../constants/icons';
import getToggleButton from '../helpers/getToggleButton';
import { InstanceObject } from '../../viewer';


export interface createStampAnnotationProps {
  name: string;
  imageUrl: any;
  buttonImage: any;
  tooltip: any;
  width: number;
  button?: any;
}

export const createStampAnnotation = ({
  name,
  imageUrl,
  buttonImage,
  tooltip,
  width,
  button,
}: createStampAnnotationProps) => R.pipeP(
  // define annot class
  createStampAnnot({
    name,
    imageUrl,
    buttonImage,
    width,
  }),

  registerTool({
    type: 'toolButton',
    toolName: `${name}Tool`,
    buttonImage,
    dataElement: name,
    tooltip,
    button,
    // hidden: ['tablet', 'mobile'],
  })
);


const createStampAnnotTool = R.pipeP(
  createStampAnnotation({
    name: 'NotaryStamp',
    imageUrl: (args: any) => {
      return (!_.isEmpty(args.config.images.stamp)) ? args.config.images.stamp : '/static/img/notary_stamp.png';
    },
    buttonImage: '/static/img/notary_stamp.png',
    tooltip: 'Notary Stamp',
    width: 150,
  }),
  createStampAnnotation({
    name: 'NotarySeal',
    imageUrl: (args: any) => {
      return (!_.isEmpty(args.config.images.seal)) ? args.config.images.seal : '/static/img/notary_seal.png';
    },
    buttonImage: '/static/img/notary_seal.png',
    tooltip: 'Notary Seal',
    width: 150,
  }),
  createStampAnnotation({
    name: 'Checkbox',
    imageUrl: () => '/lib/assets/checkBoxOn.png',
    buttonImage: '/lib/assets/checkBoxOn.png',
    tooltip: 'Checkbox',
    width: 150,
    button: (instance: any) => ({
      type: 'customElement',
      render: getToggleButton(instance, {
        name: 'CheckboxTool',
        buttonImage: '/lib/assets/checkBoxOn.png',
      }),
      title: 'Checkbox',
      dataElement: 'ChecboxButton',
    }),
  }),
  createStampAnnotation({
    name: 'RadioButton',
    imageUrl: () => {
      return '/lib/assets/radioButtonOn.png';
    },
    buttonImage: '/lib/assets/radioButtonOn.png',
    tooltip: 'RadioButton',
    width: 150,
    button: (instance: any) => ({
      type: 'customElement',
      render: getToggleButton(instance, {
        name: 'RadioButtonTool',
        buttonImage: '/lib/assets/radioButtonOn.png',
      }),
      title: 'RadioButton',
      dataElement: 'RadioButton',
    }),
  }),
  injectTool('StampTools', {
    type: 'actionButton',
    img: stampToolIcon,
    title: 'Stamps',
    dataElement: 'stampToolGroupButton',
    onClick: ({ instance }: InstanceObject) => () => instance.setActiveHeaderGroup('stampToolsGroup'),
  }),
  registerHeaderGroup({
    groupName: 'stampToolsGroup',
    toolNames: [
      'NotaryStampTool',
      'NotarySealTool',
    ],
  })
);

export default createStampAnnotTool;
