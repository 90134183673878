import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export interface ConfirmationModalProps {
  show?: boolean;
  onHide?: () => void;
  message?: string;
  onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      animation={false}
      size='sm'
    >

      <Modal.Header>Confirm</Modal.Header>
      <Modal.Body>
        {props.message}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={props.onSubmit}>Confirm</Button>
        <Button onClick={props.onHide}>Cancel</Button>
      </Modal.Footer>

    </Modal>
  );
};

export default ConfirmationModal;
