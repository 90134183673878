export type SigPadSignature = {
  name?: string;
  image?: string;
  canvas?: any;
};

export type SigPadInitials = {
  name?: string;
  image?: string;
  canvas?: any;
};

export const blankCanvas = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=';
