import { useState, useCallback, useEffect } from 'react'

function usePanelState(isLargeScreen) {
  const [leftMenuOpen, _setLeftMenuOpen] = useState(isLargeScreen);
  const [rightMenuOpen, _setRightMenuOpen] = useState(isLargeScreen);

  const setLeftMenuOpen = useCallback((val) => _setLeftMenuOpen(() => val), [])
  const setRightMenuOpen = useCallback((val) => _setRightMenuOpen(() => val), [])

  useEffect(() => {
    if (isLargeScreen) {
      setLeftMenuOpen(true);
      setRightMenuOpen(true);
    } else {
      setLeftMenuOpen(false);
      setRightMenuOpen(false);
    }
  }, [isLargeScreen, setLeftMenuOpen, setRightMenuOpen]);


  return {
    leftMenuOpen,
    rightMenuOpen,
    setLeftMenuOpen,
    setRightMenuOpen
  }
}

export default usePanelState;
