import React, { FunctionComponent } from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import { isIOSDevice } from 'document-viewer/src/utils/agents';
import { useDisablePinchZoom } from 'document-viewer/src/lib/hooks/useDisablePinchZoom';
import { DialogProps } from './types';
import { dialogContentTextStyles, useStyles } from './styles';
import clsx from 'clsx';

export const Dialog: FunctionComponent<DialogProps> = ({ open, onClose, onExited, children, size, transitionDuration, ...rest }) => {
  const classes = useStyles();
  const { disablePinchZoom, enablePinchZoom } = useDisablePinchZoom();

  const isIOS = isIOSDevice();

  if (open && isIOS) {
    disablePinchZoom();
  }

  const handleClose = () => {
    if (isIOS) {
      enablePinchZoom();
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <MuiDialog
      className={clsx(classes.root, classes.listRoot)}
      maxWidth={size || 'sm'}
      fullWidth
      open={open || false}
      onClose={handleClose}
      transitionDuration={transitionDuration || 500}
      TransitionProps={{
        onExited: onExited
      }}
      scroll='paper'
      {...rest}
    >
      {children}
    </MuiDialog>
  );
};

export const DialogActions = MuiDialogActions;
export const DialogTitle = MuiDialogTitle;
export const DialogContent = MuiDialogContent;
export const DialogContentText = withStyles(dialogContentTextStyles)(MuiDialogContentText);
