import { useCallback, useEffect, useState } from 'react';
import { deferredTrackedPromise } from 'document-viewer/src/utils/deferredPromise';
import { useDocs } from './useDocs';

export default function useCallbackOnceDocLoads<Cb extends (...args: any[]) => Promise<any>>(callback: Cb, deps: any[]): Cb {
  const { documentLoading } = useDocs();
  const [dtp, setDtp] = useState(deferredTrackedPromise<void>());
  // Build a new deferredTrackedPromise if the document starts loading again
  useEffect(() => {
    if (documentLoading) {
      // The old dtp is passed in so that any .then()s set on it that didn't execute will execute once the new dtp resolves
      setDtp((oldDtp) => deferredTrackedPromise(oldDtp));
    }
  }, [documentLoading]);
  // When the doc finishes loading, resolve the current dtp
  useEffect(() => {
    if (!documentLoading) {
      dtp.resolve();
    }
  }, [documentLoading, dtp]);

  const cbAfterDocLoads = (...args: Parameters<Cb>) => dtp.promise.then(() => callback(...args));
  // The eslint hook has to be disabled in order to use `as`, and `as` has to be used because TS doesn't understand that this function matches Cb exactly for some reason, even though it does
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(cbAfterDocLoads as Cb, [dtp, callback, ...deps]);
}
