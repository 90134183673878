import { combineReducers } from '@reduxjs/toolkit';
import { reducer as annotationsReducer } from 'document-viewer/src/slices/annotations';
import { reducer as documentsReducer } from 'document-viewer/src/slices/documents';
import { reducer as participantsReducer } from 'document-viewer/src/slices/participants';
import { reducer as themeReducer } from 'document-viewer/src/slices/theme';
import { reducer as authenticationReducer } from 'document-viewer/src/slices/authentication';
import { reducer as transactionReducer } from 'document-viewer/src/slices/transaction';
import { reducer as requestDataReducer } from 'document-viewer/src/slices/request-data';
import { reducer as stepPageReducer } from 'document-viewer/src/slices/step-page';
import { reducer as idvReducer } from 'document-viewer/src/slices/idv';
import { reducer as customTagsReducer } from 'document-viewer/src/slices/customTags';

const rootReducer = combineReducers({
  participants: participantsReducer,
  annotations: annotationsReducer,
  documents: documentsReducer,
  theme: themeReducer,
  authentication: authenticationReducer,
  transaction: transactionReducer,
  requestData: requestDataReducer,
  stepPage: stepPageReducer,
  idv: idvReducer,
  customTags: customTagsReducer,
});

export default rootReducer;
