import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Accordion } from 'document-viewer/src/components/Accordion'
import {
  AccordionDetails,
  TextField,
  Grid,
  Box,
} from '@material-ui/core';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search as SearchIcon,
} from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { ComponentProps } from './types';
import { useStyles } from './styles';
import clsx from 'clsx';

const Component: FC<ComponentProps> = ({
  onSearch,
  lastSearchQuery: searchCompletedForQuery,
  searchingThroughDocId,
  numResults,
  resultIndex,
  onRequestedNextResult,
  onRequestedPrevResult,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const inputRef = useRef<any>();
  const classes = useStyles();
  const showDetails = !!searchCompletedForQuery;

  // Clear the search query, but focus the search bar so that the user can continue typing
  const resetSearch = useCallback(() => {
    setSearchQuery('');
    onSearch('');
    if (inputRef.current) {
      const input = inputRef.current.querySelector('input');
      if (input) input.focus();
    }
  }, [onSearch]);

  // If the doc being searched changes, then the query should be emptied
  useEffect(() => setSearchQuery(''), [searchingThroughDocId]);

  return (
    <Accordion
      square
      expanded
      className={clsx(classes.noTopBorder, classes.flexShrink)}
    >
      <AccordionDetails>
        <Grid
          container
          alignItems='center'
          justifyContent='center'
          direction='row'
        >
          <Grid
            item
            xs={2}
          >
            <SearchIcon
              className={showDetails
                ? classes.searchIconWithDetails
                : classes.searchIconWithoutDetails}
            />
          </Grid>
          <Grid
            item
            xs={10}
          >
            <TextField
              ref={inputRef}
              className='search'
              label='Search'
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                if (!e.target.value) {
                  onSearch(''); // If you manually clear the search field, then all the UI about searching should also disappear
                }
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  if (searchCompletedForQuery !== searchQuery) onSearch(searchQuery);
                  else onRequestedNextResult();
                }
              }}
              InputProps={{
                endAdornment: searchQuery ? (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='clear search query'
                      onClick={resetSearch}
                      size='small'
                    >
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ) : null
              }}
            />
            {
              showDetails ? (
                <Box mt={1}>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      {numResults === 0
                        ? 'No results'
                        : `Result ${resultIndex + 1}/${numResults}`}
                    </Grid>
                    {numResults > 0 ? (
                      <Grid item>
                        <IconButton
                          aria-label='previous search result'
                          onClick={onRequestedPrevResult}
                          disabled={resultIndex === 0}
                          size='small'
                        >
                          <KeyboardArrowUp />
                        </IconButton>
                        <IconButton
                          aria-label='next search result'
                          onClick={onRequestedNextResult}
                          disabled={resultIndex + 1 === numResults}
                          size='small'
                        >
                          <KeyboardArrowDown />
                        </IconButton>
                      </Grid>
                    ) : null}
                  </Grid>
                </Box>
              ) : null
            }
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default Component;
