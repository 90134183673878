

import { makeStyles } from '@material-ui/core/styles';

const scrollBarWidth = 12;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    overflowX: 'hidden',
    opacity: 1,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),

    '& *': { scrollbarWidth: 'thin', scrollbarColor: 'rgba(0,0,0,.2) rgba(255,255,255,.3)' },
    '& *::-webkit-scrollbar': { width: scrollBarWidth },
    '& *::-webkit-scrollbar-track': { background: theme.palette.background.paper },
    '& *::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: 20,
      border: `3px solid ${theme.palette.background.paper}`
    },
  },
  rootHidden: {
    display: 'flex',
    height: '100%',
    overflowX: 'hidden',
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'flex',
    flexDirection: 'column',
    // some fiddling here to center the main content ¯\_(ツ)_/¯
  },
  contentShiftRight: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  contentShiftLeft: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  box: {
    width: 100,
    height: 50,
    position: 'absolute',
    border: '2px solid black',
    display: 'fixed',
  },
  autoSignContainer: {
    textAlign: 'center',
    background: theme.palette.background.default,
  },
}));

export default useStyles;
