import _ from 'lodash';
import { InstanceObject } from '../../viewer';

const enableContextMenuItems = async ({ instance, config = {}, tools, ...rest }: InstanceObject) => {
  const currItems: any[] = instance.contextMenuPopup.getItems();
  const { contextMenuItems = [] } = config;


  const newCtxMenuItems = _.filter(currItems, (c) => {
    return !!_.find(contextMenuItems, (el) => _.isEqual(c.dataElement, el));
  });

  instance.contextMenuPopup.update(newCtxMenuItems);


  return {
    instance,
    config,
    tools,
    ...rest,
  };
};

export default enableContextMenuItems;
