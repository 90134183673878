import { gql } from '@apollo/client';


const IFRAME_MANDATORY_FIELDS = gql`
  fragment iframeMandatoryFields on Iframe {
    id
    style
    data
    inUsed
    organizationId
  }
`;

export const GET_IFRAME_REQUEST = gql`
  query getIframeRequest($id: ID!) {
    getIframeRequest(id: $id) {
      ...iframeMandatoryFields
    }
  }
  ${IFRAME_MANDATORY_FIELDS}
`;


export const GET_IFRAME_REQUEST_WITH_EQUIPMENT_CHECK_URL = gql`
  query getIframeRequest($id: ID!) {
    getIframeRequest(id: $id) {
      ...iframeMandatoryFields
      equipmentCheckUrl
    }
  }
  ${IFRAME_MANDATORY_FIELDS}
`;
