import * as R from 'ramda';
import { injectTool } from '../initializers/injectors';
import { strikeOutToolIcon } from '../constants/icons';
import { InstanceObject } from '../../viewer';


const defineStrikeOutTool = async ({ instance, toolClasses, annotClasses, tools, ...rest }: InstanceObject) => {
  const { Annotations, Tools, docViewer } = instance;

  const { TextStrikeoutAnnotation } = Annotations;

  if (!TextStrikeoutAnnotation) {
    throw new Error('Tools.TextStrikeoutAnnotation is not defined');
  }

  // define custom tool class
  class StrikeoutTool extends Tools.TextStrikeoutCreateTool {
    constructor(docViewer: any) {
      // @ts-expect-error not sure if this is correct
      super(docViewer, TextStrikeoutAnnotation);
    }
  }

  // register the custom tool
  const toolObject = new StrikeoutTool(docViewer);

  instance.registerTool({
    toolName: 'StrikeOutTool',
    buttonImage: strikeOutToolIcon,
    buttonName: 'StrikeOutTool',
    tooltip: 'Strike out text',
    toolObject,
    showColor: 'never',
    // @ts-expect-error not sure if this is correct
  }, TextStrikeoutAnnotation);

  // add tool to toolClasses map
  toolClasses.StrikeOutTool = StrikeoutTool;
  (instance.Tools as any).StrikeOutTool = StrikeoutTool;
  // add annot to annotClasses map
  tools.StrikeOutTool = {
    type: 'toolButton',
    toolName: 'StrikeOutTool',
    dataElement: 'strikeout-tool',
  };


  // return modified args

  return {
    instance,
    annotClasses,
    toolClasses,
    tools,
    ...rest,
  };
};


const registerButton = injectTool('StrikeOutTool', {
  type: 'toolButton',
  toolName: 'StrikeOutTool',
  title: 'Strike out',
  dataElement: 'strikeout',
});


export default R.pipeP(
  defineStrikeOutTool,
  registerButton
);
