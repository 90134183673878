// eslint-disable-next-line max-classes-per-file
import { Dialog } from '@material-ui/core';
import { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useFirebase } from './useFirebase';

// If you get an error from getSessionWriteLock, you can use these classes to check which error you got
export class CannotAcquireSessionWriteLockError extends Error { }
export class MissingSessionWriteLockDetailsError extends Error { }

// Use this to acquire a single lock for a session. Pass in the details of the lock via the "props" argument.
// Props should look roughly like this: { nsOrgId: '...', nsId: '...'}
// Note that nsOrgId is ns.organizationId, NOT user.organizationId!
// Unless you pass in all of those props, no write lock will be acquired.
export function useGetSessionLock(props) {
  const firebase = useFirebase();
  const history = useHistory();
  // This has 3 states: true, false, and undefined.
  // true means the lock was acquired, false means there was an attempt to get a lock that failed.
  // undefined means there wasn't even an attempt, or the attempt is in progress, or the lock was released
  const [locked, setLocked] = useState<boolean>(false);
  const { nsOrgId, nsId, children } = props || {};
  
  useEffect(() => {
    let intervalId = null;
    (async () => {
      const writeLockRef = firebase.database().ref(`/organization/${nsOrgId}/writeLocks/${nsId}`);
        
      const writeLockRefValue = (await writeLockRef.once('value')).val();
      if(writeLockRefValue) setLocked(true);

      intervalId = setInterval( async () => {
        const writeLockRefValue = (await writeLockRef.once('value')).val();
        if(!locked && writeLockRefValue) {
          setLocked(true);
        } 
        //TODO: need clarification when user edits and closes the order the Signer page has to refresh or not
        else if(locked && !writeLockRefValue){
          setLocked(false);
          // window.alert("new session details available");
          // window.location.reload()
        }
      }, 3000)
    })();

    return () => {
      intervalId && clearInterval(intervalId);
    };

  }, [firebase, setLocked, nsOrgId, nsId, locked]);

  const lockErrorModal = useMemo<JSX.Element>(() => locked === true && (
    <Dialog
      open
      onClose={() => history.goBack()}
    >
      { children } 
      {/* pass the genericModal as children where ever lockErrorModal is called*/}
    </Dialog>
  ), [ history, locked, children]);

  return { lockErrorModal, hasSessionWriteLock: locked };
}
