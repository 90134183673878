import * as R from 'ramda';
import debug from 'debug';

const log = debug('viewer');

const callIfDefined: (...args: any[]) => any = R.when(
  R.isNil,
  R.pipe(R.tap((val) => log('callback not defined', val)), R.identity)
);

export default callIfDefined;
