import { useSubscription } from "@apollo/client";
import { DOC_STATUS_CHANGED } from "document-viewer/src/lib/gql/document";
import { useState } from "react";


export interface IUseRevision {
  transactionId: string;
}


/**
 * When a doc's status changes, update it in redux
 * @param param.transactionId The transaction.id which the docs to listen for are under
 */
function useDocStatusSubscription({ transactionId }: IUseRevision): void {
  const [subscribedTo, setSubscribedTo] = useState(transactionId);

  // this should not have to be debounced because
  // it doesn't fetch anything, just updates redux
  useSubscription(DOC_STATUS_CHANGED, {
    variables: { transactionId },
    skip: !transactionId,
    shouldResubscribe: ({ variables: { transactionId } }) => {
      if (!!transactionId && transactionId !== subscribedTo) {
        setSubscribedTo(transactionId)
        return true;
      }
      return false;
    },
  });

}

export default useDocStatusSubscription;
