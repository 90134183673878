import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TopButtonUi from 'document-viewer/src/components/TopButton/Component';
import { useParticipants } from 'document-viewer/src/lib/hooks/useParticipants';
import useAnnots from 'document-viewer/src/lib/hooks/useAnnots';
import { useDocs } from 'document-viewer/src/lib/hooks/useDocs';
import { sortAnnotsVertically } from 'document-viewer/src/utils/pdftron/sortAnnots';
import useAutoSign from 'document-viewer/src/components/TopButton/useAutoSign';
import { DocStatus } from 'document-viewer/src/utils/pdftron/docCompletion';
import useCustomTags from 'document-viewer/src/lib/hooks/useCustomTags';
import { participantCompletedDocument, getTotalParticipantTags } from 'document-viewer/src/utils/participantCompletedDocument';

function TopButton({ lockErrorModal, completedNoTagDocList, setCompletedNoTagDocList, loadingThumbnails }) {
  const { participants, selectedParticipant, selectedParticipantId, setShowSwitch } = useParticipants();
  const {
    unappliedAnnots,
    unreviewedTagsForOtherParticipants,
  } = useAnnots();
  const customTags = useCustomTags();

  const {
    docs,
    selectedDoc,
    setSelectedDoc,
    saveDoc,
  } = useDocs();

  const myUnreviewedTags = useMemo(() => sortAnnotsVertically(unappliedAnnots || []), [unappliedAnnots]);
  const participantsArray = useMemo(() => Object.values(participants), [participants]);
  const autoSign = useAutoSign(docs[selectedDoc], myUnreviewedTags, participantsArray, selectedParticipantId, useCallback(() => setShowSwitch(true), [setShowSwitch]));
  const { documentLoading } = useDocs();
  const sortedDocs = useMemo(() => Object.values(docs).sort((a, b) => (a.order - b.order)), [docs]);
  const [ noDocSelectedAndDone, setNoDocSelectedAndDone ] = useState(false);
  // find id of first incomplete document for selectedParticipant
  const findIncompleteDocId = useCallback(() => sortedDocs
    .find((doc) => !participantCompletedDocument(doc, completedNoTagDocList, selectedParticipant))?.id,
  [selectedDoc, selectedParticipant, sortedDocs, completedNoTagDocList],
  );
  // find id of NEXT incomplete document for selectedParticipant
  const findNextIncompleteDocId = useCallback(() => sortedDocs
    .find((doc) => doc.id && doc.id !== selectedDoc && !participantCompletedDocument(doc, completedNoTagDocList, selectedParticipant))?.id,
  [selectedDoc, selectedParticipant, sortedDocs, completedNoTagDocList],
  );

  const goToIncompleteDoc = useCallback(() => {
    const incompleteDocId = findIncompleteDocId();
    if (incompleteDocId !== selectedDoc) {
      setSelectedDoc(incompleteDocId);
    }
  }, [findIncompleteDocId, setSelectedDoc]);

  const onCompleteDoc = useCallback(async () => {
    await saveDoc(selectedDoc, true, completedNoTagDocList);
    if (findNextIncompleteDocId()) {
      goToIncompleteDoc();
    }
  }, [saveDoc, selectedDoc, findNextIncompleteDocId, completedNoTagDocList]);

  const markNoTagsDocRead = useCallback(() => {
    const addDocId = docs[selectedDoc].id + selectedParticipantId;

    setCompletedNoTagDocList([
      ...completedNoTagDocList,
      addDocId
    ]);

    const foundDocId = findNextIncompleteDocId();
    if (foundDocId) {
      setSelectedDoc(foundDocId);
    } else {
      setSelectedDoc('');
      setNoDocSelectedAndDone(true);
    }
  }, [setSelectedDoc, findNextIncompleteDocId, selectedDoc, docs, completedNoTagDocList, setCompletedNoTagDocList]);

  const isCompleted = useMemo(() => ( docs?.[selectedDoc]?.status === DocStatus.completed && getTotalParticipantTags(docs[selectedDoc], selectedParticipantId) > 0 ) || 
    ( completedNoTagDocList.includes((doc: { id }) => doc.id === docs?.[selectedDoc]?.id + selectedParticipantId) && getTotalParticipantTags(docs[selectedDoc], selectedParticipantId) === 0 ) || noDocSelectedAndDone,
  [docs, selectedDoc, completedNoTagDocList, selectedParticipantId, noDocSelectedAndDone]
  );

  /**
   * We put an object literal in the dependency array so that this effect hook
   * runs every time. You might wonder "why not just put goToIncompleteDoc()
   * inside the function body instead of in a useEffect hook?" We do this
   * because goToIncompleteDoc() can cause a re-render, and you are not
   * supposed to cause a re-render within a rendering function in React.
   * Instead, you should put anything that might cause a re-render within a
   * useEffect hook, an event handler, or something that will only run _after_
   * the component has rendered (meaning that this function has returned).
   * 
   * Yes, this is not desirable. In the future, we will move this to the root
   * level and it won't even be a side effect; we will just calculate what the
   * current document _should_ be and pass it down as a prop, or retrieve it
   * from a Redux selector.
   */
  useEffect(() => goToIncompleteDoc(), [{}]);

  return (
    <TopButtonUi
      autoSign={autoSign}
      markNoTagsDocRead={markNoTagsDocRead}
      loadingThumbnails={loadingThumbnails}
      selectedDoc={selectedDoc}
      otherParticipantsHaveUnreviewedTags={unreviewedTagsForOtherParticipants.length > 0}
      checkForIncompleteDocs={findNextIncompleteDocId}
      goToIncompleteDoc={goToIncompleteDoc}
      currentParticipantId={selectedParticipantId}
      hasBeenCompleted={isCompleted}
      onCompleteDoc={onCompleteDoc}
      onSwitchParticipant={useCallback(() => setShowSwitch(true), [setShowSwitch])}
      myUnreviewedTags={myUnreviewedTags}
      hasUnsavedChanges={(window as any).hasUnsaved}
      documentLoading={documentLoading}
      customTags={customTags}
      isReadOnlyDoc={docs[selectedDoc]?.readOnly}
      lockErrorModal={lockErrorModal}
    />
  );
}


export default TopButton

