import React from 'react'
import DevButtons from 'document-viewer/src/components/DevButtons'
import { useWebViewer } from 'document-viewer/src/lib/hooks/useWebViewer';

function DevButtonsContainer({ show }) {
  const { instance: wv, persistDoc, utils } = useWebViewer();

  return (!show) ? <></> : (
    <DevButtons
      onTestSeal={async () => {
        const doc = wv.docViewer.getDocument();
        const sealed = await utils.sealPDF(await doc.getFileData());
        utils.saveBufferAsPDFDoc(sealed);
      }}
      onTestFlatten={async () => {
        const doc = wv.docViewer.getDocument();
        const flattened = await utils.getWithAnnots(await doc.getFileData(), await wv.annotManager.exportAnnotations());
        utils.saveBufferAsPDFDoc(flattened);
      }}
      onPersistDoc={async () => {
        const { fileData } = await persistDoc();
        utils.saveBufferAsPDFDoc(fileData);

      }}
    />
  )
}

export default DevButtonsContainer;
