import React from 'react'
import { PasswordPrompt as Prompt } from 'document-viewer/src/components/PasswordPrompt/Component';
import { useParticipants } from 'document-viewer/src/lib/hooks/useParticipants';

function PinCodePrompt() {
  const {
    setPendingSelection,
    setSelectedParticipant,
    pendingSelection,
    pendingSelectionId,
    promptPinCode
  } = useParticipants();

  return (
    <Prompt
      handleClose={() => {
        if (pendingSelectionId) {
          setPendingSelection(null);
        }
      }}
      open={(!!pendingSelection && promptPinCode)}
      participantName={`${pendingSelection?.firstName || ''} ${pendingSelection?.lastName || ''}`}
      actualPinCode={pendingSelection?.pinCode}
      participantId={pendingSelectionId}
      onSubmit={(pId) => {
        setSelectedParticipant(pId)
      }}
    />

  )
}


export default PinCodePrompt

