import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { useQuery as useUrlQuery } from 'document-viewer/src/lib/hooks/useQuery';
import { GET_SESSION } from 'document-viewer/src/lib/gql/session';
import { useSessionStatus } from 'document-viewer/src/lib/hooks/useSessionStatus';

const useStyles = makeStyles(theme => ({
  body: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    overflow: "auto",
  },
  content: {
    padding: "3em 6em",
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    borderWidth: "0.75em",
    fontWeight: 800,
    fontSize: "2.5em",
    whiteSpace: "pre-wrap",
    textAlign: 'center',
  },
}))

function DocumentDelivery() {
  const query = useUrlQuery();
  const [ isLoading, setIsLoading ] = useState(true);
  const { setOrganizationBrandingAsTheme } = useSessionStatus();
  const { organizationId, session: sessionId } = query;
  const { loading, data } = useQuery(GET_SESSION, {
    variables: {
      sessionId,
      organizationId,
    }
  });
  const classes = useStyles(query);

  useEffect(() => {
    const fetchInitialOrganizationData = async () => {
      // TODO: Improve Redux slice logic to manage loading states and replace usage of async timers.
      try {
        await setOrganizationBrandingAsTheme();
      } catch(err) {
        console.error(err);
      } finally {
        setTimeout(()=>{
          setIsLoading(false);
        }, 1000);
      }
    }
    fetchInitialOrganizationData();
  }, []);

  const showContent = !(loading || isLoading);
  const willSendEmailNotification = data ? data.getEsign?.sendEmailNotification : true;
  const content = willSendEmailNotification ? (
    `You've completed your eSign. You'll receive an email\nof the executed document once everyone has signed.`
  ) : (
    `Thank you! Your signing is complete.`
  );

  return (
    <div className={classes.body}>
      {
        showContent && (
          <span className={classes.content}>
            {content}
          </span>
        )
      }
    </div>
  );
}

export default DocumentDelivery;
