import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import type { Action } from '@reduxjs/toolkit';
import { ENABLE_REDUX_DEV_TOOLS } from '../constants';
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

export const initStore = ({ extraArguments = {}, devTools = ENABLE_REDUX_DEV_TOOLS }) => configureStore({
  reducer: rootReducer,
  devTools,
  middleware: [
    thunk.withExtraArgument(extraArguments)
  ]
});

export type Store = ReturnType<typeof initStore>;
export type RootState = ReturnType<Store['getState']>;
export type AppDispatch = ThunkDispatch<RootState, ApolloClient<NormalizedCacheObject>, Action<string>>;
export type AppThunk = ThunkAction<void, RootState, ApolloClient<NormalizedCacheObject>, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();
