import React, { FunctionComponent } from 'react';
import { Grid, FormControl, FormControlLabel, InputLabel, MenuItem, Select, RadioGroup, Radio } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ComponentProps } from './types';
import { useStyles } from './styles';

const Component: FunctionComponent<ComponentProps> = ({ canChooseFont, font, setFont, fontChoices, canChooseColor, color, setColor, showLabels, justifyContent = 'space-between' }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid
      container
      alignItems='center'
      justifyContent={justifyContent}
      spacing={isSmallScreen ? 2 : 5}
    >
      {canChooseFont ? (
        <Grid
          item
          className={classes.flex}
          sm={5}
          xs={12}
        >
          <FormControl
            className={classes.formControl}
            fullWidth
          >
            {showLabels ? (
              <InputLabel id='sig-font'>Font</InputLabel>
            ) : null}
            <Select
              labelId='sig-font'
              data-testid='sig-font'
              value={font || fontChoices[0]}
              fullWidth
              onChange={(event) => {
                setFont(event.target.value as string);
              }}
            >
              {fontChoices.map((fontName) => (
                <MenuItem
                  key={fontName}
                  value={fontName}
                >
                  {fontName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      ) : null}

      {canChooseColor ? (
        <Grid
          item
          sm={7}
          xs={12}
        >
          {showLabels ? (
            <InputLabel htmlFor='sig-color'>Color</InputLabel>
          ) : null}
          <RadioGroup
            row
            aria-label='Color'
            id='sig-color'
            value={color}
            onChange={(e) => setColor(e.target.value as any)}
          >
            <FormControlLabel
              value='blue'
              control={<Radio />}
              label='Blue'
            />
            <FormControlLabel
              value='black'
              control={<Radio />}
              label='Black'
            />
          </RadioGroup>
        </Grid>
      ) : null}

    </Grid>
  );
};

export default Component;
