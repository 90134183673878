import { makeStyles } from "@material-ui/core";

export interface UseEllipsesProps {
  numLines?: number;
  maxWidth?: number | string;
}

const useEllipses = makeStyles({
  ellipses: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    overflowWrap: 'anywhere',
    WebkitLineClamp: ({ numLines = 1 }: UseEllipsesProps) => numLines,
    maxWidth: ({ maxWidth }: UseEllipsesProps) => maxWidth,
  },
});
export default useEllipses;
