import { Theme as MuiTheme, useTheme as muiUseTheme } from '@material-ui/core/styles';

export type Theme = MuiTheme & Partial<{
  borderColor: string;
  tabTextColor: string;
  modalDropShadows: boolean;
  buttonRadius: number;
  modalBorderRadius: number;
  topButtonAnimation: boolean;
  signatureAndInitialsInOneStep: boolean;
  onComplete: ('toast' | 'confetti')[];

  /**
   * This being true means that the participants pane will appear on the
   * right panel, meaning that there will be no room for the documents list
   * on the right panel, meaning that the left panel will be the normal
   * accordion.
   */
  showParticipants: boolean;
  zoomIcon: "top" | "right sidebar" | "none";
}>;

/**
 * This trivial override is used instead of Material UI's `useTheme()` because
 * it can be mocked. If you try to mock Material UI's `useTheme()`, you will get
 * an error because the `useTheme()` export is not configurable.
 *
 * @returns {Theme} The theme that Material UI's `useTheme()` hook would return.
 */
export const useTheme = (): Theme => muiUseTheme();
