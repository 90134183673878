export interface ConfigProps {
  viewOnly?: boolean;
  isAdminUser?: boolean;
  taggingMode?: boolean;
  taggingRoom?: boolean;
  isEsign?: boolean;
  disableElements?: string[];
  enableLogRocket?: boolean;
}

const wvConfig = ({
  viewOnly = false,
  isAdminUser = false,
  taggingMode = false,
  taggingRoom = false,
  isEsign = false,
  disableElements: de = [],
}: ConfigProps = {}) => {
  let disableElements: string[];

  if (viewOnly || taggingMode) {
    disableElements = [
      'stickyToolButton',
      'freeHandToolGroupButton',
      'menuButton',
      'miscToolGroupButton',
      'searchButton',
      'textToolGroupButton',
      'viewControlsButton',
      'linkButton',
      'shapeToolGroupButton',
      'signatureToolButton',
      'eraserToolButton',
      'thumbnailControl',
      'outlinesPanel',
      'outlinesPanelButton',
      'thumbnailsPanelButton',
      'leftPanelTabs',
      'opacitySlider',
      'strokeThicknessSlider',
      'documentControl',
    ];

    if (!taggingMode) {
      disableElements.push('freeTextToolButton', 'deleteToolButton');
    }
  } else if (isAdminUser) {
    disableElements = [
      'stickyToolButton',
      'freeHandToolGroupButton',
      'menuButton',
      'miscToolGroupButton',
      'searchButton',
      'textToolGroupButton',
      'viewControlsButton',
      'linkButton',
      'shapeToolGroupButton',
      'eraserToolButton',
      'thumbnailControl',
      'documentControl',
      'opacitySlider',
      'strokeThicknessSlider',
      'toolStylePopup',
    ];
  } else {
    disableElements = [
      'contextMenuPopup',
      /* 'richTextPopup',
      'RichTextPopup', */
      // 'fontSizeSlider',
      'richTextBoldButton',
      'richTextItalicButton',
      'richTextUnderlineButton',
      'richTextStrikeButton',
      'opacitySlider',
      'strokeThicknessSlider',
      'stickyToolButton',
      'freeHandToolGroupButton',
      'menuButton',
      'miscToolGroupButton',
      'searchButton',
      'textToolGroupButton',
      'viewControlsButton',
      'linkButton',
      'shapeToolGroupButton',
      'eraserToolButton',
      'thumbnailControl',
      'toolStylePopup',
      'documentControl',
      'bookmarksPanelButton',
      'bookmarksPanel',
      'outlinesPanelButton',
      'outlinesPanel',
    ];
  }

  if (isEsign) {
    disableElements.push('leftPanel');
    disableElements.push('leftPanelButton');
  }

  return {
    path: '/lib',
    fullAPI: taggingMode === true,
    // ui: 'beta',
    // config: config ? `${process.env.PUBLIC_URL}${config}` : undefined,
    config: '/wv-configs/vanilla-config.js',
    // disableLogs: true,
    // pdftronServer: 'https://demo.pdftron.com/',
    custom: {
      taggingMode,
      taggingRoom,
      colorPalette: [{
        toolNames: ['AnnotationCreateFreeText', 'AnnotationCreateSignature'],
        colors: ['#4B92DB', '#000000', 'transparency'],
      }],
      // fitMode: 'FitPage',
      layoutMode: 'Continuous',
      disableFeatures: [
        'TextSelection',
        'NotesPanel',
        'FilePicker',
        'Redaction',
        // 'Copy',
        'Download',
        'Print',
        'ThumbnailReordering',
      ],
      disableTools: [
        'AnnotationCreatePolygon',
        'AnnotationCreateTextHighlight',
        'AnnotationCreateTextUnderline',
      ],
      enableElements: [],
      disableElements: [
        ...disableElements,
        ...de,
      ],
    },
  };
};

export default wvConfig;
