import React, { FunctionComponent } from 'react';
import { ComponentProps, IconAndColor, stepsArray } from './types';
import { useTheme } from 'document-viewer/src/lib/hooks/useTheme';
import { useStyles } from './styles';
import { Stepper, Step, StepLabel, StepConnector, Typography, StepIconProps } from '@material-ui/core';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ReceiptIcon from '@material-ui/icons/Receipt';

export const StepIcon = (props: StepIconProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const stepIndex = +props.icon - 1;
  const iconAndColor: IconAndColor = [
    {
      icon: NetworkCheckIcon,
      color: theme.palette.primary.main,
    },
    {
      icon: LockOpenIcon,
      color: theme.palette.secondary.main,
    },
    {
      icon: ReceiptIcon,
      color: theme.palette.info.main,
    },
  ][stepIndex];

  const solid = props.active || props.completed;
  return (
    <div
      className={classes.icon}
      style={{
        [solid ? 'background' : 'border']: solid
          ? iconAndColor.color
          : `.75vmin solid ${iconAndColor.color}`,
        color: solid
          ? theme.palette.background.default
          : iconAndColor.color,
      }}
      data-testid={`SetupStepper.StepIcon${stepIndex}${solid ? '-solid' : ''}`}
    >
      <iconAndColor.icon />
    </div>
  );
}

const Component: FunctionComponent<ComponentProps> = ({ step, children, explanation = {} }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <Stepper
          alternativeLabel
          activeStep={stepsArray.indexOf(step)}
          connector={(
            <StepConnector
              classes={{
                alternativeLabel: classes.alternativeLabel,
                line: classes.line,
              }}
            />
          )}
        >
          {stepsArray.map((step) => (
            <Step key={step}>
              <StepLabel StepIconComponent={StepIcon} />
            </Step>
          ))}
        </Stepper>
        {explanation.title || explanation.description ? (
          <div className={classes.explanation}>
            {explanation.title ? <Typography variant='h4'>{explanation.title}</Typography> : null}
            {explanation.description ? <Typography>{explanation.description}</Typography> : null}
          </div>
        ) : null}
      </div>
      {children}
    </>
  );
};

export default Component;
