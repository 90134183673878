import { FunctionComponent } from 'react';
import MuiTooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { selector as requestDataSelector } from 'document-viewer/src/slices/request-data';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'document-viewer/src/store';

const Tooltip: FunctionComponent<TooltipProps> = (props) => {
  const requestData = useSelector(createSelector(requestDataSelector, (state) => state.data || {}));
  if (requestData.toolTipsEnabled || requestData.toolTipsEnabled === undefined) {
    return <MuiTooltip {...props} />;
  }
  return props.children;
};

export default Tooltip;
