import React from 'react';
import { Box, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { useSetSigInitStyles } from './styles';
import {
  IdentityFormComponent,
  KbaQuestionsComponent,
  IdCheckComponent,
} from 'document-viewer/src/components/identity-verification';
import { useParticipants } from 'document-viewer/src/lib/hooks/useParticipants';
import useOrientation from 'document-viewer/src/lib/hooks/useOrientation';
import useIsMobileScreen from 'document-viewer/src/lib/hooks/useIsMobileScreen';
import { useIdv } from 'document-viewer/src/lib/hooks/useIdv';
import { useQuery } from 'document-viewer/src/lib/hooks/useQuery';
import moment from 'moment';
import _ from 'lodash';
import { useEffectOnce } from 'react-use';
import { useKbaStyles } from './styles';
import { GET_ORG_INFO } from 'document-viewer/src/lib/gql/session';
import { useApolloClient } from '@apollo/client';
import { CredentialAnalysisProvider } from '../../utils/types/transaction';

export default function KbaStep({
  renderButton,
  participant,
  participantsLoading,
  onLockout,
  isChallengeQuestion,
  setIsChallengeQuestion,
  open
}) {
  const classes = useSetSigInitStyles();
  const styles = useKbaStyles();
  const isLandscape = useOrientation();
  const isMobileScreen = useIsMobileScreen();
  const { transactionId, org: organizationId } = useQuery();
  const { loading,  idvParticipant, questions, questionId, getKbaQuestions, kbaStart, submitAnswers, submitCredAnalysis, kbaReloadParticipant } = useIdv();
  const [idvErrored, setIdvErrored] = React.useState(false);
  const [tempParticipant, setTempParticipant] = React.useState<any>(null);
  const [ lastKbaRequestWasConfirmQuestion, setLastKbaRequestWasConfirmQuestion ] = React.useState(false);
  const [submitClicked, setSubmitClicked] = React.useState(false)
  const [ kbaPassed, setKbaPassed ] = React.useState(false);
  const [ kbaFailed, setKbaFailed ] = React.useState(false);
  const [ hasCompleteKbaResponse, setHasCompleteKbaResponse ] = React.useState(false);
  const [lastAttempt, setLastAttempt] = React.useState(false);
  const [credentialAnalysisProvider, setCredentialAnalysisProvider] = React.useState<CredentialAnalysisProvider>();
  const apollo = useApolloClient();

  const { updateParticipantStatus } = useParticipants();

  const getCredentialAnalysisProvider = () => {
    apollo.query({
      query: GET_ORG_INFO,
      variables: {
        organizationId: organizationId,
        includeSettings: true,
      }
    })
      .then((result) => {
        const { data: { getOrganizationInfo: { settings: organizationSettings } } } = result;
        const credAnalysisProviderSettings = organizationSettings
          .find((s) => s.key.trim().toLowerCase() === 'credentialanalysisprovider');
        const credAnalysisProvider = credAnalysisProviderSettings?.value?.provider;

        if (credAnalysisProvider) {
          setCredentialAnalysisProvider(CredentialAnalysisProvider[credAnalysisProvider]);
        } else {
          // TODO: remove console.error, only for debugging/qa
          console.error('Organization not setup for Credential Analysis. Default to: Jumio.');
          setCredentialAnalysisProvider(CredentialAnalysisProvider.jumio);
        }
      })
      .catch((error) => {
        // TODO: remove console.error, only for debugging/qa
        console.error('Organization received and error for Credential Analysis. Default to: Jumio.', error);
        setCredentialAnalysisProvider(CredentialAnalysisProvider.jumio);
      });
  };


  useEffectOnce(() => {
    getCredentialAnalysisProvider();
    if (participant.status === 'pending_kba_verify') {
      getKbaQuestions(participant?.id);
    }
    else if (participant.status === 'kba_verify_fail') {
      // TODO: show the KBA failure modal
      setHasCompleteKbaResponse(true);
      setKbaFailed(true);
    }
  })

  const setTempData=(data:any) => {

    if(data.idvParticipant){
      setTempParticipant(data.idvParticipant)
    }

  }

  const showKBAQuestions =
    (participant?.status === 'pending_kba_verify' ||
      (participant?.status !== 'pending_cred_analysis' &&
        tempParticipant?.status === 'pending_kba_verify')) &&
    !kbaPassed;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.hidden]: !open,
        [styles.mobileFullHeight]: showKBAQuestions,
      })}
    >
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        className={clsx({ [styles.mobileFullHeight]: showKBAQuestions })}
      >
        <Grid
          item
          xs={12}
        >
          <Box
            className={clsx({
              [styles.box]: true,
              [styles.mobileDialogsContainer]: isMobileScreen,
              [styles.mobileDialogsAlignCenter]: isMobileScreen && !isLandscape,
            })}
          >
            {
              participant?.status === 'pending_kba_start' && tempParticipant === null && (
                <IdentityFormComponent
                  onSubmit={async (values) => {

                    const data: any = await kbaStart(transactionId, participant.id, {
                      ...values,
                      firstName: participant.firstName,
                      lastName: participant.lastName,
                      email: participant.email,
                      dob: moment(values.dob, 'YYYY-MM-DD').format('MM/DD/YYYY')
                    })

                    if(_.isNull(data) || _.isEmpty(data.data.kbaStart.questions)){
                      setIdvErrored(true)
                    }else{
                      setTempData(data)
                    }

                  }}
                  participant={participant}
                  submitting={loading}
                  idvErrored={idvErrored}
                  children={<div />}
                  onCloseDialog={() => {
                    setIdvErrored(false)
                  }}
                />
              )
            }
            {
              showKBAQuestions && (
                <KbaQuestionsComponent
                  hasCompleteKbaResponse={hasCompleteKbaResponse}
                  resetTimer={() => { }}
                  lastKbaRequestWasConfirmQuestion={lastKbaRequestWasConfirmQuestion}
                  onSubmit={async (answers, isChallengeQuestion, isAbruptlyClosed) => {
                    setSubmitClicked(true);
                    const data: any = await submitAnswers(participant?.id, {
                      id: `${questionId ?? 0}`,
                      answers: _.chain(answers)
                        .toPairs()
                        .map(([questionId, answerId]) => ({ answerId, questionId }))
                        .value(),
                    },
                    isChallengeQuestion,
                    isAbruptlyClosed,
                    );

                    if(data?.submitAnswers?.passed) {
                      setLastKbaRequestWasConfirmQuestion(data.submitAnswers.kbaStatus === 'pending');
                      if(data.submitAnswers.kbaStatus === 'pending') getKbaQuestions(participant.id);
                    } else {
                      setLastKbaRequestWasConfirmQuestion(false);
                    }

                    if((typeof data === 'string' && data === 'NO_MORE_ATTEMPTS') || data.submitAnswers && data.submitAnswers.retry === false ) {
                      setKbaFailed(true);
                    } else {
                      if(data?.submitAnswers.passed &&
                        (data.submitAnswers.status === 'ready' || data.submitAnswers.status === 'pending_cred_analysis')) {

                        setKbaPassed(true);
                        updateParticipantStatus(participant.id,
                          {
                            ...participant,
                            status: data.submitAnswers.status
                          }
                        );
                        kbaReloadParticipant(participant.id);
                        setTempParticipant({
                          ...participant,
                          status: data.submitAnswers.status
                        });
                      } else if (data.submitAnswers.kbaStatus === 'pending' && data.submitAnswers.retry === true) {
                        setHasCompleteKbaResponse(true);
                        getKbaQuestions(participant.id);
                      } else {
                        lastAttempt && getKbaQuestions(participant.id);
                      }
                    }
                  }}
                  lastAttempt={lastAttempt}
                  setLastAttempt={setLastAttempt}
                  participant={participant}
                  onLastAttemptAck={() => { getKbaQuestions(participant.id);  }}
                  questions={questions}
                  participantsLoading={participantsLoading}
                  loadingQuestions={loading || kbaPassed || kbaFailed}
                  submittingAnswers={loading}
                  msBeforeTimeout={+process.env.NX_MILLISECONDS_TO_ANSWER_KBA!}
                  onTimeout={() => {}}
                  attempts={participant.kbaAttempts}
                  renderButton={renderButton}
                  kbaAttempts={participant.kbaAttempts}
                  submitClicked={submitClicked}
                  isChallengeQuestion={isChallengeQuestion}
                  setIsChallengeQuestion={setIsChallengeQuestion}
                />
              )
            }
            {
              (credentialAnalysisProvider) && (participant?.status === 'pending_cred_analysis' || (((kbaPassed && participant.kbaRequired) || (!kbaPassed && !participant.kbaRequired)) && participant.credAnalysisRequired )) && (
                <IdCheckComponent
                  onSuccess={(accessKey) => {
                    submitCredAnalysis(participant.id, accessKey, false);
                  }}
                  user={participant}
                  onFail={(accessKey) => {
                    submitCredAnalysis(participant.id, accessKey, true);
                  }}
                  saving={loading}
                  provider={credentialAnalysisProvider}
                  transactionId={transactionId}
                />
              )
            }
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
