import { FunctionComponent, useEffect } from 'react';
import { FormProps } from './types';
import { useStyles } from './styles';
import { useFormik } from 'formik';
import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup, FormControl, FormLabel } from '@material-ui/core';

const Form: FunctionComponent<FormProps> = ({ questions, loadingQuestions, submittingAnswers, onSubmit, renderButton: RenderButton }) => {
  const classes = useStyles();
  const formik = useFormik({ initialValues: {}, onSubmit });

  // Reset the form whenever questions change
  const { resetForm } = formik;
  useEffect(() => resetForm(), [resetForm, questions]);

  if (!questions) return null;
  const submitDisabled = submittingAnswers || loadingQuestions || questions.length !== Object.values(formik.values).length;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={classes.flexGrow}
    >
      <Grid
        container
        justifyContent='center'
        direction='column'
        spacing={2}
      >
        {questions.map(({ id, statement, choices }) => (
          <Grid
            item
            key={id}
          >
            <FormControl component='fieldset'>
              <Box
                mb={1}
                ml={3}
              >
                <FormLabel
                  component='legend'
                  classes={{ root: classes.unsetColor, focused: classes.unsetColor }}
                >
                  {statement}
                </FormLabel>
              </Box>
              <Box ml={6}>
                <RadioGroup
                  aria-label={statement}
                  name={id.toString()}
                  value={formik.values[id] || null}
                  onChange={formik.handleChange}
                >
                  {choices.map(({ statement, id }) => (
                    <FormControlLabel
                      key={id}
                      label={statement}
                      control={<Radio disabled={loadingQuestions || submittingAnswers} />}
                      value={id.toString()}
                    />
                  ))}
                </RadioGroup>
              </Box>
            </FormControl>
          </Grid>
        ))}
      </Grid>
      {
        (!RenderButton) ? (
          <Box
            mt={2}
            mb={1}
          >
            <Button
              variant='contained'
              color='primary'
              disabled={submitDisabled}
              type='submit'
            >
              Submit
            </Button>
          </Box>
        ) : <RenderButton disabled={submitDisabled} />
      }
    </form>
  );
};

export default Form;
