import { hotKeys } from '../constants/hotKeys';

// NOTE: Hotkeys passed in are DISABLED
export default {
  panelNames: [],
  toolNames: [
    'SignHereTagTemplateTool',
    'InitialHereTagTemplateTool',
    'FullNameTemplateTool',
    'DateTemplateTool',
    'FreeFormTemplateTool',
    'CheckHereTagTemplateTool',
    'RadioButtonTagTemplateTool',
  ],
  popupNames: [
    'AssignSigner',
    'SetDateFormat',
    'SetRequired',
  ],
  hotKeys: [
    hotKeys.F,
    hotKeys.V,
    hotKeys.R,
    hotKeys.B,
    hotKeys.Q,
    hotKeys.W,
    hotKeys.E,
    hotKeys.S,
    hotKeys.C,
    hotKeys.A,
    hotKeys.Z,
    hotKeys.X,
    hotKeys.O,
    hotKeys.L,
  ],
};
