import { WebSocketLink } from './WebSocketLink';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloClient, HttpLink, split, InMemoryCache } from '@apollo/client';

const createLink = (url: string) => (
  new HttpLink({
    uri: `${window.location.origin}${url}`,
    credentials: 'same-origin',
  })
);

export const initApollo = (withSubscriptions = true) => {

  const apiPath = process.env.NX_GQL_PATH || '/graphql';

  const link = createLink(apiPath);
  const gqlWsLink = withSubscriptions && new WebSocketLink({
    url: `${window.location.protocol === 'http:' ? 'ws://' : 'wss://'}${window.location.host}/api/subscriptions`,
    retryAttempts: 3,
    lazy: true,
  });

  return new ApolloClient({
    ssrMode: false,
    connectToDevTools: true,
    link: withSubscriptions
      ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        gqlWsLink,
        link
      )
      : link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            // allPosts: concatPagination(),
          },
        },
      },
    }),
  });
};
