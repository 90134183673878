
import _ from 'lodash';
import { muiColorPalettes } from '../constants';
import { RequestData } from './types/request-data';
import type { ThemeOptions } from '@material-ui/core';

const generateThemeOptions = (style: any, data: RequestData = {}): ThemeOptions => ({
  ...style,
  signatureAndInitialsInOneStep: data.signatureAndInitialsInOneStep ?? false,
  modalDropShadows: style.modalDropShadows ?? true,
  buttonRadius: style.buttonRadius ?? 5,
  modalBorderRadius: style.modalBorderRadius ?? 4,
  topButtonAnimation: style.topButtonAnimation ?? true,
  onComplete: style.onComplete ?? ['toast'],
  showParticipants: style.showParticipants ?? true,
  zoomIcon: style.zoomIcon ?? 'right sidebar',
  typography: {
    fontFamily: style.fontType,
  },
  palette: {
    // Adds primary: { main: theme.primaryColor } but for all of the muiColorPalettes. light, dark, and contrastText are calculated by Material UI
    ..._.reduce(muiColorPalettes, (acc, pal) => ({
      ...acc,
      [pal]: { main: style[`${pal}Color`] }
    }), {}),
    // Used to calculate light, dark, and contrastText for the above palettes
    tonalOffset: style.tonalOffset,
    contrastThreshold: style.contrastThreshold,

    text: {
      // TODO: make primary be textColor, but to find secondary and disabled, you need to do some color operations:
      // 1. If the textColor colorIsGray ? lightness <= 50 : lightness < 35, then make secondary lighter, and make disabled even lighter
      // 2. Else If the textColor colorIsGray ? lightness > 50 : lightness > 65, then make secondary color darker, and make disabled even darker
      // 3. Else, make secondary more grayscale, and the disabled color even more grayscale.
      primary: style.textColor,
      secondary: style.textColor,
    },

    background: {
      default: style.background,
      paper: style.secondaryBackground,
    },

    divider: 'rgba(0, 0, 0, 0.12)',
  },
  iconColor: style.iconColor,
  icons: style.icons || {},
  overrides: {
    MuiCssBaseline: {
      '@global': { iframe: { border: 'none' } }
    }
  },
});



export default generateThemeOptions;
