import _ from 'lodash';
import { parseName } from '@enotarylog/tag-utils/helpers/parseName';
import {
  setStateFormat,
  setDateFormat,
  setAddressFormat,
  setIsRequired,
} from '../popups/setters';
import { InstanceObject } from '../../viewer';
import { Annotations } from '@pdftron/webviewer';

export interface defineAnnotClassProps {
  className: string;
  baseClassName: keyof typeof Annotations;
  methods?: any;
  customData?: any;
}

export const defineAnnotClass = ({ className, baseClassName, methods = {}, customData = {} }: defineAnnotClassProps) => async ({ instance, annotClasses, ...rest }: InstanceObject) => {
  const C = class extends instance.Annotations[baseClassName] {
    constructor(...args: any[]) {
      super(...args);
      this.Subject = className;
      this.CustomData = { ...customData };
    }

    setSigner(id: string) {
      const signer = instance.getSignerById(id);
      const fullName = parseName(signer);

      this.FillColor = signer.color;
      // this.Author = signer.id;
      const customdata = {
        ...this.CustomData,
        ...customData,
        signerId: id,
        color: [signer.color.R, signer.color.G, signer.color.B, signer.color.A],
        name: fullName,
        fullName,
      };

      this.setContents(`${this.CustomData.label}: ${fullName}`);

      // this.setCustomData(customdata);
      this.CustomData = this.custom = customdata;

      instance.annotManager.redrawAnnotation(this as unknown as Annotations.Annotation);
      instance.annotManager.trigger('annotationChanged', [[this], 'modify', { imported: false, isUndoRedo: false }]);

      return this;
    }

    setIsRequired(isRequired: boolean) {
      return setIsRequired(isRequired, this, instance);
    }

    setDateFormat(dateFormat: any) {
      return setDateFormat(this, dateFormat, instance);
    }


    setStateFormat(stateFormat: any, signer: any) {
      return setStateFormat(this, stateFormat, signer, instance);
    }

    setAddressFormat(addressFormat: any, signer: any) {
      return setAddressFormat(this, addressFormat, signer, instance);
    }
  };


  _.chain(methods)
    .toPairs()
    .forEach(([name, fn]) => {
      C.prototype[name] = fn({ instance, annotClasses, ...rest });
    })
    .value();


  // C.prototype.elementName = className;
  Object.defineProperty(C, 'name', { value: className });

  return {
    ...rest,
    instance,
    annotClasses: {
      ...annotClasses,
      [className]: C,
    },
  };
};

export default defineAnnotClass;
