import React, { useState, useEffect, useCallback } from 'react';
import { supportsTouchEvents } from 'detect-it';
import { useFlags } from 'document-viewer/src/lib/hooks/useFlags';
import { useWebViewer } from 'document-viewer/src/lib/hooks/useWebViewer';
import useAnnots from 'document-viewer/src/lib/hooks/useAnnots';
import { scrollToAnnot, scrollToBottomOf } from 'document-viewer/src/utils/cruiseControlScroll';
import Snackbar from 'document-viewer/src/components/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useAutoScrollSpeed } from 'document-viewer/src/components/AutoScrollSpeed/useAutoScrollSpeed';
import { useEvent } from 'react-use'
import SwitchParticipant from 'document-viewer/src/components/SwitchParticipant';
import { selector as requestDataSelector } from 'document-viewer/src/slices/request-data';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'document-viewer/src/store';
import { useParticipants } from 'document-viewer/src/lib/hooks/useParticipants';
import useAuth from 'document-viewer/src/lib/hooks/useAuth';
import { Document } from 'document-viewer/src/utils/types/document';
import { Participant } from 'document-viewer/src/utils/types/participant';
import { isIOSDevice } from 'document-viewer/src/utils/agents';

// TODO: see if we can move into webviewer and trigger via docViewer.trigger('scrollToAnnot') or something
export default function useAutoSign(
  selectedDoc: Document,
  unreviewedTags: any[],
  participants: Participant[],
  currentParticipantId: string,
  switchParticipant: (p: Participant) => void,
) {
  const flags = useFlags();
  const { annotManager, docViewer, zoomLevel, instance } = useWebViewer();
  const { unreviewedTagsForOtherParticipants } = useAnnots();
  const { currenAuthenticationId } = useAuth();
  const { pendingSelection, selectedParticipantId } = useParticipants();
  const [autoSigning, _setAutoSigning] = useState(false);
  const [showCanceledAlert, setShowCanceledAlert] = useState(false);
  const [showFreeTextAlert, setShowFreeTextAlert] = useState(false);
  const { scrollPxPerSec, setIsAutoScrolling } = useAutoScrollSpeed();
  const [showSwitchParticipant, setShowSwitchParticipant] = useState(false); // Used after you finish auto scrolling'
  const requestData = useSelector(createSelector(requestDataSelector, (state) => state.data || {}));
  const [originalZoomLevel, setOriginalZoomLevel] = useState(zoomLevel);
  const [isLastUnreviewedTagTypeFreeFormText, setIsLastUnreviewedTagTypeFreeFormText] = useState(false);

  // Toggling autosign also toggles autoscrolling
  const setAutoSigning = useCallback((value: boolean): boolean => {
    _setAutoSigning(value);
    setIsAutoScrolling(value);
    if (docViewer?.trigger && !value) {
      docViewer.trigger('clearPointer');
    }
    if (value) {
      setOriginalZoomLevel(() => zoomLevel)
    }
    return value;
  }, [docViewer, setIsAutoScrolling]);

  useEvent('wheel', () => {
    setAutoSigning(false);
    if (autoSigning) {
      setShowCanceledAlert(true);
    }
  }, docViewer ? docViewer.getScrollViewElement() : window);

  useEffect(() => {
    if (instance) {
      instance.annotManager.on('annotationClicked', (annot) => {
        if (annot.Subject === 'FreeFormTemplateFreeTextAnnot' && isIOSDevice() && supportsTouchEvents) {
          setShowFreeTextAlert(true);
        }
      });
    }
  }, [instance]);

  // If you change docs or signers, cancel autosign
  useEffect(() => {
    setAutoSigning(false);
  }, [setAutoSigning, selectedDoc?.id, selectedParticipantId, pendingSelection, currenAuthenticationId]);

  // If you change docs mid-autoscroll, it could cause you to be able to switch participants, but that doesn't make any sense.
  useEffect(() => {
    setShowSwitchParticipant(false);
  }, [selectedDoc?.id]);

  // If autoSigning, sign the first tag available
  useEffect(() => {
    if (!autoSigning) return;
    if (isLastUnreviewedTagTypeFreeFormText) {
      // cancel the auto scroll is the last Unreviewed Tag Type was FREEFORMTEXTTAGTEMPLATEANNOTATION as it neds user intraction
      setIsLastUnreviewedTagTypeFreeFormText(false);
      setAutoSigning(false);
      setShowCanceledAlert(true);
      return;
    }
    if (!unreviewedTags.length) {
      const { promise, cancel } = scrollToBottomOf(docViewer.getScrollViewElement() as any, scrollPxPerSec, 0);
      promise.then(() => {
        const otherParticipantIds = participants.map(({ id }) => id).filter((id) => id !== currentParticipantId);
        const canOthersAutoSign = participants.length && unreviewedTagsForOtherParticipants.some((annot) => otherParticipantIds.includes(annot.CustomData?.signerId));
        if (canOthersAutoSign) {
          setShowSwitchParticipant(true);
        }
        setAutoSigning(false);
      }).catch(() => {
        console.log('Aborted scroll to bottom of the doc'); // eslint-disable-line
        if (originalZoomLevel === zoomLevel) {
          setAutoSigning(false);
        }
      });
      return cancel;
    }

    // Deselect the last tag that was selected (when you fill out tags, they can remain selected)
    annotManager.deselectAllAnnotations();

    let cleaningUp = false;
    const tag = unreviewedTags[0];
    const { promise, cancel } = scrollToAnnot(docViewer, tag, scrollPxPerSec);
    setIsAutoScrolling(true);
    promise
      .then(() => {
        const payload : any = { tag };

        if (isIOSDevice()) {
          payload.onApply = () => {
            if (tag.Subject === 'FreeFormTemplateFreeTextAnnot') {
              setShowFreeTextAlert(true);
            }
          };
        }

        docViewer.trigger('pointToAnnot', payload);

        if (tag.mk?.type === 'FREEFORMTEMPLATE') {
          setIsLastUnreviewedTagTypeFreeFormText(true);
        }

        if (tag.Subject === 'FreeFormTemplateFreeTextAnnot' && isIOSDevice() && supportsTouchEvents) {
          setShowFreeTextAlert(true);
        }
      })
      .catch(() => {
        // check if not cleaningUp and zoom level hasn't changed
        if (!cleaningUp && originalZoomLevel === zoomLevel) {
          setShowCanceledAlert(true);
          setAutoSigning(false);
        }
      });

    return () => {
      cleaningUp = true;
      docViewer.trigger('clearPointer'); // Also remove the pointer
      cancel();
    };
  }, [autoSigning, unreviewedTags, annotManager, docViewer, setAutoSigning, flags.autoSignDelay, scrollPxPerSec, setIsAutoScrolling, participants, currentParticipantId, unreviewedTagsForOtherParticipants, zoomLevel, originalZoomLevel]);

  return {
    autoSigning,
    setAutoSigning,
    autoScrollEnabled: requestData.signerAutoScrollEnabled,
    canStartAutoSigning: annotManager && unreviewedTags.length && !selectedDoc.readOnly,
    autoScrollComponents: (
      <>
        <Snackbar
          style={{ marginBottom: '4em' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={showCanceledAlert}
          autoHideDuration={5000}
          onClose={() => setShowCanceledAlert(false)}
        >
          <Alert
            onClose={() => setShowCanceledAlert(false)}
            severity='info'
          >
            AutoScroll was canceled
          </Alert>
        </Snackbar>
        <Snackbar
          style={{ marginBottom: '4em' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={showFreeTextAlert}
          autoHideDuration={5000}
          onClose={() => setShowFreeTextAlert(false)}
        >
          <Alert
            onClose={() => setShowFreeTextAlert(false)}
            severity='info'
          >
            Please double-click on the tag after applying it to enter your information.
          </Alert>
        </Snackbar>
        <SwitchParticipant
          open={showSwitchParticipant}
          onClose={() => setShowSwitchParticipant(false)}
          onSelect={(participant) => {
            docViewer.getScrollViewElement().scrollTo({
              left: docViewer.getScrollViewElement().scrollLeft,
              top: 0,
              behavior: 'smooth',
            });
            switchParticipant(participant);
            setShowSwitchParticipant(false);
          }}
          participants={participants}
          currentParticipantId={currentParticipantId}
          reason='Switch to another signer so they can autoscroll through the document as well'
        />
      </>
    )
  };
}
