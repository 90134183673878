import * as Yup from 'yup';
import moment from 'moment';

export default Yup
  .object()
  .shape({
    dob: Yup
      .date()
      .max(moment().subtract(1, 'days').toDate(), 'Date of birth is in the future')
      .required('Date of Birth is required')
      .typeError('Invalid Date!'),
    ssn: Yup.string()
      .test('len', 'Must be exactly 4 characters', (val) => (val && val.toString().length === 4) || (val === undefined))
      .matches(/^\d/, 'Only numbers'),
    address1: Yup
      .string()
      .required('Address is required'),
    address2: Yup.string(),
    city: Yup
      .string()
      .required('City is required'),
    state: Yup
      .string()
      .required('State is required'),
    zip: Yup
      .number()
      .required('Postal code is required'),
    phone: Yup.string(),

  });
