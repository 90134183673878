import React, { FunctionComponent } from 'react';
import { OneKbaAttemptLeftWarningProps } from './types';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from 'document-viewer/src/components/Dialog';
import { Button } from '@material-ui/core';
import { useStyles } from './styles';

const OneKbaAttemptLeftWarning: FunctionComponent<OneKbaAttemptLeftWarningProps> = ({ open, onClose }) => {
  const classes = useStyles();
  if (!open) return null;
  return (
    <div data-testid='OneKbaAttemptLeftWarning'>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onClose()
          }
        }}
        size='sm'
      >
        <DialogTitle>Warning!</DialogTitle>
        <DialogContent className={classes.warningText}>
          <DialogContentText>
            You have
            {' '}
            <strong>one more attempt left</strong>
            {' '}
            <wbr />
            {' '}
            before your session will end.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            onClick={onClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OneKbaAttemptLeftWarning;
