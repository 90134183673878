import { useState } from 'react';
import useTransaction from './useTransaction';
import { useSubscription } from '@apollo/client';
import { TRANSACTION_UPDATES } from 'document-viewer/src/lib/gql/transaction';
import { omit } from '@enotarylog/ramda';

function useTransactionUpdates(transactionId: string) {
  const { updateTransaction } = useTransaction();
  const [subscribedTo, setSubscribedTo] = useState(transactionId);
  useSubscription(TRANSACTION_UPDATES, {
    variables: { transactionId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data?.transactionUpdated) {
        updateTransaction(omit(['__typename'], data.transactionUpdated));
      }
    },
    skip: !transactionId,
    shouldResubscribe: ({ variables: { transactionId } }) => {
      if (!!transactionId && transactionId !== subscribedTo) {
        setSubscribedTo(transactionId)
        return true;
      }
      return false;
    },
  });

}

export default useTransactionUpdates;
