import React, { useEffect, useState } from 'react';
import { AuthProps, TwoFactorCodeTypes } from './types';
import TwoFactorAuth from './challenges/TwoFactorAuth';
import PersonalPassword from './challenges/PersonalPassword';
import NoVerification from './challenges/NoVerification';
import { Dialog, DialogTitle } from 'document-viewer/src/components/Dialog';
import { selector as participantsSelector, ParticipantsState } from 'document-viewer/src/slices/participants';
import { selector as authSelector, AuthenticationState, clearAuthenticating, verifyPersonalPassword, setCurrentProcess, sendVerificationCode, verifyCode } from 'document-viewer/src/slices/authentication';
import { useDispatch, useSelector } from 'document-viewer/src/store';

import { createSelector } from '@reduxjs/toolkit';
import { AuthenticationTypes } from 'document-viewer/src/utils/types/participant';
import { useParticipants } from 'document-viewer/src/lib/hooks/useParticipants';
import { useLocalStorage } from 'react-use';
import { Box, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useTheme } from 'document-viewer/src/lib/hooks/useTheme';
import { useQuery } from 'document-viewer/src/lib/hooks/useQuery';

const allParticipantsSel = createSelector(participantsSelector, (state: ParticipantsState) => state.participants.byId);
const authingSel = createSelector(authSelector, (state: AuthenticationState) => state.currentAuthentication);

export default function Auth({ sessionId, children = null, participantsCannotAct }: AuthProps) {

  const query = useQuery();
  const dispatch = useDispatch();
  const { signatureAndInitialsInOneStep } = useTheme();
  const classes = useStyles();

  const [tcAgreed, setLocal] = useLocalStorage('tcAgreed', {});
  const loading = useSelector(createSelector(authSelector, (state: AuthenticationState) => state.loading));
  const currentProcess = useSelector(createSelector(authSelector, (state: AuthenticationState) => state.currentProcess));

  // the participant thats authenticating
  const participant = useSelector(
    createSelector(
      allParticipantsSel,
      authingSel,
      (participants, currentAuth) => participants[currentAuth]
    )
  )

  const {
    setPendingSelection,
    agreeToLegalStuff,
  } = useParticipants();


  const [totalAttempts, setTotalAttempts] = useState(+process.env.NX_MAX_AUTH_ATTEMPTS);

  // if user auth type is 'none' and has already accepted t&c then dismiss modal
  useEffect(() => {
    if (
      participant?.authenticationType === AuthenticationTypes.None
      && (tcAgreed[participant?.id] || signatureAndInitialsInOneStep)
    ) {
      setPendingSelection(participant.id);
      dispatch(clearAuthenticating());
    }
  }, [dispatch, participant?.authenticationType, participant?.id, setPendingSelection, signatureAndInitialsInOneStep, tcAgreed])

  const handleAttemptsCallBack = (attempts: number) => {
    setTotalAttempts(attempts);
  }
  let verification = null;

  if (
    participant?.authenticationType === AuthenticationTypes.None
    && !tcAgreed[participant?.id]
    && !signatureAndInitialsInOneStep
  ) {
    verification = (
      <NoVerification
        name={participant.firstName}
        onComplete={() => {
          agreeToLegalStuff(participant.id, () => {
            setPendingSelection(participant.id);
            setLocal({
              ...tcAgreed,
              [participant.id]: true
            })
            dispatch(clearAuthenticating());
          });
        }}
      />
    );
  } else if (participant?.authenticationType === AuthenticationTypes.TwoFactorAuth) {
    verification = (
      <TwoFactorAuth
        signatureAndInitialsInOneStep={signatureAndInitialsInOneStep}
        name={participant.firstName}
        phone={participant.phone}
        email={participant.email}
        codeType={participant.twoFactorContact}
        sendCode={(type) => {
          dispatch(sendVerificationCode(sessionId, participant.id, type))
        }}
        currentProcess={currentProcess}
        checking={loading}
        checkCodeValid={async (code) => {
          await dispatch(verifyCode(sessionId, code, {
            organizationId: query.org,
            participantIds: query.participant,
            sessionId: query.session,
            iframeId: query.iframe,
          }))
        }}
        participant={participant}
        handleAttemptsCallBack={handleAttemptsCallBack}
      />
    );
  } else if (participant?.authenticationType === AuthenticationTypes.PersonalPassword) {
    verification = (
      <PersonalPassword
        signatureAndInitialsInOneStep={signatureAndInitialsInOneStep}
        email={participant.email}
        personalPasswordQuestion={participant.personalPasswordQuestion}
        sendAnswer={async (answer) => {
          await dispatch(verifyPersonalPassword(participant.id, answer, {
            organizationId: query.org,
            participantIds: query.participant,
            sessionId: query.session,
            iframeId: query.iframe,
          }));
        }}
        currentProcess={currentProcess}
        setCurrentProcess={(val) => dispatch(setCurrentProcess(val))}
        checking={loading}
        participant={participant}
      />
    );

  }

  return (
    <>
      {
        verification && (
          <Dialog
            open={!!participant && !participantsCannotAct}
            // onClose={() => dispatch(clearAuthenticating())}
            onClose= {() => {}}
            size='sm'
            scroll='paper'
            classes={{
              scrollPaper: classes.scrollPaper,
              paper: classes.mobileContent,
            }}
          >
            <Grid
              container
              className={classes.container}
              alignContent='center'
            >
              <Grid
                item
                xs={12}
                className={classes.container}
                style={{ width:'100%' }}
              >
                {currentProcess === 'TRY_AGAIN' && totalAttempts < +process.env.NX_MAX_AUTH_ATTEMPTS &&
                  <DialogTitle className={classes.dialogTitle} >
                    <Box >
                      <Typography
                        variant='h5'
                        className={classes.dialogHeader}
                      >
                        {' '}
                        Warning! Attempt Failed
                      </Typography>
                    </Box>
                  </DialogTitle>}
                {verification}
              </Grid>
            </Grid>
          </Dialog>
        )
      }
      {children}
    </>
  );
}
