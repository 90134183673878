import React from 'react'
import { Button } from '@material-ui/core';



const DevButtons = ({
  onTestSeal,
  onTestFlatten,
  onPersistDoc
}) => (
  <>
    <Button
      color='primary'
      disabled={false}
      onClick={onTestSeal}
      variant='contained'
      style={{ minWidth: 200, marginBottom: 8 }}
    >
      Test Seal
    </Button>

    <Button
      color='primary'
      disabled={false}
      onClick={onTestFlatten}
      variant='contained'
      style={{ minWidth: 200, marginBottom: 8 }}
    >
      Test Flatten
    </Button>
    <Button
      color='primary'
      disabled={false}
      onClick={onPersistDoc}
      variant='contained'
      style={{ minWidth: 200, marginBottom: 8 }}
    >
      Persist Doc
    </Button>

  </>
)

export default DevButtons;
