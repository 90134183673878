import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from 'document-viewer/src/components/Tooltip';
import AutoSignDisclaimer from './AutoSignDisclaimer';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAutoSignStyles, BUTTON_MAX_FONT_SIZE_IN_PX } from './styles';
import { ComponentProps } from './types';
import clsx from 'clsx';
import { Textfit } from 'react-textfit';
import { getTotalParticipantTags } from './../../utils/participantCompletedDocument';


export const TopButtonUi = ({
  otherParticipantsHaveUnreviewedTags,
  checkForIncompleteDocs,
  goToIncompleteDoc,
  markNoTagsDocRead,
  currentParticipantId,
  hasBeenCompleted,
  onCompleteDoc,
  onSwitchParticipant,
  myUnreviewedTags,
  hasUnsavedChanges,
  autoSign,
  documentLoading,
  customTags,
  lockErrorModal,
  isReadOnlyDoc,
  selectedDoc,
  loadingThumbnails
}: ComponentProps) => {
  const { autoSigning, setAutoSigning, canStartAutoSigning, autoScrollComponents, autoScrollEnabled } = autoSign;
  const classes = useAutoSignStyles();
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [runningButtonFunction, setRunningButtonFunction] = useState(false);
  const [hasShownDisclaimer, setHasShownDisclaimer] = useState(false);
  const [editCheck, setEditCheck] = useState(lockErrorModal ? true : false );
  // isCompletingDoc prevents you from trying to complete a doc twice, and forces you to wait until the new doc loads
  const [isCompletingDoc, setIsCompletingDoc] = useState(false);
  const completeDocAndStopAutoSign = useCallback(async () => {
    if(!isCompletingDoc && lockErrorModal){
      setEditCheck(true);
    } 
    if (!isCompletingDoc && !lockErrorModal) {
      setIsCompletingDoc(true);
      setAutoSigning(false);
      await onCompleteDoc();
    }
  }, [isCompletingDoc, onCompleteDoc, setAutoSigning, lockErrorModal]);
  // When a new document is loading, then you can try hitting complete again
  useEffect(() => setIsCompletingDoc(false), [documentLoading]);

  // This button can be one of the following:
  // 1. START auto sign
  // 2. STOP auto sign
  // 3. Inform the user that they cannot auto sign because canStartAutoSigning is false
  // 4a. Let the user switch to a different user because they've finished signing all their tags on this doc, but another participant on this device has not
  // 4b. Let the user switch to a signer because no signer is selected
  // 5. Complete the doc
  // 6. Let the user know that they finished signing and can move on to another doc
  // 7. Inform the user that they have finished signing and completed all the docs
  // 8. If the user can't even autoscroll but we would normally show them the autoscroll button, then hide the button by emptying out the buttonText
  // 9. Read only docs should say "View Only"
  // 10. If all the signers on this device have completed a document, but the document isn't complete because other signers on different devices haven't completed their parts of the doc, then it should tell these signers "View only"
  // FIXME: this is rendering too many times. fix
  const { toolTipLabel, buttonText, buttonFunction } = useMemo(() => {
    let buttonText = 'Get Started';
    let toolTipLabel = '';
    let buttonFunction;
    
    if (isReadOnlyDoc && (getTotalParticipantTags(selectedDoc, currentParticipantId) === 0 )) {      
      
      if(!hasBeenCompleted) {
        buttonText = checkForIncompleteDocs()
          ? 'NEXT DOCUMENT'
          : 'Complete';
        if(!documentLoading && !loadingThumbnails) {
          buttonFunction = markNoTagsDocRead;
        }
        
      } else { 

        if(otherParticipantsHaveUnreviewedTags) {
          buttonText = 'Select signer';
          if(!documentLoading && !loadingThumbnails) {
            buttonFunction = onSwitchParticipant;
          }
        } else {
          buttonText = 'Done signing';
        }

      }

    } else if (isReadOnlyDoc) {
      // 9.
      buttonText = 'View only';
      toolTipLabel = 'There\'s nothing for you to do on this document';
    } else if (myUnreviewedTags.length === 0 || documentLoading) {
      if (hasBeenCompleted) {
        if (checkForIncompleteDocs()) {
          if (!currentParticipantId) {
            buttonText = 'Select signer';
            buttonFunction = onSwitchParticipant;
          } else {
            // 6.
            buttonText = 'Back to signing';
            if(!documentLoading && !loadingThumbnails) {
              buttonFunction = goToIncompleteDoc
            }
          }
        } else {
          // 7.
          buttonText = 'Done signing';
        }
      } else if (otherParticipantsHaveUnreviewedTags) {
        if (currentParticipantId) {
          // 4a.
          buttonText = 'Select signer';
        } else {
          // 4b.
          buttonText = 'Start Signing';
        }
        if(!documentLoading && !loadingThumbnails) {
          buttonFunction = onSwitchParticipant;
        }
      } else if (documentLoading) {
        buttonText = 'Document loading';
        toolTipLabel = 'The document is loading, please wait a moment.';
      } else if (hasUnsavedChanges) {
        // 5.
        buttonText = checkForIncompleteDocs()
          ? 'Next Document'
          : 'Complete';
        toolTipLabel =
          'Save all your changes and move on to the Next Document. Others will see your changes, and you won\'t be able to undo them or add any further changes';
        if(!documentLoading && !loadingThumbnails) {          
          buttonFunction = completeDocAndStopAutoSign;
        }
      } else {
        // 10.
        buttonText = 'View only';
        toolTipLabel = 'There\'s nothing for you to do on this document';
      }
    } else if (autoSigning) {
      // 2.
      buttonText = 'Stop Autoscroll';
      toolTipLabel = 'Pause this process';
      if(!documentLoading && !loadingThumbnails) {
        buttonFunction = () => setAutoSigning(false);
      }
    } else if (!autoScrollEnabled) {
      // 8.
      buttonText = '';
    } else if (canStartAutoSigning) {
      // 1.
      toolTipLabel = 'Start signing, initialling, and dating this document';
      if(!documentLoading && !loadingThumbnails) {
        buttonFunction = () => {
          // show disclaimer only once per page load; the first time "auto scroll" is clicked
          if (hasShownDisclaimer) {
            return setAutoSigning(true);
          }
          setShowDisclaimer(true);
        };
      }
    } else {
      // 3.
      toolTipLabel = 'There are things that must be filled out before signing';
    }

    return {
      toolTipLabel,
      buttonText,
      buttonFunction:
        buttonFunction &&
        (async () => {
          setRunningButtonFunction(true);
          try {
            await buttonFunction();
          } finally {
            setRunningButtonFunction(false);
          }
        })
    };
  }, [isReadOnlyDoc, myUnreviewedTags.length, documentLoading, autoSigning, autoScrollEnabled, canStartAutoSigning, hasBeenCompleted, otherParticipantsHaveUnreviewedTags, hasUnsavedChanges, checkForIncompleteDocs, goToIncompleteDoc, currentParticipantId, onSwitchParticipant, completeDocAndStopAutoSign, setAutoSigning, hasShownDisclaimer]);

  return (
    <>
      {editCheck && lockErrorModal}
      <Tooltip title={toolTipLabel}>
        <span>
          <Button
            variant='contained'
            color={autoSigning ? 'secondary' : 'primary'}
            classes={{
              label: classes.buttonText,
              root: clsx(classes.buttonRoot, {
                [classes.invisible]: !buttonText,
              }),
            }}
            onClick={buttonFunction || (() => { console.log("Button does nothing.") })}
            disabled={!buttonFunction || runningButtonFunction}
          >
            <Textfit
              mode='single'
              forceSingleModeWidth={true}
              className={classes.textFit}
              max={BUTTON_MAX_FONT_SIZE_IN_PX}
            >
              {buttonText}
            </Textfit>
            {(runningButtonFunction || documentLoading) && (
              <CircularProgress
                size='1em'
                color='inherit'
              />
            )}
          </Button>
        </span>
      </Tooltip>
      {autoScrollComponents}
      <AutoSignDisclaimer
        open={showDisclaimer}
        customTags={customTags}
        onClose={() => {
          setShowDisclaimer(false)
        }}
        onAccept={() => {
          setHasShownDisclaimer(true);
          setAutoSigning(true);
          setShowDisclaimer(false);
        }}
      />
    </>
  );
}


export default TopButtonUi;
