import * as R from 'ramda';
import enableToolButtons from './initializers/enableToolButtons';
import enablePopups from './initializers/enablePopups';
import enableContextMenuItems from './initializers/enableContextMenuItems';
import registerTools from './tools';
import registerPopups from './popups';
import { injectToolArr } from './initializers/injectors';
import registerPanels from './panels';
import handleTemplateDblClick from './eventListeners/templateDblClick';
import enablePanels from './initializers/enablePanels';
import disableHotkeys from './initializers/disableHotkeys';
import { signatureReset, pointToAnnot } from './eventListeners';

export default R.pipeP(
  injectToolArr,

  registerTools,
  registerPopups,
  registerPanels,

  enablePopups,
  enableToolButtons,
  enablePanels,
  enableContextMenuItems,
  disableHotkeys,
  handleTemplateDblClick(),
  // @ts-ignore
  signatureReset(),
  pointToAnnot(),
);
