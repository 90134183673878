import { useMutation } from '@apollo/client';
import { ADD_AUDIT } from 'document-viewer/src/lib/gql/audit';
import { useCallback, useRef } from 'react';
import { useSessionStatus } from './useSessionStatus';

export const useAuditTrail = (participantId, docId) => {
  const [emitAuditEvent] = useMutation(ADD_AUDIT);
  const { value: { transactionId, orgId: organizationId } } = useSessionStatus();

  // A reference to an old useCallback may be in use, so refs must be used to ensure that the callback doesn't use the wrong data
  const participantIdRef = useRef(participantId);
  participantIdRef.current = participantId;
  const docIdRef = useRef(docId);
  docIdRef.current = docId;

  return {
    onAuditTrail: useCallback((data) => {
      if (!participantIdRef.current) {
        // eslint-disable-next-line no-console
        console.error('YOU CANNOT DO THIS THIS EARLY! MISSING PARTICIPANT ID!', data);
        return;
      }
      emitAuditEvent({
        variables: {
          participantId: participantIdRef.current,
          docId: docIdRef.current,
          data: { ...data.data, transactionId, organizationId },
          event: data.type
        }
      });
    }, [emitAuditEvent, transactionId, organizationId]),
  };
}
