// From backends/transaction/src/document/utils/enums/DocStatus.ts
export enum DocStatus {
  pending = 'pending',
  inProgress = 'in_progress',
  ready = 'ready',
  completed = 'completed',
  taggingStarted = 'tagging_started',
  taggingQaReady = 'tagging_qa_ready',
  taggingApproved = 'tagging_approved',
  taggingRequested = 'tagging_requested',
}

export const docIsCompleted = (doc) => doc.status === DocStatus.completed;
export function docHasBeenCompletedBy(participantId: string, doc) {
  if (docIsCompleted(doc)) return true; // Performance short-cut: if the doc is completed, then it has been completed by any participant you provide

  return doc.revisions.some((rev) => rev.completed && rev.participantId === participantId);
}
