import React, { useState, useEffect, useCallback } from 'react';
import { Drawer } from '@material-ui/core';
import { } from '@material-ui/icons';
import DocumentList from './DocumentList';
import { useWebViewer } from 'document-viewer/src/lib/hooks/useWebViewer';
import SaveAndSwitchToDocModal from './SaveAndSwitchToDocModal';
import { useLeftPanelStyles } from './styles';
import PanelToggle from './PanelToggle';
import { useDocs } from 'document-viewer/src/lib/hooks/useDocs';
import useAnnots from 'document-viewer/src/lib/hooks/useAnnots';
import { useRequestData } from 'document-viewer/src/lib/hooks/useRequestData';
import { DocStatus } from 'document-viewer/src/utils/pdftron/docCompletion';


const LeftPanel = ({ panelOpen, drawerWidth, isMobile, toggleHandler, isEsign, setLoadingThumbnails, completedNoTagDocList }) => {
  const { docViewer, instance } = useWebViewer();

  const classes = useLeftPanelStyles({ panelOpen, isMobile });

  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showSaveAndSwitchToDoc, setShowSaveAndSwitchToDoc] = useState<string | null>(null);

  const {
    docs,
    documentLocked,
    setSelectedDoc,
    selectedDoc,
    saveDoc,
  } = useDocs();


  const { appliedAnnots, clearAppliedAnnots } = useAnnots();

  useEffect(() => {
    const handleSet = () => {
      setCurrentPage(1);
      setNumPages(docViewer.getPageCount());
    };
    if (docViewer) {
      const doc = docViewer.getDocument();
      docViewer.on('documentLoaded', handleSet)

      // if doc has already loaded when we attach event-listener, set the page count here
      if (doc) {
        setCurrentPage(1);
        setNumPages(docViewer.getPageCount());
      }
      return () => docViewer.off('documentLoaded', handleSet);
    }
  }, [docViewer]);



  useEffect(() => {
    if (!docViewer) return;

    docViewer.on('pageNumberUpdated', setCurrentPage);

    // const eventsThatChangePageCount = ['documentLoaded', 'blankPagesAdded', 'blankPagesRemoved'];
    // eventsThatChangePageCount.forEach((e) => docViewer.on(e, updatePageCount));

    return () => {
      docViewer.off('pageNumberUpdated', setCurrentPage);
      // eventsThatChangePageCount.forEach((e) => docViewer.off(e, updatePageCount));
    };
  }, [docViewer]);

  const { requestData } = useRequestData();
  const saveThisDoc = useCallback(() => saveDoc(selectedDoc), [saveDoc, selectedDoc]);
  const switchToDoc = useCallback(() => {
    setSelectedDoc(showSaveAndSwitchToDoc);
    clearAppliedAnnots(selectedDoc);
  }, [clearAppliedAnnots, selectedDoc, setSelectedDoc, showSaveAndSwitchToDoc]);

  return (
    <>
      <PanelToggle
        isOpen={panelOpen}
        direction='left'
        drawerWidth={drawerWidth}
        handler={toggleHandler}
        showToggle={isMobile}
        sideBarOpenCloseStyling={requestData.sideBarOpenCloseStyling}
      />
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={panelOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <SaveAndSwitchToDocModal
          show={!!showSaveAndSwitchToDoc}
          onClose={() => setShowSaveAndSwitchToDoc(null)}
          saveDoc={saveThisDoc}
          switchToDoc={switchToDoc}
        />

        {
          instance && (
            <DocumentList
              completedNoTagDocList={completedNoTagDocList}
              setLoadingThumbnails={setLoadingThumbnails}
              docs={docs}
              currentDocId={selectedDoc}
              currentPageIndex={currentPage - 1}
              numPages={numPages}
              onPageSelected={(pageIndex) => docViewer.setCurrentPage(pageIndex + 1)}
              onDocSelected={(docId) => {}}
            />
          )
        }
        {/* {
          _.chain(currentDocThumbnails)
            .values()
            .map((val, i) => {
              return (
                <ThumbnailImage
                  index={i}
                  image={val.page}
                  style={{}}
                />
              )
            })
            .value()

        } */}

      </Drawer>
    </>
  );
};

export default LeftPanel;
