import { useCallback, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useWebViewer } from './useWebViewer';
import { SIGN_DOC } from 'document-viewer/src/lib/gql/document';
import { useQuery } from './useQuery';
import { useParticipants } from './useParticipants';
import { useHistory } from 'react-router';

// The basic idea is that you call the function returned by this and it saves the annots on the doc using the signDoc mutation.
// One of the complexities here is that when you try to signDoc, it's possible that you aren't on the latest revision.
// If that happens, you have to wait for appState to be updated so that you can grab the latest revision, and then you can retry.
// The only way to wait for appState to be updated is to wait for the next time this function is run, which is why actual callbacks are used here
export default function useSaveDoc() {

  const [signDocMutation] = useMutation(SIGN_DOC);
  const { selectedParticipantId } = useParticipants();
  const { instance, annotManager } = useWebViewer();
  const { session: sessionId } = useQuery();
  const retriesLeft = useRef<number>(null); // If calling the function fails, then this will be set to NUM_RETRIES
  // sealedDocChanges is true only if you call saveDoc() and it seals the doc.
  // If a doc has already been sealed by someone else, or even by you but since then you refreshed or switched docs, then sealedDocChanges will be false.
  const [sealedDocChanges, setSealedDocChanges] = useState(false);

  // If you switch to a different doc, then sealedDocChanges must become false because you haven't called saveDoc on it yet
  // useEffect(() => {
  //   setSealedDocChanges(false)
  // }, [docId]);
  const history = useHistory();

  const saveDoc = useCallback(async (doc = {}, participantIds, completedBy, isEsign) => {
    const { revisions } = doc;

    const payload: any = {
      sessionId,
      // The payload that will be submitted. Almost ready, it's just missing the xfdf
      participantIds,
      docId: instance.getDocId(),
      completedBy,
      basedOnVersion: revisions[revisions.length - 1].version,
      // Now that only executed tags are visible, export the annotations.
      // ALL of them, not just the visible ones; unexecuted tags must remain so that they can end up being executed
      xfdf: await annotManager.exportAnnotations(),
      isEsign,
      selectedParticipantId,
    };

    // Now that only executed tags are visible, export the annotations.
    // ALL of them, not just the visible ones; unexecuted tags must remain so that they can end up being executed
    payload.xfdf = await annotManager.exportAnnotations();
    // Now show the annotations that were just hidden - unless the docId changed
    // if (visibleAnnotsWithoutCorrIds.length > 0 && payload.docId === instance.getDocId()) {
    //   annotManager.showAnnotations(visibleAnnotsWithoutCorrIds);
    // }

    // Prevent this function from retrying while trying to run the mutation
    const prevNumRetries = retriesLeft.current;
    retriesLeft.current = 0;

    // Finally run the mutation
    try {
      const { data } = await signDocMutation({ variables: { payload } });
      setSealedDocChanges(completedBy.length > 0);
      return data;
    } catch (err) {
      console.error(err)
      retriesLeft.current = prevNumRetries - 1;
      if (err.message === 'Request failed with status code 410') {
        history.push('/session/canceled')
      }
    }
  }, [annotManager, instance, selectedParticipantId, sessionId, signDocMutation]);

  if (retriesLeft.current) {
    // _saveDoc(callbackForRetries.current);
  }


  return {
    sealedDocChanges,
    saveDoc
  };
}
