import { makeStyles, Theme } from '@material-ui/core';

export const useSignatureInitialsModalStyle = makeStyles(() => ({
  signatureTop: {
    padding: 3,
    borderRadius: '8px 8px 0 0',
    textAlign: 'center',
    marginBottom: 2
  },
  signatureBottom: {
    padding: 16,
    justifyContent: 'center',
    borderRadius: '0 0 8px 8px',
  },
  signatureCommon: {
    boxShadow: '0 4px 8px rgba(0,0,0,.3)',
    backgroundColor: '#fff',
  },
  sigMargin: { marginTop: 20 },

}));


export const useImageStyles = makeStyles((theme: Theme) => ({
  listImg: {
    maxWidth: '100%',
    maxHeight: '2rem',
  },
  Button: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': { backgroundColor: theme.palette.primary.dark },
  },
  label: {
    color: 'white',
  },
}))
