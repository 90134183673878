import { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import { parseName } from '@enotarylog/tag-utils/helpers/parseName';
import useOrientation from 'document-viewer/src/lib/hooks/useOrientation';
import useIsMobileScreen from 'document-viewer/src/lib/hooks/useIsMobileScreen';
import OneKbaAttemptLeftWarning from './OneKbaAttemptLeftWarning';
import Form from './Form';
import TimerDisplay from './TimerDisplay';
import { ComponentProps } from './types';
import { useStyles } from './styles';

const KbaQuestionsComponent: FunctionComponent<ComponentProps> = ({
  hasCompleteKbaResponse,
  participant,
  questions,
  loadingQuestions,
  submittingAnswers,
  onSubmit,
  attempts,
  onTimeout,
  msBeforeTimeout,
  renderButton,
  participantsLoading,
  onLastAttemptAck,
  lastKbaRequestWasConfirmQuestion,
  submitClicked,
  setLastAttempt,
  isChallengeQuestion,
  setIsChallengeQuestion,
}) => {
  const classes = useStyles();

  const isLandscape = useOrientation();
  const isMobileScreen = useIsMobileScreen();

  // If you submit answers and then you go down to 1 attempt, then you should get a warning
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [kbaAttempts, setKbaAttempts] = useState(participant.kbaAttempts);
  const [resetTimerPostFix, setResetTimerPostFix] = useState(Math.random().toString());
  const [showOneKbaAttemptLeftAcknowledged, setShowOneKbaAttemptLeftAcknowledged] = useState(false);
  const [timerWait, setTimerWait] = useState(false);
  const [challengeDone,setChallengeDone] = useState(false);
  // If you run out of time while submitting answers, then wait until after the answers have been submitted to say that you ran out of time
  const [ranOutOfTime, setRanOutOfTime] = useState(false);
  // const [isChallengeQuestion, setIsChallengeQuestion] = useState(false);

  const [callSetStarted, setCallSetStarted] = useState(false);

  const modalVisible = useMemo(() => participant?.status === 'pending_kba_verify', [participant]);
  // this will maintain a 'contacting server' state across multiple calls to avoid flickers to UX
  const serverCallsRunning = useMemo(() => {

    if (submittingAnswers && !callSetStarted) {
      setCallSetStarted(true);
      return true;
    }

    if (showOneKbaAttemptLeftAcknowledged && callSetStarted && (kbaAttempts === 1 || attempts === 1)) {
      setCallSetStarted(false);
      return false;
    }

    if (participantsLoading && callSetStarted) {
      setCallSetStarted(false);
      return true;
    }

    if (showOneKbaAttemptLeftAcknowledged && callSetStarted) {
      return participant.kbaAttempts < 1 ? false : loadingQuestions;
    }

    return participantsLoading || submittingAnswers || loadingQuestions;

  }, [participantsLoading, submittingAnswers, loadingQuestions, showOneKbaAttemptLeftAcknowledged, participant]);


  const timerEnabled = useMemo(() => {
    if (submittingAnswers || loadingQuestions || participantsLoading) {
      return false;
    }

    return true;

  }, [showOneKbaAttemptLeftAcknowledged, hasSubmitted, kbaAttempts, attempts, submittingAnswers])

  useEffect(() => {
    // challenge KBA logic
    if(questions.length === 1 ){
      setIsChallengeQuestion(true)
    }

    if (submittingAnswers && submitClicked && questions.length === 1) {
      setTimerWait(true);
      setChallengeDone(true)
      // localStorage.removeItem("totalTimer"); // commenting localStorage as timer Refresh functionality is not required.
    }
    if(ranOutOfTime && !submittingAnswers && questions.length === 1) {
      setTimerWait(true);
      setChallengeDone(true)
      // localStorage.removeItem("totalTimer");
    }

    if(ranOutOfTime && !submittingAnswers && kbaAttempts === 2 && questions.length=== 1){
      onSubmit({}, isChallengeQuestion);
      setHasSubmitted(true);
      setRanOutOfTime(false);
      setTimerWait(true);
    }

    // KBA
    if (ranOutOfTime && !submittingAnswers) {
      onTimeout();
    }

    if (ranOutOfTime && !submittingAnswers && kbaAttempts === 2 && questions.length > 1) {
      onSubmit({}, isChallengeQuestion);
      setHasSubmitted(true);
      setRanOutOfTime(false);
      setKbaAttempts(1);
      setTimerWait(true);
    }
    if (ranOutOfTime && !submittingAnswers && kbaAttempts === 1) {
      onSubmit({}, isChallengeQuestion);
      setHasSubmitted(true);
      setRanOutOfTime(false);
      setKbaAttempts(0);
      setTimerWait(true);
    }

    if (submittingAnswers && kbaAttempts === 2 && questions.length > 1 && !lastKbaRequestWasConfirmQuestion && submitClicked) {
      setKbaAttempts(1);
      // localStorage.removeItem("totalTimer");
    }

    if(!submittingAnswers && kbaAttempts === 2 && challengeDone) {
      setTimerWait(false);
      setChallengeDone(false);
      setIsChallengeQuestion(false);
    }

  }, [onTimeout, ranOutOfTime, submittingAnswers, showOneKbaAttemptLeftAcknowledged, kbaAttempts]);

  return (
    <>
      <div className={classes.kbaContainer}>
        <Typography
          variant='h4'
          align='center'
        >
          {parseName(participant)}
        </Typography>
        <Box my={2}>
          <Typography align='center'>
            Answer the below questions in the next
            {' '}
            {msBeforeTimeout / 60000}
            {' '}
            minutes so we are able to identify you. If you don&apos;t answer 80% correct you will have one more attempt before a 24-hour lock out.
          </Typography>
        </Box>

        <Form
          {...{ questions, loadingQuestions: (loadingQuestions || serverCallsRunning), submittingAnswers }}
          onSubmit={(result) => {
            setTimerWait(true);
            setHasSubmitted(true);
            setRanOutOfTime(false);
            onSubmit(result, isChallengeQuestion);
          }}
          renderButton={renderButton}
        />

        {(kbaAttempts === 1 || hasCompleteKbaResponse) &&
          <OneKbaAttemptLeftWarning
            open={!showOneKbaAttemptLeftAcknowledged && hasSubmitted && !submittingAnswers}
            onClose={() => {
              // localStorage.removeItem("totalTimer");
              setLastAttempt(true)
              onLastAttemptAck();
              setShowOneKbaAttemptLeftAcknowledged(true);
              setTimerWait(false);
            }}
          />}
      </div>

      <div
        className={clsx({
          [classes.countdownWrapper]: true,
          [classes.countdownSticky]: !isMobileScreen || isLandscape,
        })}
      >
        {!timerWait ?
          <TimerDisplay
            key={resetTimerPostFix + kbaAttempts}
            resetTimer={() => {
              setResetTimerPostFix(Math.random().toString());
            }}
            paused={serverCallsRunning || !timerEnabled || timerWait}
            msBeforeTimeout={msBeforeTimeout}
            onTimeout={() => setRanOutOfTime(true)}
            questions={questions}
            kbaAttempts={kbaAttempts}
            setTimerWait={setTimerWait}
            onSubmit={onSubmit}
          /> : null}
      </div>
    </>
  );
};

export default KbaQuestionsComponent;

