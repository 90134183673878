import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { Router } from 'react-router-dom';
import { initialize } from 'launchdarkly-js-client-sdk';

import { FlagsProvider } from 'document-viewer/src/lib/hooks/useFlags';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import { initStore } from './store';
import { initApollo } from 'document-viewer/src/lib/clients/apollo';
import { createBrowserHistory } from 'history';


(async () => {
  const client = initialize(process.env.NX_LAUNCH_DARKLY_API_KEY, {
    key: 'anonymous'
  });
  await client.waitForInitialization();
  const { domains = [] } = client.variation('lucky-orange', {});

  const host = window.location.host;
  const found = domains.find((el) => host.includes(el));

  if (found) {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.setAttribute("src", '/lucky-orange.js');
    head.appendChild(script);

  }
})();

const history = createBrowserHistory();
const apollo = (window as unknown as any).client = initApollo();
const store = (window as unknown as any).store = initStore({
  extraArguments: apollo
});

ReactDOM.render(
  <Router history={history}>
    <ApolloProvider client={apollo}>
      <Provider store={store}>
        <FlagsProvider>
          <App />
        </FlagsProvider>
      </Provider>
    </ApolloProvider>
  </Router>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
