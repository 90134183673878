import React, { FC } from 'react';
import type { Theme } from '../theme/index';
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Box,
  Button,
  makeStyles,
  DialogContentText,
} from '@material-ui/core';
import { defaultTheme } from '../constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: { width: '100%', height: '100%', justifyContent: 'space-between', color: defaultTheme.dialogTextColor,
    "& Paper": {
      width: '600px'
    } },
  buttonAction: { float: 'right', minWidth: 125, margin: theme.spacing(2), marginRight: theme.spacing(1), },
  buttonActionCenter: { minWidth: 125, margin: '0 auto 10px' },
  floatLeft: { float: 'left', },
  floatRight: { float: 'right', },
  dialogTitle: { backgroundColor: defaultTheme.dialogTitle , padding: '32px 24px', color: defaultTheme.dialogTextColor },
  dialogHeader: { fontSize: '1rem', fontWeight: 500 },
  deletePadding: { padding: 1, backgroundColor : defaultTheme.headerBackground },
  paper: {
    width: '600px'
  },
  dialogContentClass: { backgroundColor : defaultTheme.headerBackground, },
  dialogContentText : { color: defaultTheme.dialogSecondaryText, fontSize: '.875rem', lineHeight: '1.4' }
}));

interface DialogButton {
  buttonTitle: string;
  buttonOnClick?(): void;
}

interface GenericDialogProps {
  title: string,
  description: string,
  dialogButtons: DialogButton[],
  dialogContentClass?: string
}

const GenericDialog: FC<GenericDialogProps> = ({ title ,description, dialogButtons,dialogContentClass }) => {

  const classes = useStyles();

  return (

    <>
      <DialogTitle className={classes.dialogTitle}>
        <Box
          display='flex'
          justifyContent='space-between'
        >
          <Typography 
            variant= 'h5' 
            className={classes.dialogHeader}
          >
            {title} 
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContentClass}>
        <DialogContentText 
          id='alert-dialog-description' 
          className={classes.dialogContentText} 
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        className={classes.deletePadding}
      >
        {
          dialogButtons && dialogButtons.length > 0 && dialogButtons.map((dialogButton, i) =>
            <Button
              key = {i}
              className={dialogButton.buttonTitle === 'REFRESH' ? classes.buttonActionCenter :classes.buttonAction}
              color='primary'
              variant='contained'
              autoFocus
              onClick={() => {
                dialogButton.buttonOnClick();
              }}
              data-testid='cancel-delete-button'
            >
              {dialogButton.buttonTitle}
            </Button>)
        }

      </DialogActions>
    </>

  );
}

export default GenericDialog;
export type { DialogButton };
