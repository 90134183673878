import React from 'react';

import * as R from 'ramda';
import { injectTool } from '../initializers/injectors';
import { InstanceObject } from '../../viewer';

export interface LockButtonProps {
  instance: any;
}

export interface LockButtonState {
  locked: boolean;
}


class LockButton extends React.Component<LockButtonProps, LockButtonState> {
  constructor(props: LockButtonProps) {
    super(props);

    this.state = { locked: false };
  }

  componentDidMount() {
    const { instance } = this.props;

    this.setState({ locked: instance.getLock() });


    instance.docViewer.on('lockChanged', (locked: boolean) => this.setState({ locked }));
  }

  render() {
    return (
      <div>
        <label>Lock</label>
        <input
          type='checkbox'
          checked={this.state.locked}
          onChange={(evt) => this.props.instance.docViewer.trigger('setLockStatus', [evt.target.checked])}
        />
      </div>
    );
  }
}


const showLockButton = ({ instance }: InstanceObject) => {
  return ({
    type: 'customElement',
    title: 'Lock Checkbox',
    dataElement: 'lockCheckbox',
    render: () => <LockButton instance={instance} />,
  });
};

const registerShowSigner = R.pipeP(
  injectTool('LockCheckbox', showLockButton)
);

export default registerShowSigner;
