import React, { useCallback, useEffect, useState } from 'react';
import { Button, Step, StepLabel, Stepper, Typography, Hidden, } from '@material-ui/core';
import clsx from 'clsx';
import { blankCanvas, SigPadInitials, SigPadSignature } from './constants/signature';
import SetSigInit from './SetSigInit';
import _ from 'lodash';
import CreatePasswordPrompt from 'document-viewer/src/components/CreatePasswordPrompt';
import { useSignaturePadModalStyles } from './styles';
import { Dialog, DialogActions } from 'document-viewer/src/components/Dialog';
import { Participant } from 'document-viewer/src/utils/types/participant';
import KbaStep from './KBA';
import CombinedSetSigInit from './CombinedSetSigInit';


function getSteps(numParticipants, participant, signatureAndInitialsInOneStep) {
  let signatureAndInitialsSteps = ['Set your Signature', 'Set your Initials']

  if (signatureAndInitialsInOneStep) {
    signatureAndInitialsSteps = ['Set your Signature & Initials']
  }

  const coreSteps = (participant && (participant.kbaRequired || participant.credAnalysisRequired) && !participant.skipVerification) ?
    ['Identity Verification', ...signatureAndInitialsSteps] :
    [...signatureAndInitialsSteps];

  if (numParticipants > 1) {
    return ['Set your PIN', ...coreSteps];
  } else {
    return coreSteps;
  }
}

type Props = {
  open?: boolean;
  participantName: string;
  participantId: string;
  participant: Participant;
  participantsLoading: boolean;
  onSubmit: (pinCode: string, sig: SigPadSignature, initials: SigPadInitials, color: string) => any;
  onLockout: () => any;
  numParticipants: number;
  fontFamilies: string[];
  signatureAndInitialsInOneStep: boolean;
  isChallengeQuestion: boolean;
  setIsChallengeQuestion: React.Dispatch<React.SetStateAction<boolean>>;
};

//NOTE: https://stackoverflow.com/questions/17386707/how-to-check-if-a-canvas-is-blank
const isCanvasBlank = (canvas) => {
  if (!canvas) {
    return true;
  }
  const context = canvas.getContext('2d');
  if (!context) {
    return true;
  }

  const pixelBuffer = new Uint32Array(
    context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
  );

  return !pixelBuffer.some((color) => color !== 0);
}

const getDisabled = ({ activeType, drawn, typed }) => {
  const created = activeType === 0 ? typed : drawn;
  if (_.isNil(created?.canvas) || isCanvasBlank(created?.canvas) || _.isEmpty(created.image)) {
    return true;
  }
  return false;
};

export default function SignaturePadModal({
  open = false,
  onSubmit,
  onLockout,
  // participantId,
  participant,
  participantName,
  participantsLoading,
  numParticipants,
  fontFamilies,
  signatureAndInitialsInOneStep,
  isChallengeQuestion,
  setIsChallengeQuestion,
}: Props) {

  const [userPassword, setUserPassword] = useState<string>(participant?.pinCode);

  const classes = useSignaturePadModalStyles();
  const [color, setColor] = useState<string>('blue');
  const [signatureFontFamily, setSignatureFontFamily] = useState<string>(fontFamilies[0]);
  const [initialFontFamily, setInitialFontFamily] = useState<string>(fontFamilies[0]);

  // creating signature/initials
  const [activeStep, setActiveStep] = useState(0);
  // can click "next" button
  const [nextDisabled, setNextDisabled] = useState(false);

  // typing signature or drawing it
  const [signatureActiveType, setSignatureActiveType] = useState<number>(0);
  const [initialsActiveType, setInitialsActiveType] = useState<number>(0);
  // signature data when drawn
  const [signatureDrawn, setSignatureDrawn] = useState<SigPadSignature>({});
  const [initialsDrawn, setInitialsDrawn] = useState<SigPadInitials>({});
  // signature data when typed
  const [signatureTyped, setSignatureTyped] = useState<SigPadSignature>({});
  const [initialsTyped, setInitialsTyped] = useState<SigPadInitials>({});

  const steps = getSteps(numParticipants, participant, signatureAndInitialsInOneStep);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % steps.length);
  }, [steps.length]);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1) % steps.length);
  }, [steps.length]);

  const submitStep = useCallback(() => {
    if (steps[activeStep] === 'Set your PIN') {
      if (userPassword && userPassword.length === 4) {
        handleNext();
      }
    }

    if (steps[activeStep] === 'Set your Signature') {
      if (signatureDrawn && signatureDrawn.canvas !== blankCanvas) {
        handleNext();
      }
    }
    if (steps[activeStep] === 'Set your Initials') {
      const signature = signatureActiveType === 0 ? signatureTyped : signatureDrawn;
      const initials = initialsActiveType === 0 ? initialsTyped : initialsDrawn;

      onSubmit(userPassword, signature, initials, color);
    }

    if (steps[activeStep] === 'Set your Signature & Initials') {
      if (signatureDrawn && signatureDrawn.canvas !== blankCanvas) {
        const signature = signatureActiveType === 0 ? signatureTyped : signatureDrawn;
        const initials = signatureActiveType === 0 ? initialsTyped : initialsDrawn;
        //handleNext();
        onSubmit(userPassword, signature, initials, color);
      }
    }
  }, [activeStep, color, handleNext, initialsActiveType, initialsDrawn, initialsTyped, onSubmit, signatureActiveType, signatureDrawn, signatureTyped, steps, userPassword]);

  useEffect(() => {
    if (steps[activeStep] === 'Set your PIN') {
      if (!userPassword || userPassword.length !== 4 || !/\d{4}$/.test(userPassword)) {
        setNextDisabled(true)
      } else {
        setNextDisabled(false)
      }
    } else if (steps[activeStep] === 'Set your Signature & Initials') {
      //Check to see that signature and initials have been entered to enable next button
      const sigData = {
        activeType: signatureActiveType,
        drawn: signatureDrawn,
        typed: signatureTyped,
      }
      const initialsData = {
        activeType: initialsActiveType,
        drawn: initialsDrawn,
        typed: initialsTyped,
      }

      const disabledBySignature = getDisabled(sigData);
      const disabledByInitials = getDisabled(initialsData);
      setNextDisabled(disabledBySignature || disabledByInitials);
    } else {
      // if current step is signature
      const toCheck = steps[activeStep] === 'Set your Signature'
        ? {
          activeType: signatureActiveType,
          drawn: signatureDrawn,
          typed: signatureTyped,
        }
        : {
          activeType: initialsActiveType,
          drawn: initialsDrawn,
          typed: initialsTyped,
        };

      const nextDisabled = getDisabled(toCheck);
      setNextDisabled(nextDisabled);
    }
  }, [signatureTyped, signatureDrawn, initialsTyped, initialsDrawn, activeStep, signatureActiveType, initialsActiveType, userPassword, steps]);

  useEffect(() => {
    if (userPassword && steps[activeStep] === 'Set your PIN') {
      setActiveStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const statuses = ['pending_kba_start', 'pending_kba_verify', 'pending_cred_analysis'];
    if (steps[activeStep] === 'Identity Verification') {
      if (participant && participant.status && participant.status && _.indexOf(statuses, participant.status) === -1) {
        setActiveStep(activeStep + 1);
      }
    }
  }, [activeStep, participant, steps])

  useEffect(() => {
    setInitialFontFamily(signatureFontFamily);
  }, [signatureFontFamily])

  useEffect(() => {
    setSignatureFontFamily(fontFamilies[0]);
    setInitialFontFamily(fontFamilies[0]);
  }, [fontFamilies]);

  return (
    <div
      id='look-here'
    >
      <Dialog
        onClose={() => { }}
        onExited={() => {
          setSignatureActiveType(0);
          setInitialsActiveType(0)
          setSignatureDrawn({})
          setInitialsDrawn({})
          setSignatureTyped({})
          setInitialsTyped({})
          setColor('blue');
          setActiveStep(0);
          setNextDisabled(false);
          setUserPassword('');
          setSignatureFontFamily(fontFamilies[0]);
          setInitialFontFamily(fontFamilies[0]);
        }}

        disableAutoFocus
        disableEnforceFocus
        disablePortal
        open={open || false}
        size='md'
        classes={{
          scrollPaper: classes.scrollPaper,
          paper: classes.modalMobile,
        }}
        PaperProps={{
          style: {
            height: '750px',
            overflow: "auto",
            margin: '20px',
          }
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        scroll='paper'
      >

        <div
          className={
            clsx({
              [classes.pinModalStyle]: steps[activeStep] === 'Set your PIN',
              [classes.root]: steps[activeStep] !== 'Set your PIN',
              [classes.mobileFullHeight]: steps[activeStep] === 'Identity Verification',
            })
          }
          data-testid={`sigInitStepper-${steps[activeStep]}`}
        >
          {!signatureAndInitialsInOneStep && steps[activeStep] && (
            <Hidden mdDown>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                style={{ width: '60%', margin: 'auto auto', paddingLeft: 40, paddingBottom: 4, paddingTop: 32 }}
              >
                {steps.map((label) => (
                  <Step
                    key={label}
                  >
                    <StepLabel
                      classes={{ label: classes.stepLabel }}
                      StepIconProps={{ classes: { root: classes.icon } }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Hidden>)}

          {
            (steps[activeStep] === 'Set your Signature' || steps[activeStep] === 'Set your Initials') && (
              <p className={classes.currentStepInstructions}>
                Please create your
                {' '}
                {steps[activeStep] === 'Set your Signature' ? ` Signature ` : ` Initials `}
                {' '}
                below by either picking a font or drawing it yourself.
              </p>
            )
          }

          <Typography
            component='div'
            className={
              clsx({
                [classes.instructions]: true,
                [classes.mobileFullHeight]: steps[activeStep] === 'Identity Verification',
              })
            }
          >
            <CreatePasswordPrompt
              participantName={participantName}
              userPassword={userPassword}
              open={steps[activeStep] === 'Set your PIN'}
              setUserPassword={(evt) => {
                setUserPassword(evt.target.value);
              }}
            />

            {
              participant && (
                <KbaStep
                  participant={participant}
                  participantsLoading={participantsLoading}
                  onLockout={onLockout}
                  open={steps[activeStep] === 'Identity Verification'}
                  isChallengeQuestion={isChallengeQuestion}
                  setIsChallengeQuestion={setIsChallengeQuestion}
                  renderButton={({ disabled }) => (
                    <DialogActions className={classes.dialogActions}>
                      <Button
                        variant='contained'
                        data-testid='nextBtn'
                        disabled={disabled}
                        className={classes.backButton}
                        type='submit'
                        color='primary'
                      >
                        Next
                      </Button>
                    </DialogActions>
                  )}
                />
              )
            }

            <CombinedSetSigInit
              color={color}
              setColor={setColor}
              participantName={participantName}
              activeTab={signatureActiveType || 0}
              onActiveTabChanged={(val) => {
                setSignatureActiveType(val);
                setInitialsActiveType(val);
              }}
              open={steps[activeStep] === 'Set your Signature & Initials'}
              fontFamilies={fontFamilies}
              onChange={(type, format, data: SigPadSignature | SigPadInitials) => {
                if (type === 'initials') {
                  if (format === 'typeitin') {
                    setInitialFontFamily(initialFontFamily);
                    setInitialsTyped(data);
                  } else {
                    setInitialsDrawn(data);
                  }
                } else {
                  if (format === 'typeitin') {
                    setSignatureFontFamily(signatureFontFamily);
                    setSignatureTyped(data);
                  } else {
                    setSignatureDrawn(data);
                  }
                }
              }}
              fontFamily={signatureFontFamily}
              setFontFamily={setSignatureFontFamily}
            />

            <SetSigInit
              color={color}
              setColor={setColor}
              participantName={participantName}
              type='signature'
              activeTab={signatureActiveType || 0}
              onActiveTabChanged={(type) => {
                setSignatureActiveType(type);
                setInitialsActiveType(type);
              }}
              open={steps[activeStep] === 'Set your Signature'}
              fontFamilies={fontFamilies}
              onChange={(type, signature: SigPadSignature) => {
                if (type === 'typeitin') {
                  setSignatureFontFamily(signatureFontFamily);
                  setSignatureTyped(signature);
                } else {
                  setSignatureDrawn(signature);
                }
              }}
              fontFamily={signatureFontFamily}
              setFontFamily={setSignatureFontFamily}
            />

            <SetSigInit
              color={color}
              setColor={setColor}
              participantName={participantName}
              type='initials'
              activeTab={initialsActiveType || 0}
              onActiveTabChanged={setInitialsActiveType}
              open={steps[activeStep] === 'Set your Initials'}
              fontFamilies={fontFamilies}
              onChange={(type, initials: SigPadInitials) => {
                if (type === 'typeitin') {
                  setInitialFontFamily(initialFontFamily);
                  setInitialsTyped(initials);
                } else {
                  setInitialsDrawn(initials);
                }
              }}
              fontFamily={initialFontFamily}
              setFontFamily={setInitialFontFamily}
            />
          </Typography>
        </div>

        {
          (steps[activeStep] && steps[activeStep] !== 'Identity Verification') && (
            <DialogActions className={classes.idvDialogActions}>
              <Button
                variant='contained'
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button
                variant='contained'
                data-testid='nextBtn'
                disabled={nextDisabled}
                className={classes.backButton}
                onClick={submitStep}
                color='primary'
              >
                {steps[activeStep] === 'Set your Signature & Initials' ? 'Accept & Create Signature' : 'Next'}
              </Button>
            </DialogActions>
          )
        }
      </Dialog>
    </div >
  );
}
