import { Participant, ParticipantDocument } from "./types/participant";
import { Revision } from "./types/revision";

export const getTotalParticipantTags = (document: any, participantId: string) => document.revisions ? document.revisions.filter((aRev: Revision) => participantId === aRev.participantId).length : 0;

/**
 * A function to check is particiapant is readonly for a document and to iterate over a list of document revision objects and return true if the participant completed the document
 * @param doc - document object
 * @param participant - participant in transaction
 * @example participantCompletedDocument(doc, participants[selectedParticipantId])
 */
export const participantCompletedDocument = (document, completedNoTagDocList: string[], participant?: Participant): boolean => {
  const { id: participantId, documents = [] } = participant ?? {};

  const isDocumentIdMatching = (participantDocument?: ParticipantDocument) => participantDocument?.documentId === document.id;
  let { readOnly } = documents.find(isDocumentIdMatching) ?? {};

  if(readOnly && (document.tags === 0 || getTotalParticipantTags(document, participantId) === 0)) {
    readOnly = completedNoTagDocList.includes(document.id + participantId);
  }

  const isRevisionComplete = (revision: Revision) => revision?.participantId === participantId && revision?.completed;
  return readOnly || document.revisions?.some(isRevisionComplete);
}
