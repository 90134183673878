import React, { Component } from 'react';
import _ from 'lodash';
import * as R from 'ramda';
import annotNameIncludes from '../../helpers/annotNameIncludes';
import {
  setIsRequired,
} from '../setters';

const getTemplateAnnots = R.filter(
  (annot: any) => annotNameIncludes(annot, 'Template')
);

export interface RequiredCheckboxProps {
  annotManager?: any;
  signers?: any;
  instance?: any;
}

export interface RequiredCheckboxState {
  checked?: boolean;
  isMixed?: boolean;
}

export default class RequiredCheckbox extends Component<RequiredCheckboxProps, RequiredCheckboxState> {
  constructor(props: RequiredCheckboxProps) {
    super(props);
    this.state = { checked: true };
  }

  componentDidMount = async () => {
    const instanceCustomData = JSON.parse(this.props.instance.getCustomData());
    const selectedAnnots = this.props.annotManager.getSelectedAnnotations();

    const checked = _.reduce(selectedAnnots, (acc, annot) => acc && _.get(annot, 'CustomData.flags.required', true), true);
    const isMixed = _.reduce(selectedAnnots, (acc, annot) => acc || _.get(annot, 'CustomData.flags.required', true), false);

    _.map(selectedAnnots, (annot) => {
      if (instanceCustomData.taggingMode === false) {
        if (annotNameIncludes(annot, 'Template')) {
          annot.Locked = isMixed;
        }

        annot.NoMove = isMixed;
        annot.ContentLocked = isMixed;
      }
    });
    await this.setState({ checked, isMixed });
  }

  applyRequired = (annots: any) => {
    const checked = this.state.checked;
    _.map(annots, (annot) => {
      if (_.isNil(annot.setAddressFormat)) {
        setIsRequired(checked!, annot, this.props.instance);
      } else {
        annot.setIsRequired(checked);
      }
    });
  }

  handleChange = (annots: any) => (evt: any) => {
    this.setState({ checked: evt.target.checked }, () => {
      this.applyRequired(annots);
    });
  }

  render() {
    const instanceCustomData = JSON.parse(this.props.instance.getCustomData());
    const selectedAnnots = this.props.annotManager.getSelectedAnnotations();
    const annots = getTemplateAnnots(selectedAnnots);

    if (annots.length === selectedAnnots.length && instanceCustomData.taggingMode === true) {
      return (
        <div style={{ paddingRight: 5 }}>
          <label
            className='form-check-label'
            htmlFor='isRequired'
          >
            Required
          </label>
          <input
            type='checkbox'
            className='form-check-input'
            id='isRequired'
            checked={this.state.checked}
            onChange={this.handleChange(annots)}
          />
        </div>

      );
    }

    return <></>;
  }
}
