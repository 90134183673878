import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'document-viewer/src/lib/hooks/useTheme';
import { defaultTheme } from 'document-viewer/src/constants';

export const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    flex: 1,
  },
  fixHelperTexts: {
    '& .MuiFormHelperText-root.Mui-error': {
      height: '1em',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#507f91'
    },
    '& .MuiInput-underline': {
      '& .after': {
        borderBottom: '#507f91 !important'
      },
    },
    // '& .MuiInput-underline:after': {
    //   borderBottom: `#507f91 !important`,
    // },
    '& label': { fontSize: !theme.signatureAndInitialsInOneStep ? '.8rem' : undefined }
  },
  marginBtm: { marginBottom: 16 },
  btn: { minWidth: 200 },
  inputLabelText: theme.signatureAndInitialsInOneStep ? {
    textTransform: 'uppercase',
  } : undefined,
  custom: {
    color: "red",

  },
  dialogTitle: { backgroundColor: '#c2c9d038', padding: '32px 24px', color: defaultTheme.dialogTextColor },
  dialogContent: { width: 'auto', padding: '8px 25px' },
  dialogButton:{
    margin: '2px',
    padding: '11px 20px',
  },
  dialogMain:{
    width: '700px'
  },
  dialogText: {
    textAlign: 'left'
  }
}));
