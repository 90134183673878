import React, { useEffect, useState, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import LoadingSpinner from 'document-viewer/src/components/LoadingSpinner';
import Thumbnail from './Thumbnail';
import { DocumentThumbnailsProvider } from 'document-viewer/src/lib/hooks/useDocumentThumbnails';
import { useWebViewer } from 'document-viewer/src/lib/hooks/useWebViewer';
import { useThumbnailsStyles, DOCUMENT_THUMBNAIL_HEIGHT } from './styles';
import { CoreControls } from '@pdftron/webviewer';
import { useDocs } from 'document-viewer/src/lib/hooks/useDocs';

export default function DocumentThumbnails({ docs, docId, pageNumber, onPageSelected, setLoadingThumbnails, show }) {
  const { coreControls, docViewer } = useWebViewer();
  const classes = useThumbnailsStyles();
  const [cachedThumbnails, setCachedThumbnails] = useState([]); // Will be a sparse array of thumbnails indexed by the zero-indexed page
  const [pdftronDoc, setPdftronDoc] = useState<CoreControls.Document | null>(null);
  const loadedDocId = useRef<string>();
  const listRef = useRef<List>();
  const { currentDocThumbnails } = useDocs();


  // When we're loading a different docId, reset everything and load that doc
  useEffect(() => {
    if (loadedDocId.current !== docId && coreControls && docId in docs) {
      
      setLoadingThumbnails(true);
      
      // First of all, preven this useEffect from running again on the same docId, and nullify pdftronDoc so that a loading indicator can be shown
      loadedDocId.current = docId;
      setPdftronDoc(null);
      const { file, url } = docs[docId];
      // Start loading the doc
      coreControls
        .createDocument(file || url, {
          l: process.env.NX_PDFTRON_LICENSE,
          docId,
          extension: 'pdf',
          useDownloader: !file && url,
        })
        .then((doc: CoreControls.Document) => {
          // The doc has been loaded. Set it so that the UI can start rendering thumbnails, and replace cachedThumbnails with a perfectly-sized empty array
          setPdftronDoc(doc);
          setCachedThumbnails(new Array(doc.getPageCount()));
          setLoadingThumbnails(false);
        });
    }
  }, [coreControls, docId, loadedDocId, docs]);



  // If a list won't being shown, then the old listRef is invalid and should be removed
  if (!show || !pdftronDoc) {
    listRef.current = null;
  }

  // Ensure that as the doc scrolls, the list does too (if needed)
  useEffect(() => {
    if (show && listRef.current && typeof pageNumber === 'number') {
      listRef.current.scrollToItem(pageNumber);
    }
  }, [pageNumber, show]);

  // Ensure that if blank pages are added/removed, the list is updated
  useEffect(() => {
    if (!docViewer) return;
    const onBlankPageAdded = () => setCachedThumbnails((thumbs) => thumbs.concat([undefined]));
    const onBlankPageRemoved = () => setCachedThumbnails((thumbs) => thumbs.slice(0, thumbs.length - 1));
    docViewer.on('blankPagesAdded', onBlankPageAdded);
    docViewer.on('blankPagesRemoved', onBlankPageRemoved);
    return () => {
      docViewer.off('blankPagesAdded', onBlankPageAdded);
      docViewer.off('blankPagesRemoved', onBlankPageRemoved);
    };
  }, [docViewer]);

  useEffect(() => {
    if (!show) {
      return;
    }
    setLoadingThumbnails(!pdftronDoc)
  }, [{}]);

  if (!show) {
    return null;
  }

  if (!pdftronDoc) {
    return (
      <div className={classes.centeredText}>
        <LoadingSpinner className={classes.loadingSpinner} />
      </div>
    );
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <DocumentThumbnailsProvider
          thumbnails={currentDocThumbnails}
          highlightedPageNumber={pageNumber}
          onPageSelected={onPageSelected}
          docId={docId}
        >
          <InfiniteLoader
            isItemLoaded={(pageIndex) => !!cachedThumbnails[pageIndex]}
            itemCount={cachedThumbnails.length}
            loadMoreItems={() => { }}
            minimumBatchSize={3}
            threshold={4}
          >
            {({ onItemsRendered, ref }) => (
              <List
                className={classes.thumbnailList}
                itemSize={parseInt(DOCUMENT_THUMBNAIL_HEIGHT)}
                height={height}
                width={width}
                itemCount={cachedThumbnails.length}
                onItemsRendered={onItemsRendered}
                ref={(theRef) => {
                  ref(theRef);
                  listRef.current = theRef;
                }}
              >
                {Thumbnail}
              </List>
            )}
          </InfiniteLoader>
        </DocumentThumbnailsProvider>
      )}
    </AutoSizer>
  );
}
