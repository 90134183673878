import React, { ReactElement } from 'react';
import {
  Description as PageIcon,
  Build as ToolsIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import { usePanelToggleStyles } from './styles';

// TODO: use showToggle prop to hide close btn on desktop

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PanelToggle = ({ isOpen, direction, drawerWidth, showToggle, handler, sideBarOpenCloseStyling }) => {
  const classes = usePanelToggleStyles();
  const extraStyles = { [direction]: isOpen ? drawerWidth : 0 };

  let icon: ReactElement;
  let closeIcon: ReactElement;
  if (sideBarOpenCloseStyling === 'bar') {
    icon = direction === 'left' ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />;
    closeIcon = direction === 'left' ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />;
  } else {
    icon = direction === 'left' ? <PageIcon /> : <ToolsIcon />;
    closeIcon = direction === 'left' ?
      <KeyboardArrowLeftIcon className={classes.arrowIcon} />
      : <KeyboardArrowRightIcon className={classes.arrowIcon} />;
  }

  return (
    <div
      className={clsx(classes.root, classes[sideBarOpenCloseStyling], {
        [classes.isOpen]: isOpen,
        [classes.isIcon]: sideBarOpenCloseStyling !== 'bar' && isOpen,
      })}
      style={extraStyles}
      onClick={handler}
    >
      {isOpen ? closeIcon : icon}
    </div>
  );
}

export default PanelToggle;
