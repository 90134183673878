import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import type { Document } from 'document-viewer/src/utils/types/document';
import type { UUID } from 'document-viewer/src/utils/types/uuid';
import useEllipses from 'document-viewer/src/utils/styles/useEllipses';
import { participantCompletedDocument } from 'document-viewer/src/utils/participantCompletedDocument';
import { Participant } from 'document-viewer/src/utils/types/participant';

export interface Props {
  docs: Record<UUID, Document>;
  docIds: UUID[];
  selectedDocId?: UUID;
  selectedParticipant?: Participant;
  completedNoTagDocList: string[];
  onDocSelected?: (documentId: UUID) => void;
}

export default function SimpleDocumentList(props: Props) {
  const {
    docs,
    docIds,
    selectedDocId,
    selectedParticipant,
    onDocSelected,
    completedNoTagDocList
  } = props;

  const { ellipses } = useEllipses({ numLines: 4, maxWidth: '135px' });

  return (
    <List
      component='nav'
    >
      {docIds.map((id) => {
        const doc = docs[id];
        if (!doc) {
          return null;
        }
        const labelId = `checkbox-list-label-${id}`;
        const checked = participantCompletedDocument(doc, completedNoTagDocList, selectedParticipant);
        return (
          <Tooltip
            title={doc.title}
            aria-label={doc.title}
          >
            <ListItem
              key={id}
              selected={selectedDocId === id}
              button={true}
              onClick={() => onDocSelected ? onDocSelected(id) : null}
            >

              <ListItemIcon>
                <Checkbox
                  edge='start'
                  checked={checked}
                  tabIndex={-1}
                  disableRipple={true}
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: ellipses,
                }}
              >
                {doc.title}
              </ListItemText>
            </ListItem>
          </Tooltip>
        );
      })}
    </List>
  );
}
