import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  searchIconWithDetails: {
    marginBottom: theme.spacing(2.25),
    opacity: 0.6,
  },
  searchIconWithoutDetails: {
    marginTop: theme.spacing(2.25),
    opacity: 0.6,
  },
  noTopBorder: {
    borderTop: 0,
  },
  flexShrink: { flexShrink: 0 },
}));
