
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import React, { FC } from "react";
import Page from "document-viewer/src/components/Page"
import { defaultTheme, ENL_LOGO } from "document-viewer/src/constants"
const useStyles = makeStyles(() => ({
  root:{
    background:defaultTheme.dialogTitle,
    width:'100%',
    height:'100%'
  },
  logo:{
    marginLeft: '32px',
    height: '43px',
    marginTop:'10px'
  },
  textTitle:{
    color:defaultTheme.primaryColor,
    fontWeight:'bold',
    fontFamily: 'Cera, sans-serif' 

  },
  subTitle:{
    fontFamily: 'Cera, sans-serif'
  },
  headerWidth:{
    height:'64px',
    backgroundColor:defaultTheme.headerBackground,
  }
}));

const sessionCanceled: FC = () => 
{
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return (
    <Page
      className={classes.root}
      title='session has been canceled'
    >
      {
        window === window.parent && (
          <header className={classes.headerWidth}>
            <img
              alt='Logo'
              className={classes.logo}
              src={ENL_LOGO}
            />
          </header>
        )
      }
      <br/>
      <br/>
      <div style={{ marginTop: '50px' }}>
        <Typography
          align='center'
          variant='h5'
          className={classes.textTitle}
        >
          SORRY WE CANNOT CONTINUE WITH THIS SESSION.
        </Typography>
        <Typography
          align='center'
          variant='subtitle2'
          color='textSecondary'
          className={classes.subTitle}
        >
          The request for this session was canceled. Please contact the sender 
          <br/>
          for more information.
        </Typography>
      </div>
    </Page>
  )
  ;
}
export default sessionCanceled;
  