import SwitchParticipantModal from 'document-viewer/src/components/SwitchParticipant';
import { useParticipants } from 'document-viewer/src/lib/hooks/useParticipants';
import _ from 'lodash';
import useAuth from 'document-viewer/src/lib/hooks/useAuth';
import { Participant } from 'document-viewer/src/utils/types/participant';



export default function SwitchParticipant() {

  const {
    participants,
    selectedParticipantId,
    setShowSwitch,
    showSwitch
  } = useParticipants();


  const { checkAuth } = useAuth();


  return (
    <SwitchParticipantModal
      participants={_.values(participants)}
      currentParticipantId={selectedParticipantId}
      open={showSwitch}
      onSelect={(participant: Participant) => {
        setShowSwitch(false);
        return checkAuth(participant.id)
      }}
      onClose={() => {
      }}
      autoSelectIfOnlyOne
    />
  )
}
