export const sessionPages = {
  INFO: 'info',
  EQUIPMENT_CHECK: 'equipment-check',
  DOCUMENT_UPLOAD: 'doc-upload',
  // In some places this is called id-verification, in others it's called verification :/
  ID_VERIFICATION: 'id-verification',

  WAITING_ROOM: 'waiting-room',
  NOTARY_ROOM: 'notary-room',
  ORG_NOTARY_ROOM: 'org-notary-room',

  PAYMENTS: 'payments',
  DOCUMENT_DELIVERY: 'document-delivery',

  VERIFICATION_FAILURE: 'verification-failure',
};

export const noEquipmentCheckNeededPages = [sessionPages.INFO, sessionPages.PAYMENTS, sessionPages.DOCUMENT_DELIVERY, sessionPages.VERIFICATION_FAILURE];
export const notaryRoomPages = [sessionPages.WAITING_ROOM, sessionPages.NOTARY_ROOM, sessionPages.ORG_NOTARY_ROOM];
