import * as R from 'ramda';

import { injectTool } from '../initializers/injectors';
import { removeFormFieldsIcon } from '../constants/icons';
import { InstanceObject } from '../../viewer';


// TODO: add a handler for docViewer.on('removeFormFields', () =>{})
const removeFormFields = {
  type: 'actionButton',
  img: removeFormFieldsIcon,
  title: 'Remove Form Fields',
  dataElement: 'removeFormFields',
  onClick: ({ instance }: InstanceObject) => async () => {
    instance.docViewer.trigger('confirmationModal', [{
      message: 'Are you sure you want to delete all form fields? This will remove all form fields in all documents.',
      onConfirm: 'removeFormFields',
    }]);
  },
};


const registerRemoveFormFields = R.pipeP(
  injectTool('RemoveFormFields', removeFormFields)
);

export default registerRemoveFormFields;
