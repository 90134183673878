// Strip out all annotation from the xfdf EXCEPT the whiteout and strikethrough annots
export default async function stripSpecialAnnots(xfdf: string) {
  const doc = new DOMParser().parseFromString(xfdf, 'application/xml');
  const annots = await doc.getElementsByTagName('annots');

  if (annots.length === 0) {
    return;
  }

  await Promise.all(Array.from(annots[0].children).map(async (xmlNode) => {
    if (xmlNode.getAttribute('subject') !== 'Strikeout' && xmlNode.getAttribute('subject') !== 'WhiteOutAnnotation') {
      xmlNode.parentNode!.removeChild(xmlNode);
    }

    // Remove the border
    if (xmlNode.getAttribute('subject') === 'WhiteOutAnnotation') {
      xmlNode.setAttribute('color', '#FFFFFF');
    }
  }));

  return new XMLSerializer().serializeToString(doc);
}
