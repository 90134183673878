/* eslint-disable no-param-reassign */
import { parseDate } from '@enotarylog/tag-utils/helpers/parseDate';
import { createContent } from '@enotarylog/tag-utils/createContent';

export const setStateFormat = (annot: any, stateFormat: any, signer: any, instanceObject: any) => {
  annot.CustomData.flags = annot.CustomData.flags || {};
  annot.CustomData.flags.stateFormat = stateFormat;
  annot.setCustomData({
    ...annot.CustomData,
    flags: {
      ...annot.CustomData.flags,
      stateFormat,
    },
  });

  annot.setContents(createContent(annot.CustomData, signer));
  instanceObject.annotManager.redrawAnnotation(annot);
  instanceObject.annotManager.trigger('annotationChanged', [[annot], 'modify', { imported: false, isUndoRedo: false }]);

  return annot;
};

export const setDateFormat = (annot: any, dateFormat: any, instanceObject: any) => {
  annot.CustomData.flags = annot.CustomData.flags || {};
  annot.CustomData.flags.dateFormat = dateFormat;
  annot.setCustomData({
    ...annot.CustomData,
    flags: {
      ...annot.CustomData.flags,
      dateFormat,
    },
  });

  annot.setContents(parseDate(new Date(), dateFormat));

  instanceObject.annotManager.redrawAnnotation(annot);
  instanceObject.annotManager.trigger('annotationChanged', [[annot], 'modify', { imported: false, isUndoRedo: false }]);

  return annot;
};

export const setAddressFormat = (annot: any, addressFormat: any, signer: any, instanceObject: any) => {
  annot.CustomData.flags = annot.CustomData.flags || {};
  annot.CustomData.flags.addressFormat = addressFormat;
  annot.setCustomData({
    ...annot.CustomData,
    flags: {
      ...annot.CustomData.flags,
      addressFormat,
    },
  });
  annot.setContents(createContent(annot.CustomData, signer));
  instanceObject.annotManager.redrawAnnotation(annot);
  instanceObject.annotManager.trigger('annotationChanged', [[annot], 'modify', { imported: false, isUndoRedo: false }]);

  return annot;
};

export const setIsRequired = (isRequired: boolean, annot: any, instanceObject: any) => {
  const instanceCustomData = JSON.parse(instanceObject.getCustomData());

  if (instanceCustomData.taggingMode === false) {
    annot.NoMove = isRequired;
    annot.Locked = isRequired;
  }

  annot.CustomData.flags = annot.CustomData.flags || {};
  annot.CustomData.flags.required = isRequired;
  annot.setCustomData({
    ...annot.CustomData,
    flags: {
      ...annot.CustomData.flags,
      required: isRequired,
    },
  });

  instanceObject.annotManager.redrawAnnotation(annot);
  instanceObject.annotManager.trigger('annotationChanged', [[annot], 'modify', { imported: false, isUndoRedo: false }]);

  return annot;
};
