import React, { FunctionComponent, useEffect, useState } from 'react';
import { Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Tooltip from 'document-viewer/src/components/Tooltip';
import { ComponentProps, ComponentUIProps } from './ComponentProps';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { makeStyles, useTheme } from '@material-ui/styles';
import type { Theme } from 'document-viewer/src/lib/hooks/useTheme';
import { useWebViewer } from 'document-viewer/src/lib/hooks/useWebViewer';
const useStyles = makeStyles(() => ({
  listRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
  gutters: {
    'padding-left': '.5rem',
    'padding-right': '.5rem',
  },
  listIcon: {
    minWidth: 0,
    justifyContent: 'center',
    color: 'inherit',
  },
}));

export const DocumentZoom: FunctionComponent<ComponentProps> = ({ initialZoomLevel }) => {
  const { docViewer } = useWebViewer();

  return (
    <DocumentZoomUi
      initialZoomLevel={initialZoomLevel}
      zoomIncrements={10}
      minZoom={5}
      maxZoom={9999}
      zoomTo={(zoomLevel) => docViewer.zoomTo(zoomLevel)}
      subscribeToZoom={(handler) => {
        if (docViewer) {
          docViewer.on('zoomUpdated', handler);
          return () => docViewer.off('zoomUpdated', handler);
        }

        return () => { };
      }}
    />
  )
}

export const DocumentZoomUi: FunctionComponent<ComponentUIProps> = ({ initialZoomLevel, zoomTo, subscribeToZoom, zoomIncrements, minZoom, maxZoom }) => {
  const theme = useTheme<Theme>();
  const zoomAtTheTop: boolean = (theme?.zoomIcon === 'top');
  const styles = useStyles();
  const [zoom, setZoom] = useState(initialZoomLevel);
  const zoomOut = (e) => {
    let zoomDecrease = zoomIncrements;
    if (e.shiftKey) {
      zoomDecrease *= 2;
    }
    const zoomLevel = Math.ceil(zoom - zoomDecrease);
    if (zoomLevel <= minZoom) {
      zoomTo(minZoom / 100);
    } else {
      zoomTo(zoomLevel / 100);
    }
  };
  const zoomIn = (e) => {
    let zoomIncrease = zoomIncrements;
    if (e.shiftKey) {
      zoomIncrease *= 2;
    }
    const zoomLevel = Math.ceil(zoom + zoomIncrease);
    if (zoomLevel >= maxZoom) {
      zoomTo(maxZoom / 100);
    } else {
      zoomTo(zoomLevel / 100);
    }
  };

  useEffect(() => subscribeToZoom((zoom) => {
    setZoom(Math.ceil(zoom * 100))
  }), [subscribeToZoom]);

  useEffect(() => {
    setZoom(initialZoomLevel);
  }, [initialZoomLevel]);

  return (
    /**
     * I don't think this `Grid` is necessary. It _does_ have an effect, but
     * it just condenses the elements contained closer together, which could
     * be achieved with simpler elements.
     */
    <Grid
      container
      direction='row'
      alignItems='center'
    >
      <List className={styles.listRow}>
        <Tooltip title='Zoom out'>
          <ListItem
            className={styles.gutters}
            button
            onClick={zoomOut}
          >
            <ListItemIcon className={styles.listIcon}>
              {
                zoomAtTheTop
                  ? <ZoomOutIcon />
                  : <RemoveCircleOutlineIcon />
              }
            </ListItemIcon>
          </ListItem>
        </Tooltip>
        {zoomAtTheTop && (
          <ListItem>
            <ListItemText>
              {zoom}
              %
            </ListItemText>
          </ListItem>
        )}
        <Tooltip title='Zoom in'>
          <ListItem
            className={styles.gutters}
            button
            onClick={zoomIn}
          >
            <ListItemIcon className={styles.listIcon}>
              {
                zoomAtTheTop
                  ? <ZoomInIcon />
                  : <AddCircleOutlineIcon />
              }
            </ListItemIcon>
          </ListItem>
        </Tooltip>
        {!zoomAtTheTop && (
          <ListItem>
            <ListItemText>
              {zoom}
              %
            </ListItemText>
          </ListItem>
        )}
      </List>
    </Grid>
  );
};
