import { createSelector } from '@reduxjs/toolkit';
import { RootState, useDispatch, useSelector } from 'document-viewer/src/store';
import { selector as docsSelector, byIdSelector, allIdsSelector, setSelectedDocId, DocumentsState, Thumbnails, DocThumbnails, addThumbnail, saveDoc, setLocked, updateDoc, updateDocStatus, setLoading } from 'document-viewer/src/slices/documents';
import { setDocAnnots, determineReviewedAnnots } from 'document-viewer/src/slices/annotations';
import { Document } from 'document-viewer/src/utils/types/document';
import { useWebViewer } from './useWebViewer';
import useAnnots from './useAnnots';
import useSaveDoc from 'document-viewer/src/lib/hooks/useSaveDoc';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useCallback } from 'react';
import { DocStatus } from 'document-viewer/src/utils/pdftron/docCompletion';
import { selector as participantsSel } from 'document-viewer/src/slices/participants';
import { participantCompletedDocument } from 'document-viewer/src/utils/participantCompletedDocument';
import { setShowCompletedNotification } from 'document-viewer/src/slices/transaction';
import { participantsAllIdsSel, selectedParticipantSel } from './useParticipants';


export const selectedDocSel = createSelector(docsSelector, (docState: DocumentsState) => docState.selected)
export const allThumbnailsSel = createSelector(docsSelector, (docState: DocumentsState) => docState.thumbnails.byId);
export const allThumbnailDocIdsSel = createSelector(docsSelector, (docState: DocumentsState) => docState.thumbnails.allIds)
export const currentThumbnailsSel = createSelector(allThumbnailsSel, selectedDocSel, (thumbsById, docId) => thumbsById[docId] || {})
export const lockedSel = createSelector(docsSelector, (docState: DocumentsState) => !!docState.locked);
export const loadingSel = createSelector(docsSelector, (docState: DocumentsState) => !!docState.loading);
// For the current participant (or the first one, if no participant is selected - this logic is valid for now), grab all their DocumentParticipants
export const documentParticipantsSel = createSelector(participantsSel, ({ participants, selectedParticipant }) => {
  const currentParticipant = participants.byId[selectedParticipant];
  const firstParticipant = participants.byId[participants.allIds[0]];
  return currentParticipant?.documents || firstParticipant?.documents;
});
export const visibleDocIdsSel = createSelector(byIdSelector, allIdsSelector, documentParticipantsSel, (byId, allIds, docParticipants) => {
  // If there are some DocumentParticipants, use them to calculate visibleDocIds. Otherwise, make all docs visible temporarily, until DocumentParticipants are loaded and the documentParticipantsSel changes
  // TODO: figure out how to determine if this is a RON, and if it is a RON, then the else condition should be used
  if (docParticipants) {
    return docParticipants
      .filter(({ visible }) => visible)
      .sort((a, b) => ((byId[a.documentId]?.order ?? 0) - (byId[b.documentId]?.order ?? 0)))
      .map(({ documentId }) => documentId);
  } else {
    return allIds;
  }
});
// TODO: figure out how to determine if this is a RON, and if it is a RON, then readOnly should always be set to false
export const visibleDocsWithReadOnlyInfoSel = createSelector(byIdSelector, documentParticipantsSel, visibleDocIdsSel, (byId, docParticipants, visibleIds): Record<string, Document> => Object
  .fromEntries(visibleIds
    .map((id) => byId[id])
    .filter((doc) => !!doc)
    .map((doc) => ([
      doc.id,
      {
        ...doc,
        readOnly: docParticipants.find(({ documentId }) => documentId === doc.id)?.readOnly || false,
      }
    ]))
  ));

export interface IUseDocs {
  docs: Record<string, Document>;
  docIds: Array<string>;
  selectedDoc: string | null;
  setSelectedDoc: (id: string) => Promise<void>;
  addThumbnail: (docId: string, pageIndex: number, page?: HTMLCanvasElement, annot?: HTMLCanvasElement) => void;
  documentLocked: boolean;
  documentLoading: boolean;
  setDocumentLoading(val: boolean): void;
  lockDocument: (val: boolean) => void;
  updateDoc: (doc: Document) => void;
  updateDocStatus: (docId: string, status: `${DocStatus}`) => void;
  saveDoc: (docId: string, loadDoc?: boolean, completedNoTagDocList?: string[]) => void;
  allThumbnailDocIds: Array<string>;
  allThumbnails: Thumbnails,
  currentDocThumbnails: DocThumbnails
}

export function useDocs(): IUseDocs {

  const dispatch = useDispatch();
  const { instance, persistDoc } = useWebViewer();

  const selectedDoc = useSelector<string | null>(selectedDocSel) as ReturnType<typeof selectedDocSel>;
  const documentLocked = useSelector<boolean>(lockedSel) as ReturnType<typeof lockedSel>;
  const documentLoading = useSelector<boolean>(loadingSel) as ReturnType<typeof loadingSel>;

  const { appliedAnnots, reviewedAnnotIds, clearAppliedAnnots } = useAnnots();

  const { saveDoc: saveDocMutation } = useSaveDoc();
  const allThumbnailDocIds = useSelector(allThumbnailDocIdsSel) as ReturnType<typeof allThumbnailDocIdsSel>;
  const allThumbnails = useSelector(allThumbnailsSel) as ReturnType<typeof allThumbnailsSel>
  const currentDocThumbnails = useSelector(currentThumbnailsSel) as ReturnType<typeof currentThumbnailsSel>
  const visibleDocIds = useSelector(visibleDocIdsSel);
  const visibleDocsWithReadOnlyInfo = useSelector(visibleDocsWithReadOnlyInfoSel);
  const selectedParticipant = useSelector(selectedParticipantSel) as ReturnType<typeof selectedParticipantSel>
  const allParticipantIds = useSelector(participantsAllIdsSel) as ReturnType<typeof participantsAllIdsSel>;

  return {
    docs: visibleDocsWithReadOnlyInfo,
    selectedDoc,
    docIds: visibleDocIds,
    setSelectedDoc: useCallback(async (id) => dispatch(setSelectedDocId(id)), [dispatch]),
    documentLocked,
    documentLoading,
    addThumbnail: useCallback((docId: string, pageIndex: number, page?: HTMLCanvasElement, annot?: HTMLCanvasElement) => dispatch(addThumbnail(docId, pageIndex, page, annot)), [dispatch]),
    updateDoc: useCallback((doc: Document) => dispatch(updateDoc(doc)), [dispatch]),
    saveDoc: useCallback(async (dId, loadDoc, completedNoTagDocList = []) => {
      dispatch(setLocked(true));
      const persisted = await persistDoc();
      const allAnnots = instance?.annotManager.getAnnotationsList() ?? [];

      const appliedAndReviewedAnnots = _.uniq([...appliedAnnots, ...reviewedAnnotIds]);

      // Check inside of the appliedAnnots to ensure that all the required annot ids are present.

      const participantIds = allAnnots.reduce((_participantIds, annot) => {
        if (appliedAndReviewedAnnots.includes(annot.Id)) {
          const participantId = annot?.CustomData?.signerId ?? null;
          if (participantId && !_participantIds.includes(participantId)) {
            _participantIds.push(participantId);
          }
        }

        return _participantIds;
      }, []);

      const completedBy = _.chain(participantIds)
        .filter((participantId) => {
          // annots that are tags
          // FIXME: [V2-156] This could be made more efficient.

          const allRequiredAnnotIds = allAnnots.reduce((_allRequiredAnnotIds, annot) => {
            const { signerId, corrId, flags } = annot?.CustomData ?? {};

            const isUniqueRequiredExecutableAnnot = signerId === participantId && !corrId && flags?.required && !_allRequiredAnnotIds.includes(annot.Id);

            if (isUniqueRequiredExecutableAnnot) _allRequiredAnnotIds.push(annot.Id);

            return _allRequiredAnnotIds;
          }, []);

          const intersection = _.intersection(allRequiredAnnotIds, _.uniq(appliedAnnots));

          return (intersection.length === allRequiredAnnotIds.length);
        })
        .value()

      const { signDocument: doc } = await saveDocMutation(visibleDocsWithReadOnlyInfo[dId], participantIds, completedBy, true);
      dispatch(saveDoc(dId, persisted.fileData, persisted.xfdf))
      dispatch(updateDoc(doc));
      // single participant browser session
      if (allParticipantIds.length === 1) {
        const participantCompletedAllDocs: boolean = Object.values(visibleDocsWithReadOnlyInfo).every((d) =>
          d.id === dId ?
            // use document returned from mutation if it is the current doc
            participantCompletedDocument(doc, completedNoTagDocList, selectedParticipant) :
            // make sure participant is either readonly or has completed the doc
            d.readOnly || participantCompletedDocument(d, completedNoTagDocList, selectedParticipant)
        );
        if (participantCompletedAllDocs) {
          dispatch(setShowCompletedNotification({ show: true }));
        }
      } else {
        dispatch(updateDocStatus({ docId: dId, status: doc.status }));
      }

      clearAppliedAnnots(dId);
      dispatch(setDocAnnots(dId, allAnnots));
      dispatch(determineReviewedAnnots({ docId: dId }));
      dispatch(setLocked(false));

    }, [dispatch, persistDoc, instance?.annotManager, saveDocMutation, visibleDocsWithReadOnlyInfo, allParticipantIds.length, clearAppliedAnnots, appliedAnnots, reviewedAnnotIds, selectedParticipant]),
    lockDocument: useCallback((val) => dispatch(setLocked(val)), [dispatch]),
    setDocumentLoading: useCallback((val) => dispatch(setLoading(val)), [dispatch]),
    updateDocStatus: useCallback((docId, status) => dispatch(updateDocStatus({ docId, status })), [dispatch]),
    allThumbnailDocIds,
    allThumbnails,
    currentDocThumbnails
  }
}

export const withDocs = connect((state: RootState) => ({
  allThumbnailDocIds: allThumbnailDocIdsSel(state),
  allThumbnails: allThumbnailsSel(state),
  currentDocThumbnails: currentThumbnailsSel(state)
}), (dispatch) => ({
  addThumbnail: (docId: string, pageIndex: number, page?: HTMLCanvasElement, annot?: HTMLCanvasElement) => dispatch(addThumbnail(docId, pageIndex, page, annot)),
  lockDocument: (val) => dispatch(setLocked(val)),
}))
