
import config from '@enotarylog/pdftron-webviewer/notarize/webviewer/lib/configs/wv';
import * as toolConfigs from '@enotarylog/pdftron-webviewer/notarize/webviewer/lib/configs';
import * as R from '@enotarylog/ramda';
import { useFlags } from 'document-viewer/src/lib/hooks/useFlags';
import _ from 'lodash';

import Viewer from '@enotarylog/pdftron-webviewer/notarize/webviewer/viewer';
import { useWebViewer } from 'document-viewer/src/lib/hooks/useWebViewer';
import { useQuery } from 'document-viewer/src/lib/hooks/useQuery';
import { useParticipants } from 'document-viewer/src/lib/hooks/useParticipants';
import { useDocs } from 'document-viewer/src/lib/hooks/useDocs';
import useAnnots from 'document-viewer/src/lib/hooks/useAnnots';
import { selector as requestDataSelector } from 'document-viewer/src/slices/request-data';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'document-viewer/src/store';
import { useAuditTrail } from 'document-viewer/src/lib/hooks/useAuditTrail';
import { isFlattenedAnnot } from 'document-viewer/src/utils/pdftron/tagHelpers';
import { supportsTouchEvents } from 'detect-it';
import { useMemo } from 'react';

declare global {
  interface Window {
    globalDocumentLoading: boolean;
  }
}

const isMobileEnvironment = !!supportsTouchEvents;

export default function EsignViewer({
  sessionId,
  onDocumentLoaded = R.identity,
}) {
  const { instance: wv, setInstance, applyTag } = useWebViewer();
  const {
    removeAppliedAnnot,
    setDocAnnots,
    addReviewedAnnot,
    addAppliedAnnot
  } = useAnnots();



  const requestData = useSelector(createSelector(requestDataSelector, (state) => state.data || {}));
  const flags = useFlags();
  const query = useQuery();
  const { participants, selectedParticipantId, participantIdsOnThisDevice } = useParticipants();
  const { selectedDoc, docs, setDocumentLoading, documentLoading } = useDocs();
  const { onAuditTrail } = useAuditTrail(selectedParticipantId, selectedDoc);

  const insertLuckyOrange = useMemo<boolean>(() => {
    const { domains = [] } = flags['luckyOrange'] || {};
    const found = _.find(domains, (domain) => window.location.host.includes(domain));
    return !!found;
  }, [flags]);



  window.globalDocumentLoading = documentLoading;
  return (
    <Viewer
      config={{
        ...config({
          isEsign: true,
          disableElements: [
            'searchOverlay',
            'signatureToolButton',
            'header',
            'pageNavOverlay',
            'signatureModal',
            /* 'annotationStyleEditButton', ENL2020-1959 */
            'annotationGroupButton'
          ],
        }),
        css: `/api/pdftronTheme?iframe=${query.iframe}&org=${query.org}`,
        fullAPI: true,
        flattenClientSide: false,
        isMobile: isMobileEnvironment,
      }}
      isEsign={true}
      toolConfig={{
        ...toolConfigs['signer'],
        disableDblClick: true
      }}
      isAdminUser={false}
      flattenSpecialFields={false}
      docs={docs}
      signers={_.values(participants).map((participant) => ({ ...participant, user: participant }))}
      selectedDoc={wv && selectedDoc}
      selectedSigner={selectedParticipantId}
      currentUser={selectedParticipantId}
      isReadOnly={docs[selectedDoc]?.readOnly}
      onAuditTrail={onAuditTrail}
      onBeforeLoadDocument={async (instance, prevDocId) => {
        instance.docViewer.on('annotationsLoaded',() => {
          setDocumentLoading(false);
        })
        setDocumentLoading(true);
        if (!prevDocId) return;
      }}
      onAnnotationsImported={async (instance) => {
        const annotations = instance.annotManager.getAnnotationsList();

        annotations.map((a) => {
          a.ReadOnly = true;
          if (!requestData.deletableAnnotsEnabled && a.CustomData?.corrId) {
            a.Locked = true;
          }
          a.Author = a.Author || a.CustomData.signerId;
          return a;
        });

        const corrIds = _.chain(annotations)
          .filter((a) => !!a.CustomData.corrId)
          .map('CustomData.corrId')
          .value() as Array<string>;

        const annotsToHide = _.chain(annotations)
          .filter((a) => _.indexOf(corrIds, a.Id) > -1 || isFlattenedAnnot(a) || !participantIdsOnThisDevice.includes(a.Author))
          .value();

        await instance.annotManager.hideAnnotations(annotsToHide);

        setDocAnnots(instance.getDocId(), annotations);
      }}
      onAnnotationAdded={(a) => {
        const annot = wv.annotManager.getAnnotationById(a.id);
        const isTextTemplate = (annot.Subject?.toLowerCase() === "free text");
        const isSignatureTemplate = (annot.Subject?.toLowerCase() === "signature");
        annot.NoResize = !(isTextTemplate || isSignatureTemplate);
        annot.LockedContents = !isTextTemplate;
        annot.NoMove = true;
        if (!requestData.deletableAnnotsEnabled) {
          annot.Locked = !isTextTemplate;
        }
        // See https://enotarylog.atlassian.net/browse/ENL2020-2091 for why you should not select SVG tags.
        wv.annotManager.deselectAllAnnotations();
        wv.annotManager.updateAnnotation(annot);
      }}
      onAnnotationDeleted={(a) => {
        const annot = wv.annotManager.getAnnotationById(a.corrId);
        if (!annot) {
          return;
        }
        // Because the annot was deleted, the tag should be unhidden so it can be executed again
        annot.Hidden = false;
        wv.annotManager.showAnnotation(annot)
        // A required tag should definitely show up as unreviewed. But what about a non-required tag?
        // TODO: discuss with Jen if this code needs to change for non-required tags. Right now, she says she's not sure and she needs more time, but this fix can go in
        annot.setCustomData('applied', false);
        annot.setCustomData('reviewed', false);
        // Update redux
        removeAppliedAnnot(selectedDoc, annot);
      }}
      onAnnotationUpdated={(a) => {
        if (isMobileEnvironment && a?.subject === "Free text" && a?.type === "annotation") {
          wv.setToolMode(wv.Tools.ToolNames.PAN);
        }

        if (!a) {
          return;
        }

        if (a.hidden || a.Hidden) {
          const annot: any = wv.annotManager.getAnnotationById(a.id || a.Id);
          annot.hidden = true;
          annot.Hidden = true;
          addAppliedAnnot(selectedDoc, annot);
        }
      }}
      bindDocEvents={async (inst) => {
        const annotations = await inst.annotManager.importAnnotations(docs[selectedDoc].xfdf);
        const corrIds = _.chain(annotations)
          .filter((annot) => annot.CustomData.corrId)
          .map('CustomData.corrId')
          .value();
        const toShow = _.chain(annotations)
          .filter((a) => _.indexOf(corrIds, a.Id) === -1 && !isFlattenedAnnot(a) && participantIdsOnThisDevice.includes(a.Author))
          .value()

        inst.annotManager.showAnnotations(toShow);

        onDocumentLoaded(inst);
      }}
      onInit={async (instance) => {
        instance.setLock(false);
        instance.hideMessage();
        await setInstance(instance);
        const { annotManager } = instance;
        // ENL2020-1959 - docViewerOnly
        annotManager.on('annotationSelected', async (annots) => {
          await instance.enableElements(['annotationStyleEditButton']);

          // If one annotations is selected disable the annotationsStyleEditButton if its a signature
          const selectedAnnot: any = _.head(annots);

          if (selectedAnnot && selectedAnnot.Subject === 'Signature') {
            await instance.disableElements(['annotationStyleEditButton']);
          }
        });

        // this is a custom event on annotationManager
        annotManager.on('annotationClicked', applyTag)
        annotManager.on('annotationReviewed', (annot) => {
          const docId = instance.getDocId();
          annot.setCustomData('reviewed', true);
          addReviewedAnnot(docId, annot)
        });

        /**
         * There is an `annotManager` event called `annotationDoubleClicked` but there is `annotationClicked`.  We will attach an event
         * listener to the `docViewer` click event and then check if the click was on an annotation.  If it was, we will trigger the
         * `annotationDoubleClicked` event on the `annotManager` so that we can handle the double click event.
         *
         */
        const onClick = (evt) => {
          const annot = instance.annotManager.getAnnotationByMouseEvent(evt);
          if (annot && (annot.Subject.includes('Template') || annot.CustomData?.type === 'FREEFORMTEXTTAGTEMPLATEANNOTATION')) {
            instance.annotManager.trigger('annotationClicked', annot);
          }
        };
        instance.docViewer.on('click', onClick);

        if (supportsTouchEvents) {
          instance.docViewer.on('tap', onClick);
        }
      }}
      nsId={sessionId}
      pdftronServer={flags?.enablePdfTronServer ? process.env.REACT_APP_PDFTRON_SERVER_URL : ''}
      insertLuckyOrange={insertLuckyOrange}
    />
  );
}
