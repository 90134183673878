import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

export interface VADisclaimerModalProps {
  show?: boolean;
  disabled?: boolean;
  onAccept?: React.MouseEventHandler<HTMLButtonElement>;
  onReject?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function VADisclaimerModal(props: VADisclaimerModalProps) {
  return (
    <Modal
      style={{ zIndex: 3000 }}
      show={props.show}
      onHide={() => null}
      variant='primary'
      centered
    >

      <Modal.Body>
        <p className='m-0'>
          <strong>NOTICE: </strong>
          If you are a vulnerable adult as defined in s. 415.102, Florida Statutes,
          the documents you are about to sign are not valid if witnessed by means of audio-video
          communication technology. If you suspect you may be a vulnerable adult, you should have
          witnesses physically present with you before signing.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <button
          type='button'
          className='btn btn-sm btn-primary col-sm-5'
          disabled={props.disabled}
          onClick={props.onAccept}
        >
          I understand and want to proceed
        </button>

        <button
          type='button'
          className='btn btn-sm btn-default col-sm-6'
          disabled={props.disabled}
          onClick={props.onReject}
        >
          Terminate Session and return to home page
        </button>
      </Modal.Footer>
    </Modal>
  );
}

VADisclaimerModal.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
};

VADisclaimerModal.defaultProps = {
  show: false,
  disabled: false,
};
