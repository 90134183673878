import React from 'react';
import { Button, List, ListItem, ListItemText } from '@material-ui/core';
import { useImageStyles, useSignatureInitialsModalStyle } from './styles';
import { ComponentProps } from './types'
import clsx from 'clsx';


const Image = ({ image, alt, onClick }) => {
  const classes = useImageStyles();

  if (image) {
    return (
      <img
        className={classes.listImg}
        src={image}
        alt={alt}
      />
    )
  }

  return (
    <Button
      classes={{
        root: classes.Button,
        label: classes.label,
      }}
      onClick={onClick}
    >
      Set
    </Button>
  );
}

export default function SignatureInitialView({ signature, initials, onClick }: ComponentProps) {
  const classes = useSignatureInitialsModalStyle();

  return (
    <List>
      <ListItemText
        className={clsx(classes.signatureCommon, classes.signatureTop)}
      >
        Signature
      </ListItemText>

      <ListItem className={clsx(classes.signatureCommon, classes.signatureBottom)}>
        <Image
          alt='signature'
          image={signature}
          onClick={onClick}
        />
      </ListItem>



      <ListItemText
        className={clsx(classes.signatureCommon, classes.signatureTop, classes.sigMargin)}
      >
        Initials
      </ListItemText>

      <ListItem
        className={clsx(classes.signatureCommon, classes.signatureBottom)}
      >
        <Image
          alt='initials'
          image={initials}
          onClick={onClick}
        />
      </ListItem>
    </List>
  );
}
