import { isEmpty } from '@enotarylog/ramda';
import _ from 'lodash';

interface CustomDataType {
  type: string
}
interface Annot {
  CustomData: CustomDataType

}


export const loadSignatureInitials = async (instance, type, sig) => {
  deleteCurrentSignatureInitials(instance, type);
  // get instance of the signature tool
  const sigTool = instance.docViewer.getTool('AnnotationCreateSignature');
  const stamp = new instance.Annotations.StampAnnotation();

  // TODO: compute aspect ratio
  stamp.Width = 200;
  stamp.Height = 100;

  // get the base64 converted image of signature/initial
  stamp.ImageData = sig;

  stamp.Subject = 'Signature';

  stamp.CustomData = {
    sigType: type,
    type,
  };
  // save the signature/initial
  await sigTool.saveSignatures([stamp]);
};

export const deleteCurrentSignatureInitials = (instance, type) => {
  const sigTool = instance.docViewer.getTool('AnnotationCreateSignature');

  const currentSigInits = sigTool.getSavedSignatures() as Annot[];
  const index = _.findIndex(currentSigInits, (item) => (item.CustomData.type === type));
  if (index === -1) {
    return;
  }
  sigTool.deleteSavedSignature(index);
};

export const loadSetSignatureInitials = async (participant, instance) => {
  const { signature, initials } = participant || {};
  if (!isEmpty(signature) && !isEmpty(initials) && instance) {
    loadSignatureInitials(instance, 'signature', signature);
    loadSignatureInitials(instance, 'initials', initials);
  }
};
