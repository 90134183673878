import _ from 'lodash';
import { InstanceObject } from '../../viewer';
import { backIcon } from '../constants/icons';

export interface registerHeaderGroupProps {
  groupName: string;
  toolNames: string[];
}

const registerHeaderGroup = ({
  groupName,
  toolNames,
}: registerHeaderGroupProps) => async ({ instance, tools, ...rest }: InstanceObject) => {
  const neededTools = _.map(toolNames, (toolName) => {
    return tools[toolName];
  });

  instance.setHeaderItems((obj: any) => {
    // eslint-disable-next-line no-param-reassign
    obj.headers[groupName] = [
      { type: 'spacer' },
      { type: 'divider' },
      ...neededTools,
      { type: 'divider' },
      {
        type: 'actionButton',
        img: backIcon,
        title: 'Back',
        dataElement: 'goBack',
        onClick: async () => {
          await instance.setToolMode('AnnotationEdit');
          await instance.setActiveHeaderGroup('default');
        },

      },
    ];

    return obj;
  });

  return {
    ...rest,
    tools,
    instance,
  };
};

export default registerHeaderGroup;
