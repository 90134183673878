import LoadingSpinner from "document-viewer/src/components/LoadingSpinner";
import React from "react";
import { useAsync } from "react-use";
import { useTypeItInStyles } from "./styles";
import { Textfit } from 'react-textfit';
import FontFaceObserver from 'fontfaceobserver';
import clsx from "clsx";

const TextPreview = ({ type = 'signature', nameFont, color, name, height = '160px', isVisible }) => {
  const styles = useTypeItInStyles();
  const { loading } = useAsync(() => new FontFaceObserver(nameFont).load(), [nameFont]);
  return (
    <div
      className={type === 'signature' ? styles.signatureText : styles.initialsText}
    >
      <div
        style={{
          fontFamily: nameFont,
          color: color,
          height: height,
          overflowX: 'hidden',
          textOverflow: 'hidden',
        }}
        className={clsx(styles.signatureOrInitials)}
      >
        {
          loading || !isVisible
            ? <LoadingSpinner />
            : name.length < 8
              ? name
              : (
                <Textfit
                  mode='single'
                  forceSingleModeWidth
                  style={{ textOverflow: 'hidden', maxWidth: '100%' }}
                >
                  {name}
                </Textfit>
              )
        }
      </div>
    </div>
  );
};

export default TextPreview
