import React, { useState, useCallback } from 'react'
import Collab from '@enotarylog/pdftron-webviewer/notarize/webviewer/collab';
import { withServerProvider, useServer } from '@enotarylog/pdftron-webviewer/notarize/lib/hooks/useServerProvider';
import { withAppStateProvider, useAppState } from '@enotarylog/pdftron-webviewer/notarize/lib/hooks/AppState';

import * as R from '@enotarylog/ramda';
import _ from 'lodash'
import license from '@enotarylog/pdftron-webviewer/license';
import { useEffectOnce } from 'react-use';
import { useFirebase } from 'document-viewer/src/lib/hooks/useFirebase';
import { sessionPages } from 'document-viewer/src/lib/constants/sessionPages';
import bPromise from 'bluebird'
import usePresence from 'document-viewer/src/lib/hooks/usePresence';
import { useQuery } from 'document-viewer/src/lib/hooks/useQuery';
import { useQuery as useApolloQuery } from '@apollo/client';
import { GET_NS } from 'document-viewer/src/lib/gql/notary-session';

const whiteLabelId = '8d976a23-b865-4fcd-9165-ddc0aedaf614'

function NotaryRoom(props) {

  const [, setWvInst] = useState(null);
  const appState = useAppState();
  const firebase = useFirebase();
  const query = useQuery();
  const server = useServer();


  usePresence({
    nsId: query.nsId,
    witnesses: 0,
    page: 'waiting-room'
  })

  useEffectOnce(() => {

    // if (appState.status === 'complete_fail') {
    //   // If the session has a status of complete failed redirect user
    //   router.push(`/notarization/${router.query.nsId}`);
    // }

    /* sessionReloadedAt Firebase value
     * Used to reload the session for the consumer if modified with global editing
     * This is important to do when global editing changes because if the users KBA status happens to change durring
     * global editing then that user would half to enter their pin and go back through KBA
     * NOTE: could also be used for compliance based reloads (like kba reset)
     * */
    server.bind('onCompletingChanged', null, ({ val: completionStatus }) => {
      if (completionStatus === 'completed') {
        // leaveRoom();
      }
    }, 'main');

    server.bind('onAuthorsChanged', null, ({ val }) => {
      appState.setSigners(R.mergeDeepRight(appState.getSigners(), val || {}));
    }, 'main');

    // listen for ns status changes
    server.bind('onStatusChanged', null, async ({ val: status }) => {
      const assignedToOrg = appState.notarySession?.request?.assignedToOrg;

      // Change page in fb when in progress to move ENL retail flow card over to notary room column
      const orgRef = firebase.database().ref(`/organization/${whiteLabelId}`);

      const presenceRef = orgRef.child('sessions')
        .child(props.nsId);

      if (status === 'in_progress' && !assignedToOrg) {
        await presenceRef.child('page').set(sessionPages.NOTARY_ROOM);
      } else if (!assignedToOrg) {
        await presenceRef.child('page').set(sessionPages.WAITING_ROOM);
      }

      if (appState.status === 'in_progress' && appState.status !== status) {
        await bPromise.map(_.values(appState.signers), (signer) => server.updateAuthor(signer.id, { joined: false }));
        const authors = await server.getAuthors();

        appState.setSigners(R.mergeDeepRight(appState.getSigners(), authors || {}));
      }

      if (status !== 'in_progress' && (status === 'pending_payment' || (status || '').includes('complete'))) {
        // return router.push(`/notarization/${router.query.nsId}`);
      }

      appState.setStatus(status);
    }, 'main');

    server.bind('onPinModalChanged', null, ({ val }) => {
      appState.setPinModal(val);
    }, 'main');

    server.bind('onAuthPinModalChanged', null, ({ val }) => {
      appState.setAuthPinModal(val);
    }, 'main');

    server.bind('onSelectedSignerChanged', null, ({ val }) => {
      appState.setSelectedSigner(val);
    }, 'main');

    server.bind('onPinModalChanged', null, ({ val }) => {
      appState.setPinModal(val);
    }, 'main');

    // server.bind('onStatusChanged', null, async () => api.getNs(router.query.nsId)
    //   .then(R.prop('data'))
    //   .then(R.prop('notary'))
    //   .then(appState.setNotary), 'main');

    return () => {
      server.unbindAll('main');
    };
  });

  return (
    <Collab
      userType='signer'
      onVADisclaimerSubmit={R.identity}
      userPinAuthModalOpen={R.identity}
      pdftronServer={false}
      {...props}
      height='100%'
      selectedDoc={_.last(_.keys(props.docs))}
      onInit={setWvInst}
      flattenSpecialFields
      showDoc
    />

  )
}





const composeComponent = R.compose(
  React.memo,
  withAppStateProvider(({ runId, whiteLabelId }) => {

    const query = useQuery();
    const { data, error, loading } = useApolloQuery(GET_NS, { variables: { id: query.nsId }, });

    const getState = useCallback(() => ({
      loading: loading,
      error: error,
      value: {
        signerLocation: 'remote',
        runId,
        docs: _.chain(data?.notarySession?.docs)
          .map((d) => ({
            ...d,
            url: `${window.location.origin}/api/viewDocument?gcsRefId=${encodeURIComponent(d.gcsRefId)}`
          }))
          .groupBy('id')
          .mapValues(_.head)
          .value(),
        notary: data?.notarySession?.notary,
        witnesses: data?.notarySession?.witnesses || 0,
        enableLogRocket: false,
        config: { l: license },
        selectedDoc: _.get(_.last(data?.notarySession?.docs), 'id'),
        notarySession: data?.notarySession,
        isAdminUser: false,
        nsId: data?.notarySession?.id,
        signers: data?.notarySession?.notarySessionUsers,
        status: data?.notarySession?.status,
        whiteLabelId,
        // twilio: statusState.value?.twilio,
      },
    }), [loading, error, runId, data?.notarySession, whiteLabelId]);


    return getState();

  }),
  withServerProvider((props) => {
    // const { organizationId, nsId } = useParams();
    const appState = useAppState();
    const firebase = useFirebase();
    const query = useQuery()

    return {
      ...props,
      nsId: query.nsId,
      whiteLabelId,
      firebase,
      rtdbNamespace: props.notarySession?.request?.assignedToOrg || whiteLabelId,
      signers: props.signers,
      signerLocation: props.signerLocation,
      requiredSigners: props.signers,
      userId: props.userId,
      user: props.user,
      runId: appState.getRunId(),
      userType: props.userType,
    };
  })

);

export default composeComponent(NotaryRoom);
