export function obscureEmail(email: string) {
  const [name, domain] = email.split('@');
  const firstLetter = name.charAt(0);
  const stars = '*'.repeat(name.length - 1);
  const endOfEmailStr = domain || 'unknown';

  return `${firstLetter}${stars}@${endOfEmailStr}`;
}

export function obscurePhone(phone: string) {
  const lastFourPhone = phone ? phone.substring(phone.length - 4) : '****';

  return `******${lastFourPhone}`;
}
