import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'document-viewer/src/lib/hooks/useTheme';

const drawerWidth = 240;

interface PropsType {
  isMobile?: boolean;
  panelOpen?: boolean;
}

export const useLeftPanelStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    left: 0,
    height: '100%',
    zIndex: 99,

    // TS only needs this first declaration to infer the props type
    position: ({ panelOpen, isMobile }: PropsType) => (!isMobile && panelOpen) ? 'relative' : 'fixed',
    display: ({ panelOpen }) => !panelOpen ? 'none' : 'inherit',
  },
  drawerPaper: {
    width: drawerWidth,
    height: '100%',
    top: 'unset',
    position: 'relative',
    overflowY: 'hidden',
    display: 'flex',
    background: theme.palette.background.paper,
    boxShadow: '0 6px 8px rgba(0,0,0, .1)',
  },
  drawerHeader: {
    height: '46px',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-start',
  },
  thumbnail: {
    display: 'flex',
    paddingBottom: 40,
    justifyContent: 'center',
    '& > canvas': {
      border: '1px solid grey',
    },
  },

}));

export const useRightPanelStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    right: 0,
    height: '100%',

    // TS only needs this first declaration to infer the props type
    position: ({ panelOpen, isMobile }: PropsType) => (!isMobile && panelOpen) ? 'relative' : 'fixed',
    display: ({ panelOpen }) => !panelOpen ? 'none' : 'inherit',
  },
  drawerPaper: {
    width: drawerWidth,
    top: 'unset',
    position: 'relative',
    background: theme.palette.background.paper,
    overflowY: 'unset',
    boxShadow: '0 6px 8px rgba(0,0,0, .1)',
  },
  drawerHeader: {
    height: '46px',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-start',
  },

  iconLabel: {
    color: 'inherit',
    cursor: 'pointer',
    padding: '.5rem',
    transition: 'transform ease-in .2s',
    '&span': { paddingLeft: '.5rem' },
    '&:hover': {
      color: theme.palette.primary.main,
      transform: 'scale(1.05)'
    },
    '& > * + *': {
      marginLeft: '1em',
    }
  },

  noBorder: { borderBottom: 'none !important' },

  search: { '& *': {
    borderColor: `${theme.palette.text.primary} !important`
  } },

  successLight: { backgroundColor: theme.palette.success.light },

  activeIconLabel: { color: theme.palette.primary.main },

  participantBox: {
    padding: 16,
    borderRadius: '8px 0 0 8px',
    margin: 16,
    marginRight: 0,
    boxShadow: '0 4px 4px rgba(0,0,0,.2)'
  },
  summaryHeader: {
    margin: 0,
    padding: 0,
    cursor: 'auto',
    textTransform: 'uppercase'
  },

  flexShrink: { flexShrink: 0 },
  zoomTxt: { fontSize: '1rem' },

}));

export const usePanelToggleStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: 'pointer',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    fontSize: '1.2rem',
    zIndex: 999,
    position: 'fixed',
    padding: 4,
  },
  isOpen: {
    padding: 0,
  },
  isIcon: {
    background: `${theme.palette.text.primary} !important`,
  },
  bar: {
    padding: 0,
    height: '100%',
    background: theme.palette.background.default,
    filter: 'brightness(.9)',
  },
  icon: {
    margin: 8,
    borderRadius: '100%',
    background: theme.palette.background.paper,
    boxShadow: '0 4px 4px rgba(0,0,0,.2)',
  },
  arrowIcon: {
    height: '1.8rem',
    width: '1.8rem',
    color: theme.palette.background.paper,
  },
}));
