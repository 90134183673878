import firebase from 'firebase/app';
import 'firebase/auth'; // If you need it
import 'firebase/database'; // If you need it
import firebaseConfig from 'document-viewer/src/lib/constants/firebase.config';




// Check that `window` is in scope for the analytics module!
if (typeof window !== 'undefined' && !firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  // To enable analytics. https://firebase.google.com/docs/analytics/get-started
  if ('measurementId' in firebaseConfig) firebase.analytics();
  (window as unknown as any).firebase = firebase;
}

export default firebase;
