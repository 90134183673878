/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import _ from 'lodash';
import * as R from 'ramda';
import { parseName } from '@enotarylog/tag-utils/helpers/parseName';
import { tagTypes } from '@enotarylog/tag-utils/constants/tagTypes';
import { createContent } from '@enotarylog/tag-utils/createContent';
import { parseField } from '@enotarylog/tag-utils/helpers/parseField';
import annotNameIncludes from '../../helpers/annotNameIncludes';
import { loadImgWithDataUrl, getSvgDimensions } from '../../helpers/loadImg';


export interface SelectSignerProps {
  annotManager?: any;
  instance?: any;
  signers?: any;
  customTags?: any;
}

export interface SelectSignerState {
  signerId?: string | null;
}

export default class SelectSigner extends Component<SelectSignerProps, SelectSignerState> {
  constructor(props: SelectSignerProps) {
    super(props);
    this.state = { signerId: null };
  }

  componentDidMount = async () => {
    const selectedAnnots = this.props.annotManager.getSelectedAnnotations();


    const annots = _.filter(selectedAnnots, (a) => {
      return (a.Subject && a.Subject.includes('Template'));
    });

    const hasNotaryTag = _.filter(selectedAnnots, (a) => {
      return a.CustomData.type in tagTypes.notaryVerbiageTags || annotNameIncludes(a, 'SealTag') || a.CustomData.type === tagTypes.addressTags.STATETEMPLATE.type;
    });
    const hasSignerTemplate = _.filter(selectedAnnots, (annot) => {
      return (annot.CustomData.type in tagTypes.nameTags);
    });


    const notary = _.find(this.props.signers, { type: 'notary' });


    if (annots.length === 1) {
      const signerId = annots[0].CustomData.signerId;

      if (R.not(R.isEmpty(hasNotaryTag))) {
        await this.setState({ signerId: `${notary.id}` });
        _.map(annots, (a) => {
          this.setSigner(`${notary.id}`, a);
        });
      } else if (R.not(R.isEmpty(hasSignerTemplate)) && notary?.id === signerId) {
        // Set to head of signers
        _.map(annots, (a) => {
          this.setSigner(_.head<any>(this.props.signers).id, a);
        });
        this.setState({ signerId: _.head<any>(this.props.signers).id });
      } else await this.setState({ signerId });
    } else {
      // Set to an hidden option unassigned signer so a signer must be selected
      await this.setState({ signerId: '-1' });
    }
  }

  setSigner = async (id: string, annot: any) => {
    const { instance } = this.props;

    const signer = instance.getSignerById(id);
    const fullName = parseName({
      lastName: '',
      ...signer,
    });

    const color = [signer.color.R, signer.color.G, signer.color.B, signer.color.A];
    const customdata = {
      ...annot.CustomData,
      signerId: id,
      color,
      name: fullName,
      fullName,
    };
    const annotName = (annot.Subject || annot.constructor?.name || '').toUpperCase();

    if (annotNameIncludes(annot, 'SealTag')) {
      annot.CustomData = customdata;
      annot.custom = customdata;
      annot.Author = id;

      annot.setModified(true);

      instance.annotManager.drawAnnotationsFromList([...instance.annotManager.getAnnotationsList(), annot]);
      instance.annotManager.trigger('annotationChanged', [[annot], 'modify', { imported: false, isUndoRedo: false }]);
      instance.annotManager.trigger('setSelectedSigner', [id]);

      return this;
    }

    if (annotName in tagTypes.imageTags) {
      const tag = (this.props.customTags[annotName] || tagTypes.imageTags[annotName as keyof typeof tagTypes.imageTags])
      const svg = !('svg' in tag) ? '' : tag.svg
        .replace(/\{\{color\}\}/ig, `rgb(${signer.color.R}, ${signer.color.G}, ${signer.color.B}, ${signer.color.A})`)
        .replace(/\{\{signername\}\}/ig, signer?.fullName ? signer?.fullName : fullName);
      const win = window.URL || window.webkitURL;
      const blob = new Blob([svg], { type: 'image/svg+xml' });
      const url = win.createObjectURL?.(blob);
      const { dataUrl } = await loadImgWithDataUrl(url, getSvgDimensions(svg));

      annot.CustomData = customdata;
      annot.custom = customdata;
      annot.Author = id;

      annot.ImageData = dataUrl;
      annot.setModified(true);
      instance.annotManager.redrawAnnotation(annot);
      instance.annotManager.drawAnnotationsFromList([...instance.annotManager.getAnnotationsList(), annot]);
      instance.annotManager.trigger('annotationChanged', [[annot], 'modify', { imported: false, isUndoRedo: false }]);
      instance.annotManager.trigger('setSelectedSigner', [id]);

      return this;
    }


    let content = createContent(annot.CustomData, signer);

    if (R.isNil(content) || R.isEmpty(content)) {
      // Nil or empty give no field entered give no styling
      content = parseField(annot.CustomData.type);
      annot.setRichTextStyle({ 0: {} });
    } else {
      annot.setRichTextStyle({ 0: {
        'text-decoration': 'underline',
      } });
    }

    if (R.not(annot.CustomData.type in tagTypes.dateTags)) {
      annot.setContents(content);
    }


    annot.FillColor = new instance.Annotations.Color(...color);
    annot.CustomData = customdata;
    annot.custom = customdata;
    annot.Author = id;

    annot.setModified(true);

    instance.annotManager.drawAnnotationsFromList([...instance.annotManager.getAnnotationsList(), annot]);
    instance.annotManager.trigger('annotationChanged', [[annot], 'modify', { imported: false, isUndoRedo: false }]);
    instance.annotManager.trigger('setSelectedSigner', [id]);

    return this;
  }

  render() {
    const selectedAnnots = this.props.annotManager.getSelectedAnnotations();

    const templateAnnots = _.filter(selectedAnnots, (a) => {
      return (a.Subject && a.Subject.includes('Template')) || _.forEach(tagTypes.imageTags, (type) => {
        annotNameIncludes(a, type);
      });
    });

    // NOTE: Stop signer select from showing on notary tags and stop showing up on freetext PERIOD
    const annots = _.filter(templateAnnots, (annot) => {
      return R.not(annot.CustomData.type in tagTypes.notaryVerbiageTags)
      && R.not(annotNameIncludes(annot, 'SealTag'))
      && annot.CustomData.type !== tagTypes.addressTags.STATETEMPLATE.type
      && annot.Subject !== 'Free text';
    });
    const consumer = _.find(this.props.signers, { type: 'consumer' });
    const otherSigners = _.chain(this.props.signers)
      .filter((el) => !_.isEqual(el.type, 'consumer'))
      .sortBy(['lastName', 'firstName'])
      .value();

    let signers = _.filter([consumer, ...otherSigners], (el) => !_.isNil(el));
    // NOTE: Stop notary from show up on signer templates
    const hasSignerTemplate = _.filter(annots, (annot) => {
      return (annot.CustomData.type in tagTypes.nameTags);
    });

    if (R.not(R.isEmpty(hasSignerTemplate))) {
      signers = _.filter(signers, (signer) => {
        return signer.type !== 'notary';
      });
    }


    // TODO: set annot.Author and annot.CustomData.type
    if (annots.length === selectedAnnots.length) {
      return (
        <div>
          <label htmlFor='signer'>Signer: </label>
          <select
            value={this.state.signerId as string}
            onChange={(ev) => {
              _.map(annots, (a) => {
                this.setSigner(ev.target.value, a);
                this.setState({ signerId: ev.target.value });
              });
            }}
          >
            <option
              key='-1'
              value='-1'
              hidden
              style={{ display: 'none' }}
            >
              Select A Signer
            </option>
            {
              _.map(signers, (signer) => {
                return (
                  <option
                    key={signer.id}
                    value={signer.id}
                  >
                    { signer.type !== 'notary' ? parseName(signer) : 'Notary'}
                  </option>
                );
              })
            }
          </select>
        </div>
      );
    }

    return null;
  }
}
