import React from 'react'

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { Suspense, lazy } from 'react';

// This provides LaunchDarkly, without having any user specified

export const LDClient = lazy(async () => {
  const LDClient = await asyncWithLDProvider({
    clientSideID: process.env.NX_LAUNCH_DARKLY_API_KEY!,
    user: { anonymous: true },
    options: {
      allAttributesPrivate: true,
      bootstrap: {
        autoSignDelay: 1,
      },
    },
  })

  return { default: LDClient };
});


export const FlagsProvider = ({ children }) => (
  <Suspense fallback={<></>}>
    <LDClient>
      {children}
    </LDClient>
  </Suspense>
)

export { useFlags } from 'launchdarkly-react-client-sdk';
