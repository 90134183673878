import * as R from 'ramda';
import _ from 'lodash';
import fetch from 'isomorphic-unfetch';
import registerAnnotationType from '../initializers/registerAnnotationType';
import defineToolClass from '../initializers/defineToolClass';
import defineAnnotClass from '../initializers/defineAnnotClass';
import { loadImgWithDataUrl, getSvgDimensions } from '../helpers/loadImg';
import { InstanceObject } from '../../viewer';

// NOTE: this is used for creating a "img/stamp annotation" inside the notary room
const onMouseLeftUp = ({ imageUrl, width: _width = 300 }: any) => async ({ instance, context, ...rest }: InstanceObject) => {
  instance.Tools.GenericAnnotationCreateTool.prototype.mouseLeftDown.apply(context.tool, context.args);

  let width = _width + 0;

  const { tool } = context;

  let annotation;

  const { dataUrl, img } = (!_.isFunction(imageUrl)) ? await loadImgWithDataUrl(imageUrl) : await loadImgWithDataUrl(await imageUrl({ instance, context, ...rest }));

  if (!_.isEmpty(tool.annotation)) {
    tool.aspectRatio = img.width / img.height;
    let height = width / tool.aspectRatio;

    const rotation = instance.docViewer.getCompleteRotation(1) * 90;

    tool.annotation.Rotation = rotation;

    if (rotation === 270 || rotation === 90) {
      const t = height;

      height = width;
      // eslint-disable-next-line no-param-reassign
      width = t;
    }

    tool.annotation.ImageData = dataUrl;
    tool.annotation.Width = width;
    tool.annotation.Height = height;
    tool.annotation.X -= width / 2;
    tool.annotation.Y -= height / 2;

    annotation = tool.annotation;
  }

  instance.Tools.GenericAnnotationCreateTool.prototype.mouseLeftUp.apply(context.tool, context.args);

  if (annotation) {
    instance.annotManager.deselectAllAnnotations();
    instance.annotManager.redrawAnnotation(annotation);
    instance.setToolMode('AnnotationEdit');
    instance.annotManager.selectAnnotation(annotation);
  }
};


// eslint-disable-next-line no-unused-vars
const onMouseLeftDown = () => R.identity;

export interface createStampAnnotationProps {
  name: string;
  imageUrl: string;
  width: number;
  buttonImage?: any;
}

const createStampAnnotation = ({
  name,
  imageUrl,
  width,
}: createStampAnnotationProps) => R.pipeP(
  // define annot class
  defineAnnotClass({
    className: `${name}Annotation`,
    baseClassName: 'StampAnnotation',
    customData: { imageUrl },
    methods: {
      onDoubleClicked: ({ instance }: InstanceObject) => async function (this: any) {
        const sigTool = instance.docViewer.getTool('AnnotationCreateSignature');
        const [sig] = sigTool.getSavedSignatures();
        const res = await fetch(this.CustomData.imageUrl);
        const svgString = await res.text();


        if (!sig) {
          return;
        }

        const sigDataUrl = await sigTool.getPreview(sig);
        const newSvgStr = svgString.replace('<image xlink:href=""', `<image xlink:href="${sigDataUrl}"`);
        const DOMURL = window.URL || window.webkitURL;
        const svg = new Blob([newSvgStr], { type: 'image/svg+xml;charset=utf-8' });
        const svgurl = DOMURL.createObjectURL(svg);

        const { dataUrl /* img */ } = await loadImgWithDataUrl(svgurl, getSvgDimensions(newSvgStr));

        this.ImageData = dataUrl;

        return instance.annotManager.redrawAnnotation(this);
      },
    },
  }),

  // define tool class that uses annot class
  defineToolClass({
    className: `${name}Tool`,
    annotClassName: `${name}Annotation`,
    baseClassName: 'GenericAnnotationCreateTool',
    switchIn: async ({ instance }: InstanceObject) => instance.setActiveHeaderGroup('default'),
    onMouseLeftDown: onMouseLeftDown(),
    onMouseMove: ({ instance, context }: InstanceObject) => {
      return instance.Tools.AnnotationSelectTool.prototype.mouseMove.apply(context.tool, context.args);
    },
    onMouseLeftUp: onMouseLeftUp({ imageUrl, width }),
  }),

  // register the custom annot class to webviewer
  registerAnnotationType({
    elementName: name,
    annotClassName: `${name}Annotation`,
  })
);


export default createStampAnnotation;
