import { useEffect, useRef, useState, useReducer } from 'react';
import _ from 'lodash';
import { useFirebase } from 'document-viewer/src/lib/hooks/useFirebase';

const isLoadedReducer = (__, action) => {
  if (action.type === 'LOADED') {
    return {
      loaded: true,
    };
  }

  if (action.type === 'UNLOADED') {
    return {
      loaded: true,
    };
  }

  return {
    loaded: false,
  };
};

export default function usePresence(conf) {
  const firebase = useFirebase();
  const [, setConnectionRefAttached] = useState(false);
  const connectionRef = useRef<firebase.default.database.Reference>();
  const presenceRef = useRef<firebase.default.database.Reference>();
  const [{ loaded }, dispatch] = useReducer(isLoadedReducer, { loaded: false });

  const whiteLabelId = '8d976a23-b865-4fcd-9165-ddc0aedaf614'

  useEffect(() => {
    if (firebase) {
      const connRef = firebase.database().ref('.info/connected');
      const orgRef = firebase.database().ref(`/organization/${whiteLabelId}`);

      const pRef = orgRef.child('sessions')
        .child(conf.nsId);


      connectionRef.current = connRef;
      presenceRef.current = pRef;

      const conn = connectionRef.current;

      conn.on('value', async (snapshot) => {
        if (snapshot.val() === false) {
          console.debug('%cfirebase not connected 🔥!', 'color: red; font-size:20px');

          return;
        }

        console.debug('%cfirebase connected 🔥!', 'color: blue; font-size:20px');

        try {
          await pRef.onDisconnect().remove();

          await pRef.child('connected').set(true);

          const currSnap = await pRef.once('value');
          const currVal = currSnap.val();
          const currUtc = (new Date()).toISOString();
          const p = _.pickBy({
            ...currVal,
            page: conf.page,
            witnesses: conf.witnesses,
            connected: true,
            createdAt: !currVal.createdAt ? currUtc : currVal.createdAt,
            updatedAt: currUtc,
          }, _.identity);

          await pRef.set(p);
          setConnectionRefAttached(true);
          dispatch({ type: 'LOADED' });
        } catch (error) {
          console.error(error);
        }
      });

      return () => {
        (async () => {
          await pRef.child('page').set('n/a');
          dispatch({ type: 'UNLOADED' });
          await conn.off('value');
        })()
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (conf.updateAuthorsConnectedOnDisconnect) {
      Object.keys(conf.updateAuthorsConnectedOnDisconnect).forEach((id) => {
        firebase.database().ref(`/organization/${whiteLabelId}/rooms/${conf.nsId}/authors/${id}/connected`)
          .onDisconnect()
          .set(false);
      });
    }
  }, [conf.nsId, conf.updateAuthorsConnectedOnDisconnect, firebase, whiteLabelId]);


  return {
    refs: {
      connection: connectionRef.current,
      presence: presenceRef.current,
    },
    isLoading: !loaded,
  };
}
