import { makeStyles } from '@material-ui/core/styles';
import LoadingScreen from 'document-viewer/src/components/LoadingScreen';
import Grid from '@material-ui/core/Grid';
import useEvent from 'react-use/esm/useEvent';
import { useLocalStorage } from 'react-use';
import { useRequestData } from 'document-viewer/src/lib/hooks/useRequestData';
import { useEffect } from 'react';
import { useQuery } from 'document-viewer/src/lib/hooks/useQuery';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  iframe: {
    height: '615px',
    width: '100%',
  },
}));

export default function EquipmentCheck({ onComplete }) {
  const { loading, equipmentCheckUrl } = useRequestData();
  const classes = useStyles();
  const [passedEqCheckForSession, setPassedEqCheckForSession] = useLocalStorage('passedEqCheckForSession', null);
  const { session } = useQuery();

  useEvent('message', ({ data, source }) => {
    if (data.event === 'equipment-check-passed') {
      setPassedEqCheckForSession(session);

      // Propagate the equipment-check-passed event upwards if this is in an iframe
      if (window.parent !== window && source !== window.parent) {
        // Only emit super safe info that can safely be leaked to anyone, like "equipment-check-passed", not any PII or ids that could be used for anything interesting.
        // If you need to emit something more interesting, don't use the origin '*'
        window.parent.postMessage({ event: data.event }, '*');
      }
    }
  });


  useEffect(() => {
    if (passedEqCheckForSession === session) {
      onComplete();
    }
  }, [onComplete, passedEqCheckForSession, session])



  if (loading) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <Grid
      className={classes.root}
      container
      direction='column'
    >
      <Grid item></Grid>
      <Grid item>
        <iframe
          src={equipmentCheckUrl}
          title='Equipment Check'
          allow='camera *;microphone *;fullscreen'
          className={classes.iframe}
        />
      </Grid>
    </Grid>
  );
}
