import * as R from 'ramda';
import _ from 'lodash';
import fetch from 'isomorphic-unfetch';
import { parseName } from '@enotarylog/tag-utils/helpers/parseName';
import { tagTypes } from '@enotarylog/tag-utils/constants/tagTypes';
import registerTool from '../initializers/registerTool';
import registerAnnotationType from '../initializers/registerAnnotationType';
import defineToolClass from '../initializers/defineToolClass';
import defineAnnotClass from '../initializers/defineAnnotClass';
import { loadImgWithDataUrl, getSvgDimensions } from '../helpers/loadImg';
import { InstanceObject } from '../../viewer';

const minimumDimensionSize = 8;

// NOTE: this is used for creating a "img/stamp tag in the doc-upload"
const onMouseLeftUp = ({ imageUrl, svgPath, name, width: _width = 300, maintainAspectRatio = true }: any) => async ({ instance, config, context, ...rest }: InstanceObject) => {
  instance.Tools.GenericAnnotationCreateTool.prototype.mouseLeftDown.apply(context.tool, context.args);

  const { tool } = context;

  let annotation;
  let width = _width + 0;
  let height = 0;
  const { img } = (!_.isFunction(imageUrl)) ? await loadImgWithDataUrl(imageUrl) : await loadImgWithDataUrl(await imageUrl({ instance, context, ...rest }));


  if (!_.isEmpty(tool.annotation)) {
    tool.aspectRatio = img.width / img.height;
    height = width / tool.aspectRatio;

    const rotation = instance.docViewer.getCompleteRotation(1) * 90;

    tool.annotation.Rotation = rotation;

    if (rotation === 270 || rotation === 90) {
      const t = height;

      height = width;
      // eslint-disable-next-line no-param-reassign
      width = t;
    }

    // On create set to signer
    const currentSignerId = instance.getSelectedSigner();
    const signer = instance.getSignerById(currentSignerId);

    if (!signer) {
      console.debug('No signer selected');

      return;
    }

    const color = signer ? [signer.color.R, signer.color.G, signer.color.B, signer.color.A] : undefined;

    const fullName = parseName({
      lastName: '',
      ...signer,
    });


    const customdata = {
      ...tool.annotation.CustomData,
      signerId: currentSignerId,
      color,
      name: fullName,
      fullName,
    };

    tool.annotation.CustomData = customdata;
    tool.annotation.custom = customdata;
    const svgString = config.customTags?.[customdata.type] || svgPath;
    let coloredString = svgString;
    const upperCaseName = name.toUpperCase();

    if (Object.keys(tagTypes.imageTags).some((tagName) => tagName.includes(upperCaseName))) {
      coloredString = svgString
        .replace(/\{\{Color\}\}/ig, `rgb(${signer.color.R}, ${signer.color.G}, ${signer.color.B}, ${signer.color.A})`)
        .replace(/\{\{SignerName\}\}/ig, signer?.fullName ? signer?.fullName : fullName);
      tool.annotation.CustomData.signerId = currentSignerId;
    } else {
      // for seal stamp Tag
      tool.annotation.CustomData.signerId = 'Notary';
    }

    const win = window.URL || window.webkitURL;
    const blob = new Blob([coloredString], { type: 'image/svg+xml' });
    const url = win.createObjectURL(blob);
    const { dataUrl } = await loadImgWithDataUrl(url, getSvgDimensions(coloredString));
    tool.annotation.Opacity = 1;
    tool.annotation.ImageData = dataUrl;
    tool.annotation.Width = width;
    tool.annotation.Height = height;
    tool.annotation.MaintainAspectRatio = maintainAspectRatio;
    tool.annotation.X -= width / 2;
    tool.annotation.Y -= height / 2;

    annotation = tool.annotation;
  }

  instance.Tools.GenericAnnotationCreateTool.prototype.mouseLeftUp.apply(context.tool, context.args);

  if (annotation) {
    instance.annotManager.on('annotationChanged', (annotations, action) => {
      if (action) {
        if (annotations[0].ToolName === 'CheckHereTagTemplateTool' || annotations[0].ToolName === 'RadioButtonTagTemplateTool') {
          if (annotations[0]?.Height < 10) {
            annotations[0].Width = 10;
            annotations[0].Height = 10;
          }
          if (annotations[0]?.Height > 32) {
            annotations[0].Width = 32;
            annotations[0].Height = 32;
          }
        }
        else if (
          annotations[0]?.Height < minimumDimensionSize
          && (
            annotations[0].ToolName === 'SignHereTagTemplateTool'
            || annotations[0].ToolName === 'InitialHereTagTemplateTool'
          ))
        {
          annotations[0].Height = minimumDimensionSize;
          annotations[0].Width = tool.aspectRatio * minimumDimensionSize;
        }
      }
    })
    instance.annotManager.redrawAnnotation(annotation);
    instance.setToolMode('AnnotationEdit');
    instance.annotManager.selectAnnotation(annotation);
  }
};


// eslint-disable-next-line no-unused-vars
const onMouseLeftDown = () => R.identity;

export interface createStampTagProps {
  name: string;
  imageUrl: string;
  buttonImage: any;
  svgPath: string;
  width: number;
  maintainAspectRatio?: boolean;
  toolGroup: any;
}

const createStampTag = ({
  name,
  imageUrl,
  maintainAspectRatio = true,
  svgPath,
  width,
}: createStampTagProps) => R.pipeP(
  // define annot class
  defineAnnotClass({
    className: `${name}Annotation`,
    baseClassName: 'StampAnnotation',
    customData: { type: _.toUpper(`${name}Annotation`), imageUrl },
    methods: {
      // NOTE: this doesn't seem to do anything
      onDoubleClicked: ({ instance }: InstanceObject) => async function (this: any) {
        const sigTool = instance.docViewer.getTool('AnnotationCreateSignature');
        const [sig] = sigTool.getSavedSignatures();
        const res = await fetch(this.CustomData.imageUrl);
        const svgString = await res.text();


        if (!sig) {
          return;
        }

        const sigDataUrl = await sigTool.getPreview(sig);
        const newSvgStr = svgString.replace('<image xlink:href=""', `<image xlink:href="${sigDataUrl}"`);
        const DOMURL = window.URL || window.webkitURL;
        const svg = new Blob([newSvgStr], { type: 'image/svg+xml;charset=utf-8' });
        const svgurl = DOMURL.createObjectURL(svg);

        const { dataUrl /* img */ } = await loadImgWithDataUrl(svgurl, getSvgDimensions(newSvgStr));

        this.ImageData = dataUrl;

        return instance.annotManager.redrawAnnotation(this);
      },
    },
  }),

  // define tool class that uses annot class
  defineToolClass({
    className: `${name}Tool`,
    annotClassName: `${name}Annotation`,
    baseClassName: 'GenericAnnotationCreateTool',
    // switchIn: async ({ instance }) => instance.setActiveHeaderGroup(toolGroup),
    onMouseLeftDown: onMouseLeftDown(),
    onMouseMove: ({ instance, context }: InstanceObject) => {
      return instance.Tools.AnnotationSelectTool.prototype.mouseMove.apply(context.tool, context.args);
    },
    onMouseLeftUp: onMouseLeftUp({ maintainAspectRatio, imageUrl, svgPath, name, width }),
  }),

  // register the custom annot class to webviewer
  registerAnnotationType({
    elementName: name,
    annotClassName: `${name}Annotation`,
  })
);

export interface createStampTemplateTagProps {
  name: string;
  imageUrl: any;
  svgPath: string;
  buttonImage: any;
  tooltip: any;
  maintainAspectRatio?: boolean;
  width: number;
  hotKey?: any;
  toolGroup: any;
}

export const createStampTemplateTag = ({
  name,
  imageUrl,
  svgPath,
  buttonImage,
  tooltip,
  maintainAspectRatio = true,
  width,
  hotKey,
  toolGroup,
}: createStampTemplateTagProps) => R.pipeP(
  // define annot class
  createStampTag({
    name,
    imageUrl,
    svgPath,
    maintainAspectRatio,
    buttonImage,
    width,
    toolGroup,
  }),

  registerTool({
    type: 'toolButton',
    toolName: `${name}Tool`,
    buttonImage,
    dataElement: name,
    tooltip: `${tooltip} ${hotKey ? `(${hotKey})` : ''}`,
    // hidden: ['tablet', 'mobile'],
  }, { clearOnDraw: false, hotKey, toolGroup })
);

export default createStampTemplateTag;
