import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'document-viewer/src/lib/hooks/useTheme';
import * as bowser from 'bowser';

export const DOCUMENT_THUMBNAIL_HEIGHT = '150px';
export const ICON_SIZE = '15px';

const browserName = bowser.getParser(window.navigator.userAgent).getBrowserName();

// The wrapper of all thumbnails
export const useThumbnailsStyles = makeStyles((theme: Theme) => ({
  centeredText: {
    textAlign: 'center',
    width: '100%',
    lineHeight: 1,
  },
  thumbnailList: { overflowX: 'hidden', paddingBottom: 20 },

  loadingSpinner: { marginTop: theme.signatureAndInitialsInOneStep ? 16 : undefined },
}));

// Each <Thumbnail />
export const useThumbnailStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center' as const,
    padding: 10,
    marginTop: theme.signatureAndInitialsInOneStep ? 16 : undefined,
  },
  thumbContainer: {
    cursor: 'pointer',
    transition: 'transform ease-in .2s',
    display: 'inline-block' as const,
    position: 'relative' as const,
    height: '100%',
    '& canvas': { boxShadow: '0 0 3px 5px rgba(0,0,0,.1) !important' },
    '&:hover': { transform: 'scale(1.1)' },
  },
  inactiveThumb: {
    height: '100%',
    '& img, & canvas': {
      border: `5px solid transparent`,
    },
  },
  activeThumb: {
    height: '100%',
    '& img, & canvas': {
      border: `5px solid ${theme.palette.divider}`,
    },
  },
  icons: {
    position: 'absolute' as const,
    bottom: '0px',
    right: '0px',
    display: 'inline-flex' as const,
    flexWrap: 'wrap-reverse',
    fontSize: ICON_SIZE,
    padding: `0 .5em .5em 0`,
    '& > *': {
      fontSize: '1em',
      background: theme.palette.secondary.main,
      opacity: .8,
      boxShadow: browserName === 'Safari' ? undefined : '0 0 .4em #000A',
      borderRadius: '.5em',
    },
    '& > * + *': {
      marginLeft: '.25em',
    },
  },
  primaryBackground: {
    background: theme.palette.primary.main,
  },
  secondaryBackground: {
    background: theme.palette.secondary.main,
  },
  successLight: {
    background: theme.palette.success.light,
    color: 'rgba(0,0,0,0)' // hide icon content, show only dot
  },
  warningLight: {
    background: theme.palette.warning.main,
    color: 'rgba(0,0,0,0)' // hide icon content, show only dot
  },
  errorLight: {
    background: theme.palette.error.light,
    color: 'rgba(0,0,0,0)' // hide icon content, show only dot
  },
}));

