
import { makeStyles } from '@material-ui/core';
import { Theme } from 'document-viewer/src/lib/hooks/useTheme';

export const useStyles = makeStyles((theme: Theme) => ({

  pwPromptForm: {
    height: theme.signatureAndInitialsInOneStep ? '80%' : undefined
  },

  pwPromptGrid: { height: '100%', textAlign: 'center' },

  pwPromptField: { minWidth: 200, },

  pwPromptButton: {
    minWidth: 200,
    margin: 8,
    position: theme.signatureAndInitialsInOneStep ? 'absolute' : undefined,
    bottom: theme.signatureAndInitialsInOneStep ? 16 : undefined,
  },
  dialogTitle: { textAlign: 'center' }
}));
