import React from 'react';
import Button from '@material-ui/core/Button';
import { Props } from './props';
import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from 'document-viewer/src/components/Dialog';
import { Grid } from '@material-ui/core';
import { useAsyncFn } from 'react-use';
import useCallbackOnceDocLoads from 'document-viewer/src/lib/hooks/useCallbackOnceDocLoads';

const useStyles = makeStyles(() => ({
  btn: { margin: '16px 8px' },
  wideBtn: { minWidth: 200 },
  container: { height: '100%' },
  dialogActions: { justifyContent: 'center' }
}));

export type SaveAndSwitchToDocModalProps = Props;

export default function SaveAndSwitchToDocModal(
  { show, onClose, saveDoc, switchToDoc }: Props
) {
  const classes = useStyles();
  const saveDocAfterLoading = useCallbackOnceDocLoads(saveDoc, [saveDoc]);
  const [saveDocState, pleaseSaveAndSwitch] = useAsyncFn(async () => {
    await saveDocAfterLoading();
    setTimeout(switchToDoc, 0);
    onClose();
  }, [saveDocAfterLoading, switchToDoc, onClose]);
  const switchDocAfterLoading = useCallbackOnceDocLoads(switchToDoc, [switchToDoc]);
  const [switchDocState, pleaseSwitchDocWithoutSaving] = useAsyncFn(async () => {
    await switchDocAfterLoading();
    onClose();
  }, [switchDocAfterLoading, switchToDoc, onClose]);

  const loading = switchDocState.loading || saveDocState.loading;

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby='save-and-switch-to-doc-title'
      aria-describedby='save-and-switch-to-doc-description'
      size='sm'
    >
      <Grid
        container
        className={classes.container}
        alignContent='center'
      >
        <Grid
          item
          xs={12}
        >
          <DialogTitle id='save-and-switch-to-doc-title'>Warning</DialogTitle>
          <DialogContent>
            <DialogContentText id='save-and-switch-to-doc-description'>
              You have made changes to the document without saving, are you sure you&apos;d like to switch documents?
              {/* Note that only saveDocState is checked because switchDoc will never reject */}
              {saveDocState.error && <p>Error saving! Please try again</p>}
            </DialogContentText>
          </DialogContent>
        </Grid>
      </Grid>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={`${classes.btn} ${classes.wideBtn}`}
          variant='contained'
          color='secondary'
          type='button'
          disabled={loading}
          onClick={pleaseSwitchDocWithoutSaving}
        >
          Switch without saving
        </Button>
        <Button
          className={classes.btn}
          variant='contained'
          type='button'
          disabled={loading}
          onClick={onClose}
        >
          Stay
        </Button>
        <Button
          className={`${classes.btn} ${classes.wideBtn}`}
          variant='contained'
          color='primary'
          type='button'
          disabled={loading}
          autoFocus
          onClick={pleaseSaveAndSwitch}
        >
          Save and switch
        </Button>
      </DialogActions>
    </Dialog>
  );
}
