import { participantCompletedDocument, getTotalParticipantTags } from "./participantCompletedDocument";
import { DocStatus } from "./pdftron/docCompletion";
import { Participant } from "./types/participant";

/**
 * a function to take in a document object and return if either the document is completed or if a participant completed the document
 * @param doc Document object
 * @param participant participant
 * @example docIsCompletedOrParticipantCompletedDoc(doc, participants[selectedParticipantId])
 */
export const docIsCompletedOrParticipantCompletedDoc = (doc, participant: Participant, completedNoTagDocList: string[]): boolean => {
  if (doc) {
    return (doc.status === DocStatus.completed && getTotalParticipantTags(doc, participant?.id) !== 0) || participantCompletedDocument(doc, completedNoTagDocList, participant);
  }
  return false
}

