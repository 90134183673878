import { gql } from '@apollo/client';


export const SIGN_DOC = gql`
  mutation signDoc($payload: SignDocumentInput!) {
    signDocument(payload: $payload) {
      id
      status
      revisions {
        id
        documentId
        participantId
        gcsRefId,
        version
        completed
        completedAt
      }
      latestRevision {
        id
        version
        gcsRefId
        xfdf
      }
    }
  }
`;


export const REVISIONS_ADDED = gql`
  subscription OnRevisionAdded($docId: String!) {
    documentRevisionsAdded(docId: $docId) {
      id
      revisions {
        id
        documentId
        participantId
        xfdf
        gcsRefId
        version
        completed
        completedAt
      }
      status
    }
  }
`;

export const DOC_STATUS_CHANGED = gql`
  subscription DocStatusChanged($transactionId: String!) {
    document: documentUpdated(transactionId: $transactionId){
      id
      status
    }
  }
`;
