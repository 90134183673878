import React from 'react'
import { Accordion } from './Accordion'
import {
  AccordionSummary,
  AccordionDetails,
  Grid,
  useTheme,
} from '@material-ui/core';
import Tooltip from 'document-viewer/src/components/Tooltip';
import { DocumentZoom } from 'document-viewer/src/components/DocumentZoom';
import AutoScrollSpeed from 'document-viewer/src/components/AutoScrollSpeed';
import { useRightPanelStyles } from './menus/styles';
import NotesModal from './menus/NotesModal';
import {
  Info as InfoIcon,
  Help as HelpIcon,
  Build as BuildIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import type { Theme } from 'document-viewer/src/lib/hooks/useTheme';
import type { UUID } from 'document-viewer/src/utils/types/uuid';
import type { Document } from 'document-viewer/src/utils/types/document';
import clsx from 'clsx';

const usePanelButtonStyles = makeStyles((theme: Theme) => ({
  activeIconLabel: { color: theme.palette.primary.main },
  iconLabel: {
    color: 'inherit',
    cursor: 'pointer',
    padding: '.5rem',
    transition: 'transform ease-in .2s',
    '&span': { paddingLeft: '.5rem' },
    '&:hover': {
      color: theme.palette.primary.main,
      transform: 'scale(1.05)'
    },
    '& > * + *': {
      marginLeft: '1em',
    }
  },
}))

const PanelToolButton = ({
  text,
  onClick,
  title,
  active,
  icon
}) => {
  const theme = useTheme<Theme>();
  const classes = usePanelButtonStyles(theme);

  return (
    <Tooltip title={title}>
      <Grid
        xs={12}
        item
        container
        className={clsx(classes.iconLabel, active && classes.activeIconLabel)}
        onClick={onClick}
        alignItems='center'
      >
        {icon}
        <span>{text}</span>
      </Grid>
    </Tooltip>
  )
}

export interface Props {
  docs: Record<UUID, Document>;
  isMobile: boolean;
  showGetInformationInDocumentViewer: boolean;
  showRequestHelpInDocumentViewer: boolean;
  showCorrectionsNeededInDocumentViewer: boolean;
  toolsLabelEnabled: boolean;
  zoomLabelEnabled: boolean;
  searchEnabled: boolean;
  showAutoScrollSpeed: boolean;
}

export default function PanelTools(props: Props) {
  const {
    docs,
    isMobile,
    showGetInformationInDocumentViewer,
    showRequestHelpInDocumentViewer,
    showCorrectionsNeededInDocumentViewer,
    toolsLabelEnabled,
    zoomLabelEnabled,
    searchEnabled,
    showAutoScrollSpeed,
  } = props;

  const theme = useTheme<Theme>();
  const classes = useRightPanelStyles({});

  /**
   * If there are no tools to display,
   * or zoom is in sidebar but screen size is mobile
   * bail out.
   */

  const noToolsButZoom = !showAutoScrollSpeed
    && !showGetInformationInDocumentViewer
    && !showRequestHelpInDocumentViewer
    && !showCorrectionsNeededInDocumentViewer;

  if (
    (noToolsButZoom && theme?.zoomIcon !== 'right sidebar')
    || (noToolsButZoom && theme?.zoomIcon === 'right sidebar' && isMobile)
  ) {
    return null;
  }

  let accordionMarginTop = undefined;
  if (searchEnabled) {
    if (toolsLabelEnabled) {
      accordionMarginTop = theme.spacing(-2);
    } else {
      accordionMarginTop = theme.spacing(2);
    }
  }

  return (
    <Accordion
      square
      expanded
      className={classes.flexShrink}
    >
      {toolsLabelEnabled && (
        <AccordionSummary
          aria-controls='panel1d-content'
          id='panel1d-header'
        >
          <h2 className={classes.summaryHeader}>Tools</h2>
        </AccordionSummary>
      )}

      <AccordionDetails>
        <Grid
          container
          style={{ marginTop: accordionMarginTop }}
        >
          {(
            !isMobile
            && theme.zoomIcon === 'right sidebar'
          ) &&
            <Grid
              xs={12}
              item
            >
              {
                (!showGetInformationInDocumentViewer &&
                  !showRequestHelpInDocumentViewer &&
                  !showCorrectionsNeededInDocumentViewer &&
                  zoomLabelEnabled)
                  ? <span className={classes.zoomTxt}>Zoom</span>
                  : null
              }
              <DocumentZoom initialZoomLevel={100} />
            </Grid>}

          {
            showGetInformationInDocumentViewer && (
              <PanelToolButton
                title='See more information about this document'
                active={false}
                onClick={() => { }}
                text='Get Information'
                icon={<InfoIcon />}
              />
            )
          }

          {
            showRequestHelpInDocumentViewer && (
              <PanelToolButton
                active={false}
                onClick={() => { }}
                text='Request Help'
                title='Request help from {sender} for this document'
                icon={<HelpIcon />}
              />
            )
          }


          {
            showCorrectionsNeededInDocumentViewer && (
              <PanelToolButton
                active={false}
                onClick={() => { }}
                text='Corrections Needed'
                title='This document needs corrections before signing'
                icon={<BuildIcon />}
              />
            )
          }

          {showAutoScrollSpeed && <AutoScrollSpeed onlyShowWhenAutoScrolling={false} />}
        </Grid>
      </AccordionDetails>



      <NotesModal
        open={false}
        onClose={() => { }}
        docs={Object.values(docs)}
      />
    </Accordion>
  )
}
