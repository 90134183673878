import React, { useEffect } from 'react';
// import { ThemeProvider } from 'document-viewer/src/lib/hooks/useTheme';
import { routes, renderRoutes } from './routes';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { useSelector } from 'document-viewer/src/store';
import { selector as themeSelector, ThemeState } from 'document-viewer/src/slices/theme';
import { createSelector } from '@reduxjs/toolkit';
import generateThemeOptions from './utils/generateThemeOptions';
import { CssBaseline } from '@material-ui/core';
import WebFont from 'webfontloader';
import { useRequestData } from 'document-viewer/src/lib/hooks/useRequestData';

function App() {
  const themeSettings = useSelector(createSelector(themeSelector, (state: ThemeState) => state.settings));
  useEffect(() => {
    if (!themeSettings.fontType) return;
    if (themeSettings.fontType.toLowerCase() === 'cera') {
      WebFont.load({
        custom: {
          families: ['Cera'],
          urls: [`${process.env.PUBLIC_URL}/static/fonts/cera.css`],
        }
      });
    } else {
      WebFont.load({
        google: {
          families: [themeSettings.fontType],
        },
      });
    }
  }, [themeSettings.fontType]);

  const { requestData } = useRequestData();

  return (
    <ThemeProvider
      theme={createTheme(generateThemeOptions(themeSettings, requestData))}
    >
      <CssBaseline />
      {renderRoutes(routes)}
    </ThemeProvider>
  );
}

export default App;
