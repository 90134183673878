import React from 'react';
import { FlexAccordion, FlexAccordionDetails } from 'document-viewer/src/components/Accordion/flex';
import DocumentThumbnails from 'document-viewer/src/components/DocumentThumbnails';
import DocHeader from './DocHeader';
import { useStyles } from './styles';
import Box from '@material-ui/core/Box';
import type { UUID } from 'document-viewer/src/utils/types/uuid';
import clsx from 'clsx';

interface Props {
  isExpanded?: boolean;
  completedNoTagDocList: string[];
  handleExpand: (docId: string) => (event: React.ChangeEvent<Record<string, any>>, expanded?: boolean) => void;
  currentDocId: UUID;
  doc: any;
  docs?: any;
  currentPageIndex: number;
  onPageSelected: (...args: any[]) => any;
  numPages?: number;
  hiddenHeader?: boolean;
  showPageNumber?: boolean;
  inactive?: boolean;
  setLoadingThumbnails: (isLoading: boolean) => void;
}

export default function Doc(props: Props) {
  const {
    isExpanded,
    completedNoTagDocList,
    handleExpand,
    currentDocId,
    doc,
    docs,
    currentPageIndex,
    onPageSelected,
    numPages,
    hiddenHeader,
    showPageNumber,
    setLoadingThumbnails,
    inactive
  } = props;
  const classes = useStyles({});
  return (
    <FlexAccordion
      square
      expanded={isExpanded}
      className={clsx(isExpanded ? classes.grow : classes.docMinHeight, classes.docAccordion)}
    >
      <Box display={hiddenHeader ? 'none' : undefined}>
        <DocHeader
          completedNoTagDocList={completedNoTagDocList}
          doc={doc}
          isCurrentDoc={currentDocId === doc.id}
          inactive={inactive}
        />
      </Box>
      <FlexAccordionDetails className={classes.thumbnailsContainer}>
        {
          (showPageNumber ?? true) && (
            <span className={classes.docPaginationText}>
              Page
              {' '}
              {currentPageIndex + 1}
              {' '}
              of
              {' '}
              {numPages}
            </span>
          )
        }
        <div className={classes.docDetailsDiv}>
          {currentDocId === doc.id ? (
            <DocumentThumbnails
              show={isExpanded}
              docs={docs}
              docId={doc.id}
              setLoadingThumbnails={setLoadingThumbnails}
              pageNumber={isExpanded ? currentPageIndex : undefined}
              onPageSelected={onPageSelected}
            />
          ) : null}
        </div>
      </FlexAccordionDetails>
    </FlexAccordion>
  );
}
