import { FunctionComponent, useCallback, lazy, Suspense } from 'react';
import { BirthdayPickerProps, ComponentProps } from './types';
import ErrorGetKbanQuestionsWarning from './ErrorGetKbaQuestionsDialog';
import { useFormik } from 'formik';
import { Button, Typography, Grid, MenuItem, TextField, Select, FormHelperText, FormControl, InputLabel, Box } from '@material-ui/core';
import validationSchema from './schema';
import states from './states';
import { parseName } from '@enotarylog/tag-utils/helpers/parseName';
import { useStyles } from './styles';
import * as _ from 'lodash';
import clsx from 'clsx';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useTheme } from 'document-viewer/src/lib/hooks/useTheme';

// Import this separately, because other than this we don't need this picker; it just bloats the size of the initial bundle
const BirthdayPicker: FunctionComponent<BirthdayPickerProps> = lazy(() => import('./BirthdayPicker'));

// This is never used. Is it needed?
// const Row = ({ children }) => (
//   <Grid
//     container
//     item
//     direction='row'
//     justifyContent='space-between'
//     alignItems='center'
//     spacing={4}
//   >
//     {children}
//   </Grid>
// );

//const fields = ['firstName', 'middleName', 'lastName', 'email', 'phone', 'ssn', 'dob', 'address1', 'address2', 'city', 'state', 'zip'];
const fields = ['firstName', 'middleName', 'lastName', 'email'];

const Form: FunctionComponent<ComponentProps> = ({
  participant,
  submitting,
  onSubmit,
  renderButton: RenderButton,
  idvErrored,
  onCloseDialog,
}) => {
  const classes = useStyles();
  const { signatureAndInitialsInOneStep } = useTheme();
  const { handleChange, handleSubmit, values, errors, touched, handleBlur, setFieldValue, setFieldTouched } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: Object.fromEntries(fields.map((key) => ([key, participant?.[key] || '']))),
  });

  const errorFor = useCallback((field: string) => (touched[field] && (errors[field] as string)), [errors, touched]);
  const idFor = useCallback((field: string) => `IdentityForm-${field}-${participant.id}`, [participant.id]);
  const propsFor = useCallback((field, label) => {
    const helperText = errorFor(field);
    const id = idFor(field);
    return {
      helperText: helperText || ' ',
      error: !!helperText,
      name: field,
      id,
      label: label,
      value: values[field],
      onChange: handleChange,
      onBlur: handleBlur,
      fullWidth: true,
      type: 'text',
      disabled: submitting,
      InputLabelProps: { htmlFor: id },
    };
  }, [errorFor, idFor, values, handleChange, handleBlur, submitting]);

  const submitDisabled = submitting || !_.isEmpty(errors);

  return (
    <form
      onSubmit={handleSubmit}
      className={clsx(classes.grow, classes.fixHelperTexts, classes.inputLabelText)}
    >
      {/* <Typography
        variant='h4'
        align='center'
        gutterBottom
        className={classes.marginBtm}
      >
        {parseName(participant)}
      </Typography> */}
      <Grid
        container
        spacing={signatureAndInitialsInOneStep ? 1 : 2}
      >
        <Grid
          item
          md={4}
          xs={12}
        >
          <TextField
            {...propsFor('firstName', 'First Name')}
            fullWidth
            disabled
            color='secondary'
            helperText='If incorrect, please contact sender'
          />
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
        >
          <TextField
            {...propsFor('middleName', 'Middle Name')}
            fullWidth
            disabled
            color='secondary'
          />
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
        >
          <TextField
            {...propsFor('lastName', 'Last Name')}
            fullWidth
            disabled
            color='secondary'
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            {...propsFor('email', 'Email Address')}
            disabled
            helperText='If incorrect, please contact sender'
            fullWidth
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <MuiPhoneNumber
            fullWidth
            type='phone'
            name='phone'
            className='form-control'
            placeholder='Phone Number'
            onBlur={(event) => {
              event.target.value.length <= 2 &&
                setFieldTouched('phone', true);
              values.phone.length > 2 && handleBlur(event);
            }}
            onChange={(e) => {
              setFieldValue('phone', e);
            }}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
            value={values.phone}
            defaultCountry='us'
            countryCodeEditable={false}
            disableDropdown={true}
            disableAreaCodes
            // label={`Phone number${values?.authenticationType === 'two-factor-authentication' ? '*' : ''}`}
            label='Phone Number'
            data-testid='phone-number-field'
            color='secondary'
          />
          {/* <TextField
            {...propsFor('phone', 'Phone Number')}
            fullWidth
          /> */}
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            {...propsFor('ssn', 'Last 4 digits of SSN (optional)')}
            type='password'
            inputProps={{ maxLength: 4 }}
            fullWidth
            color='secondary'
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <Suspense fallback={<div />}>
            <BirthdayPicker
              {...propsFor('dob', 'Date of Birth')}
              value={values.dob || null}
              color='secondary'
              onChange={(date) => {
                setFieldValue('dob', date);
              }}
              InputLabelProps={{ htmlFor: idFor('dob'), }}
            />
          </Suspense>
        </Grid>
      </Grid>
      <Box
        mt={signatureAndInitialsInOneStep ? 2 : 4}
        mb={signatureAndInitialsInOneStep ? 0 : 2}
      >
        <Typography
          variant='h5'
          align='center'
          gutterBottom
        >
          Last known United States Mailing Address
        </Typography>
      </Box>
      <Grid
        container
        spacing={signatureAndInitialsInOneStep ? 1 : 2}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            {...propsFor('address1', 'Address')}
            color='secondary'
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            {...propsFor('address2', 'Address 2 (optional)')}
            color='secondary'
          />
        </Grid>


        <Grid
          item
          md={5}
          xs={12}
        >
          <TextField
            {...propsFor('city', 'City')}
            color='secondary'
          />
        </Grid>
        <Grid
          item
          md={2}
          xs={4}
        >
          <FormControl
            error={!!errorFor('state')}
            fullWidth
          >
            <InputLabel id='state-picker'>State/Province</InputLabel>
            <Select
              data-testid='state-selector'
              labelId='state-picker'
              value={values.state || ''}
              name='state'
              onChange={handleChange}
              onBlur={handleBlur}
              color='secondary'
            >
              <MenuItem value=''></MenuItem>
              {
                states.map((state) => (
                  <MenuItem
                    value={state}
                    key={state}
                  >
                    {state}
                  </MenuItem>
                ))
              }
            </Select>
            <FormHelperText>{errorFor('state') || ' '}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid
          item
          md={5}
          xs={8}
        >
          <TextField
            {...propsFor('zip', 'Postal Code')}
            color='secondary'
            inputProps={{ maxLength: 5 }}
          />
        </Grid>
      </Grid>
      {
        idvErrored ? (
          <ErrorGetKbanQuestionsWarning
            open={idvErrored}
            onClose={() => {
              onCloseDialog();
            }}
          />
        ) : <></>
      }
      {
        !RenderButton ? (
          <Box
            mt={signatureAndInitialsInOneStep ? 0 : 2}
            textAlign='center'
          >
            <Button
              variant='contained'
              color='primary'
              disabled={submitDisabled}
              type='submit'
              className={classes.btn}
            >
              Submit
            </Button>
          </Box>
        ) : <RenderButton disabled={submitDisabled} />
      }
    </form>
  );
};

export default Form;
