import React from 'react';
import * as R from 'ramda';
import { injectTool } from '../../initializers/injectors';
import SetStateFormat from './component';
import { InstanceObject } from '../../../viewer';


const registerSetStateFormat = R.pipeP(
  injectTool('SetStateFormat', ({ instance }: InstanceObject) => ({
    type: 'customElement',
    title: 'Set State Format',
    render: () => (
      <SetStateFormat
        annotManager={instance.annotManager}
        instance={instance}
        signers={instance.getSigners()}

      />
    ),
  }))
);


export default registerSetStateFormat;
