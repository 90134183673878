import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'document-viewer/src/lib/hooks/useTheme';

export const useStyles = makeStyles((theme: Theme) => ({

  root: {
    '& .MuiDialog-paper': {
      boxShadow: theme.modalDropShadows ? undefined : 'none !important',
      borderRadius: (theme.modalBorderRadius !== undefined)
        ? `${theme.modalBorderRadius}px !important`
        : undefined,
    },
    '& .MuiButton-root': {
      borderRadius: (theme.buttonRadius !== undefined)
        ? `${theme.buttonRadius}px !important`
        : undefined,
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
    },
  },

  // additional class for List Style only
  listRoot: theme.signatureAndInitialsInOneStep ? {

    minHeight: 0,
    minWidth: 0,
    maxHeight: 'none',
    maxWidth: 'none',

    '& .MuiDialog-paperWidthXs, .MuiDialog-paperWidthSm, .MuiDialog-paperWidthMd': {
      '& .MuiSelect-select, .MuiInputLabel-root, p, h2, h3, h4, h5': {
        textTransform: 'uppercase',
      },
      height: 450,
      width: 600,
    },
    '& .MuiDialog-paperWidthLg, .muiDialog-paperWidthXl': {
      height: 500,
      width: 750,
    },

    textTransform: 'uppercase',

    justifyContent: 'space-between',

    '& button': { fontWeight: 700 },
    '& h2, h3, h4, h5': {
      fontSize: 16,
      fontWeight: 700,
      textAlign:'center',
    },
    '& .MuiInputBase-root, .MuiTypography-body1': { fontSize: 13 },

    '& input[type=text]': { minWidth: 200 },

    '& .MuiFormControlLabel-label': {
      fontWeight: 700,
      textTransform: 'uppercase',
      '& a': {
        color: '#5555ff',
        textDecoration: 'none' }
    },

  } : undefined,

}));

export const dialogContentTextStyles = {
  root: {
    '.MuiFormControl-root': {
      width: '100%',
    },
  },
};
