
import { RequestDataState, getIframeRequest, selector as requestDataSelector } from 'document-viewer/src/slices/request-data';
import { createSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'document-viewer/src/store';
import { RequestData } from 'document-viewer/src/utils/types/request-data';

const requestDataSel = createSelector(requestDataSelector, (state: RequestDataState) => state.data || {});
const equipmentCheckUrlSel = createSelector(requestDataSel, (state: RequestData) => state.equipmentCheckUrl);
const loadingSel = createSelector(requestDataSelector, (state: RequestDataState) => state.loading);


export function useRequestData(){

  const dispatch = useDispatch();
  const requestData = useSelector<RequestData>(requestDataSel);
  const equipmentCheckUrl = useSelector<string | undefined>(equipmentCheckUrlSel);
  const loading = useSelector<boolean>(loadingSel);




  return {
    requestData,
    equipmentCheckUrl,
    loading,
    getRequestData: async (id: string, eqCheck: boolean) => dispatch(getIframeRequest(id, eqCheck))
  }
}
