import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "document-viewer/src/store";
import { Transaction } from "document-viewer/src/utils/types/transaction";
import { GET_SESSION_INFO, SET_ESIGN_IN_PROGRESS } from "document-viewer/src/lib/gql/session";
import { setDocuments } from "./documents";
import { setParticipants } from "./participants";
import { setTheme } from "./theme";
import { defaultTheme } from "../constants";
import * as R from '@enotarylog/ramda';
import { getCustomTags } from "./customTags";

export interface TransactionState extends Transaction {
  esignId?: string | null;
  loading: boolean;
  showCompletedNotification: boolean;
  participantsCannotAct: boolean;
}

export const initialState: TransactionState = {
  id: null,
  esignId: null,
  status: null,
  loading: false,
  showCompletedNotification: false,
  participantsCannotAct: false,
  lockedOut: false,
  failureReason: null,
};

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setLoading: (state: TransactionState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTransaction: (state: TransactionState, action: PayloadAction<TransactionState>) => {
      Object.assign(state, action.payload);
    },
    setShowCompletedNotification(state: TransactionState, { payload }: PayloadAction<{ show: boolean }>) {
      state.showCompletedNotification = payload.show;
    },
    setParticipantsCannotAct: (state: TransactionState, action: PayloadAction<boolean>) => {
      state.participantsCannotAct = !!action.payload;
    },
  }
});



type SetTransactionInput = TransactionState & {
  esignStatus: string;
  auth0OrganizationId: string;
};
export const setTransaction = ({ esignStatus, auth0OrganizationId, ...transaction }: SetTransactionInput): AppThunk => async (dispatch, getState, client) => {

  dispatch(getCustomTags(auth0OrganizationId));
  dispatch(slice.actions.setTransaction(transaction));

  if (esignStatus === 'pending') {
    try {
      await client.mutate({
        mutation: SET_ESIGN_IN_PROGRESS,
        variables: {
          id: transaction.esignId,
          organizationId: auth0OrganizationId
        },
      });
    } catch (err) {
      console.error({ err })
      if (err.graphQLErrors?.length) dispatch(slice.actions.setParticipantsCannotAct(true));
    }
  }
}

export const getTransaction = (organizationId: string, participantIds: string[], sessionId: string, iframeId: string): AppThunk => async (dispatch, getState, client) => {

  dispatch(slice.actions.setLoading(true));
  const rtn = await client.query({
    query: GET_SESSION_INFO,
    partialRefetch: false,
    returnPartialData: false,
    variables: {
      organizationId,
      participantIds,
      sessionId,
      iframeId
    }
  });

  if (!rtn) {
    return;
  }

  const { data } = rtn;
  const { getParticipants, getEsign, } = data;

  dispatch(setDocuments(getEsign.documents));
  dispatch(slice.actions.setTransaction({
    ...getEsign.transaction,
    esignId: getEsign.id,
    esignStatus: getEsign.status,
  }));
  dispatch(setParticipants(getParticipants));

  const orgStyle = getEsign.iframeRequest.organization.settings.style || {};

  dispatch(setTheme(R.mergeDeepRight(defaultTheme, R.mergeDeepRight(orgStyle, getEsign.iframeRequest.style || {}))))

  dispatch(slice.actions.setLoading(false));

}




export const reducer = slice.reducer;
export const selector = (state: { transaction: TransactionState }) => state.transaction;
export default slice;
export const { setShowCompletedNotification, setTransaction: updateTransaction } = slice.actions;
