import { gql } from '@apollo/client';

export const GET_NS = gql`
  query getNs($id: ID!) {
    notarySession: getNotarySession(id: $id) {
      id
      witnesses
      docs {
        id
        gcsRefId
        docRevisions {
          versionNumber
          gcsRefId
          xmlAnnotation
        }
      }
      notary {
        id
      }
      request {
        id
        assignedToOrg
      }
      notarySessionUsers {
        id
        type
        status
        externalId
        kbaAttempts
        kbaRequired
        credAnalysisRequired
        kbaPassed
        credAnalysisPassed
        user {
          id
          firstName
          middleName
          lastName
          email
          userType
        }
      }
    }
  }
`
