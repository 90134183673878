import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSessionStatus } from 'document-viewer/src/lib/hooks/useSessionStatus';
import Auth from 'document-viewer/src/components/authentication';
import { AuthProvider } from 'document-viewer/src/components/authentication/AuthProvider';
import EsignViewer from 'document-viewer/src/containers/EsignViewer';
import clsx from 'clsx';
import { Fade, useMediaQuery, Box, Typography, Container } from '@material-ui/core';
import { useTheme } from 'document-viewer/src/lib/hooks/useTheme';
import RightPanel from 'document-viewer/src/components/menus/RightPanel';
import LeftPanel from 'document-viewer/src/components/menus/LeftPanel';
import TopButton from 'document-viewer/src/containers/TopButton';
import { AutoScrollSpeedProvider } from 'document-viewer/src/components/AutoScrollSpeed';
import { useQuery } from 'document-viewer/src/lib/hooks/useQuery';
import ErrorScreen from 'document-viewer/src/components/ErrorScreen';
import LoadingScreen from 'document-viewer/src/components/LoadingScreen';
import { useDebounce, useEffectOnce } from 'react-use';
import useStyles from './styles/session';
import { useWebViewer } from 'document-viewer/src/lib/hooks/useWebViewer';
import SessionCompletedSnackbar from 'document-viewer/src/components/SessionCompletedSnackBar';
import usePanelState from 'document-viewer/src/lib/hooks/usePanelState';
import SignaturePadModal from 'document-viewer/src/containers/SignaturePadModal';
import SwitchParticipant from 'document-viewer/src/containers/SwitchParticipant';
import PinCodePrompt from 'document-viewer/src/containers/PinCodePrompt';
import DevButtons from 'document-viewer/src/containers/DevButtons';
import useRevisionSubscription from 'document-viewer/src/lib/hooks/useRevisionSubscription';
import { useDocs } from 'document-viewer/src/lib/hooks/useDocs';
import { useRequestData } from 'document-viewer/src/lib/hooks/useRequestData';
import useDocStatusSubscription from 'document-viewer/src/lib/hooks/useDocStatusSubscription';
import useTransactionUpdates from 'document-viewer/src/lib/hooks/useTransactionUpdates';
import useTransaction from 'document-viewer/src/lib/hooks/useTransaction';
import confetti from 'canvas-confetti';
import { DocumentZoom } from 'document-viewer/src/components/DocumentZoom';
import AutoScrollSpeed from 'document-viewer/src/components/AutoScrollSpeed';
import { useAutoScrollSpeed } from 'document-viewer/src/components/AutoScrollSpeed/useAutoScrollSpeed';
import ParticipantCannotActDialog from 'document-viewer/src/components/ParticipantCannotActDialog';
import { useGetSessionLock } from '@enotarylog/hooks/useGetSessionLock';
import GenericDialog from 'document-viewer/src/components/GenericDialog';
import { participantCompletedDocument } from "./../utils/participantCompletedDocument";
import { useParticipants } from 'document-viewer/src/lib/hooks/useParticipants';
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { UPDATE_PARTICIPANT } from 'document-viewer/src/lib/gql/participant';
import { isIOSDevice } from 'document-viewer/src/utils/agents';

const nbsp = '\xA0';
const drawerWidth = 240;

const appendQueryToUrl = (url, query) => {
  try {
    const queryString = new URLSearchParams(query).toString();
    return `${url}?${queryString}`;
  } catch (err) {
    return url;
  }
}

const apolloClient = new ApolloClient({
  uri: `${process.env.NX_INTERNAL_API_URL}${process.env.NX_GQL_PATH}`,
  cache: new InMemoryCache(),
});

export function Session({ isEsign }) {
  const query = useQuery();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { instance: wv, currentPage, numberOfPages } = useWebViewer();
  const { isAutoScrolling } = useAutoScrollSpeed();
  const [completedNoTagDocList, setCompletedNoTagDocList] = useState([]);
  const [loadingThumbnails, setLoadingThumbnails] = useState(false);
  const { selectedDoc, docs } = useDocs();
  useRevisionSubscription({ docId: selectedDoc, isEsign });
  const { transactionId, showCompletedNotification, setShowCompletedNotification, participantsCannotAct, failureReason, transactionStatus } = useTransaction();
  useDocStatusSubscription({ transactionId });
  useTransactionUpdates(transactionId);
  const { requestData } = useRequestData();
  const isLargeScreen = useMediaQuery('(min-width: 1200px)');

  const { leftMenuOpen, rightMenuOpen, setLeftMenuOpen, setRightMenuOpen } = usePanelState(isLargeScreen);

  // If participant has saved their signature and initials set load viewer to true

  const [loaded, setLoaded] = useState<boolean>(!!wv);
  const [completionDeferred, setCompletionDeferred] = useState(false);
  const [isChallengeQuestion, setIsChallengeQuestion] = useState(false);

  const history = useHistory();
  const { selectedParticipant, loading } = useParticipants();
  const participantSigningCompleted = window.localStorage.getItem('participantSigningCompleted');

  if (transactionId && selectedParticipant && (participantSigningCompleted === `${transactionId}-${selectedParticipant.id}`)) {
    history.replace(appendQueryToUrl(`/session/complete`, query));
  }

  const isIOS = isIOSDevice();
  const [loadViewer, setLoadViewer] = useState<boolean>(!isIOS);

  /*
    NOTE: This is for iOS devices only.

    This will make the eSign viewer load after the participant has input their Signature and
    Initials.

    If they refresh the screen or switch to another browser for some reason, this will also make
    the viewer load again after they complete T&C, two factor auth, or personal password.
  */
  useEffect(() => {
    if (
      !participantsCannotAct &&
      !loadViewer &&
      selectedParticipant &&
      selectedParticipant.signature &&
      selectedParticipant.initials
    ) {
      setLoadViewer(true);
    }
  }, [selectedParticipant]);

  const hasUnreviewedNoTagsDocs = () => {
    const sortedDocs = Object.values(docs).sort((a, b) => (a.order - b.order));
    return sortedDocs.some((doc: any) => doc.id && !participantCompletedDocument(doc, completedNoTagDocList, selectedParticipant))
  };

  const showTopButton = true;
  const showAutoScrollSpeedTopButton = (!isLargeScreen);

  // Prevents the GQL query from firing twice
  const [statusCompleted, setStatusCompleted] = useState(false);
  const finishedCompleting = !((!completionDeferred && !showCompletedNotification) || hasUnreviewedNoTagsDocs());

  const updateParticipantStatus = () => {
    apolloClient.mutate({
      mutation: UPDATE_PARTICIPANT,
      variables: {
        participant: {
          id: selectedParticipant?.id,
          status: "completed",
        },
        transactionId,
        agreeToLegalStuff: false,
      },
    });
    setStatusCompleted(true);
  };

  if (finishedCompleting && !statusCompleted) {
    updateParticipantStatus();
  }

  const throwConfettiAndRedirect = useCallback((duration: number) => {
    const lsAndRedirect = () => {
      window.localStorage.setItem('transactionCompleted', 'true');
      window.localStorage.setItem('participantSigningCompleted', `${transactionId}-${selectedParticipant.id}`);
      history.replace(appendQueryToUrl(`/session/complete`, query));
    };
    const useConffeti = (theme.onComplete || []).includes('confetti');
    if (finishedCompleting && !useConffeti) {
      lsAndRedirect()
    }
    // don't show confetti if session is incomplete or requestData omits it
    if (!completionDeferred) {
      if (!showCompletedNotification || !useConffeti) {
        return;
      }
    }

    if (hasUnreviewedNoTagsDocs()) {
      setCompletionDeferred(true);
      return;
    }
    const endTime = Date.now() + duration;
    const redirectDelay = 100;

    setTimeout(() => lsAndRedirect(), duration + redirectDelay);

    const colors = [theme.palette.primary.main, theme.palette.secondary.main];

    (function frame() {

      confetti({
        particleCount: 2,
        angle: 60,
        spread: 100,
        origin: { x: 0 },
        colors: colors,
        disableForReducedMotion: true,
        useWorker: true,
        zIndex: 9999,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 100,
        origin: { x: 1 },
        colors: colors,
        disableForReducedMotion: true,
        useWorker: true,
        zIndex: 9999,
      });

      if (Date.now() < endTime) {
        requestAnimationFrame(frame);
      }
    }());


  }, [showCompletedNotification, completionDeferred, hasUnreviewedNoTagsDocs, theme.onComplete, theme.palette.primary.main, history, theme.palette.secondary.main, finishedCompleting]);

  useDebounce(async () => throwConfettiAndRedirect(3000), 100, [throwConfettiAndRedirect]);

  function genericDialogBox() {
    return (
      <GenericDialog
        title='Editing in progress'
        description='This session is currently being edited by the organization and is unable to be completed. Click REFRESH to try again or come back later to complete the session.'
        dialogButtons={[{ buttonTitle: 'REFRESH', buttonOnClick: () => window.location.reload() }]}
      />
    )
  }
  const propsHook = {
    nsOrgId: query.org,
    nsId: transactionId,
    children: genericDialogBox()
  }

  const { lockErrorModal } = useGetSessionLock(transactionId && propsHook);

  return (
    <>
      {loadViewer && !loaded && <LoadingScreen />}

      <ParticipantCannotActDialog
        failureReason={failureReason}
        open={!isChallengeQuestion && participantsCannotAct}
        transactionStatus={transactionStatus}
      />

      <ParticipantCannotActDialog
        failureReason='Challenge Failed'
        open={isChallengeQuestion && participantsCannotAct}
      />

      <Fade
        in={loaded}
        timeout={1000}
      >
        <div
          className={classes.root}
        >

          {/* LEFT PANEL */}
          <LeftPanel
            completedNoTagDocList={completedNoTagDocList}
            setLoadingThumbnails={setLoadingThumbnails}
            isEsign={isEsign}
            panelOpen={loaded && leftMenuOpen}
            drawerWidth={drawerWidth}
            isMobile={!isLargeScreen}
            toggleHandler={() => {
              setLeftMenuOpen(!leftMenuOpen);
              !isLargeScreen && setRightMenuOpen(false);
            }}
          />

          <main
            className={clsx(classes.content, {
              [classes.contentShiftRight]: loaded && rightMenuOpen,
              [classes.contentShiftLeft]: loaded && leftMenuOpen
            })}
            style={{ marginBottom: '67px' }}
          >
            <Box
              className={classes.autoSignContainer}
              display='flex'
              justifyContent='space-around'
            >
              {(theme?.zoomIcon === 'top') && (
                <Box
                  textAlign='center'
                  height='100%'
                  flex={1}
                >
                  <Container>
                    {
                      (showAutoScrollSpeedTopButton && isAutoScrolling)
                        ? (
                          <Box
                            minWidth='20vw'
                            /**
                             * It was specifically requested that the max width
                             * match that of the autoscroll speed on the right
                             * sidebar.
                             */
                            maxWidth={drawerWidth}
                            margin='auto'
                          >
                            <AutoScrollSpeed onlyShowWhenAutoScrolling={false} />
                          </Box>
                        )
                        : (
                          <Box pt={theme.spacing(0.2)}>
                            <Typography>
                              {`Page ${currentPage}${nbsp}/${nbsp}${numberOfPages}`}
                            </Typography>
                          </Box>
                        )
                    }
                  </Container>
                </Box>
              )}
              {showTopButton && (
                <Box flex={1}>
                  <TopButton
                    setCompletedNoTagDocList={setCompletedNoTagDocList}
                    loadingThumbnails={loadingThumbnails}
                    completedNoTagDocList={completedNoTagDocList}
                    lockErrorModal={lockErrorModal}
                  />
                </Box>
              )}
              {(theme?.zoomIcon === 'top') && (
                <Box
                  flex={1}
                  textAlign='center'
                  height='100%'
                >
                  <Container>
                    <Box
                      width='50%'
                      margin='auto'
                    >
                      <DocumentZoom initialZoomLevel={100} />
                    </Box>
                  </Container>
                </Box>
              )}
            </Box>

            {loadViewer && (
              <EsignViewer
                sessionId={query.session}
                onDocumentLoaded={() => setLoaded(true)}
              />
            )}
          </main>

          <RightPanel
            completedNoTagDocList={completedNoTagDocList}
            panelOpen={loaded && rightMenuOpen}
            drawerWidth={drawerWidth}
            isMobile={!isLargeScreen}
            toolsLabelEnabled={requestData.toolsLabelEnabled}
            searchEnabled={requestData.searchEnabled}
            zoomLabelEnabled={requestData.zoomLabelEnabled}
            showAutoScrollSpeed={isAutoScrolling && (
              ((theme?.showParticipants ?? true))
              || (!showAutoScrollSpeedTopButton)
            )}
            toggleHandler={() => {
              setRightMenuOpen(!rightMenuOpen);
              !isLargeScreen && setLeftMenuOpen(false);
            }}
            isEsign={isEsign}
          >
            <DevButtons
              show={process.env.NODE_ENV !== 'production'}
            />
          </RightPanel>
        </div>
      </Fade>

      {
        !participantsCannotAct ?
          <>
            <SignaturePadModal
              participantsLoading={loading}
              isChallengeQuestion={isChallengeQuestion}
              setIsChallengeQuestion={setIsChallengeQuestion}
              onLockout={() => {
                setLoaded(false);
                window.location.reload();
              }}
            />

            <PinCodePrompt />

            <SwitchParticipant />

            {
              (theme.onComplete || []).includes('toast') && !hasUnreviewedNoTagsDocs() ?
                <SessionCompletedSnackbar
                  show={showCompletedNotification || completionDeferred}
                  onClose={() => setShowCompletedNotification(false)}
                />
                : null
            }
          </>
          : null
      }
    </>
  );
}

export default function EsignSession() {
  const query = useQuery();
  const { participantsCannotAct } = useTransaction();
  const history = useHistory();
  const {
    getSessionInfo,
    error,
    value,
  } = useSessionStatus();

  const currentParticipantSigningCompleted = value?.participants[0]?.status === 'completed';

  if (currentParticipantSigningCompleted) {
    history.replace(appendQueryToUrl(`/session/complete`, query));
  }

  if (value.esignStatus === 'canceled') {
    history.push('/session/canceled');
  }

  useEffectOnce(() => {
    getSessionInfo();
  });

  if (error) {
    return (
      <ErrorScreen
        error={error}
      />
    );
  }

  return (
    (value && !currentParticipantSigningCompleted) &&
      <AuthProvider
        bypass={false}
        session={query.session}
        participants={value.participants || []}
      >
        <Auth
          sessionId={query.session as string}
          participants={value.participants}
          participantsCannotAct={participantsCannotAct}
        >
          <AutoScrollSpeedProvider>
            <Session isEsign={value.isEsign} />
          </AutoScrollSpeedProvider>
        </Auth>
      </AuthProvider>
  );
}
