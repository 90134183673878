import * as R from 'ramda';
import _ from 'lodash';
import { INameSpec, IUserSpec } from '../types';

export const parseName = (userObj: INameSpec | IUserSpec): string => {
  const user: INameSpec = _.get(userObj, 'user', userObj);
  const firstName = _.upperFirst(_.get(user, 'firstName'));
  const middleName = _.upperFirst(_.get(user, 'middleName'));
  const lastName = _.upperFirst(_.get(user, 'lastName'));

  return `${firstName}${(middleName) ? ` ${middleName}` : ''} ${lastName}`
}

type StringRtnStr = (str: string) => string;

export const toFullName: StringRtnStr = R.pipe(
  R.split(' '),
  R.map(_.upperFirst),
  R.join(' ')
);
