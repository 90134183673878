import React from 'react';
import _ from 'lodash';

import { Dropdown } from 'react-bootstrap';
import { SelectCallback } from 'react-bootstrap/esm/helpers';

export interface DocSelectorWrapperProps {
  className?: string;
  children?: React.ReactNode;
}

const DocSelectorWrapper = ({ className, children }: DocSelectorWrapperProps) => (
  <div
    className={className}
    style={{ boxShadow: 'none !important' }}
  >
    {children}
  </div>
);

export interface DocSelectorProps {
  docs?: any;
  selectedDoc?: any;
  onChange?: SelectCallback;
}

const DocSelector = React.memo(({ docs, selectedDoc, onChange }: DocSelectorProps) => {
  const selected = docs?.[selectedDoc]?.title;

  return (
    <DocSelectorWrapper className='col-xs-12 text-center'>
      <Dropdown
        onSelect={onChange}
      >
        <Dropdown.Toggle
          variant='blank'
          id='doc-select'
        >
          {selected || 'Loading Docs...'}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {
            _.map(docs, ({ title }, docId) => (
              <Dropdown.Item
                key={docId}
                eventKey={docId}
                value={docId}
                // active={docId === appState.selectedDoc}
              >
                {title}
              </Dropdown.Item>
            ))
          }
        </Dropdown.Menu>
      </Dropdown>
    </DocSelectorWrapper>
  );
});

// @ts-expect-error defaultProps doesn't exist
DocSelector.defaultProps = {
  docs: {},
};

export default DocSelector;
