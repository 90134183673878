import * as R from 'ramda';

import { injectTool } from '../initializers/injectors';
import { removeFormFieldsIcon } from '../constants/icons';
import { InstanceObject } from '../../viewer';


// TODO: add a handler for docViewer.on('removeFormFields', () =>{})
const removeFormFields = {
  type: 'actionButton',
  img: removeFormFieldsIcon,
  title: 'Clear All Annotations',
  dataElement: 'clearAllAnnots',
  onClick: ({ instance }: InstanceObject) => async () => {
    instance.docViewer.trigger('confirmationModal', [{
      message: 'Are you sure you want to delete all annotations? This will remove all annotations in all documents.',
      onConfirm: 'removeAllAnnots',
    }]);
  },
};


const registerRemoveFormFields = R.pipeP(
  injectTool('RemoveAllAnnots', removeFormFields)
);

export default registerRemoveFormFields;
