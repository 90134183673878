import React, { useEffect } from 'react';
import * as R from 'ramda';
import _ from 'lodash';
import bPromise from 'bluebird';
import debug from 'debug';
import { useAsync } from 'react-use';
import { useServer } from '../lib/hooks/useServerProvider';
import useAppState from '../lib/hooks/AppState';

import {
  importFbaseVal,
  delFbaseVal,
  importWidgetFbaseVal,
  delWidgetFbaseVal,
  importField,
  setBlankPages,
  lockWebviewer,
  nsCompleting,
  setSelectedSigner,
  setModifiedXfdf,
  setCorrAnnot,
} from './lib/helpers/import';
import Viewer, { Instance } from './viewer';
import * as toolConfigs from './lib/configs';
import signatureResetOnOtherDocs from './lib/helpers/signatureResetOtherDocs';

// from @enotarylog/ramda
const tapP = (fn: (data: any) => any) => (data: any) => Promise.resolve(fn(data)).then(() => data)

const log = debug('collab');


const invokeServerMethod = (fn: (...args: any[]) => any) => R.pipe(
  R.tap((args) => log('annotation created', args)),
  R.converge(fn, [R.prop('id'), R.identity])
);

export interface CollabProps {
  onAuditTrail: any;
  userType: string;
  showDoc?: boolean;
  notarySession?: any;
  onSelectedSignerChanged: any;
}

function Collab(props: CollabProps) {
  const server = useServer();
  const appState = useAppState();


  const { loading } = useAsync(async () => {
    const selDoc = await server.getSelectedDocId();


    // if no doc set, then it to the first one
    if (!selDoc && !appState.docs?.[selDoc] && appState.isAdminUser) {
      const firstDoc = _.head(_.keys(appState.docs));

      appState.setSelectedDoc(firstDoc);
      await server.setSelectedDocId(firstDoc!);
    }
  });


  useEffect(() => {
    if (server) {
      server.bind('onSelectedDocIdChanged', appState.selectedDoc, ({ val }: any) => {
        appState.setSelectedDoc(val);
      }, 'main');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [server]);

  if (loading) {
    return null
  }

  return (
    <Viewer
      {...props}
      /*
        * appState
        */
      config={appState.config}
      toolConfig={toolConfigs[props.userType as keyof typeof toolConfigs]}
      currentUser={appState.currentUser}
      isAdminUser={appState.isAdminUser}
      signers={_.values(appState.getSigners() || {})}
      docs={appState.docs}
      runId={appState.runId}
      selectedDoc={(props.showDoc || props.userType === 'admin') && appState.selectedDoc}
      selectedSigner={appState.selectedSigner || '-1'}
      blankPages={appState.blankPages[appState.getSelectedDoc()]}
      signatures={appState.signatures}
      images={appState.images}
      showVaDisclaimer={appState.showVaDisclaimer}
      xfdf={appState.xfdf}
      loadedDocs={appState.loadedDocs}
      nsId={appState?.notarySession?.id || props?.notarySession?.id}


      /**
       * The following props synchronize data in firebase with webviewer
       */
      getLoadedDocs={() => server.getLoadedDocs()}

      // bind non-doc-specific listeners for downstream updates; when firebase pushes data to browser, update webviewer
      // NOTE: events in here may be unbound in viewer.js! These events are only bound once!
      bindEvents={(inst: Instance) => bPromise.all([
        server.bind('onLockChanged', inst.getDocId(), lockWebviewer(inst), 'main'),
        server.bind('onSelectedSignerChanged', inst.getDocId(), setSelectedSigner(inst, appState), 'main'),
        server.bind('onVaDisclaimerChanged', inst.getDocId(), ({ val }: any) => appState.setShowVaDisclaimer(val), 'main'),
        server.bind('onConsumerSignaturesChanged', inst.getDocId(), ({ val }: any) => {
          appState.setSignatures({ ...appState.signatures, ...(val || {}) });
        }, 'main'),
        server.bind('onLoadedDocsChanged', inst.getDocId(), ({ val }: any) => {
          appState.setLoadedDocs(val || []);
        }, 'main'),
        server.bind('onCompletingChanged', inst.getDocId(), nsCompleting(inst), 'main'),
      ])}
      unbindEvents={() => server.unbindAll('all')}

      // bind doc-specific listeners for downstream updates; when firebase pushes data to browser, update webviewer
      bindDocEvents={(inst: Instance) => bPromise.all([
      // server.addLoadedDocs(inst.getDocId()),
        server.bind('onAnnotationCreated', inst.getDocId(), importFbaseVal(inst)),
        server.bind('onAnnotationUpdated', inst.getDocId(), importFbaseVal(inst)),
        server.bind('onAnnotationDeleted', inst.getDocId(), delFbaseVal(inst)),
        server.bind('onWidgetCreated', inst.getDocId(), importWidgetFbaseVal(inst)),
        server.bind('onWidgetUpdated', inst.getDocId(), importWidgetFbaseVal(inst)),
        server.bind('onWidgetDeleted', inst.getDocId(), delWidgetFbaseVal(inst)),
        server.bind('onFieldAdded', inst.getDocId(), importField(inst)),
        server.bind('onFieldUpdated', inst.getDocId(), importField(inst)),
        server.bind('onBlankPagesChanged', inst.getDocId(), R.pipeP(
          tapP(setBlankPages(inst)),
          tapP(({ val, key }: any) => {
            appState.setBlankPages({ ...appState.blankPages, [key]: val });
          })
        )),
      ])}
      unbindDocEvents={() => server.unbindAll()}


      // upstream updates; when webviewer emits changes, push it up to firebase
      onAddDocLoaded={(docId: string) => {
        if (appState.isAdminUser) {
          server.addLoadedDocs(docId);
        }
      }}
      onAnnotationAdded={R.juxt([invokeServerMethod(server.createAnnotation), setCorrAnnot(server)])}
      onAnnotationUpdated={R.juxt([invokeServerMethod(server.updateAnnotation), setCorrAnnot(server)])}
      onAnnotationDeleted={R.juxt([invokeServerMethod(server.deleteAnnotation), setCorrAnnot(server, 'delete')])}
      onWidgetAdded={invokeServerMethod(server.createWidget)}
      onWidgetUpdated={invokeServerMethod(server.updateWidget)}
      onWidgetDeleted={invokeServerMethod(server.deleteWidget)}
      onLockChanged={server.setLock}

      onRemoveFormFields={server.clearWidgets}
      onRemoveAllAnnots={() => server.resetSession(appState.selectedDoc, true)}
      onRemoveSignatures={async (userId: string, docId: string, type: string) => {
        const annotsToAudit = await signatureResetOnOtherDocs(userId, docId, server, type);

        annotsToAudit.forEach(() => {
          const auditTrail = [
            {
              type: 'USER_REMOVED_SIGNATURE',
              data: {
                nsId: appState?.notarySession?.id || props?.notarySession?.id,
                nsUserId: userId,
                userType: 'signer',
                action: 'deleted',
                docId,
                docTitle: appState.docs[docId].title,
              },
            },
          ];

          props.onAuditTrail(...auditTrail);
        });
      }}
      onSelectedSignerChanged={
        R.pipeP(
          tapP(server.setSelectedSigner),
          tapP(props.onSelectedSignerChanged || R.identity))
      }
      onVaDisclaimerChanged={(selectedSigner?: string, show?: boolean) => {
        if (!selectedSigner || selectedSigner === '-1' || !show) {
          return server.setShowVaDisclaimer(false);
        }

        const signer = appState.signers[selectedSigner];

        if (signer && signer.connected && signer.runId) {
          server.setShowVaDisclaimer(signer.runId);
        }
      }}

      onSaveSignature={server.createSignatures}

      onBlankPagesAdded={(docId: string, currBlankPages: any) => server.setBlankPages(docId, currBlankPages)}
      onBlankPagesRemoved={(docId: string, currBlankPages: any) => server.setBlankPages(docId, Math.max(currBlankPages, 0))}

      onFieldUpdated={async ({ name, value, docId, widget }) => {
        if (!widget || !widget.CustomData.id || !value) {
          return;
        }

        await server.setField(widget.CustomData.id, {
          docId,
          name,
          value,
        });
        await server.updateWidget(widget.CustomData.id, {
          fieldName: name,
          fieldValue: value,
        });
      }}

      onDocumentChanged={R.pipeP(
        // @ts-ignore
        R.juxt([
          setModifiedXfdf(server),
          ({ newDocId }) => server.setSelectedDocId(newDocId),
        ])
      )}
      createAnnotation={server.createAnnotation}


      onAuditTrail={(...args: any) => props.onAuditTrail(...args)}
    />
  );
}

Collab.defaultProps = {
  onAnnotationAdded: R.identity,
  onAnnotationUpdated: R.identity,
  onAnnotationDeleted: R.identity,
};


const composeComponent = R.compose(
  R.identity
);


export default composeComponent(Collab);
