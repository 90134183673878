import _ from 'lodash';
import { InstanceObject } from '../../viewer';


const customTypeToPointerText = {
  DATETEMPLATE: 'Date Here',
  DAYTEMPLATE: 'Date Here',
  MONTHTEMPLATE: 'Date Here',
  YEARTEMPLATE: 'Date Here',
  SIGNHERETAGTEMPLATEANNOTATION: 'Sign Here',
  INITIALHERETAGTEMPLATEANNOTATION: 'Initial Here',
  SEALTAGTEMPLATEANNOTATION: 'Seal Here',
  COMMISSIONSTAMPTEMPLATE: 'Stamp Here',
};

const handlePointToAnnot = () => ({ instance, ...rest }: InstanceObject) => {
  let domElements: any = {};
  const elementHeightIfCanSkip = 73;
  const elementHeightIfCannotSkip = 47;
  const maxElementWidth = 150; // It can be smaller than this

  const getMouseLocation = (e: any, iframeWindow: HTMLIFrameElement['contentWindow'], canSkip: boolean) => {
    const displayMode = instance.docViewer.getDisplayModeManager().getDisplayMode();
    const docContainer = iframeWindow!.document.querySelector('.DocumentContainer');
    // gutter is how much horizontal space there is between the actual document and the end of the webviewer iframe, on one side
    const gutter = (docContainer!.clientWidth - docContainer!.querySelector('.document')!.clientWidth) / 2;

    if (gutter >= maxElementWidth) {
      // This pointer will fit in the gutter
      const gutterPointer = displayMode.pageToWindow({ x: 0, y: e.Y }, e.PageNumber - 1);

      return {
        x: gutterPointer.x - maxElementWidth + 50,
        y: gutterPointer.y,
        pointerDirection: 'right',
      };
    }

    const pointerFromBelow = displayMode.pageToWindow({ x: e.X, y: e.Y + e.Height }, e.PageNumber - 1);
    const elementHeight = canSkip ? elementHeightIfCanSkip : elementHeightIfCannotSkip;

    if (pointerFromBelow.y + elementHeight > docContainer!.clientHeight) {
      const pointerFromAbove = displayMode.pageToWindow({ x: e.X, y: e.Y }, e.PageNumber - 1);

      return {
        x: pointerFromAbove.x,
        y: pointerFromAbove.y - elementHeight,
        pointerDirection: 'down',
      };
    }

    return {
      x: pointerFromBelow.x,
      y: pointerFromBelow.y,
      pointerDirection: 'up',
    };
  };


  instance.docViewer.on('pointToAnnot', (data: any) => {
    const { tag: annot, onApply } = data;
    const canSkip = !_.isUndefined(annot?.CustomData?.flags?.required) && !annot.CustomData.flags.required;
    const pointerText = customTypeToPointerText[annot.CustomData?.type as keyof typeof customTypeToPointerText] || 'Fill Here';
    const iframeWindow = instance.iframeContainer.querySelector('iframe')!.contentWindow;

    // refer to getMouseLocation implementation above
    const { pointerDirection, ...windowCoordinates } = getMouseLocation(annot, iframeWindow, canSkip);

    const displayMode = instance.docViewer.getDisplayModeManager().getDisplayMode();
    const page = displayMode.getSelectedPages(windowCoordinates, windowCoordinates);
    const clickedPage = (page.first !== null) ? page.first : instance.docViewer.getCurrentPage() - 1;

    const pageCoordinates = displayMode.windowToPage(windowCoordinates, clickedPage);

    const zoom = instance.docViewer.getZoom();
    let doAppend = true;
    const customElement = ((iframeWindow!.document.getElementById('pointer'))
      ? iframeWindow!.document.getElementById('pointer')
      : document.createElement('div')) as HTMLElement;

    if (customElement.parentElement) {
      if (customElement.parentElement.id !== `pageContainer${clickedPage}`) {
        customElement.parentElement.removeChild(customElement);
        doAppend = true;
      } else {
        doAppend = false;
      }
    }

    customElement.id = 'pointer';
    customElement.className = 'shake-horizontal';

    // TODO: pack these into a class
    customElement.style.position = 'absolute';
    customElement.style.top = `${pageCoordinates.y * zoom}px`;
    customElement.style.transition = 'top 0.5s';
    customElement.style.zIndex = '100';
    customElement.style.minWidth = '80px';
    customElement.style.minHeight = '30px';
    customElement.style.maxWidth = `${maxElementWidth}px`; // DO NOT CHANGE THIS! Change maxElementWidth to be the right number instead
    customElement.style.textAlign = 'center';
    customElement.style.boxShadow = '0 5px 7px rgba(0,0,0, .3)';
    customElement.style.left = `${pageCoordinates.x * zoom}px`;
    customElement.style.verticalAlign = 'sub';
    customElement.style.fontWeight = '900';
    customElement.style.borderRadius = '3px';
    customElement.style.overflow = 'hidden';

    const pointerTextElem = document.createElement('div');
    const arrow = {
      up: '￪',
      right: '→',
      down: '￬',
      left: '←',
    }[pointerDirection];

    pointerTextElem.style.backgroundColor = 'yellow';
    pointerTextElem.style.padding = '10px';
    pointerTextElem.style.cursor = 'pointer';

    pointerTextElem.innerHTML = `<span id="pointerText">${pointerText}<span style="font-size: 2rem; padding: 0; margin: 0; line-height: 1rem;">${arrow}</span></span>`;

    pointerTextElem.addEventListener('click', (e) => {
      onApply && onApply();
      instance.annotManager.trigger('annotationClicked', annot);
      e.preventDefault();
      e.stopPropagation();
    }, { capture: true });

    const orSkipElem = document.createElement('div');

    orSkipElem.style.padding = '5px';
    orSkipElem.style.backgroundColor = '#fff';
    orSkipElem.style.fontSize = '.9rem';
    orSkipElem.style.cursor = 'pointer';

    orSkipElem.innerText = 'Or Skip';

    orSkipElem.addEventListener('click', (e) => {
      annot.setCustomData('reviewed', true);
      instance.annotManager.trigger('annotationUpdated', annot);
      instance.annotManager.trigger('annotationReviewed', annot);
      e.preventDefault();
      e.stopPropagation();
    });

    const pageContainer = iframeWindow!.document.getElementById(`pageContainer${clickedPage}`);

    if (doAppend) {
      pageContainer!.appendChild(customElement);
      customElement.appendChild(pointerTextElem);

      if (canSkip) {
        customElement.appendChild(orSkipElem);
      }
    }

    if (!domElements[clickedPage]) {
      domElements[clickedPage] = [];
    }

    // save left and top so we can scale them when the zoom changes
    domElements[clickedPage].push({
      element: customElement,
      left: pageCoordinates.x,
      top: pageCoordinates.y,
    });
  });


  instance.docViewer.on('clearPointer', () => {
    // Remove each element from the page
    Object.values(domElements).flat().forEach(({ element }: any) => {
      try {
        element.parentElement.removeChild(element);
      } catch (err) { /* Don't sweat it, this pointer may have already been removed somehow */ }
    });
    domElements = {};

    // In case there is still a pointer on the page, get rid of it too
    const iframeWindow = instance.iframeContainer.querySelector('iframe')!.contentWindow;
    const pointer = iframeWindow!.document.getElementById('pointer');

    if (!pointer) {
      return;
    }

    pointer.parentElement!.removeChild(pointer);
  });


  instance.docViewer.on('pageComplete', (pageIndex: number) => {
    if (domElements[pageIndex]) {
      const zoom = instance.docViewer.getZoom();
      const iframeWindow = instance.iframeContainer.querySelector('iframe')!.contentWindow;

      const pageContainer = iframeWindow!.document.getElementById(`pageContainer${pageIndex}`);

      // add back and scale elements for the rerendered page
      domElements[pageIndex].forEach((elementData: any) => {
        /* eslint-disable */
        elementData.element.style.left = `${elementData.left * zoom}px`;
        elementData.element.style.top = `${elementData.top * zoom}px`;
        /* eslint-enable */
        pageContainer!.appendChild(elementData.element);
      });
    }
  });

  instance.docViewer.on('beforeDocumentLoaded', () => {
    domElements = {};
  });

  return {
    instance,
    ...rest,
  };
};

export default handlePointToAnnot;
