import * as R from 'ramda';
import _ from 'lodash';
import initRectAnnot from '../../initializers/initRectAnnot';
import registerAnnotationType from '../../initializers/registerAnnotationType';
import registerTool from '../../initializers/registerTool';
import defineToolClass from '../../initializers/defineToolClass';
import createTextField from '../../actions/createTextField';
import { InstanceObject } from '../../../viewer';
import { parseName } from '@enotarylog/tag-utils/helpers/parseName';

const createTemplateTool = (name: string, buttonImage: any, hotKey?: any, toolGroup?: any) => R.pipeP(
  initRectAnnot(`${name}Template`, { label: name }),
  defineToolClass({
    className: `${name}TemplateTool`,
    annotClassName: `${name}TemplateRectAnnot`,
    baseClassName: 'GenericAnnotationCreateTool',
    // defaults: {},

    onMouseLeftDown: () => R.identity,
    onMouseLeftUp: async ({
      instance,
      config,
      context,
      annotClasses,
      ...rest
    }: InstanceObject) => {

      instance.Tools.GenericAnnotationCreateTool.prototype.mouseLeftDown.apply(context.tool, context.args);

      const { tool } = context;
      let annotation;
      let width = 10;
      let height = 60;
      const currentSignerId = instance.getSelectedSigner();
      const signer = instance.getSignerById(currentSignerId);
      if (!signer) {
        console.debug('No signer selected');
        return;
      }

      const fullName = parseName({
        lastName: '',
        ...signer,
      });

      width = ['FullName', 'FreeForm'].includes(name) ? (
        fullName.length > 10 ? (65 + (fullName.length * 3)) : 300
      ) : width;

      tool.annotation.Width = width;
      tool.annotation.Height = height;
      tool.annotation.X -= width / 2;
      tool.annotation.Y -= height / 2;
      tool.annotation.FontSize = '11pt';

      annotation = tool.annotation;
      instance.Tools.GenericAnnotationCreateTool.prototype.mouseLeftUp.apply(context.tool, context.args);
      instance.annotManager.redrawAnnotation(annotation);
      instance.setToolMode('AnnotationEdit');
      instance.annotManager.deleteAnnotation(annotation);
    },

    onAnnotationAdded: createTextField({
      name: `${name}Template`,
      annotClassName: `${name}TemplateFreeTextAnnot`,
    }),
  }),
  registerAnnotationType({ elementName: 'template', annotClassName: `${name}TemplateFreeTextAnnot` }),
  registerTool({
    annotClassName: `${name}TemplateFreeTextAnnot`,
    toolName: `${name}TemplateTool`,
    buttonImage,
    buttonName: `${name}Tool`,
    tooltip: (name === 'FreeForm') ? 'Free-form text the signer can type into' : `${_.startCase(name)} Tag ${hotKey ? `(${hotKey})` : ''}`,
    colorPalette: [],
    showColor: 'never',
  }, { clearOnDraw: false, hotKey, toolGroup })
);

export default createTemplateTool;
