import { GridJustification } from "@material-ui/core";

// If you want the font selector, here are the props for it
interface ComponentWithFontProps {
  canChooseFont: true, // Must be true, not a boolean, or else the union below wouldn't have an easy to determine if you're doing the right thing
  font: string,
  setFont(font: string): void,
  fontChoices: string[],
};
// If you don't want the font selector, then you can provide font selector props out of laziness, but you must not provide canChooseFont={true}
type ComponentWithoutFontProps = Omit<Partial<ComponentWithFontProps>, 'canChooseFont'> & { canChooseFont?: false };
type ComponentFontProps = ComponentWithFontProps | ComponentWithoutFontProps;

// Same idea for fonts is applied here
interface ComponentWithColorProps {
  canChooseColor: true,
  color: 'blue' | 'black',
  setColor(color: 'blue' | 'black'): void,
};
type ComponentWithoutColorProps = Omit<Partial<ComponentWithColorProps>, 'canChooseColor'> & { canChooseColor?: false };
type ComponentColorProps = ComponentWithColorProps | ComponentWithoutColorProps;

// The component uses a font + color selector, so both are exposed, along with common props
interface ComponentCommonProps {
  showLabels?: boolean,
  justifyContent?: GridJustification,
};
export type ComponentProps = ComponentFontProps & ComponentColorProps & ComponentCommonProps;
