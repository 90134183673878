import * as R from 'ramda';
import { injectTool } from '../initializers/injectors';
import { vaDisclaimerIcon } from '../constants/icons';
import AuditActions from '../constants/auditActions';
import { createSendEvent } from '../eventListeners/auditTrails';
import { InstanceObject } from '../../viewer';


const showVaButton = ({ instance, ...rest }: InstanceObject) => {
  const { nsId, docs } = rest.config;
  const isNotary = () => instance.annotManager.getIsAdminUser();
  const sendEvent = createSendEvent(instance, nsId, isNotary, docs);

  return ({
    type: 'statefulButton',
    initialState: 'NotActive',
    states: {
      NotActive: {
        title: 'VA Disclaimer',
        img: vaDisclaimerIcon,
        onClick: async () => {
          instance.annotManager.trigger('vaDisclaimerChanged', [true]);
        },
      },
      Active: {
        title: 'VA Disclaimer (Open)',
        getContent: () => 'VA Open',
        onClick: async () => {
          instance.annotManager.trigger('vaDisclaimerChanged', [false]);
        },
      },
    },
    mount: (update: any) => {
      // Checkout https://www.pdftron.com/api/web/CoreControls.DocumentViewer.html to see more APIs and events with docViewer
      // We want to update this button when page number changes so it can have the correct content;
      instance.annotManager.on('vaModalDismissed', () => {
        update('NotActive');

        // Accounts for edge cases if modal is dismissed when the selected signer is -1 then we can assume it was closed
        // Either because the signer disconnected with it open or the modal was closed after switching signers to 'Not Set'
        const selectedSigner = instance.getSelectedSigner();

        if (selectedSigner === '-1') {
          sendEvent(
            'hide',
            AuditActions.NOTARY_CLOSED_VA_ACCEPTANCE
          );
        }
      });
      instance.annotManager.on('vaModalOpen', () => {
        update('Active');
      });
    },
    title: 'VA Disclaimer',
    dataElement: 'vaDisclaimer',
  });
};

const registerShowSigner = R.pipeP(
  injectTool('VaDisclaimer', showVaButton)
);

export default registerShowSigner;
