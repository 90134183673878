import { gql } from '@apollo/client';

export const GET_TRANSACTION = gql`
  query getTransaction($transactionId: ID!) {
    getTransactionId(id: $transactionId) {
      id
      email
      lockedOut
      failureReason
      status
      settings {
        _raw
        style
      }
    }
  }
`

export const TRANSACTION_UPDATES = gql`
  subscription transactionUpdates($transactionId: String!) {
    transactionUpdated(id: $transactionId) {
      status
      lockedOut
      failureReason
    }
  }
`;
