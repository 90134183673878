import React from 'react';
import type { FC } from 'react';
import {
  makeStyles,
  Typography,
} from '@material-ui/core';
import 'nprogress/nprogress.css';
// import type { Theme } from 'document-viewer/src/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 99999,

    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  }
}));

export interface ErrorScreenProps {
  error: Error;
}

const ErrorScreen: FC<ErrorScreenProps> = ({ error }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
        align='center'
        // variant={mobileDevice ? 'h4' : 'h1'}
        color='textPrimary'
      >
        {error.message || String(error)}
      </Typography>
    </div>
  );
};

export default ErrorScreen;
