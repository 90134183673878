import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GET_CUSTOM_TAGS } from 'document-viewer/src/lib/gql/customTags';
import { AppThunk } from 'document-viewer/src/store';
import { CustomTags } from 'document-viewer/src/utils/types/customTags';

export interface CustomTagsState {
  customTags: CustomTags;
}

export const initialState: CustomTagsState = {
  customTags: {},
};

const slice = createSlice({
  name: 'customTags',
  initialState,
  reducers: {
    setCustomTags(state: CustomTagsState, { payload }: PayloadAction<{ customTags: CustomTags }>) {
      state.customTags = payload.customTags;
    }
  }
});

export const getCustomTags = (organizationId): AppThunk => async (dispatch, getState, client) => {
  const { data } = await client.query({
    query: GET_CUSTOM_TAGS,
    variables: { organizationId },
  });
  dispatch(setCustomTags(data));
}

export const { setCustomTags } = slice.actions;
export const reducer = slice.reducer;
export const selector = (state: { customTags: CustomTagsState }) => state.customTags.customTags;
export default slice;
