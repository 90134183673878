/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { InstanceObject } from '../../viewer';

export interface registerToolProps {
  type?: any;
  dataElement?: any;
  toolName: string;
  annotClassName?: string;
  buttonImage?: any;
  buttonName?: string;
  tooltip?: any;
  showColor?: any;
  colorPalette?: any;
  button?: any;
}

export interface registerToolOpts {
  clearOnDraw?: any;
  hotKey?: any;
  toolGroup?: any;
}

const registerTool = ({
  toolName,
  annotClassName,
  buttonImage,
  buttonName,
  tooltip,
  showColor,
  colorPalette,
  button,
}: registerToolProps, opts: registerToolOpts = {}) => async ({ instance, config, tools, toolClasses, annotClasses, ...rest }: InstanceObject) => {
  // register the tool
  const toolObject = new toolClasses[toolName](instance.docViewer);

  if (opts.clearOnDraw) {
    toolObject.on('annotationAdded', () => {
      return instance.setActiveHeaderGroup('default');
    });
  }

  if (opts.hotKey) {
    instance.hotkeys.on(opts.hotKey, () => {
      if (opts.toolGroup) {
        // NOTE: if editing free text don't switch header
        const freetextAnnots = instance.annotManager.getAnnotationsList().filter((annot: any) => annot instanceof instance.Annotations.FreeTextAnnotation);

        if (freetextAnnots) {
          const isEditingFreetext = freetextAnnots.some((annot: any) => annot.getEditor()?.hasFocus());

          if (isEditingFreetext) return;
        }


        instance.setActiveHeaderGroup(opts.toolGroup);
      }

      instance.setToolMode(toolName);
    });
  }


  await instance.registerTool({
    toolName,
    buttonImage,
    buttonName,
    tooltip,
    toolObject,
    showColor,
  }, annotClassName && annotClasses[annotClassName]);

  (instance.Tools as any)[toolName] = toolClasses[toolName];

  if (colorPalette) {
    instance.setColorPalette({
      toolNames: [toolName],
      colors: colorPalette,
    });
  }

  return {
    ...rest,
    instance,
    toolClasses,
    annotClasses,
    config,
    tools: {
      ...tools,
      [toolName]: (button) ? button(instance) : {
        type: 'toolButton',
        toolName,
        dataElement: _.lowerFirst(toolName),
        // hidden: ['tablet', 'mobile'],
      },
    },
  };
};


export default registerTool;
