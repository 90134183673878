import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { setParticipants, setPendingSelection } from './participants';
import { CHECK_AUTH, SEND_VERIFICATION_CODE, UPDATE_PARTICIPANT, VERIFY_CODE, VERIFY_PERSONAL_PASSWORD } from 'document-viewer/src/lib/gql/authentication';
import { AuthStates } from 'document-viewer/src/components/authentication/types';
import { AppThunk } from 'document-viewer/src/store';
import { setTransaction } from './transaction';


export interface AuthenticationState {
  loading: boolean;
  currentProcess: AuthStates | null;
  currentAuthentication: string | null;
  authenticated: string[]
}

const initialState: AuthenticationState = {
  currentAuthentication: null,
  currentProcess: AuthStates.Initial,
  authenticated: [],
  loading: false
};

const slice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAuthenticated: (state: AuthenticationState, action: PayloadAction<{ participantIds: string[] }>) => {
      state.authenticated = action.payload.participantIds;
    },
    setAuthenticating: (state: AuthenticationState, action: PayloadAction<{ participantId: string }>) => {
      const existing = _.find(state.authenticated, (el) => el === action.payload.participantId);
      if (existing) {
        return;
      }
      state.currentAuthentication = action.payload.participantId;
    },
    clearAuthenticating: (state: AuthenticationState) => {
      state.currentProcess = AuthStates.Initial;
      state.currentAuthentication = null;
    },
    setLoading: (state: AuthenticationState, action: PayloadAction<boolean>) => {
      state.loading = !!action.payload;
    },
    setCurrentProcess: (state: AuthenticationState, action: PayloadAction<AuthStates>) => {
      state.currentProcess = action.payload;
    }
  }
});


export const clearAuthenticating = () => slice.actions.clearAuthenticating();
export const setCurrentProcess = (val: AuthStates) => slice.actions.setCurrentProcess(val);

export const setAuthenticatedParticipants = (pIds: string[]) => slice.actions.setAuthenticated({ participantIds: pIds });


export const checkAuth = (sessionId, participantId): AppThunk => async (dispatch, getState, client) => {

  const { data } = await client.query({
    query: CHECK_AUTH,
    variables: {
      auth: `${sessionId}-${participantId}`,
    }
  });

  if (data.checkAuth) {
    const existing = _.find(getState().authentication.authenticated, (el) => el === participantId);
    if (existing) {
      dispatch(slice.actions.clearAuthenticating());

      // if successfully authenticated
      return dispatch(setPendingSelection(participantId));
    }
  }

  const existing = _.find(getState().authentication.authenticated, (el) => el === participantId);
  if (existing) {
    dispatch(slice.actions.clearAuthenticating());
    return dispatch(setPendingSelection(participantId));
  }
  return dispatch(slice.actions.setAuthenticating({ participantId }))
};


export const verifyPersonalPassword = (participantId, answer, opts): AppThunk => async (dispatch, getState, client) => {
  dispatch(slice.actions.setLoading(true));

  const { data } = await client.mutate({
    mutation: VERIFY_PERSONAL_PASSWORD,
    variables: {
      participantId,
      personalPasswordAnswer: answer
    }
  });


  // if verified
  if (data.verifyPersonalPassword) {
    const transactionId = getState().transaction.id;
    const esignId = getState().transaction.esignId;

    await Promise.all([
      client.mutate({
        mutation: UPDATE_PARTICIPANT,
        variables: {
          participant: {
            id: participantId,
            emailVerified: true,
          },
          transactionId
        }
      }),
    ])

    const isAuth = JSON.parse(localStorage.getItem('isAuth') || '{}');
    localStorage.setItem('isAuth', JSON.stringify({
      session: esignId,
      participants: [
        ...(isAuth.participants || []),
        participantId
      ]
    }))

    dispatch(slice.actions.setCurrentProcess(AuthStates.CodeValidated));
    dispatch(slice.actions.setLoading(false));
    const pIds = getState().authentication.authenticated;
    dispatch(setAuthenticatedParticipants([...pIds, participantId]))
    dispatch(clearAuthenticating());

    return dispatch(setPendingSelection(participantId));
  } else {

    const { data: { getParticipants, getEsign: { id, status, transaction } } } = await fetch("/graphql", {
      "headers": {
        "accept": "*/*",
        "accept-language": "en-US,en;q=0.9",
        "content-type": "application/json",
      },
      "referrerPolicy": "strict-origin-when-cross-origin",
      "body": JSON.stringify({
        operationName: 'getInitialData',
        variables: {
          ...opts,
        },
        query: "query getInitialData($organizationId: ID!, $participantIds: [String]!, $sessionId: String!, $iframeId: ID!) {\n  getParticipants(ids: $participantIds) {\n    id\n    firstName\n    middleName\n    lastName\n    kbaAttempts\n    email\n    phone\n    twoFactorContact\n    status\n    authenticationType\n    authenticationFailures\n    authenticationFailures\n    authenticationPassed\n    personalPasswordQuestion\n    kbaPassed\n    kbaRequired\n    credAnalysisRequired\n    credAnalysisPassed\n    signature\n    initials\n    textColor\n    order\n    address1\n    address2\n    city\n    state\n    zip\n    phone\n    twoFactorContact\n    order\n    skipVerification\n    documents {\n      documentId\n      isCompleted\n      readOnly\n      visible\n      __typename\n    }\n    __typename\n  }\n  getEsign(id: $sessionId, organizationId: $organizationId, iframeId: $iframeId) {\n    id\n    status\n    transaction {\n      id\n      status\n      auth0OrganizationId\n      lockedOut\n      failureReason\n      __typename\n    }\n    documents {\n      id\n      description\n      title\n      status\n      order\n      tags\n      revisions {\n        url\n        gcsRefId\n        id\n        participantId\n        version\n        xfdf\n        completed\n        __typename\n      }\n      __typename\n    }\n    iframeRequest {\n      id\n      style\n      data\n      inUsed\n      organizationId\n      __typename\n    }\n    __typename\n  }\n}\n"
      }),
      "method": "POST",
      "mode": "cors",
      "credentials": "omit"
    })
      .then((resp) => resp.json());

    dispatch(setTransaction({
      ...transaction,
      esignId: id,
      esignStatus: status,
    }));

    dispatch(setParticipants(getParticipants))
    if (transaction.lockedOut !== true) {
      dispatch(slice.actions.setCurrentProcess(AuthStates.TryAgain));
    }
    dispatch(slice.actions.setLoading(false));
  }


}



export const sendVerificationCode = (sessionId, participantId, type) => async (dispatch, getState, client) => {
  dispatch(slice.actions.setLoading(true));

  let data
  try {
    data = (await client.mutate({
      mutation: SEND_VERIFICATION_CODE,
      variables: {
        sessionId,
        participantId,
        type
      }
    })).data;
  } catch (err) {
    dispatch(slice.actions.setCurrentProcess(AuthStates.ErrorSendingCode));
  }

  if (data?.sendVerificationCode === true) {
    dispatch(slice.actions.setCurrentProcess(AuthStates.CodeSent));
  }
  dispatch(slice.actions.setLoading(false));
};

export const verifyCode = (sessionId, code, opts) => async (dispatch, getState, client) => {
  dispatch(slice.actions.setLoading(true));
  const participantId = getState().authentication.currentAuthentication;
  const { data } = await client.mutate({
    mutation: VERIFY_CODE,
    variables: {
      sessionId,
      code,
      participantId,
    }
  });



  if (data.verifyCode) {
    const transactionId = getState().transaction.id;
    const esignId = getState().transaction.esignId;

    await Promise.all([
      client.mutate({
        mutation: UPDATE_PARTICIPANT,
        variables: {
          participant: {
            id: participantId,
            emailVerified: true,
          },
          transactionId
        }
      }),


      // client.mutate({
      //   mutation: SET_AUTH,
      //   variables: {
      //     auth: `${}`
      //   }
      // })

    ])

    const isAuth = JSON.parse(localStorage.getItem('isAuth') || '{}');
    localStorage.setItem('isAuth', JSON.stringify({
      session: esignId,
      participants: [
        ...(isAuth.participants || []),
        participantId
      ]
    }))

    dispatch(slice.actions.setCurrentProcess(AuthStates.CodeValidated));
    dispatch(slice.actions.setLoading(false));
    const pIds = getState().authentication.authenticated;
    dispatch(setAuthenticatedParticipants([...pIds, participantId]))
    dispatch(clearAuthenticating());
    dispatch(slice.actions.setLoading(false));
    return dispatch(setPendingSelection(participantId));
  }
  else {
    const { data: { getEsign: { id, status, transaction } } } = await fetch("/graphql", {
      "headers": {
        "accept": "*/*",
        "accept-language": "en-US,en;q=0.9",
        "content-type": "application/json",
      },
      "referrerPolicy": "strict-origin-when-cross-origin",
      "body": JSON.stringify({
        operationName: 'getInitialData',
        variables: {
          ...opts,
          // organizationId: query.org,
          // participantIds: query.participant,
          // sessionId: query.session,
          // iframeId: query.iframe,
        },
        query: "query getInitialData($organizationId: ID!, $participantIds: [String]!, $sessionId: String!, $iframeId: ID!) {\n  getParticipants(ids: $participantIds) {\n    id\n    firstName\n    middleName\n    lastName\n    kbaAttempts\n    email\n    phone\n    twoFactorContact\n    status\n    authenticationType\n    authenticationFailures\n    authenticationPassed\n    personalPasswordQuestion\n    kbaPassed\n    kbaRequired\n    credAnalysisRequired\n    credAnalysisPassed\n    signature\n    initials\n    textColor\n    order\n    address1\n    address2\n    city\n    state\n    zip\n    phone\n    twoFactorContact\n    order\n    skipVerification\n    documents {\n      documentId\n      isCompleted\n      readOnly\n      visible\n      __typename\n    }\n    __typename\n  }\n  getEsign(id: $sessionId, organizationId: $organizationId, iframeId: $iframeId) {\n    id\n    status\n    transaction {\n      id\n      status\n      auth0OrganizationId\n      lockedOut\n      failureReason\n      __typename\n    }\n    documents {\n      id\n      description\n      title\n      status\n      order\n      tags\n      revisions {\n        url\n        gcsRefId\n        id\n        participantId\n        version\n        xfdf\n        completed\n        __typename\n      }\n      __typename\n    }\n    iframeRequest {\n      id\n      style\n      data\n      inUsed\n      organizationId\n      __typename\n    }\n    __typename\n  }\n}\n"
      }),
      "method": "POST",
      "mode": "cors",
      "credentials": "omit"
    })
      .then((resp) => resp.json());

    dispatch(setTransaction({
      ...transaction,
      esignId: id,
      esignStatus: status,
    }))

    if (transaction.lockedOut !== true) {
      dispatch(slice.actions.setCurrentProcess(AuthStates.TryAgain));
    }
    dispatch(slice.actions.setLoading(false));
  }

}



// (window as any).checkAuth = (sessionId, participantId) => client.query({
//   query: CHECK_AUTH,
//   variables: {
//     auth: `${sessionId}-${participantId}`,
//   }
// })



export const reducer = slice.reducer;
export const selector = (state) => state.authentication;
export default slice;
