import { makeStyles } from '@material-ui/core/styles';
import type { Theme } from 'document-viewer/src/lib/hooks/useTheme';

export const BUTTON_MAX_WIDTH = '250px';
export const BUTTON_MAX_FONT_SIZE_IN_PX = 27; // 1.7rem
export const useAutoSignStyles = makeStyles((theme: Theme) => ({
  buttonRoot: {
    height: '50px',
    borderRadius: (theme.buttonRadius !== undefined)
      ? `0 0 ${theme.buttonRadius}px ${theme.buttonRadius}px`
      : '0 0 8px 8px',
    minWidth: BUTTON_MAX_WIDTH,
    boxShadow: '0 5px 7px rgba(0,0,0, .3)',
    marginBottom: 8,
    marginTop: -1, // prevent 1px line on bounce (windows only?)
    animation: (theme.topButtonAnimation ?? true)
      ? `$shimmer 1000ms infinite alternate`
      : undefined,
    '&:hover': (theme.topButtonAnimation ?? true)
      ? { animation: `$pulse 500ms infinite alternate` }
      : undefined,
    [theme.breakpoints.down('sm')]: {
      height: '40px',
    },
  },
  buttonText: {
    fontSize: `${BUTTON_MAX_FONT_SIZE_IN_PX}px`,
    '& > * + *': {
      marginLeft: '.5rem',
    },
    '& span': { fontWeight: 'bold !important', },
    [theme.breakpoints.down('sm')]: {
      fontSize: `${BUTTON_MAX_FONT_SIZE_IN_PX * 0.75}px`,
    },
  },
  '@keyframes pulse': {
    to: {
      transform: 'scale(1.1)',
    },
  },
  '@keyframes shimmer': {
    to: {
      filter: 'brightness(1.3)',
    },
  },
  invisible: {
    visibility: 'hidden',
  },
  textFit: {
    maxWidth: BUTTON_MAX_WIDTH,
    width: '100%',
  },
}));
