import React, { createContext, useEffect, useState, useContext, useCallback } from 'react';
import { useEffectOnce, useLocalStorage } from 'react-use';
import { AuthStates } from './types';
import * as R from '@enotarylog/ramda';
import _ from 'lodash'
import { useDispatch, useSelector } from 'document-viewer/src/store';
import { setAuthenticatedParticipants } from 'document-viewer/src/slices/authentication';
import { selector as authSelector } from 'document-viewer/src/slices/authentication';
import { createSelector } from '@reduxjs/toolkit';
import { useQuery } from 'document-viewer/src/lib/hooks/useQuery';
import { setParticipantPinCodes } from 'document-viewer/src/slices/participants';



const AuthCtx = createContext({
  isAuth: null,
  currentProcess: AuthStates.Initial,
  setCurrentProcess: R.identity,
  showModal: false,
  setShowModal: (_: boolean) => { },
  // list of participants who have authenticated
  authenticatedParticipantIds: [],
  participants: [],
  // checkAuth: R.identity,
  // id of participant currently doing auth
  participantId: null,
});



export function AuthProvider({ children, session, participants, bypass }) {
  const [showModal, setShowModal] = useState(false);
  const [authedParticipants, setAuthedParticipants] = useState([]);
  const [participantId] = useState(null);

  const [authUser, setLocal] = useLocalStorage('isAuth', null);
  const [pinCodes] = useLocalStorage('pinCodes', {});

  const [currentProcess, setCurrentProcess] = useState<AuthStates>(AuthStates.Initial);
  const [isAuth] = useState<boolean | null>(bypass);

  const authenticatedParticipants = useSelector(createSelector(authSelector, (state) => state.authenticated))
  const dispatch = useDispatch();
  const query = useQuery();

  useEffectOnce(() => {
    if (authUser && authUser.participants && authUser.session === query.session) {
      dispatch(setAuthenticatedParticipants(authUser.participants))
    }
  });

  useEffectOnce(() => {
    dispatch(setParticipantPinCodes(pinCodes))
  });

  useEffect(() => {
    if (authenticatedParticipants && authenticatedParticipants.length > 0) {
      if (_.difference(authUser?.participants || [], authenticatedParticipants).length > 0 || _.difference(authenticatedParticipants, authUser?.participants || []).length > 0) {
        setLocal({
          session: query.session,
          participants: authenticatedParticipants
        })
      }
    }
  }, [authUser?.participants, authenticatedParticipants, query.session, setLocal])


  // if localstorage value received, then set authedParticipants
  useEffect(() => {
    if (authUser && authUser?.session === session && authUser?.participants) {
      setAuthedParticipants(authUser.participants);
      dispatch(setAuthenticatedParticipants(authUser.participants))
    }
  }, [authUser, dispatch, session])





  const getParticipants = useCallback(() => _.chain(participants)
    .groupBy('id')
    .mapValues((val) => _.head(val))
    .value() as any[], [participants])

  const getCurrUserIsAuthed = useCallback(() => !_.isNil(_.find(authedParticipants, (el) => el === participantId)), [authedParticipants, participantId])

  const currParcipants = getParticipants();

  const ctx = {
    isAuth,
    authenticatedParticipantIds: authedParticipants,
    participantId,
    currentProcess,
    setCurrentProcess,
    showModal,
    setShowModal,
    currUserAuthed: getCurrUserIsAuthed,
    participants: currParcipants,
  };

  return <AuthCtx.Provider value={ctx}>{children}</AuthCtx.Provider>;
}

export const useAuth = () => useContext(AuthCtx);
