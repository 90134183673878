import { useQuery } from 'document-viewer/src/lib/hooks/useQuery';
import { SessionProvider } from 'document-viewer/src/lib/hooks/useSessionStatus';
import { WebViewerProvider } from 'document-viewer/src/lib/hooks/useWebViewer';


export default function EsignGuard({ children }) {
  const query = useQuery()
  return (
    <SessionProvider
      organizationId={query.org}
      participantIds={query.participant}
      sessionId={query.session}
      iframeId={query.iframe}
    >
      <WebViewerProvider
        // selectedParticipantId={''}
        // checkAuth={() => { }}
        sessionId={query.session}
      >
        {children}
      </WebViewerProvider>
    </SessionProvider>
  )
}
