import React, { useCallback, useState } from 'react';
import {
  Drawer, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import { useTheme } from 'document-viewer/src/lib/hooks/useTheme';
import { useWebViewer } from 'document-viewer/src/lib/hooks/useWebViewer';
import { useRightPanelStyles } from './styles';
import PanelToggle from './PanelToggle';
import { useFlags } from 'document-viewer/src/lib/hooks/useFlags';
import { useQuery } from 'document-viewer/src/lib/hooks/useQuery';
import { useDispatch } from 'document-viewer/src/store';
import ParticipantsList from 'document-viewer/src/components/ParticipantsList';
import { checkAuth } from 'document-viewer/src/slices/authentication';
import { useDocs } from 'document-viewer/src/lib/hooks/useDocs';
import { useParticipants } from 'document-viewer/src/lib/hooks/useParticipants';
import PanelTools from 'document-viewer/src/components/PanelTools';
import DocumentSearch from 'document-viewer/src/components/DocumentSearch';
import { useRequestData } from 'document-viewer/src/lib/hooks/useRequestData';
import useDocSearch from 'document-viewer/src/lib/hooks/useDocSearch';
import SimpleDocumentList from 'document-viewer/src/components/SimpleDocumentList/index';
import SaveAndSwitchToDocModal from './SaveAndSwitchToDocModal';
import useAnnots from 'document-viewer/src/lib/hooks/useAnnots';
import type { UUID } from 'document-viewer/src/utils/types/uuid';
import { Accordion } from 'document-viewer/src/components/Accordion'


const RightPanel = ({
  panelOpen,
  drawerWidth,
  isMobile,
  toolsLabelEnabled,
  searchEnabled,
  zoomLabelEnabled,
  toggleHandler,
  children,
  showAutoScrollSpeed,
  completedNoTagDocList,
  isEsign,
}) => {
  const flags = useFlags();
  const theme = useTheme();
  const classes = useRightPanelStyles({ panelOpen, isMobile });
  const dispatch = useDispatch();
  const {
    docs,
    setSelectedDoc,
    selectedDoc,
    documentLocked,
    saveDoc,
    docIds,
  } = useDocs();
  const { appliedAnnots, clearAppliedAnnots } = useAnnots();
  const {
    participants,
    selectedParticipant,
    selectedParticipantId,
  } = useParticipants();
  const { instance } = useWebViewer();
  const query = useQuery();
  const { requestData } = useRequestData();
  const docSearch = useDocSearch(instance);
  const [showSaveAndSwitchToDoc, setShowSaveAndSwitchToDoc] = useState<string | null>(null);
  const saveThisDoc = useCallback(() => saveDoc(selectedDoc, false, completedNoTagDocList), [saveDoc, selectedDoc]);
  const switchToDoc = useCallback(() => {
    setSelectedDoc(showSaveAndSwitchToDoc);
    clearAppliedAnnots(selectedDoc);
  }, [clearAppliedAnnots, selectedDoc, setSelectedDoc, showSaveAndSwitchToDoc]);

  return (
    <>
      <PanelToggle
        isOpen={panelOpen}
        direction='right'
        drawerWidth={drawerWidth}
        showToggle={isMobile}
        handler={toggleHandler}
        sideBarOpenCloseStyling={requestData?.sideBarOpenCloseStyling}
      />
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='right'
        open={panelOpen}
        classes={{ paper: classes.drawerPaper, }}
      >
        {searchEnabled && flags.showSearchInDocumentViewer && (
          <DocumentSearch {...docSearch} />
        )}
        <SaveAndSwitchToDocModal
          show={!!showSaveAndSwitchToDoc}
          onClose={() => setShowSaveAndSwitchToDoc(null)}
          saveDoc={saveThisDoc}
          switchToDoc={switchToDoc}
        />
        <PanelTools
          docs={docs}
          isMobile={isMobile}
          showGetInformationInDocumentViewer={flags.showGetInformationInDocumentViewer}
          showRequestHelpInDocumentViewer={flags.showRequestHelpInDocumentViewer}
          showCorrectionsNeededInDocumentViewer={flags.showCorrectionsNeededInDocumentViewer}
          toolsLabelEnabled={toolsLabelEnabled}
          zoomLabelEnabled={zoomLabelEnabled}
          searchEnabled={searchEnabled}
          showAutoScrollSpeed={showAutoScrollSpeed}
        />
        {theme?.showParticipants ?? true ? (
          <>
            <ParticipantsList
              onClick={(pId) => dispatch(checkAuth(query.session, pId))}
              participants={participants}
              selectedParticipant={selectedParticipantId}
            />
            {children}
          </>
        ) : (
          <Accordion
            square
            expanded={true}
            className={classes.noBorder}
          >
            <AccordionSummary>
              <h2
                className={classes.summaryHeader}
              >
                Documents
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <SimpleDocumentList
                completedNoTagDocList={completedNoTagDocList}
                docs={docs}
                selectedDocId={selectedDoc}
                selectedParticipant={selectedParticipant}
                docIds={docIds}
                onDocSelected={(docId: UUID): void => {}}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </Drawer>
    </>
  );
};

export default RightPanel;
