import { useRef } from 'react';

/**
 * This hook disables pinch zoom on iOS devices. It does this by listening to
 * touch events and preventing the default behavior if the user is trying to
 * zoom in.
 *
 * The reason we do this is because the pinch zoom behavior on iOS devices
 * was crashing the application when a modal was open on top of the document viewer.
 *
 * See ticket V2-8155 for more details.
 *
 * @returns {{ disablePinchZoom: (isIOSDevice?: boolean) => void; enablePinchZoom: () => void; }}
 */
export const useDisablePinchZoom = () => {
  const pinchStart = useRef(0);
  const areEventsBound = useRef(false);

  // Gets a rough estimate of the distance between touches
  const getDistanceBetweenTouches = (e: TouchEvent) =>
    Math.hypot(
      e.touches[0].pageX - e.touches[1].pageX,
      e.touches[0].pageY - e.touches[1].pageY
    );

  const onTouchStart = (e: TouchEvent) => {
    // Check if 2 or more fingers are touching the screen
    if (e.touches.length >= 2) {
      pinchStart.current = getDistanceBetweenTouches(e);
    }
  };

  const onTouchMove = (e: TouchEvent) => {
    if (e.touches.length >= 2) {
      const distance = getDistanceBetweenTouches(e);

      // If the distance increased, we can assume the user is trying to zooming in.
      if (distance > pinchStart.current) {
        e.preventDefault();
      }
    }
  };

  const disablePinchZoom = () => {
    if (!areEventsBound.current) {
      document.addEventListener('touchstart', onTouchStart, { passive: false });
      document.addEventListener('touchmove', onTouchMove, { passive: false });
      areEventsBound.current = true;
    }
  };

  const enablePinchZoom = () => {
    document.removeEventListener('touchstart', onTouchStart);
    document.removeEventListener('touchmove', onTouchMove);
    areEventsBound.current = false;
    pinchStart.current = 0;
  };

  return {
    disablePinchZoom,
    enablePinchZoom,
  };
};
