export const THUMBNAIL_SIZE = 150;

const getThumbnailSize = (pageWidth: number, pageHeight: number) => {
  let width;
  let height;
  let ratio;

  if (pageWidth > pageHeight) {
    ratio = pageWidth / THUMBNAIL_SIZE;
    width = THUMBNAIL_SIZE;
    height = Math.round(pageHeight / ratio);
  } else {
    ratio = pageHeight / THUMBNAIL_SIZE;
    width = Math.round(pageWidth / ratio); // Chrome has trouble displaying borders of non integer width canvases.
    height = THUMBNAIL_SIZE;
  }

  return {
    width,
    height,
  };
};

interface IGenerateThumbnailRtn {
  pageIndex: number;
  page: HTMLCanvasElement;
  annot: HTMLCanvasElement;
}

const initThumbnailUtils = (core, currentDocThumbnails) => {
  const utils = {
    renderAnnot: (pageIndex: number) => {
      const pageWidth = core.getPageWidth(pageIndex);
      const pageHeight = core.getPageHeight(pageIndex);
      const pageNumber = pageIndex + 1;
      const { width, height } = getThumbnailSize(pageWidth, pageHeight);

      const annotCanvas = currentDocThumbnails[pageIndex]?.annot || document.createElement('canvas');
      annotCanvas.className = 'annotation-image';
      annotCanvas.style.maxWidth = `${THUMBNAIL_SIZE}px`;
      annotCanvas.style.maxHeight = `${THUMBNAIL_SIZE}px`;

      const ctx = annotCanvas.getContext('2d');

      let zoom = 1;
      let rotation = core.getCompleteRotation(pageNumber) - core.getRotation(pageNumber);
      if (rotation < 0) {
        rotation += 4;
      }
      const multiplier = 1;
      if (rotation % 2 === 0) {
        annotCanvas.width = width;
        annotCanvas.height = height;
        zoom = annotCanvas.width / pageWidth;
        zoom /= multiplier;
      } else {
        annotCanvas.width = height;
        annotCanvas.height = width;

        zoom = annotCanvas.height / pageWidth;
        zoom /= multiplier;
      }

      core.setAnnotationCanvasTransform(ctx, zoom, rotation);

      const options = {
        pageNumber,
        overrideCanvas: annotCanvas,
        namespace: 'thumbnails',
        overridePageRotation: rotation,
        overridePageCanvas: currentDocThumbnails[pageIndex]?.page,
      };
      core.drawAnnotations(options);
      return annotCanvas;
      // dispatch(addThumbnail(selectedDoc, pageIndex, undefined, annotCanvas))
    },
    generateThumbnail: (pageIndex: number) => new Promise((res) => {
      core.loadThumbnailAsync(pageIndex, (thumb: HTMLCanvasElement) => {
        thumb.style.height = '100%'
        // thumb.style.width = '115px';
        thumb.style.boxShadow = '0 5px 8px lightgrey';
        const annotCanvas = utils.renderAnnot(pageIndex);
        return res({
          pageIndex,
          annot: annotCanvas,
          page: thumb
        })
      });
    }) as Promise<IGenerateThumbnailRtn>
  }
  return utils;
};


export default initThumbnailUtils;
