import _ from 'lodash';
import C2S from 'canvas2svg';
import trimCanvas from 'trim-canvas';

interface min {
  minX: number;
  minY: number;
}

interface path {
  x: number;
  y: number;
}

function drawPathSegment(ctx: CanvasRenderingContext2D, paths: path[] = [], min?: min) {
  if (paths.length === 0 || !min) {
    return;
  }

  let h = paths.length;

  if (h >= 3) {
    ctx.moveTo(paths[0].x - min.minX, paths[0].y - min.minY);

    for (h = 1; h < paths.length - 2; h++) {
      const { x: x1, y: y1 } = paths[h];
      const { x: x2, y: y2 } = paths[h + 1];
      const cx = ((x1 + x2) / 2) - min.minX;
      const cy = ((y1 + y2) / 2) - min.minY;

      ctx.quadraticCurveTo(x1 - min.minX, y1 - min.minY, cx, cy);
    }

    if (h + 1 < paths.length) {
      const { x: x1, y: y1 } = paths[h];
      const { x: x2, y: y2 } = paths[h + 1];

      ctx.quadraticCurveTo(x1 - min.minX, y1 - min.minY, x2 - min.minX, y2 - min.minY);
    }
  } else if (h === 2) {
    ctx.moveTo(paths[0].x - min.minX, paths[0].y - min.minY);
    ctx.lineTo(paths[1].x - min.minX, paths[1].y - min.minY);
  } else if (h === 1) {
    ctx.moveTo(paths[0].x - min.minX, paths[0].y - min.minY);
    ctx.arc(paths[0].x, paths[0].y, 0.5, 0, 2 * Math.PI);
  }
}


const getPathDim = (paths: path[][], lineWidth = 3) => {
  const allPaths = _.flatten(paths);
  if (allPaths.length === 0) {
    throw new Error("No paths given")
  }
  const maxX = _.maxBy(allPaths, 'x')!.x;
  const maxY = _.maxBy(allPaths, 'y')!.y;
  const minX = _.minBy(allPaths, 'x')!.x - lineWidth;
  const minY = _.minBy(allPaths, 'y')!.y - lineWidth;
  const width = (maxX - minX) + lineWidth;
  const height = (maxY - minY) + lineWidth;

  return {
    min: { minX, minY },
    width,
    height,
  };
};


function drawPathsToContext(paths: path[][], getContext: (width: number, height: number) => any) {
  const { min: { minX, minY }, width, height } = getPathDim(paths);

  const ctx = getContext(width, height);

  ctx.globalAlpha = 1;
  ctx.strokeStyle = 'rgba(75,146,219,1)';
  ctx.lineWidth = 3;
  ctx.beginPath();

  _.map(paths, (p) => drawPathSegment(ctx, p, { minX, minY }));

  ctx.stroke();

  return ctx;
}


export const toDataUrl = (paths: path[][]) => {
  const canvas = document.createElement('canvas');

  drawPathsToContext(paths, (width: number, height: number) => {
    canvas.width = width;
    canvas.height = height;

    return canvas.getContext('2d');
  });

  return trimCanvas(canvas).toDataURL('image/png', 1.5);
};


export const toSvg = (paths: path[][]) => {
  const ctx = drawPathsToContext(paths, (width: number, height: number) => new C2S(width, height));

  return ctx.getSerializedSvg(true);
};

export const toSvgElement = (paths: path[][]) => {
  const ctx = drawPathsToContext(paths, (width: number, height: number) => new C2S(width, height));

  return ctx.getSvg();
};
