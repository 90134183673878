import { FunctionComponent } from 'react';
import MuiSnackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import { selector as requestDataSelector } from 'document-viewer/src/slices/request-data';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'document-viewer/src/store';

const Snackbar: FunctionComponent<SnackbarProps> = (props) => {
  const requestData = useSelector(createSelector(requestDataSelector, (state) => state.data || {}));
  if (requestData.toastNotificationsEnabled) {
    return <MuiSnackbar {...props} />;
  }
  return null;
};

export default Snackbar;
