
import { gql } from '@apollo/client';
export const GET_STEP_PAGE = gql`
  query getStepPage($transactionId: String!, $sessionId: String){
    getStepPage (tId: $transactionId, sessionId: $sessionId){
      page
      step
      type
      participant
      witnesses
    }
  }
`;



export const NS_USER_SUBSCRIPTION = gql`
  subscription getNsUser($nsUserId: UUID!){
    notarySessionUser: notary_session_user_by_pk(id: $nsUserId){
      id
      updatedAt: updated_at
      user {
        updatedAt: updated_at
      }
    }
  }
`
