import React, { createContext, useContext, useRef } from 'react';
import { identity } from '@enotarylog/ramda';
import { useLazyQuery, useApolloClient } from '@apollo/client';
import { TransactionStatus } from 'document-viewer/src/utils/types/transaction';
import { useDispatch } from 'react-redux';
import { setDocuments } from 'document-viewer/src/slices/documents';
import _ from 'lodash';
import { setParticipants } from 'document-viewer/src/slices/participants';
import { setTransaction } from 'document-viewer/src/slices/transaction';
import { setTheme, setSigFonts } from 'document-viewer/src/slices/theme';
import * as R from '@enotarylog/ramda';
import { defaultTheme, defaultData } from '../../constants';
import { GET_SESSION_INFO, GET_ORG_INFO } from 'document-viewer/src/lib/gql/session';
import { setData } from 'document-viewer/src/slices/request-data';
import { defaultSigFonts } from 'document-viewer/src/utils/constants';
import { useEffectOnce } from 'react-use';

export interface SessionState {
  loading: boolean;
  error: Error | null;
  getSessionInfo: () => void;
  setOrganizationBrandingAsTheme: () => void;
  value: {
    documents: any;
    participants: any[];
    transactionId: string | null;
    status: string | null;
    esignStatus: string | null;
    orgEmail: string | null;
    orgId: string | null;
    isEsign: boolean | null;
  };
}

const SessionCtx = createContext<SessionState>({
  loading: false,
  error: null,
  getSessionInfo: identity,
  setOrganizationBrandingAsTheme: identity,
  value: {
    documents: {},
    participants: [],
    transactionId: null,
    status: null,
    esignStatus: null,
    orgEmail: null,
    orgId: null,
    isEsign: null,
  },
});

export function useSessionStatus() {
  return useContext(SessionCtx);
}


export function SessionProvider({ organizationId, participantIds, sessionId, iframeId, children }) {
  const dispatch = useDispatch();
  const apollo = useApolloClient()

  const createSessionHandler = (styleSetting: string) => async (opts) => {
    const { data: { getOrganizationInfo } } = await apollo.query({
      query: GET_ORG_INFO,
      variables: { organizationId, includeSettings: true }
    });
    dispatch(setSigFonts(
      [ // These are all the possible sources of sigFonts, and the first one to have a non-zero-length array in it will be used
        opts.getEsign?.iframeRequest?.data?.sigFonts,
        getOrganizationInfo?.config?.sigFonts,
        defaultSigFonts,
      ].find((sigFonts) => sigFonts && sigFonts.length > 0),
    ));

    dispatch(setDocuments(opts.getEsign.documents))
    dispatch(setTransaction({
      ...opts.getEsign.transaction,
      esignId: opts.getEsign.id,
      esignStatus: opts.getEsign.status,
    }))
    dispatch(setParticipants(opts.getParticipants))

    const orgStyle = ((getOrganizationInfo.settings || [])
      .find((s: { key: string, value: object }) => s.key === styleSetting) || {})
      .value || {};

    dispatch(setData({
      data: { ...defaultData, ..._.get(opts.getEsign, 'iframeRequest.data', {}) }
    }));

    const iframeReqStyle = _.get(opts.getEsign, 'iframeRequest.style', {}) || {};

    dispatch(setTheme(R.mergeDeepRight(
      defaultTheme,
      R.mergeDeepRight(orgStyle, iframeReqStyle)
    )));
  }

  const [getSessionInfo, { loading, error, data = {} }] = useLazyQuery(GET_SESSION_INFO, {
    variables: {
      organizationId,
      participantIds,
      sessionId,
      iframeId
    },
    ssr: false,
    onCompleted: createSessionHandler('style'),
    onError: (err) => {
      console.error(err);
    }
  });

  const setOrganizationBrandingAsTheme = async () => {
    const { data } = await apollo.query({
      query: GET_SESSION_INFO,
      variables: {
        organizationId,
        participantIds,
        sessionId,
        iframeId,
      }
    });

    createSessionHandler('brandingColors')(data);
  };

  useEffectOnce(() => {
    getSessionInfo()
  });

  const { getParticipants: participants = [], getOrganizationInfo: organizationInfo = {}, getEsign: esign = {} } = data;
  const transaction = esign.transaction || {};
  const documents = Object.fromEntries((esign.documents || []).map((doc) => [doc.id, doc]));

  const esignDocumentRef = useRef([]); // Necessary to avoid unnecessarily unsubscribing from doc revision changes in the useEffect
  esignDocumentRef.current = esign.documents || [];

  // Subscribe to changes in all of the documents

  const state: SessionState = {
    loading,
    error,
    getSessionInfo: () => { },
    setOrganizationBrandingAsTheme,
    value: {
      transactionId: transaction.id,
      participants,
      status: transaction.status,
      esignStatus: esign.status,
      orgEmail: organizationInfo.email,
      orgId: organizationInfo.id,
      documents,
      isEsign: transaction.status === TransactionStatus.esignPending,
    },
  };

  return <SessionCtx.Provider value={state}>{children}</SessionCtx.Provider>;
}
