import { selector as participantSelector } from 'document-viewer/src/slices/participants';
import { currentDocumentSel } from 'document-viewer/src/slices/documents';
import { addReviewedAnnot, removeAppliedAnnot, addAppliedAnnot, selector as annotSelector, clearAppliedAnnots, setPendingAnnot, clearPendingAnnot, setDocAnnots, appliedAnnotIdsByIdSel, reviewedAnnotIdsByIdSel } from 'document-viewer/src/slices/annotations';
import { createSelector } from '@reduxjs/toolkit';
import { RootState, useDispatch, useSelector } from 'document-viewer/src/store';
import { Annotations } from '@pdftron/webviewer';
import _ from 'lodash'
import { connect } from 'react-redux';
import { isUnreviewedAnnot } from 'document-viewer/src/utils/pdftron/tagHelpers';
import { useCallback } from 'react';


const currentParticipantSel = createSelector(participantSelector, (p) => p.selectedParticipant);
const pendingAnnotSel = createSelector(annotSelector, (p) => p.pendingAnnot);
const annotationsSel = createSelector(annotSelector, (annot) => annot.annotations.byId)
const appliedAnnotsSel = createSelector(appliedAnnotIdsByIdSel, currentDocumentSel, (appliedAnnotIds, docId) => appliedAnnotIds[docId] || []);
const reviewedAnnotsSel = createSelector(reviewedAnnotIdsByIdSel, currentDocumentSel, (reviewedAnnotIds, docId) => reviewedAnnotIds[docId] || []);


const currentDocAnnotsSel = createSelector(annotationsSel, currentDocumentSel, (allAnnots, docId) => allAnnots[docId]);
const currentParticipantAnnotsSel = createSelector(
  currentDocAnnotsSel,
  currentParticipantSel,
  (docAnnots, currPid) => _.filter(docAnnots, (annot) => annot.CustomData.signerId === currPid)
);

const unappliedAnnotsForParticipantSel = createSelector(
  currentParticipantAnnotsSel,
  appliedAnnotsSel,
  reviewedAnnotsSel,
  (allParticipantAnnots, appliedAnnots, reviewed) => {
    const rtn = _.filter(allParticipantAnnots, (a) => _.indexOf([...reviewed, ...appliedAnnots], a.Id) === -1);
    return rtn;
  }
);

const unreviewedTagsForOtherParticipantsSel = createSelector(currentDocAnnotsSel, participantSelector, currentParticipantSel, (annots, p, cp) => {
  const otherP = _.filter(p.participants.allIds, (pId) => pId !== cp);
  return _.filter(annots, (annot) => _.indexOf(otherP, annot.CustomData?.signerId) > -1 && isUnreviewedAnnot(annot));
})



export default function useAnnots() {
  const dispatch = useDispatch();
  const currentDocAnnots = useSelector<Annotations.Annotation[]>(currentDocAnnotsSel);
  const currentParticipantAnnots = useSelector(currentParticipantAnnotsSel)
  const pendingAnnot = useSelector(pendingAnnotSel);
  const unappliedAnnots = useSelector(unappliedAnnotsForParticipantSel);
  const reviewedAnnotIds = useSelector(reviewedAnnotsSel);
  const appliedAnnots = useSelector(appliedAnnotsSel);
  const unreviewedTagsForOtherParticipants = useSelector(unreviewedTagsForOtherParticipantsSel);

  return {
    annotations: currentDocAnnots,
    appliedAnnots,
    unappliedAnnots,
    reviewedAnnotIds,
    currentParticipantAnnots,
    pendingAnnot,
    unreviewedTagsForOtherParticipants,
    setDocAnnots: useCallback((docId: string, annots: Annotations.Annotation[]) => dispatch(setDocAnnots(docId, annots)), [dispatch]),
    removeAppliedAnnot: useCallback((selectedDoc, annot: Annotations.Annotation) => dispatch(removeAppliedAnnot(selectedDoc, annot.Id)), [dispatch]),
    addReviewedAnnot: useCallback((selectedDoc, annot: Annotations.Annotation) => dispatch(addReviewedAnnot(selectedDoc, annot.Id)), [dispatch]),
    addAppliedAnnot: useCallback((selectedDoc, annot: Annotations.Annotation) => dispatch(addAppliedAnnot(selectedDoc, annot.Id)), [dispatch]),
    clearAppliedAnnots: useCallback((docId: string) => dispatch(clearAppliedAnnots(docId)), [dispatch]),
    setPendingAnnot: useCallback((annot: Annotations.Annotation) => dispatch(setPendingAnnot(annot)), [dispatch]),
    clearPendingAnnot: useCallback(() => dispatch(clearPendingAnnot()), [dispatch]),
  }
}

export const withAnnots = connect((state: RootState) => ({
  annotations: currentDocAnnotsSel(state),
  appliedAnnots: appliedAnnotsSel(state),
  currentParticipantAnnots: currentParticipantAnnotsSel(state),
}), (dispatch) => ({
  removeAppliedAnnot: (selectedDoc, annot: Annotations.Annotation) => dispatch(removeAppliedAnnot(selectedDoc, annot.Id)),
  addAppliedAnnot: (selectedDoc, annot: Annotations.Annotation) => dispatch(addAppliedAnnot(selectedDoc, annot.Id)),
  clearAppliedAnnots: (docId: string) => dispatch(clearAppliedAnnots(docId)),
  setPendingAnnot: (annot: Annotations.Annotation) => dispatch(setPendingAnnot(annot)),
  clearPendingAnnot: () => dispatch(clearPendingAnnot()),
}));
