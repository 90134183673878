import { FileWithPath } from 'react-dropzone';

interface loadDocFromDetails {
  name: string
};

const extractFileExtension = (loadDocFrom: loadDocFromDetails | FileWithPath  ): string => {
  
  // if(loadDocFrom instanceof Blob || loadDocFrom instanceof File) {
  //   return 'pdf';
  // }
  
  let fileExtension = 'pdf';
  const basePath = loadDocFrom?.name || '';
  try {
    // const basePath = typeof loadDocFrom !== 'string' ? loadDocFrom.path : loadDocFrom; 

    // extract file extension from signed url string
    if(/\?/.test(basePath)) {
      const foundBasePathParts = basePath.split('?');
      fileExtension = foundBasePathParts[0].split('.').pop();
    } else {
      fileExtension = basePath.split('.').pop();
    }
  } catch (e) {
    console.error(e)
  }
  return fileExtension;

};

export default extractFileExtension;
