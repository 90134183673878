import bPromise from 'bluebird';
import _ from 'lodash';
import { InstanceObject } from '../../viewer';


export const applyFormFieldCreate = () => async ({ instance }: InstanceObject) => {
  const { Annotations } = instance;
  const annotManager = instance.docViewer.getAnnotationManager();

  (Annotations.WidgetAnnotation as any).getContainerCustomStyles = function (widget: any) {
    if (widget) {
      return {
        border: '1px dashed #ff0000',
        backgroundColor: 'rgba(50, 115, 220, 0.3)',
      };
    }
  };

  (Annotations.WidgetAnnotation as any).getCustomStyles = function (widget: any) {
    if (widget instanceof Annotations.TextWidgetAnnotation) {
      return { 'background-color': 'rgba(50, 115, 220, 0.3)' };
    }

    if (widget) {
      return {
        border: '0px solid #000',
      };
    }
  };

  const customTypes = [
    'SIGNATURE',
    'INITIALS',
    'CHECKBOX',
    'FORM',
    'CHECK',
    'TEXT',
  ];

  const annotations = [...annotManager.getAnnotationsList()];

  const fieldManager = instance.annotManager.getFieldManager();

  const isFreeText = (annot: any) => annot.Subject !== 'Widget' && annot instanceof Annotations.FreeTextAnnotation && (!_.isEmpty((annot as any).custom) || !_.isEmpty(annot.CustomData));

  const annotationsList = _.filter(annotations, isFreeText);

  const { toDelete: annotsToDelete, toDraw } = await bPromise.reduce(annotationsList, async (acc: any, annot: any) => {
    let field;
    let inputAnnot;

    // if existing annotation has a `custom` property then use its value to convert to our custom annotation
    if (annot.custom) {
      if (annot instanceof Annotations.FreeTextAnnotation && customTypes.indexOf((annot as any).custom.type) !== -1) {
        // create flag
        // @ts-expect-error not sure if this is correct
        const flags = new Annotations.WidgetFlags();

        if ((annot as any).custom.flags.readOnly) {
          flags.set('ReadOnly', true);
        }

        if ((annot as any).custom.flags.multiline) {
          flags.set('Multiline', true);
        }

        if ((annot as any).custom.flags.required) {
          flags.set('Required', (annot as any).custom.flags.required);
        }

        if ((annot as any).custom.flags.edit) {
          flags.set('Edit', (annot as any).custom.flags.edit);
        }

        if ((annot as any).custom.flags.dateFormat) {
          flags.set('DateFormat', (annot as any).custom.flags.dateFormat);
        }

        if ((annot as any).custom.flags.stateFormat) {
          flags.set('StateFormat', (annot as any).custom.flags.stateFormat);
        }

        if ((annot as any).custom.flags.addressFormat) {
          flags.set('AddressFormat', (annot as any).custom.flags.addressFormat);
        }


        const runId = instance.getRunId();

        // create a form field based on the type of annotation
        // ref: https://www.pdftron.com/webviewer/demo/pdf-form-build
        // ref: https://www.pdftron.com/documentation/web/guides/forms/create-checkbox-field/
        if ((annot as any).custom.type === 'TEXT' || (annot as any).custom.type === 'FORM') {
          const identifier = `form.${runId}.${(annot as any).custom.id}.${(annot as any).custom.author}.${(annot as any).custom.signerId}.${(annot as any).custom.name}`;

          // @ts-expect-error not sure if this is correct
          field = new Annotations.Forms.Field(identifier, { type: 'Tx', value: (annot as any).custom.value, flags });
          // @ts-expect-error not sure if this is correct
          inputAnnot = new Annotations.TextWidgetAnnotation(field);
        } else if ((annot as any).custom.type === 'SIGNATURE' || (annot as any).custom.type === 'INITIALS') {
          const type = ((annot as any).custom.type === 'INITIALS') ? 'initials' : 'signature';
          const identifier = `${type}.${runId}.${(annot as any).custom.id}.${(annot as any).custom.author}.${(annot as any).custom.signerId}.${(annot as any).custom.name}`;

          // @ts-expect-error not sure if this is correct
          field = new Annotations.Forms.Field(identifier, { type: 'Sig', value: (annot as any).custom.value, flags });


          inputAnnot = new (Annotations as any).BetterSigWidgetAnnotation(field, {
            appearance: '_DEFAULT',
            appearances: {
              _DEFAULT: {
                // eslint-disable-next-line max-len
                Normal: { data: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC', offset: { x: 100, y: 100 } },
              },
            },
          });
        } else if ((annot as any).custom.type === 'CHECKBOX' || (annot as any).custom.type === 'CHECK') {
          const identifier = `checkbox.${runId}.${(annot as any).custom.id}.${(annot as any).custom.author}.${(annot as any).custom.signerId}.${(annot as any).custom.name}`;

          const font = new Annotations.Font({ name: 'Helvetica' });

          field = new Annotations.Forms.Field(identifier, {
            type: 'Btn',
            // @ts-expect-error not sure if this is correct
            value: 'Off',
            flags,
            font,
          });

          inputAnnot = new Annotations.CheckButtonWidgetAnnotation(field, {
            appearance: 'Off',
            appearances: { Off: {}, Yes: {} },
          });
        } else {
          // exit early for other annotations
          return {
            ...acc,
          };
        }


        inputAnnot.PageNumber = annot.getPageNumber();
        inputAnnot.X = annot.getX();
        inputAnnot.Y = annot.getY();
        inputAnnot.rotation = annot.Rotation;

        if (annot.Rotation === 0 || annot.Rotation === 180) {
          inputAnnot.Width = annot.getWidth();
          inputAnnot.Height = annot.getHeight();
        } else {
          inputAnnot.Width = annot.getHeight();
          inputAnnot.Height = annot.getWidth();
        }

        inputAnnot.custom = inputAnnot.CustomData = { ...(annot as any).custom, ...annot.CustomData, id: (annot as any).custom.id };
        inputAnnot.Author = (annot as any).custom.name;
        (Annotations.WidgetAnnotation as any).getContainerCustomStyles(inputAnnot);
        (Annotations.WidgetAnnotation as any).getCustomStyles(inputAnnot);

        annotManager.addAnnotation(inputAnnot, false);
        fieldManager.addField(field);

        return {
          toDraw: [...acc.toDraw, inputAnnot],
          toDelete: [...acc.toDelete, annot],
        };
      }
    }

    return acc;
  }, {
    toDelete: [],
    toDraw: [],
  });

  // await annotManager.addAnnotations(toAdd, false);
  await bPromise.all([
    annotManager.deleteAnnotations(annotsToDelete, true, false, false),
    annotManager.drawAnnotationsFromList(toDraw),
  ]);


  // set tool to AnnotationEdit
  await instance.setToolMode('AnnotationEdit');

  return instance.setActiveHeaderGroup('default');
};

export default applyFormFieldCreate;
