import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'document-viewer/src/lib/hooks/useTheme';

export const useStyles = makeStyles((theme: Theme) => ({
  nameDisplay: {
    fontSize:  theme.signatureAndInitialsInOneStep ? 16 : 'xxx-large',
    fontWeight: theme.signatureAndInitialsInOneStep ? 700 : undefined,
    textAlign: 'center',
  },
  passwordText: {
    textAlign: 'center',
  },
  hidden: {
    display: 'none'
  },
  createPwPromptContainer: {
    height: '100%',
    textAlign: 'center',
  }
}));
