import React from 'react'
import map from 'lodash/map';
import { Button } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'document-viewer/src/components/Dialog';

interface Doc {
  id: string;
  title: string;
  description: string;
}

interface ComponentProps {
  onClose: (x: any) => Promise<any> | any;
  docs: Array<Doc>;
  open: boolean;
}

const NotesModal = ({ open, onClose, docs: docsObj }: ComponentProps) => {

  const docs = map(docsObj);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Session Information</DialogTitle>
      <DialogContent>
        {map(docs, (doc) => (
          <React.Fragment key={doc?.id}>
            <h3 className='text-muted'>{doc?.title}</h3>
            <p className='signer-information mt-1'>{doc?.description || 'Instructions for this document were not provided.'}</p>
          </React.Fragment>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={onClose}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotesModal;
