import { InstanceObject } from '../../viewer';
import AuditActions from '../constants/auditActions';

const simplifySubject = (annotPayload: any) => {
  return (annotPayload?.subject || '').toLowerCase().trim().replace(/ /g, '');
};

export const createSendEvent = (instance: any, nsId: string, isNotary: () => boolean, docs: any[]) => {
  return (action: any, type: string) => {
    const nsUserId = instance.annotManager.getCurrentUser();
    const docId = instance.getDocId();

    if (!nsId || nsUserId === '-1' || nsUserId === 'Guest') {
      return;
    }

    instance.annotManager.trigger('auditEvent', [{
      type,
      data: {
        nsId,
        nsUserId,
        userType: isNotary() ? 'notary' : 'signer',
        action,
        docId,
        docTitle: docs[docId].title,
      },
    }]);
  };
};

const createAuditTrailAddedAnnot = ({ sendEvent, isNotary }: any) => (annotPayload: any) => {
  const subject = simplifySubject(annotPayload);

  if (!subject) {
    return;
  }

  if (subject === 'signature') {
    if (annotPayload.annotType === 'initials') {
      sendEvent('add', isNotary() ? AuditActions.NOTARY_APPLIED_INITIALS : AuditActions.USER_APPLIED_INITIALS);
    } else {
      sendEvent('add', isNotary() ? AuditActions.NOTARY_APPLIED_SIGNATURE : AuditActions.USER_APPLIED_SIGNATURE);
    }
  } else if (subject === 'notarysealannotation') {
    sendEvent('add', AuditActions.NOTARY_APPLIED_COMMISSION_STAMP);
  } else if (subject === 'notarycertannotation') {
    sendEvent('add', AuditActions.NOTARY_APPLIED_CERTIFICATE);
  } else if (subject === 'freetext') {
    sendEvent('add', isNotary() ? AuditActions.NOTARY_APPLIED_TEXT : AuditActions.USER_APPLIED_TEXT);
  } else if (subject === 'checkboxannotation') {
    sendEvent('add', isNotary() ? AuditActions.NOTARY_APPLIED_CHECKBOX : AuditActions.USER_APPLIED_CHECKBOX);
  } else if (subject === 'radiobuttonannotation') {
    sendEvent('add', isNotary() ? AuditActions.NOTARY_APPLIED_RADIOBUTTON : AuditActions.USER_APPLIED_RADIOBUTTON);
  }
};

const createAuditTrailModifiedAnnot = ({ sendEvent, isNotary }: any) => (annotPayload: any) => {
  const subject = simplifySubject(annotPayload);

  if (!subject) {
    return;
  }

  if (subject === 'signature') {
    if (annotPayload.annotType === 'initials') {
      sendEvent('modify', isNotary() ? AuditActions.NOTARY_MODIFIED_INITIALS : AuditActions.USER_MODIFIED_INITIALS);
    } else {
      sendEvent('modify', isNotary() ? AuditActions.NOTARY_MODIFIED_SIGNATURE : AuditActions.USER_MODIFIED_SIGNATURE);
    }
  } else if (subject === 'notarysealannotation') {
    sendEvent('modify', AuditActions.NOTARY_MODIFIED_COMMISSION_STAMP);
  } else if (subject === 'notarycertannotation') {
    sendEvent('modify', AuditActions.NOTARY_MODIFIED_CERTIFICATE);
  } else if (subject === 'freetext') {
    sendEvent('modify', isNotary() ? AuditActions.NOTARY_MODIFIED_TEXT : AuditActions.USER_MODIFIED_TEXT);
  }
};

const createAuditTrailDeletedAnnot = ({ sendEvent, isNotary }: any) => (annotPayload: any) => {
  const subject = simplifySubject(annotPayload);

  if (!subject) {
    return;
  }

  if (subject === 'signature') {
    if (annotPayload.annotType === 'initials') {
      sendEvent('deleted', isNotary() ? AuditActions.NOTARY_REMOVED_INITIALS : AuditActions.USER_REMOVED_INITIALS);
    } else {
      sendEvent('deleted', isNotary() ? AuditActions.NOTARY_REMOVED_SIGNATURE : AuditActions.USER_REMOVED_SIGNATURE);
    }
  } else if (subject === 'notarysealannotation') {
    sendEvent('deleted', AuditActions.NOTARY_REMOVED_COMMISSION_STAMP);
  } else if (subject === 'notarycertannotation') {
    sendEvent('deleted', AuditActions.NOTARY_REMOVED_CERTIFICATE);
  } else if (subject === 'freetext') {
    sendEvent('deleted', isNotary() ? AuditActions.NOTARY_REMOVED_TEXT : AuditActions.USER_REMOVED_TEXT);
  } else if (subject === 'checkboxannotation') {
    sendEvent('deleted', isNotary() ? AuditActions.NOTARY_REMOVED_CHECKBOX : AuditActions.USER_REMOVED_CHECKBOX);
  } else if (subject === 'radiobuttonannotation') {
    sendEvent('deleted', isNotary() ? AuditActions.NOTARY_REMOVED_RADIOBUTTON : AuditActions.USER_REMOVED_RADIOBUTTON);
  }

};

const handleAuditTrail = () => ({ instance, ...rest }: InstanceObject) => {
  const { nsId, docs } = (rest as any).config;
  const isNotary = () => instance.annotManager.getIsAdminUser();
  const sendEvent = createSendEvent(instance, nsId, isNotary, docs);

  instance.annotManager.on('annotationAdded', createAuditTrailAddedAnnot({ sendEvent, isNotary }));
  instance.annotManager.on('annotationUpdated', createAuditTrailModifiedAnnot({ sendEvent, isNotary }));
  instance.annotManager.on('annotationDeleted', createAuditTrailDeletedAnnot({ sendEvent, isNotary }));
  instance.annotManager.on('vaDisclaimerChanged', (show: boolean) => {
    const selectedSigner = instance.getSelectedSigner();

    // if no signer is selected don't fire
    if (selectedSigner !== '-1') {
      if (!show) { // always log closed if closing
        return sendEvent(
          'hide',
          AuditActions.NOTARY_CLOSED_VA_ACCEPTANCE
        );
      }

      const signer = instance.getSignerById(selectedSigner);

      if (signer.connected) { // only log open if signer is connected
        return sendEvent(
          'show',
          AuditActions.NOTARY_PROMPTED_VA_ACCEPTANCE
        );
      }
    }
  });
  instance.docViewer.on('blankPagesAdded', () => sendEvent('add', AuditActions.NOTARY_ADDED_BLANK_PAGE));
  instance.docViewer.on('blankPagesRemoved', () => sendEvent('delete', AuditActions.NOTARY_REMOVED_BLANK_PAGE));
  instance.docViewer.on('removeAllAnnots', () => sendEvent('delete', AuditActions.NOTARY_RESET_DOCUMENT));
};

export default handleAuditTrail;

