import { useDocs } from "./useDocs";
import { useSubscription } from "@apollo/client";
import { REVISIONS_ADDED } from "document-viewer/src/lib/gql/document";
import { useState } from "react";
import { useDebounce } from "react-use";

export interface IUseRevision {
  docId: string;
  isEsign: boolean;
}

/**
 * This hook is responsible for listening to events sent over SSE and
 * dispatching updateDoc
 * @param param.sessionId Subscribe to events sent over server-sent events.
 */
function useRevisionSubscription({ docId, isEsign }: IUseRevision): void {

  const [subscriptionData, setSubscriptionData] = useState(null);
  const { updateDoc } = useDocs();

  useDebounce(async () => {
    if (!subscriptionData || isEsign) return;
    const doc = subscriptionData?.documentRevisionsAdded;
    if (doc) updateDoc(doc);
  }, 500, [subscriptionData, updateDoc]);

  useSubscription(REVISIONS_ADDED, {
    variables: { docId },
    onSubscriptionData: ({ subscriptionData: { data } }) => setSubscriptionData(data),
    skip: !docId,
    shouldResubscribe: () => true
  });
}

export default useRevisionSubscription;
