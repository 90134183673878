import { Tools } from '@pdftron/webviewer';
import * as R from 'ramda';
import { InstanceObject } from '../../viewer';

export interface defineToolClassProps {
  className: string;
  annotClassName?: string;
  baseClassName: keyof typeof Tools;
  onAnnotationAdded?: any;
  switchIn?: any;
  defaults?: any;
  onMouseLeftDown?: any;
  onMouseLeftUp?: any;
  onMouseMove?: any;
}

const defineToolClass = ({
  className,
  annotClassName,
  baseClassName,
  onAnnotationAdded = R.always(R.identity),
  switchIn = R.always(R.identity),
  defaults,
  onMouseLeftDown,
  onMouseLeftUp,
  onMouseMove,
}: defineToolClassProps) => async ({ instance, toolClasses, annotClasses, ...rest }: InstanceObject) => {
  const BaseClass: any = instance.Tools[baseClassName];

  const C = class extends BaseClass {
    defaults: any;
    constructor(docViewer: any) {
      super(docViewer, annotClassName && (annotClasses[annotClassName] || (instance.Annotations as any)[annotClassName]));
      this.defaults = {
        StrokeColor: new instance.Annotations.Color(0, 0, 0, 0),
        FillColor: new instance.Annotations.Color(0, 0, 0, 0),
        StrokeThickness: 1,
        Opacity: 1
      }
      this.defaults = defaults || this.defaults;

      this.defaults.FillColor = new instance.Annotations.Color(255, 141, 0, 0.5);
      this.on('annotationAdded', onAnnotationAdded({ instance, toolClasses, annotClasses, ...rest }));
    }

    switchIn(...args: any[]) {
      super.switchIn(...args);

      return switchIn({ context: { tool: this, args }, instance, toolClasses, annotClasses, ...rest });
    }

    mouseLeftDown(...args: any[]) {
      if (onMouseLeftDown) {
        return onMouseLeftDown({
          context: { tool: this, args, super: () => super.mouseLeftDown(...args) },
          instance,
          toolClasses,
          annotClasses,
          ...rest,
        });
      }

      super.mouseLeftDown(...args);
    }

    mouseLeftUp(...args: any[]) {
      if (onMouseLeftUp) {
        return onMouseLeftUp({
          context: { tool: this, args, super: () => super.mouseLeftUp(...args) },
          instance,
          toolClasses,
          annotClasses,
          ...rest,
        });
      }

      super.mouseLeftUp(...args);
    }

    mouseMove(...args: any[]) {
      if (onMouseMove) {
        return onMouseMove({
          context: { tool: this, args, super: () => super.mouseMove(...args) },
          instance,
          toolClasses,
          annotClasses,
          ...rest,
        });
      }

      super.mouseMove(...args);
    }
  };


  if (!onMouseLeftDown) {
    C.prototype.mouseLeftDown = BaseClass.prototype.mouseLeftDown;
  }

  if (!onMouseLeftUp) {
    C.prototype.mouseLeftUp = BaseClass.prototype.mouseLeftUp;
  }

  return {
    ...rest,
    toolClasses: {
      ...toolClasses,
      [className]: C,
    },
    annotClasses,
    instance,
  };
};

export default defineToolClass;
