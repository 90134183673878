import { gql } from '@apollo/client';


export const GET_TRANSACTION_PARTICIPANTS = gql`
  query getParticipantsByTransactionId($transactionId: ID!){
    getParticipantsByTransactionId(transactionId: $transactionId){
      id
      firstName
      lastName
      middleName
    }
  }
`;

export const UPDATE_PARTICIPANT = gql`
  mutation($participant: ParticipantUpdateInput!, $transactionId: String!, $agreeToLegalStuff: Boolean = false) {
    updateParticipant(participant: $participant, transactionId: $transactionId, agreeToLegalStuff: $agreeToLegalStuff) {
      id
      signature
      initials
      textColor
    }
  }
`;

export const AGREE_TO_LEGAL_STUFF = gql`
  mutation($participantId: String!, $transactionId: String!) {
    agreeToLegalStuff (transactionId: $transactionId, participantId: $participantId)
  }
`;

export const PARTICIPANT_SUBSCRIPTION = gql`
  subscription participantSubscription($pId: String!) {
    participantUpdated(pId: $pId){
      id,
      updatedAt
    }
  }
`

export const GET_PARTICIPANT = gql`
  query getParticipant($pId: String!) {
    getParticipant(id: $pId) {
      id
      kbaAttempts
      status
      signature
      authenticationFailures
      initials
      textColor
    }
  }
`
