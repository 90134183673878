import { FC } from 'react';
import previewConfig from '@enotarylog/pdftron-webviewer/notarize/webviewer/lib/configs/preview';
import config from '@enotarylog/pdftron-webviewer/notarize/webviewer/lib/configs/wv';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Viewer, { Instance } from '@enotarylog/pdftron-webviewer/notarize/webviewer/viewer';
import { log } from 'console';

interface DocumentPreviewProps {
  url: string,
}

export const DocumentPreview: FC<DocumentPreviewProps> = ({
  url,
}) => {
  const flags = useFlags();

  return (
    <Viewer
      data-testid='document-preview-viewer'
      config={{
        ...config({
          viewOnly: true,
          isAdminUser: true,
          disableElements: [
            'selectToolButton'
          ],
        }),
        enableAnnotations: false,
      }}
      isReadOnly={true}
      isAdminUser={true}
      toolConfig={previewConfig}
      docs={{ 'url': { url } }}
      selectedDoc='url'
      height='100%'
      pdftronServer={flags?.enloDashboardPdftronServer ? process.env.NX_PDFTRON_SERVER_URL : ''}
      onInit={(instance: Instance) => {
        instance.hotkeys.off(instance.hotkeys.Keys['5']);
      }}
      onDocumentLoaded={(instance: Instance) => {
        instance.hideMessage('Loading...');
        instance.setFitMode(instance.FitMode.FitWidth);
        
        // the following removes some mobile view styling if the viewer is less than 640px wide.
        const iframe = instance.iframeContainer.children[0] as HTMLIFrameElement;
        const headerItems = iframe?.contentDocument?.querySelector("[data-element='header']>.HeaderItems") as HTMLElement;
        if (headerItems) {
          headerItems.style.overflow = 'hidden';
        }
        iframe?.contentDocument?.querySelector("[data-element='zoomOutButton']")?.classList.remove('hide-in-mobile');
        iframe?.contentDocument?.querySelector('[data-element="zoomInButton"]')?.classList.remove('hide-in-mobile');
        iframe?.contentDocument?.querySelector('[data-element="zoomOverlayButton"]')?.classList.remove('hide-in-mobile');
        
        instance.hotkeys.off(instance.hotkeys.Keys['5']);
      }}
    />
  )
}
