// A promise, but with reject and resolve extracted out
export interface DeferredPromise<T> {
  promise: Promise<T>;
  reject(err?: any): void;
  resolve(value: T): void;
  pending: boolean; // Becomes false once a promise resolves or rejects
}
export function deferredPromise<T>(): DeferredPromise<T> {
  let resolve: (value: T) => void;
  let reject: (reason?: any) => void;
  let pending = true;
  const promise = new Promise<T>((res, rej) => {
    resolve = (value) => {
      pending = false;
      res(value);
    };
    reject = (error) => {
      pending = false;
      rej(error);
    };
  });
  return {
    resolve,
    reject,
    promise,
    // Needed because otherwise when pending changes, this won't update properly
    get pending() {
      return pending;
    }
  }
}

// Sometimes you want to be able to make a promise, but then you resolve it and you want to un-resolve it.
// One way of handling that is to simply build a new promise. But what if the old promise never resolved or rejected yet?
// The following solves that problem.
// Example use case:
//   const dtp1 = deferredTrackedPromise<string>();
//   dtp1.promise.then(fn1);
//   const dtp2 = deferredTrackedPromise(dtp1);
//   dtp2.promise.then(fn2);
//   dtp2.resolve('hi');
//   // Now both fn1 and fn2 get called!
//   const dtp3 = deferredTrackedPromise(dtp2);
//   dtp3.resolve('bye');
//   // No functions get called - the previous ones have already been called
interface PromiseTracking<T> {
  thens: ((val: T) => any)[];
  catches: ((err?: any) => any)[];
  finallies: (() => any)[];
}
export type DeferredTrackedPromise<T> = DeferredPromise<T> & PromiseTracking<T>;
export function deferredTrackedPromise<T>(prevDtp?: DeferredTrackedPromise<T>): DeferredTrackedPromise<T> {
  if (prevDtp) {
    // There's another dtp that may have had .then and .catch calls on it that need to be replayed, so replay it
    const newDtp = deferredTrackedPromise<T>();
    if (prevDtp.pending) {
      prevDtp.thens.forEach((fn) => newDtp.promise.then(fn));
      prevDtp.catches.forEach((fn) => newDtp.promise.catch(fn));
      // You may be wondering, why not add another line here but for finally? The reason is that .finally calls get turned into then+catch calls
    }
    return newDtp;
  }

  // No prevDtp, so create a dtp. It's just a deferredPromise that tracks calls to .then, .catch, and .finally
  const defP = deferredPromise<T>();
  const realThen = defP.promise.then;
  const realCatch = defP.promise.catch;
  const realFinally = defP.promise.finally;
  const thens = [];
  const catches = [];
  const finallies = [];
  defP.promise.then = (fn1, fn2) => {
    thens.push(fn1);
    if (fn2) {
      catches.push(fn2);
    }
    return realThen.call(defP.promise, fn1, fn2);
  };
  defP.promise.catch = (fn) => {
    catches.push(fn);
    return realCatch.call(defP.promise, fn);
  };
  defP.promise.finally = (fn) => {
    finallies.push(fn);
    return realFinally.call(defP.promise, fn);
  };
  return {
    thens,
    catches,
    finallies,
    promise: defP.promise,
    reject: defP.reject,
    resolve: defP.resolve,
    // Needed because otherwise, a value for pending will be spread in and then will never get updated
    get pending() {
      return defP.pending;
    }
  };
}
