import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Typography, Button, TextField } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { DocumentPreview } from '@enotarylog/pdftron-webviewer/viewer/components/DocumentPreview';
import { DialogTitle, DialogContent, DialogContentText, DialogActions } from 'document-viewer/src/components/Dialog';
import { useParticipants } from 'document-viewer/src/lib/hooks/useParticipants';
import { isMobileDevice } from 'document-viewer/src/utils/agents';
import { useStyles } from './styles';
import { AuthStates, PersonalPasswordProps } from '../types';
import TermsAndConditions from './Terms';

export default function PersonalPassword({
  personalPasswordQuestion,
  sendAnswer,
  currentProcess,
  setCurrentProcess,
  checking,
  signatureAndInitialsInOneStep,
  participant,
}: PersonalPasswordProps) {

  const classes = useStyles();

  const [answer, setAnswer] = useState('');
  const [passwordAttempts, setPasswordAttempts] = useState(participant.authenticationFailures);
  const [checkedTermsConditionPrivacyPolicy, setTermsConditionPrivacyPolicyChecked] = useState(false);
  const [checkedeSignConsent, setSignConsentChecked] = useState(false);

  const { agreeToLegalStuff } = useParticipants();

  const allVisibleStyle = { marginTop: '8px' };
  const isMobile = isMobileDevice();

  const submitCheck = useCallback((answer) => {
    sendAnswer(answer);
    setPasswordAttempts(passwordAttempts + 1);
  }, [sendAnswer, passwordAttempts, setPasswordAttempts]);

  // Reset the currentProcess when answer is cleared
  useEffect(() => {
    if (answer === '') {
      setCurrentProcess(AuthStates.Initial);
    }
  }, [answer, setCurrentProcess]);

  const tryAgain = currentProcess === 'TRY_AGAIN';
  let submitDisabled: boolean;

  if (signatureAndInitialsInOneStep) {
    submitDisabled = checking || !answer;
  } else {
    submitDisabled = checking || !answer || !checkedTermsConditionPrivacyPolicy || !checkedeSignConsent;
  }

  const maxAttempts = +process.env.NX_MAX_AUTH_ATTEMPTS;
  const attemptsRemaining = maxAttempts - passwordAttempts;

  const failureMsg = useMemo(() => {
    if ((attemptsRemaining) === 2) {
      return <b>two more attempts left</b>;
    } else {
      return <b>one more attempt left</b>;
    }
  }, [attemptsRemaining]);

  if (attemptsRemaining < 1) {
    return null;
  }

  return (
    <>
      {!tryAgain && (
        <>
          <DialogContent className={classes.verticallyCentered}>
            <Box
              display='block'
              justifyContent={signatureAndInitialsInOneStep ? 'center' : 'space-between'}
              alignItems='center'
              flexDirection='column'
              className={clsx(
                classes.verticallySpaced,
                classes.centeredListStyle
              )}
            >
              <>
                <DialogTitle
                  style={allVisibleStyle}
                  className={classes.noPadding}
                >
                  Let&apos;s confirm your identity
                </DialogTitle>

                <Typography
                  style={allVisibleStyle}
                  className={classes.centeredListStyle}
                >
                  {participant.firstName && (
                    <>
                      <strong>{participant.firstName}</strong>
                      , we need to make sure it&apos;s you before signing.
                      {signatureAndInitialsInOneStep ? <br /> : ' '}
                      Please answer the challenge question below:
                    </>
                  )}

                  {!participant.firstName && (
                    <>
                      <Skeleton />
                      <Skeleton />
                    </>
                  )}
                </Typography>

                <p
                  style={allVisibleStyle}
                  className={classes.centeredListStyle}
                >
                  {personalPasswordQuestion}
                </p>

                <TextField
                  autoFocus
                  placeholder='Answer'
                  onKeyUp={(e) =>
                    e.key === 'Enter' &&
                    !submitDisabled &&
                    submitCheck(answer)}
                  value={answer}
                  onChange={({ target }) => setAnswer(target.value)}
                />

                {!signatureAndInitialsInOneStep ? (
                  <TermsAndConditions
                    termsAndPrivacyPolicy={checkedTermsConditionPrivacyPolicy}
                    setTermsAndPrivacyPolicy={setTermsConditionPrivacyPolicyChecked}
                    signConsent={checkedeSignConsent}
                    setSignConsent={setSignConsentChecked}
                  />
                ) : null}
              </>
            </Box>

            {!signatureAndInitialsInOneStep ? <br /> : null}

            {!signatureAndInitialsInOneStep && !isMobile ? (
              <div
                style={{
                  width: '100%',
                  height: '350px',
                }}
              >
                <DocumentPreview url='https://app.enotarylog.com/static/pdf/esign_policy.pdf' />
              </div>
            ) : null}
          </DialogContent>

          <DialogActions
            className={clsx(classes.dialogActions, classes.noPaddingTop)}
          >
            <Button
              color='primary'
              disabled={submitDisabled}
              onClick={() => {
                if (!signatureAndInitialsInOneStep) {
                  agreeToLegalStuff(participant.id, () =>
                    submitCheck(answer)
                  );
                } else {
                  submitCheck(answer);
                }
              }}
              variant='contained'
              className={clsx(classes.button, classes.marginTopSm)}
            >
              Submit
            </Button>
          </DialogActions>
        </>
      )}

      {tryAgain && (
        <div className={classes.titleAndContentContainer}>
          <div className={classes.titleAndContentInnerContainer}>
            <DialogTitle className={classes.dialogTitle}>
              <Box>
                <Typography
                  variant='h5'
                  className={classes.dialogHeader}
                >
                  {' '}
                  Warning! Attempt Failed
                </Typography>
              </Box>
            </DialogTitle>

            <DialogContent className={classes.dialogContentClass}>
              <DialogContentText className={classes.dialogContentText}>
                <br />
                The response you have entered is incorrect.
                <br />
                You have&nbsp;
                {failureMsg}
                &nbsp;before the session will be locked.
              </DialogContentText>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setAnswer('');
                  setTermsConditionPrivacyPolicyChecked(false);
                  setSignConsentChecked(false);
                }}
                className={clsx(classes.button)}
              >
                Retry
              </Button>
            </DialogActions>
          </div>
        </div>
      )}
    </>
  );
}
