import React, { FunctionComponent } from 'react';
import { ComponentProps } from './types';
import { useAutoScrollSpeed } from './useAutoScrollSpeed';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useStyles } from './styles';

const Component: FunctionComponent<ComponentProps> = ({ onlyShowWhenAutoScrolling }) => {

  const { isAutoScrolling, scrollPxPerSec, setScrollPxPerSec } = useAutoScrollSpeed();
  const isLargeScreen = useMediaQuery('(min-width: 900px)');
  const classes = useStyles();

  if (onlyShowWhenAutoScrolling && !isAutoScrolling) {
    return null;
  }

  return (
    <>
      <Typography
        id='scroll-speed-slider'
        gutterBottom
      >
        Scroll Speed
      </Typography>
      <Grid
        container
        spacing={2}
        alignItems='center'
      >
        {
          isLargeScreen && (
            <Grid item>
              <span onClick={() => setScrollPxPerSec(Math.max(0, scrollPxPerSec - 1))}>
                Steady
              </span>
            </Grid>
          )
        }
        <Grid
          item
          xs
        >
          <Slider
            value={scrollPxPerSec}
            min={1}
            max={300}
            onChange={(_, speed: number) => setScrollPxPerSec(speed)}
            aria-labelledby='scroll-speed-slider'
            className={classes.slider}
          />
        </Grid>
        {
          isLargeScreen && (
            <Grid item>
              <span onClick={() => setScrollPxPerSec(Math.max(0, scrollPxPerSec + 1))}>
                Rapid
              </span>
            </Grid>
          )
        }
      </Grid>
    </>
  );
};

export default Component;
