import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'axios';
import bPromise from 'bluebird';
import { AppThunk, RootState } from 'document-viewer/src/store';
import { Document } from 'document-viewer/src/utils/types/document';
import { Revision } from 'document-viewer/src/utils/types/revision';
import { DocStatus } from 'document-viewer/src/utils/pdftron/docCompletion';
import { setShowCompletedNotification } from './transaction';


export interface Thumbnail {
  page: HTMLCanvasElement | any
  annot: HTMLCanvasElement | any
}

export interface DocThumbnails {
  [pageIndex: number]: Thumbnail
}

export interface Thumbnails {
  [docId: string]: DocThumbnails
}

export interface DocumentsState {
  documents: {
    byId: Record<string, Document>,
    allIds: string[]
  };
  selected: string | null;
  locked: boolean;
  loading: boolean;
  thumbnails: {
    byId: Thumbnails
    allIds: string[]
  }
}


const initialState: DocumentsState = {
  documents: {
    byId: {},
    allIds: []
  },
  selected: null,
  locked: false,
  loading: false,
  thumbnails: {
    byId: {},
    allIds: []
  }
};

const slice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setSelectedDocId(state: DocumentsState, action: PayloadAction<{ docId: string }>) {
      const dId = _.find(state.documents.allIds, (el) => _.isEqual(el, action.payload.docId));
      if (!dId) {
        return;
      }
      state.selected = action.payload.docId;
    },
    setDocuments(state: DocumentsState, action: PayloadAction<{ documents: Array<Document> }>) {
      const { payload } = action;
      state.documents.byId = _.reduce(payload.documents, (acc, doc) => (
        {
          ...acc,
          [doc.id]: {
            ...doc,
            xfdf: doc.latestRevision?.xfdf || _.last(doc.revisions)?.xfdf,
            title: doc.title,
            description: doc.description,
          }
        }
      ), {});

      const allDocIds = _.chain(payload.documents)
        .sortBy('order')
        .map('id')
        .value();

      state.documents.allIds = _.uniq(allDocIds);

      if (!state.selected) {
        state.selected = _.head(state.documents.allIds) ?? null;
      }

    },
    updateDoc(state: DocumentsState, action: PayloadAction<Document>) {
      const doc = action.payload;
      state.documents.byId[doc.id] = {
        ...state.documents.byId[doc.id],
        ...doc,
        xfdf: doc.latestRevision?.xfdf || _.last(doc.revisions)?.xfdf,
      }
    },
    addThumbnail(state: DocumentsState, action: PayloadAction<{
      docId: string,
      pageIndex: number,
      page?: HTMLCanvasElement,
      annot?: HTMLCanvasElement,
    }>) {
      const { payload } = action;
      const { docId, pageIndex, page, annot } = payload;

      const docThumbs = state.thumbnails.byId[docId] || {};
      docThumbs[pageIndex] = {
        ...docThumbs[pageIndex],
        page,
        annot
      };

      state.thumbnails.byId[docId] = docThumbs;
      state.thumbnails.allIds = _.uniq([...state.thumbnails.allIds, docId])
    },
    saveDoc(state: DocumentsState, action: PayloadAction<{ docId: string, xfdf: string, fileData: ArrayBuffer }>) {
      const { docId, xfdf, fileData } = action.payload;
      state.documents.byId[docId].url = fileData;
      state.documents.byId[docId].xfdf = xfdf;
    },
    setLocked(state: DocumentsState, action: PayloadAction<boolean>) {
      state.locked = action.payload;
    },
    setLoading(state: DocumentsState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    updateDocStatus(state: DocumentsState, { payload }: PayloadAction<{ docId: string, status: `${DocStatus}` }>) {
      const doc = state.documents.byId[payload.docId];
      if (doc) {
        doc.status = payload.status;
      }
    }
  }
});


export const addThumbnail = (docId: string, pageIndex: number, page?: HTMLCanvasElement, annot?: HTMLCanvasElement) => slice.actions.addThumbnail({ docId, pageIndex, page, annot });

export const setSelectedDocId = (docId: string): AppThunk => (dispatch, getState) => {
  const isLocked = getState().documents.locked;
  if (isLocked || getState().documents.selected === docId) {
    return;
  }
  dispatch(slice.actions.setSelectedDocId({ docId }));
}

export const setDocuments = (documents: Document[]): AppThunk => async (dispatch) => {
  const docs = await bPromise.map(documents, async (d, index) => {
    const lastRevision = _.last(d.revisions) as Revision;
    const url = lastRevision.url || `${window.location.origin}/api/viewDocument?gcsRefId=${encodeURIComponent(lastRevision.gcsRefId) as string}`;
    return {
      ...d,
      url: index !== 0
        ? url
        : await axios.get(url, {
          responseType: 'blob'
        }).then(({ data }) => data),
    };
  });

  return dispatch(slice.actions.setDocuments({ documents: docs }));
};


export const setLocked = (isLocked: boolean) => slice.actions.setLocked(isLocked);
export const setLoading = (isLoading: boolean) => slice.actions.setLoading(isLoading);

export const updateDoc = (doc: Document) => async (dispatch) => {
  let payload = doc;
  try {
    payload = {
      ...doc,
      // get the most recent version of the document pdf
      url: await axios.get(`${window.location.origin}/api/viewDocument?gcsRefId=${encodeURIComponent(doc.latestRevision?.gcsRefId || _.last(doc.revisions)?.gcsRefId)}`, {
        responseType: 'blob'
      }).then(({ data }) => data)
    }
  } catch (error) {
    // we wait to procede with the previous doc object url field if this errors
    // since it only prevents the user from seeing changes to the document
    // that occuring while the user was signing
    console.error(error)
  }
  dispatch(slice.actions.updateDoc(payload));
}

// const participantIds = getState().participants.participants.allIds;
// const revisionVer = getState().documents.documents.byId[documentId].revisions.length;

// const payload = {
//   participantIds,
//   isEsign: true,
//   completedBy: participantIds,
//   docId: documentId,
//   xfdf,
//   basedOnVersion: revisionVer
// };

// await client.mutate({
//   mutation: SIGN_DOC,
//   variables: { payload }
// });

// TODO: send it to the backend
export const saveDoc = (documentId: string, fileData: ArrayBuffer, xfdf: string): AppThunk => async (dispatch) => dispatch(slice.actions.saveDoc({
  docId: documentId,
  fileData,
  xfdf
}));

export const updateDocStatus = ({ docId, status }): AppThunk => async (dispatch, getState) => {
  if (status === DocStatus.completed) {
    const { documents: { documents } } = getState();
    const thereAreOtherIncompleteDocs = documents.allIds.some((id) => documents.byId[id].status !== DocStatus.completed && docId !== id);
    if (!thereAreOtherIncompleteDocs) {
      dispatch(setShowCompletedNotification({ show: true }));
    }
  }

  return dispatch(slice.actions.updateDocStatus({ docId, status }));
};

export const reducer = slice.reducer;
export const selector = (state: RootState) => state.documents;
export const byIdSelector = (state: RootState) => state.documents.documents.byId;
export const allIdsSelector = (state: RootState) => state.documents.documents.allIds;
export const currentDocumentSel = (state: RootState) => state.documents.selected;
export default slice;
