import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'document-viewer/src/lib/hooks/useTheme';

export const useStyles = makeStyles((theme: Theme) => ({
  unsetColor: {
    color: 'unset !important',
  },
  kbaContainer: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    // don't let this container's size cause the list-style modal to scroll
    height: theme.signatureAndInitialsInOneStep ? undefined : '60vh',
    // extra padding for list-style to prevent timer overlap
    paddingBottom: theme.signatureAndInitialsInOneStep ? 48 : undefined,
    display: 'flex',
    flexDirection: 'column',
    textTransform: theme.signatureAndInitialsInOneStep ? 'uppercase' : 'none',
  },
  flexGrow: {
    flex: 1,
  },
  countdownWrapper: {
    marginTop: '20px',
  },
  countdownSticky: {
    // This countdown needs to stay visible as the user scrolls, but position: 'sticky' takes up vertical space! Here's a hack to get around that
    position: 'sticky',
    float: 'right',
    height: 0,
    // Found via trial and error; feel free to adjust
    transform: `translateY(${
      theme.signatureAndInitialsInOneStep ? -108 : -120
    }px)`,
    // Drop-shadows from MUI would be hidden without these two lines:
    bottom: '2px',
    wordWrap: 'break-word',
    paddingRight: '2px',
    marginTop: 0,
    marginRight: theme.signatureAndInitialsInOneStep ? -24 : undefined,
  },
  countdownCard: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '30vw',
    },
    textAlign: theme.signatureAndInitialsInOneStep ? 'center' : 'inherit',
    width: 'max-content',
    margin: '0 0 0 auto',
    '& p': { marginBottom: 0 },
  },
  countdownTxt: { fontSize: '.7rem' },

  timeRemaining: {
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },

  warningText: theme.signatureAndInitialsInOneStep
    ? {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
    : undefined,
}));
