import { makeStyles } from '@material-ui/core/styles';
import { divideLength } from 'document-viewer/src/utils/styles/lengthMath';

export const STEP_SIZE = '10vmin';
export const CONNECTOR_LINE_HEIGHT = '3px';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    '& > *': {
      background: 'unset',
    }
  },
  alternativeLabel: {
    top: `calc(${divideLength(STEP_SIZE, 2)} - ${divideLength(CONNECTOR_LINE_HEIGHT, 2)})`,
  },
  line: {
    margin: `0px calc(${divideLength(STEP_SIZE, 1.25)} - 20px)`,
    height: CONNECTOR_LINE_HEIGHT,
    border: 0,
    background: 'black',
  },
  icon: {
    zIndex: 1,
    width: STEP_SIZE,
    height: STEP_SIZE,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    '& > *': {
      width: '50%',
      height: '50%',
    },
  },
  explanation: {
    '& > *': {
      marginBottom: theme.spacing(3),
      lineHeight: 1,
      textAlign: 'center',
    },
  },
}));
