import { makeStyles } from '@material-ui/core/styles';
import { defaultTheme } from 'document-viewer/src/constants';
import { Theme } from 'document-viewer/src/lib/hooks/useTheme';

export const useStyles = makeStyles((theme: Theme) => ({
  radioButtonLabel: {
    color: 'unset !important',
  },

  verticallySpaced: {
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
    '& p': {
      fontSize: '1rem !important',
      marginBottom: 0,
    },
  },
  verticallyCentered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  centeredListStyle: {
    textAlign: theme.signatureAndInitialsInOneStep ? 'center' : 'inherit',
    flex: theme.signatureAndInitialsInOneStep ? 'initial !important' : undefined,
  },
  radioButtonFormControlLabel: {
    alignItems: 'flex-start',
    '& > .MuiIconButton-root': {
      paddingTop: 0,
    },
  },
  flexGrow: {
    flex: 1,
  },
  checkboxContainer: {
    height: '100%',
    alignSelf: 'flex-start',
    marginTop: -8
  },
  formControlLabel: { display: 'flex', paddingTop: 16 },

  button: {
    minWidth: 150,
    marginBottom: 8,
  },

  dialogActions: {
    justifyContent: 'center',
    alignItems: 'center',
    background:'#fff',
    marginTop:0
  },
  titleAndContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
    maxWidth:'md'
  },
  containerBackground: {
    background: defaultTheme.secondaryBackground,
  },
  titleAndContentInnerContainer: {
    width: theme.signatureAndInitialsInOneStep ? 'unset' : '100%',
  },
  noPadding: { padding: '0 !important' },
  noPaddingTop: { paddingTop: '0 !important' },

  codeMsg: { marginTop: 0 },

  marginTopSm: { marginTop: 8 },

  checkboxesContainer: {
    padding: '0 16px',
    height: '100%',
  },

  centered: { textAlign: 'center' },

  pdfContent: {
    margin: '32px 0'
  },
  dialogContentClass: { backgroundColor : defaultTheme.headerBackground },
  dialogContentText : { color: defaultTheme.dialogSecondaryText, fontSize: '.875rem', lineHeight: '1.4' },
  dialogTitle: { backgroundColor: defaultTheme.dialogTitle , padding: '32px 24px', color: defaultTheme.dialogTextColor },
  dialogHeader: { fontSize: '1rem', fontWeight: 500 },
  allVisibleStyle: { marginTop: '8px' },
}));
