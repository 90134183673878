export const defaultSigFonts = ['Dancing Script', 'Pacifico', 'Cookie', 'Cedarville Cursive', 'Homemade Apple', 'Damion', 'Mr Bedfort'];

export const APP_VERSION = process.env.NX_BUILD_ID || '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const ENL_LOGO = '/static/logo.png';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  ORG_STYLE: 'ORG_STYLE',
  ENL: 'eNotaryLog',
  UNICORN: 'UNICORN'
};

export const PAD = 3;
export const ENL_RED = '#ed5f42';
export const ENL_TEAL = '#507f91';
export const ENL_GREEN = '#1DE9B6';
export const CORAL = '#ED5F42';
export const PEWTER = '#507f91'
export const BACKGROUND = '#F4F6F8'
export const BORDER_GRAY = '#e0e0e0'
export const ENL_CHECK_MARK = '#3AD29F';
export const TITLE_TEXT = '#343746'
export const TIME_ZONE_12HR = '12hr';
export const TIME_ZONE_24HR = '24hr';
export const BUTTON_TEXT = '#187082';
export const BUTTON_BACKGROUND = '#E4EFF1';
export const SITEMAP = {

  dashboard: { label: 'Dashboard', path: '/app/reports/dashboard', },

  allOrders: { label: 'Orders', path: '/app/orders', },

  eSignOrders: { label: 'eSign', path: '/app/esignature-dashboard', },

  createNewOrder: { label: 'Create New Order', path: '/app/orders/create', },

  editOrder: { label: 'Edit Order', path: '/app/orders/edit/:id', },

  ronOrders: { label: 'RON Orders', path: '/app/management/documents', },

  taggingQueue: { label: 'Tagging Queue', path: '/app/tagging-queue', },

  // TODO: fix path
  notaryQueue: { label: 'Notary Queue', path: '/app/reports/dashboard2', },

  enlOrganizations: { label: 'ENL Organizations', path: '/app/management/organizations', },

  users: { label: 'Users', path: '/app/management/users', },

  usersEdit: { label: 'Users', path: '/app/management/users/undefined/edit', },

  myOrganization: { label: 'My Organization', path: '/app/management/my-organization', },

  // TODO: fix path
  calendar: { label: 'Calendar', path: '/app/reports/dashboard5', },

  profile: { label: 'Profile', path: '/app/profile', },

};