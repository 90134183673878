import React from 'react'

import Snackbar from 'document-viewer/src/components/Snackbar';
import Alert from '@material-ui/lab/Alert';



const SessionCompletedSnackbar = ({ show, onClose }) => (
  <Snackbar
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    open={show}
    autoHideDuration={15000}
    onClose={onClose}
  >
    <Alert
      onClose={onClose}
      severity='success'
    >
      You have completed all documents for this eSign session.
    </Alert>
  </Snackbar>
)

export default SessionCompletedSnackbar;
