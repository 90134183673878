/* eslint-disable no-multi-assign */
import * as R from 'ramda';
import _ from 'lodash';
import bPromise from 'bluebird';
import debug from 'debug';
import { filterSignerProps } from './helpers';

const log = debug('server');

export interface ServerProps {
  nsId: any;
  userId: any;
  runId: any;
  user: any;
  signers: any;
  signerLocation: any;
  token: any;
  rtdbNamespace: any;
  userType: any;
  firebase: any;
}

export class Server {
  public nsId: any;
  public userId: any;
  public userType: string;
  public requiredSigners: any;
  public user: any;
  public isAdminUser: boolean;
  public signerLocation: any;
  public rtdbNamespace: any;
  public firebase: any;
  public token: any;
  public roomRef?: any;
  public selectedSignerRef?: any;
  public annotationsRef?: any;
  public modifiedXfdf?: any;
  public widgetRef?: any;
  public fieldRef?: any;
  public xfdfRef?: any;
  public connectionRef?: any;
  public statusRef?: any;
  public authorsRef?: any;
  public notaryRef?: any;
  public participantsRef?: any;
  public blankPagesRef?: any;
  public lockRef?: any;
  public vaDisclaimerRef?: any;
  public selectedDocIdRef?: any;
  public pageRef?: any;
  public selectedDocTitleRef?: any;
  public vaDisclaimerRejectedRef?: any;
  public completingRef?: any;
  public sessionReloadedRef?: any;
  public consumerSignatures?: any;
  public pinModalRef?: any;
  public authPinModalRef?: any;
  public loadedDocsRef?: any;
  public updatedAtRef?: any;
  public refLists ?: any;
  public initializedRefs?: any;
  public mainRefs?: any;
  public allRefs?: any;
  public pageInstRef?: any;
  public runId?: any;

  constructor({ nsId, userId, runId, user, signers, signerLocation, token, rtdbNamespace, userType, firebase }: ServerProps) {
    this.nsId = nsId;
    this.userId = userId;
    this.runId = runId;
    this.userType = user?.userType || 'signer';

    // the signers that are required
    this.requiredSigners = _.filter(signers, ({ userType, type }) => userType !== 'admin' && type !== 'remotesigner');

    this.user = user;
    this.userId = userId;
    this.isAdminUser = userType === 'admin';

    this.signerLocation = signerLocation;

    this.rtdbNamespace = _.isEmpty(rtdbNamespace) ? 'rooms' : `organization/${rtdbNamespace}/rooms`;


    // Initialize Firebase
    this.firebase = firebase;
    this.token = token;
  }

  init = async () => {
    const { nsId, userId } = this;

    if (this.token) {
      await this.signInWithToken(this.token);
    } else {
      await this.signInAnonymously();
    }


    const roomRef = (this.roomRef = this.firebase
      .database()
      .ref(this.rtdbNamespace)
      .child(nsId));


    // update the /organization/<orgId>/rooms/<nsId>/updatedAt value whenever updates happen
    this.roomRef.on('child_changed', (snapshot: any) => {
      // return if updatedAt is the key that was changed to not cause infinite loop
      if (snapshot.key === 'updatedAt') {
        return;
      }

      this.roomRef.update({
        updatedAt: Date.now(),
      });
    });

    this.selectedSignerRef = roomRef.child('selectedSigner');
    this.annotationsRef = roomRef.child('annotations');
    this.modifiedXfdf = roomRef.child('modifiedXfdf');
    this.widgetRef = roomRef.child('widgets');

    this.fieldRef = roomRef.child('fields');
    this.xfdfRef = roomRef.child('xfdf');
    this.connectionRef = this.firebase.database().ref('.info/connected');

    this.statusRef = roomRef.child('status');
    this.authorsRef = roomRef.child('authors');
    this.notaryRef = roomRef.child('notary');

    this.participantsRef = roomRef.child('participants');
    this.blankPagesRef = roomRef.child('blankPages');
    this.lockRef = roomRef.child('locked');
    this.vaDisclaimerRef = roomRef.child('vaDisclaimerShown');
    this.selectedDocIdRef = roomRef.child('selectedDocId');
    this.pageRef = roomRef.child('pageNumber');
    this.selectedDocTitleRef = roomRef.child('selectedDocTitle');
    this.vaDisclaimerRejectedRef = roomRef.child('vaDisclaimerRejected');
    this.completingRef = roomRef.child('isCompleting');
    this.sessionReloadedRef = roomRef.child('sessionReloadedAt');
    this.consumerSignatures = roomRef.child('consumerSignatures');
    this.pinModalRef = roomRef.child('pinModalOpen');
    this.authPinModalRef = roomRef.child('showAuthPinModal');
    this.loadedDocsRef = roomRef.child('loadedDocsRef');
    this.updatedAtRef = roomRef.child('updatedAt');

    await this.vaDisclaimerRef.set(false);

    this.refLists = {};
    this.initializedRefs = [];
    this.mainRefs = [];
    this.allRefs = [];

    return new bPromise((res) => {
      return this.connectionRef.on('value', async (snapshot: any) => {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() === false) {
          console.debug('%cfirebase not connected 🔥!', 'color: red; font-size:20px');

          return;
        }

        console.debug('%cfirebase connected 🔥!', 'color: blue; font-size:20px');


        // if im a signer and we're all on the same machine (ie consumer flow)
        if (!this.isAdminUser && this.signerLocation === 'local') {
          // add presence for each signer
          await this.addPresences(this.requiredSigners);

          return res();
        }

        if (!_.isNil(userId) && !_.isNil(this.user)) {
          await this.addPresence(this.user);

          return res();
        }

        return res();
      });
    });
  };


  markAsDisconnected = () => this.userId && this.authorsRef
    .child(this.userId)
    .child('connected')
    .set(false);


  setShowVaDisclaimer = (val: any) => this.vaDisclaimerRef.set(val);

  setVaDisclaimerRejected = (val: any) => this.vaDisclaimerRejectedRef.set(val);


  createBinding = (ref: any, event: any, callbackFunction: (...args: any[]) => any, unbindList = 'default') => {
    const initializedRef = ref.on(event, callbackFunction);


    const refList = (unbindList === 'main') ? this.mainRefs : this.initializedRefs;
    const rmRef = () => ref.off(event, initializedRef);

    refList.push(rmRef);
    this.allRefs.push(rmRef);


    // initializedRef._name = event;
    this.refLists[unbindList] = this.refLists[unbindList] || [];
    this.refLists[unbindList].push(rmRef);


    return initializedRef;
  }

  unbindAll = async (unbindList = 'default') => {
    if (unbindList === 'all') {
      log('unbinding all refs');

      await bPromise.map(_.toPairs(this.refLists), ([listName, refs = []]: [string, any]) => {
        log(`unbinding ${listName} refs`, refs.length);

        return bPromise.map(refs, (unbind: () => void) => unbind());
      });
    } else if (_.isEmpty(this.refLists[unbindList])) {
      console.error(`unbinding an empty list of refs: ${unbindList}`);
    } else {
      log(`new: unbinding ${unbindList} refs`, this.refLists[unbindList].length);
      await bPromise.map(this.refLists[unbindList], (unbind: () => void) => unbind());
      this.refLists[unbindList] = [];
    }


    if (unbindList === 'main') {
      log('unbinding main refs', this.mainRefs.length);
      await bPromise.map(this.mainRefs, (unbind: () => void) => unbind());
      this.mainRefs = [];
    } else if (unbindList === 'all') {
      log('unbinding all refs', this.allRefs.length);
      await bPromise.map(this.allRefs, (unbind: () => void) => unbind());
      this.allRefs = [];
      this.mainRefs = [];
      this.initializedRefs = [];
      this.roomRef.off('child_changed');
    } else {
      log('unbinding initialized refs', this.initializedRefs.length);
      await this.annotationsRef.off();
      await this.widgetRef.off();
      await this.pageRef.off();
      await bPromise.map(this.initializedRefs, (unbind: () => void) => unbind());
      this.initializedRefs = [];
    }
  }

  bind = (action: any, docId: any, cbFunc: any = docId, unbindList = 'default') => {
    const callbackFunction = R.pipe(R.applySpec({ val: R.invoker(0, 'val'), key: R.prop('key') }), cbFunc);

    log(`binding: ${action}, ${unbindList}`);

    switch (action) {
      case 'onAuthStateChanged':
        return this.firebase.auth().onAuthStateChanged(async (user: any) => {
          if (user) {
            return cbFunc(user);
          }

          // Author is not logged in
          try {
            await this.signInWithToken(this.token);
          } catch (error) {
            console.error(error);
            throw error;
          }
        });


      case 'onPageChanged':
        this.pageInstRef = this.pageInstRef ? this.pageInstRef : this.pageRef
          .orderByKey()
          .equalTo(docId);

        return this.pageInstRef
          .on('value', callbackFunction);

      case 'onFieldAdded':
        return this.createBinding(this.fieldRef, 'child_added', callbackFunction, unbindList);
      case 'onFieldUpdated':
        return this.createBinding(this.fieldRef, 'child_changed', callbackFunction, unbindList);
      case 'onWidgetCreated':
        return this.createBinding(this.widgetRef, 'child_added', callbackFunction, unbindList);
      case 'onWidgetUpdated':
        return this.createBinding(this.widgetRef, 'child_changed', callbackFunction, unbindList);
      case 'onWidgetDeleted':
        return this.createBinding(this.widgetRef, 'child_removed', callbackFunction, unbindList);
      case 'onAnnotationCreated':
        return this.createBinding(this.annotationsRef, 'child_added', callbackFunction, unbindList);
      case 'onAnnotationUpdated':
        return this.createBinding(this.annotationsRef, 'child_changed', callbackFunction, unbindList);
      case 'onAnnotationDeleted':
        return this.createBinding(this.annotationsRef, 'child_removed', callbackFunction, unbindList);
      case 'onBlankPagesChanged':
        return this.createBinding(this.blankPagesRef.child(docId), 'value', callbackFunction, unbindList);
      case 'onSelectedSignerChanged':
        return this.createBinding(this.selectedSignerRef, 'value', callbackFunction, unbindList);

      case 'onParticipantsChanged':
        return this.createBinding(this.participantsRef, 'value', callbackFunction);
      case 'onUpdatedAtChanged':
        return this.createBinding(this.updatedAtRef, 'value', callbackFunction, unbindList);
      case 'onLockChanged':
        return this.createBinding(this.lockRef, 'value', callbackFunction, unbindList);
      case 'onVaDisclaimerChanged':
        return this.createBinding(this.vaDisclaimerRef, 'value', callbackFunction, unbindList);
      case 'onAuthorsChanged':
        return this.createBinding(this.authorsRef, 'value', callbackFunction, unbindList);
      case 'onAuthorConnected':
        return this.createBinding(this.authorsRef, 'child_added', callbackFunction, unbindList);
      case 'onAuthorDisconnected':
        return this.createBinding(this.authorsRef, 'child_removed', callbackFunction, unbindList);
      case 'onAuthorChanged':
        return this.createBinding(this.authorsRef, 'child_changed', callbackFunction, unbindList);
      case 'onConsumerSignaturesChanged':
        return this.createBinding(this.consumerSignatures, 'value', callbackFunction, unbindList);
      case 'onSelectedDocIdChanged':
        return this.createBinding(this.selectedDocIdRef, 'value', callbackFunction, unbindList);
      case 'onVaDisclaimerRejected':
        return this.vaDisclaimerRejectedRef.on('value', callbackFunction);
      case 'onSessionReloadedAt':
        return this.createBinding(this.sessionReloadedRef, 'value', callbackFunction, unbindList);
      case 'onCompletingChanged':
        return this.createBinding(this.completingRef, 'value', callbackFunction, unbindList);
      case 'onPinModalChanged':
        return this.createBinding(this.pinModalRef, 'value', callbackFunction, unbindList);
      case 'onAuthPinModalChanged':
        return this.createBinding(this.authPinModalRef, 'value', callbackFunction, unbindList);
      case 'onStatus':
        return this.createBinding(this.statusRef, 'value', callbackFunction, unbindList);
      case 'onStatusChanged':
        return this.createBinding(this.statusRef, 'value', callbackFunction, unbindList);
      case 'onLoadedDocsChanged':
        return this.createBinding(this.loadedDocsRef, 'value', callbackFunction, unbindList);

      default:
        console.error('The action is not defined.', action);
        break;
    }
  };

  setShowAuthPinModal = (val: any) => this.pinModalRef.set(val)

  getShowAuthPinModal = () => this.pinModalRef.once('value').then(R.invoker(0, 'val'))

  getFields = () => this.fieldRef.once('value')
    .then(R.invoker(0, 'val'))
    .then((fields: any) => _.mapKeys(fields, (val, key) => key.replace(/__/ig, ' ').replace(/_/ig, '.')))

  setField = (name: string, val: any) => this.fieldRef.child(name.replace(/ /ig, '__').replace(/\./ig, '_')).set(val)

  getAuthors = () => this.authorsRef.once('value').then(R.invoker(0, 'val'));

  updateAuthors = (authors: any) => this.authorsRef.set(authors);

  setAuthor = (authorId: string, authorName: string) => this.authorsRef
    .child(authorId)
    .child('authorName')
    .set(authorName);

  checkAuthor = (authorId: string, openReturningAuthorPopup: any, openNewAuthorPopup: any) => {
    this.authorsRef.once('value', (authors: any) => {
      if (authors.hasChild(authorId)) {
        this.authorsRef.child(authorId).once('value', (data: any) => {
          const val = data.val();

          openReturningAuthorPopup(`${val.firstName} ${val.lastName}`);
        });
      } else {
        openNewAuthorPopup();
      }
    });
  };

  setPageNumber = (docId: string, num = 1) => this.pageRef.child(docId).set(num);

  getPageNumber = (docId: string) => this.pageRef.child(docId).once('value')
    .then(R.pipe(R.invoker(0, 'val'), R.defaultTo(1)));

  setBlankPages = (docId: string, num = 0) => {
    if (_.isNaN(num)) {
      return;
    }

    return this.blankPagesRef.child(docId).set(num);
  }


  resetBlankPages = async () => {
    const currBlankPages = (await this.blankPagesRef.once('value').then(R.invoker(0, 'val')) || {});
    const newBlankPages = _.mapValues(currBlankPages, R.always(0));

    return this.blankPagesRef.set(newBlankPages);
  };

  getBlankPagesByDocId = (docId: string) => this.blankPagesRef.child(docId).once('value').then(R.invoker(0, 'val'))

  getBlankPages = () => this.blankPagesRef.once('value').then(R.invoker(0, 'val')) || {}

  createAuthors = (signers: any) => {
    return _.chain(signers)
      .map(({ firstName, lastName, id }) => ({
        id,
        fullName: `${firstName} ${lastName}`,
      }))
      .map(({ id, fullName }) => this.authorsRef
        .child(id)
        .child('authorName')
        .set(fullName))
      .thru((proms) => bPromise.all(proms))
      .value();
  }

  authenticate = () => new bPromise((res) => {
    this.bind('onAuthStateChanged', (user: any) => ((user) ? res(user) : this.signInAnonymously()));
  })

  signInWithToken = async (token: string) => {
    await this.firebase
      .auth()
      .setPersistence(this.firebase.auth.Auth.Persistence.SESSION);

    return this.firebase.auth().signInWithCustomToken(token);
  };

  signInAnonymously = async () => {
    await this.firebase
      .auth()
      .setPersistence(this.firebase.auth.Auth.Persistence.SESSION);

    return this.firebase.auth().signInAnonymously();
  };

  removeAllXfdf = () => this.xfdfRef.set({});

  setSelectedSigner = (signerId: string) => this.selectedSignerRef.set(signerId || '-1');

  getSelectedSigner = () => this.selectedSignerRef.once('value').then(R.invoker(0, 'val'))

  clearAnnotations = () => this.annotationsRef.set({});

  clearWidgets = async () => {
    this.widgetRef.set({});
    // const allSigWigets = await this.widgetRef
    //   .orderByChild('subtype')
    //   .equalTo('SIGNATURE')
    //   .once('value')
    //   .then(R.pipe(R.invoker(0, 'val')));
    // const allInitialsWidgets = await this.widgetRef
    //   .orderByChild('subtype')
    //   .equalTo('INITIALS')
    //   .once('value')
    //   .then(R.pipe(R.invoker(0, 'val')));
    // const updateVal = _.mapValues({ ...allSigWigets, ...allInitialsWidgets }, R.always(null));

    // return this.widgetRef.update(updateVal);
  }

  clearAll = async () => bPromise.all([
    this.annotationsRef.set({}),
    this.widgetRef.set({}),
  ])


  resetSession = async (currSelectedDoc: any, skipDeleteSignatures: any) => {
    if (!skipDeleteSignatures) {
      await this.deleteAllSignatures();
    }

    await bPromise.all([
      this.setShowVaDisclaimer(false),
      this.resetBlankPages(),
      this.clearAll(),
      this.clearLoadedDocs(),
      this.clearModifiedXfdf(),
    ]);
    await this.setSelectedDocId('-1');

    return this.setSelectedDocId(currSelectedDoc);
  }


  createWidget = (widgetId: string, widgetData: any) => this.widgetRef.child(widgetId).set(widgetData);

  updateWidget = (widgetId: string, widgetData: any) => this.widgetRef.child(widgetId).update(widgetData);

  deleteWidget = (widgetId: string) => this.widgetRef.child(widgetId).remove();

  createAnnotation = (annotationId: string, annotationData: any) => this.annotationsRef.child(annotationId).set(annotationData);

  updateAnnotation = (annotationId: string, annotationData: any) => this.annotationsRef.child(annotationId).update(annotationData);

  deleteAnnotation = (annotationId: string) => this.annotationsRef.child(annotationId).remove();

  getAnnotation = (annotationId: string) => this.annotationsRef.child(annotationId).once('value');

  getAnnotations = (docId: string) => this.annotationsRef
    .orderByChild('docId')
    .equalTo(docId)
    .once('value')
    .then(R.invoker(0, 'val'))


  setLock = (val: any) => this.lockRef.set(val);

  getLock = () => this.lockRef.once('value').then((data: any) => data.val());


  updateAuthor = (authorId: string, authorData: any) => this.authorsRef.child(authorId).update(authorData);

  updateParticipant = (authorId: string, authorData: any) => this.authorsRef.child(authorId).update(authorData);

  getInitialAnnotations = async (docId: string) => {
    const annotsSnapshot = await this.annotationsRef.once('value');

    return _.chain(annotsSnapshot.val())
      .filter((annot) => {
        if (docId) {
          return annot.docId === docId;
        }

        return true;
      })
      .value();
  };

  saveXfdf = (docId: string, xfdf: string) => this.xfdfRef.child(docId).set(xfdf)

  getDocXfdf = (docId: string) => this.xfdfRef.child(docId).once('value').then(R.invoker(0, 'val'));

  getXfdf = () => this.xfdfRef.once('value').then(R.invoker(0, 'val'));

  getModifiedXfdf = () => this.modifiedXfdf.once('value').then(R.invoker(0, 'val'));

  showCompleting = (val: any) => this.completingRef.set(val);

  setSelectedDocId = (docId: string) => this.selectedDocIdRef.set(docId);

  getSelectedDocId = () => this.selectedDocIdRef.once('value').then(R.invoker(0, 'val'))

  createSignatures = (signerId: string, data: any) => this.consumerSignatures.child(signerId).set(data);

  getSignatures = (signerId: string) => this.consumerSignatures.child(signerId).once('value')
    .then(R.invoker(0, 'val'))

  deleteSignature = (signerId: string, type: string) => this.consumerSignatures.child(signerId).child(type).remove();

  deleteAllSignatures = () => this.consumerSignatures.set({});


  markReady = async (runId: string) => {
    const authorsSnapshot = await this.authorsRef.once('value');
    const authors = authorsSnapshot.val();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const markReady = R.apply(R.useWith(R.unapply(R.identity), [R.identity, R.assoc('status', 'ready')]));
    const setUsersReady = R.pipe(
      R.toPairs,
      // @ts-ignore
      R.filter(R.pipe(R.nth(1), R.propEq('runId', runId))),
      R.map(markReady),
      R.fromPairs
    );

    const onDevice = setUsersReady(authors);

    return this.authorsRef.update(onDevice);
  }

  setStatus = async (status: any) => this.statusRef.set(status);

  getStatus = async () => this.statusRef.once('value').then(R.invoker(0, 'val'));


  saveModifiedXfdf = (docId: string, xfdf: string) => this.modifiedXfdf.child(docId).set(xfdf);

  clearModifiedXfdf = () => this.modifiedXfdf.set({});

  getLoadedDocs = async () => this.loadedDocsRef.once('value')
    .then(R.invoker(0, 'val'))
    .then(R.defaultTo([]))

  addLoadedDocs = async (docId: string) => {
    const loadedDocs = (await this.getLoadedDocs() || []);

    return this.loadedDocsRef.set(_.filter(_.uniq([...loadedDocs, docId]), (el) => !_.isNil(el)));
  }

  clearLoadedDocs = async () => this.loadedDocsRef.set({})


  addPresences = async (signers: any[]) => bPromise.map(signers, (s) => this.addPresence(s))

  addPresence = async (user: any, optionalRef?: any) => {
    const ref = (optionalRef) || this.authorsRef.child(user.id);


    const snapshot = await ref.once('value');


    if (this.signerLocation === 'remote') {
      if (snapshot.exists()) {
        const value = snapshot.val();

        if (value.connected && value.runId !== this.runId) {
          // eslint-disable-next-line max-len
          const errMsg = 'You are already authenticated on a different device. Please continue your session on that device or close the other web browser window to continue on this device.';
          const err = new Error(errMsg);

          throw err;
        }
      }
    }

    // If we are currently connected, then use the 'onDisconnect()'
    // method to add a set which will only trigger once this
    // client has disconnected by closing the app,
    // losing internet, or any other means.
    await ref
      .onDisconnect()
      // .update({
      //   connected: false,
      //   runId: null,
      //   connectedAt: null,
      //   joined: null,
      //   ready: null,
      // });
      .remove();

    // The promise returned from .onDisconnect().set() will
    // resolve as soon as the server acknowledges the onDisconnect()
    // request, NOT once we've actually disconnected:
    // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

    // We can now safely set ourselves as 'online' knowing that the
    // server will mark us as offline once we lose connection.
    return ref.set(filterSignerProps({
      ...user,
      connected: true,
      runId: this.runId,
      connectedAt: +new Date(),
    }));
  }

  setParticipantStatus = (nsUserId: string, status: any) => this.participantsRef
    .child(nsUserId)
    .update({ status });

  setAuthorStatus = (nsUserId: string, status: any) => this.authorsRef
    .child(nsUserId)
    .update({ status });
}

export const createServer = async (serverOpts: ServerProps) => {
  const server = new Server(serverOpts);

  await server.init();

  return server;
};

export default createServer;
