import React, { useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Props } from './props';
import { groupBy } from '@enotarylog/ramda';
import { useStyles } from './styles';
import DocListSection from './DocListSection';
import Box from '@material-ui/core/Box';
import Doc from './Doc';
import { useTheme } from 'document-viewer/src/lib/hooks/useTheme';
import { useAutoScrollSpeed } from 'document-viewer/src/components/AutoScrollSpeed/useAutoScrollSpeed';
import { useParticipants } from 'document-viewer/src/lib/hooks/useParticipants';
import { docIsCompletedOrParticipantCompletedDoc } from 'document-viewer/src/utils/docIsCompletedOrParticipantCompletedDoc';

export type DocumentListProps = Props;

export default function DocumentList({ docs, currentDocId, currentPageIndex, numPages, onDocSelected, onPageSelected, completedNoTagDocList, setLoadingThumbnails  }: Props) {
  const { isAutoScrolling } = useAutoScrollSpeed();
  const theme = useTheme();
  const classes = useStyles({});
  const {
    selectedParticipant
  } = useParticipants();
  const isLargeScreen = useMediaQuery('(min-width: 1200px)');
  const scrollSpeedWillDisplacePageNumberFromTop = (
    !isLargeScreen
    && (theme?.showParticipants === false)
  );
  const [collapsedCurrentDoc, setCollapsedCurrentDoc] = useState(false);
  const currentDoc = docs[currentDocId];
  const currentDocIsCompleted = docIsCompletedOrParticipantCompletedDoc(currentDoc, selectedParticipant, completedNoTagDocList)

  const [collapsedCompletedSection, setCollapsedCompletedSection] = useState(!currentDocIsCompleted);
  const [collapsedIncompleteSection, setCollapsedIncompleteSection] = useState(currentDocIsCompleted);
  const handleExpand = (docId: string) => (event: any, newExpanded: boolean) => {
    if (newExpanded) {
      if (docId !== currentDocId) {
        onDocSelected(docId);
      } else {
        setCollapsedCurrentDoc(false);
      }
    } else {
      setCollapsedCurrentDoc(true);
    }
  };

  // When the current document changes, be sure to expand it and it's section (completed vs not completed)
  useEffect(() => {
    setCollapsedCurrentDoc(false);
    if (currentDocIsCompleted) {
      setCollapsedCompletedSection(false);
    } else {
      setCollapsedIncompleteSection(false);
    }
  }, [currentDocId, currentDocIsCompleted]);

  const { completedDocs, incompleteDocs } = useMemo(() => 
    groupBy((doc) => docIsCompletedOrParticipantCompletedDoc(doc, selectedParticipant, completedNoTagDocList)
      ? 'completedDocs'
      : 'incompleteDocs'
    , Object.values(docs))
  , [docs, selectedParticipant, completedNoTagDocList]);

  const numberOfDocuments = (completedDocs?.length || 0) + (incompleteDocs?.length || 0);

  // If there's only one doc, just render that one doc
  if (numberOfDocuments === 1) {
    const doc = Object.values(docs)[0] as any;
    return (
      <Box
        my={2}
        display='flex'
        flexGrow={1}
      >
        <Doc
          setLoadingThumbnails={setLoadingThumbnails}
          completedNoTagDocList={completedNoTagDocList}
          isExpanded={(currentDocId === doc.id) && (!collapsedCurrentDoc)}
          key={doc.id}
          doc={doc}
          {...{ docs, handleExpand, currentPageIndex, numPages, onPageSelected, currentDocId }}
          showPageNumber={theme?.showParticipants || (scrollSpeedWillDisplacePageNumberFromTop && isAutoScrolling)}
          hiddenHeader
        />
      </Box>
    );
  }

  if (!theme?.showParticipants && (numberOfDocuments > 0)) {
    return (
      <Box
        my={2}
        display='flex'
        flexGrow={1}
        className={classes.marginCorrection}
      >
        <Doc
          completedNoTagDocList={completedNoTagDocList}
          setLoadingThumbnails={setLoadingThumbnails}
          isExpanded={true}
          doc={currentDoc}
          {...{ docs, handleExpand, currentPageIndex, numPages, onPageSelected, currentDocId }}
          showPageNumber={theme?.showParticipants || (scrollSpeedWillDisplacePageNumberFromTop && isAutoScrolling)}
          hiddenHeader
        />
      </Box>
    );
  }

  // These will be passed into both <DocListSection>s
  const commonProps = {
    currentDocIsCompleted,
    collapsedCurrentDoc,
    setCollapsedCurrentDoc,
    currentDocId,
    docs,
    handleExpand,
    currentPageIndex,
    numPages,
    onPageSelected,
  };

  return (
    <Box className={classes.docListBox}>
      {completedDocs && (
        <DocListSection
          completedNoTagDocList={completedNoTagDocList}    
          setLoadingThumbnails={setLoadingThumbnails}
          collapsedSection={collapsedCompletedSection}
          setCollapsedSection={setCollapsedCompletedSection}
          docsInSection={completedDocs}
          toolTipTitle='Completed documents'
          title='Completed'
          summaryClass={classes.completed}
          summaryTextClass={classes.textSuccessContrast}
          isCompletedSection
          {...commonProps}
        />
      )}
      {incompleteDocs && (
        <DocListSection
          completedNoTagDocList={completedNoTagDocList}  
          setLoadingThumbnails={setLoadingThumbnails}
          collapsedSection={collapsedIncompleteSection}
          setCollapsedSection={setCollapsedIncompleteSection}
          docsInSection={incompleteDocs}
          toolTipTitle='Documents to be signed'
          title='To Sign'
          summaryClass={classes.toSign}
          summaryTextClass={classes.textPrimaryContrast}
          {...commonProps}
        />
      )}
    </Box>
  );
}
