
import { makeStyles } from '@material-ui/core';
import { Theme } from 'document-viewer/src/lib/hooks/useTheme';

const isiOSFirefox = navigator.userAgent.indexOf("FxiOS") > -1;

const getMobileFullHeight = (theme: Theme) => ({
  mobileFullHeight: {
    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
  },
});

export const useSignaturePadModalStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  pinModalStyle: {
    width: '100%',
    height: theme.signatureAndInitialsInOneStep ? '100%' : undefined,
  },
  scrollPaper: {
    alignItems: 'flex-start',
  },
  modalMobile: {
    [theme.breakpoints.down("md")]: {
      // due to the way the modal is rendered in firefox, we need to use a different maxHeight
      maxHeight: isiOSFirefox ? 'calc(95% - 80px)' : 'calc(100% - 100px)',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      // due to the way the modal is rendered in firefox, we need to use a different maxHeight
      maxHeight: isiOSFirefox ? 'calc(97% - 80px)' : 'calc(96% - 100px)',
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: 'calc(100% - 100px)',
    },
  },
  dialogActions: { margin: 8, justifyContent: 'center' },
  idvDialogActions: {
    marginTop: theme.signatureAndInitialsInOneStep ? '0 !important' : 'inherit',
    marginBottom: 8,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: 24,
    },
  },
  backButton: {
    // marginRight: theme.spacing(1),
    minWidth: '100px',
  },
  instructions: {
    marginBottom: theme.signatureAndInitialsInOneStep ? 0 : theme.spacing(1),
    height: theme.signatureAndInitialsInOneStep ? '100%' : undefined
  },
  icon: {
    width: 40,
    height: 40,
    marginTop: -8,
    zIndex: 99
  },
  stepLabel: { fontSize: '1.1rem' },
  currentStepInstructions: {
    textAlign: 'center',
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    backButton: {
      minWidth: '200px',
    },
  },
  ...getMobileFullHeight(theme),
}));

export const SIG_CANVAS_HEIGHT = 160;
export function getSigCanvasBorder({ isMdUp, combinedStyle }: { isMdUp: boolean, combinedStyle: boolean }) {
  return isMdUp && !combinedStyle ? 12 : 3;
}

export const useKbaStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: theme.spacing(3),
  },
  mobileDialogsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  mobileDialogsAlignCenter: {
    alignItems: 'center',
  },
  ...getMobileFullHeight(theme),
}));

export const PLACEHOLDER_FONT_SIZE = '20px';
export const useDrawItStyles = makeStyles((theme: Theme) => ({
  sigCanvas: {
    border: `${getSigCanvasBorder({ isMdUp: false, combinedStyle: theme.signatureAndInitialsInOneStep })}px solid #CCCCCC`,
    background: '#fff',
    height: `${SIG_CANVAS_HEIGHT + getSigCanvasBorder({ isMdUp: false, combinedStyle: theme.signatureAndInitialsInOneStep }) * 3}px`,
    display: 'relative',
  },
  placeholder: {
    position: 'absolute',
    top: `calc(50% - ${PLACEHOLDER_FONT_SIZE})`,
    fontSize: PLACEHOLDER_FONT_SIZE,
    textAlign: 'center',
    width: '100%',
  },
  sigCanvasAutoSizer: {
    position: 'absolute',
    zIndex: 1,
  },
  clearBtnOnCanvas: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    right: 0,
  },
  [theme.breakpoints.up('md')]: {
    sigCanvas: {
      borderWidth: getSigCanvasBorder({ isMdUp: true, combinedStyle: theme.signatureAndInitialsInOneStep }),
      height: `${SIG_CANVAS_HEIGHT + getSigCanvasBorder({ isMdUp: true, combinedStyle: theme.signatureAndInitialsInOneStep }) * 2}px`,
    },
  },
}));

export const useSetSigInitStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  hidden: {
    display: 'none',
  },
  legalese: {
    textTransform: theme.signatureAndInitialsInOneStep ? 'uppercase' : 'none',
    fontSize: theme.signatureAndInitialsInOneStep ? '12px' : '13px'
  },
  drawItDialogContent: {
    padding: 0,
  },
  typeInDialogContent: {
    padding: 0,
    '&:first-child': {
      paddingTop: 0,
    },
  },
  [theme.breakpoints.up('md')]: {
    drawItDialogContent: {
      padding: theme.spacing(3),
    },
    typeInDialogContent: {
      padding: theme.spacing(3),
      '& > *': {
        marginBottom: '30px',
      }
    },
    rightSideSpacingMdUp: {
      paddingRight: theme.spacing(2),
    },
  },
}));


export const useTypeItInStyles = makeStyles((theme: Theme) => ({
  signatureOrInitials: {
    background: '#ffffff',
    padding: 0,
    fontWeight: 'bolder',
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    border: `${getSigCanvasBorder({ isMdUp: false, combinedStyle: theme.signatureAndInitialsInOneStep })}px solid #CCCCCC`,
    boxSizing: 'content-box',
    maxWidth: '100%',
    fontSize: '30px',
    flex: 1,
    // make sure the signature text isn't all caps in List View
    textTransform: theme.signatureAndInitialsInOneStep ? 'none' : 'inherit',
  },
  topRow: {
    paddingBottom: theme.spacing(theme.signatureAndInitialsInOneStep ? 1 : 2),
    paddingTop: theme.spacing(theme.signatureAndInitialsInOneStep ? 1 : 0),
    width: '100%',
    margin: 0,
    '& > .MuiGrid-item': {
      paddingTop: 0,
      paddingBottom: theme.spacing(2),
    },
  },
  [theme.breakpoints.up('md')]: {
    signatureOrInitials: {
      minWidth: '150px',
      fontSize: '50px',
      borderWidth: getSigCanvasBorder({ isMdUp: true, combinedStyle: theme.signatureAndInitialsInOneStep }),
    },
    topRow: {
      '& > .MuiGrid-item': {
        paddingBottom: 0,
      },
    },
  },
  signatureText: {
    overflowX: 'hidden',
  },
  initialsText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflowX: 'hidden',
  },
}));
