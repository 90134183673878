import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { TimerDisplayProps } from './types';
import { useStyles } from './styles';
import Countdown from 'react-countdown';
import { useLocalStorage } from 'react-use';
import { useParams } from 'react-router';
import { Typography, Card, CardContent } from '@material-ui/core';
// Must all be plural
const timerUnits = ['days', 'hours', 'minutes', 'seconds'];

const TimerDisplay: FunctionComponent<TimerDisplayProps> = ({ msBeforeTimeout, paused, resetTimer, onTimeout, questions, kbaAttempts, setTimerWait, onSubmit }) => {
  
  const countdownRef = useRef<any>();
  const ranOutOfTimeRef = useRef(false);
  const [timeRunsOutAt, setTimeRunsOut] = useState(Date.now() + msBeforeTimeout);
  const [questionRefreshed, setQuestionRefreshed] = useState('');
  // commented out timerRefresh functionality //
  // const [totalTimer, setTotalTimer] = useLocalStorage("totalTimer", null);
  // const [session,setSession] = useLocalStorage("session", null)
  // const transaction = useParams()['transactionId']
  const classes = useStyles();

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    window.addEventListener('unload', handleEndKba)
    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('unload', handleEndKba);
      // handleEndKba();
    }
  }, [])

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = ''
  }

  const handleEndKba = () => {
    // please don't remove the commented lines
    // setIsAbruptlyClosed(true);
    onSubmit({}, false ,true);
    // setTimeout(onTimeout, 0);
    // setKbaAttempts(0);
    // setHasSubmitted(true);
  }

  useEffect(() => {
    if(countdownRef && countdownRef.current) {
    
      if(paused) {
        if(!countdownRef.current.isPaused()) {
          countdownRef.current.pause();
        }
      } else {
        
        if(countdownRef.current.isPaused()) {
          resetTimer();
        }
        
      }
    }
  }, [ paused, countdownRef ]);

  // useEffect(() => {
  //   setTimeRunsOut(Date.now() + (parseInt(totalTimer,10) && parseInt(totalTimer,10) !== 0 ? (transaction===session ? parseInt(totalTimer,10) :msBeforeTimeout) : msBeforeTimeout))
  // },[totalTimer, session, transaction, msBeforeTimeout])

  return ( 
    <Card className={classes.countdownCard}>
      <CardContent>
        <Typography
          variant='h5'
          className={classes.timeRemaining}
        >
          Time Remaining
        </Typography>
        <Countdown
          ref={countdownRef}
          key={questionRefreshed}
          date={timeRunsOutAt}
          intervalDelay={msBeforeTimeout < 1000 ? 1 : 1000}
          renderer={({ total, completed, ...timeLeft }) => {
          //   window.onbeforeunload = function () {
          //     if (
          //       parseInt(localStorage.getItem("totalTimer")) != null || !isNaN(parseInt(localStorage.getItem("totalTimer")))
          //     ){
          //       setTotalTimer(JSON.stringify(total));
          //       setSession(transaction)
          //     }
          //     // localStorage.setItem("totaltime", JSON.stringify(total));
          //   };

            if (completed) {
              // localStorage.removeItem("totalTimer");
              setTimerWait(true);
              // If completed happens, then time has run out. If onTimeout has not already been called, then call it asynchronously
              if (!ranOutOfTimeRef.current) {
                ranOutOfTimeRef.current = true;
                setTimeout(onTimeout, 0); // Must be async or else react will throw a tantrum
              }
              return <p className={classes.countdownTxt}> Time is up!</p >;
            } else {
              const timeStr = timerUnits
                .filter((unit) => timeLeft[unit] >= 1)
                .map((unit) => {
                  const num = timeLeft[unit];
                  const plural = num !== 1;
                  return `${num} ${plural ? unit : unit.substr(0, unit.length - 1)}`;
                }).join(' and ')
              return <p className={classes.countdownTxt}>{timeStr}</p>;
            }
          }}
        />
      </CardContent>
    </Card>
  );
};

export default TimerDisplay;
