import { ComponentType } from 'react';

export const stepsArray = ['Equipment Check', 'Personal Information', 'Session'] as const;
export type SetupStepperSteps = (typeof stepsArray)[number];

export type Explanation = {
  title?: string;
  description?: string;
};
export type ComponentProps = {
  step: SetupStepperSteps;
  explanation?: Explanation;
};

export type IconAndColor = {
  icon: ComponentType;
  color: string;
};
