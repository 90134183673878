import _ from 'lodash';
import * as R from 'ramda';
import { createContent } from '@enotarylog/tag-utils/createContent';
import { parseName, toFullName } from '@enotarylog/tag-utils/helpers/parseName';
import { parseField } from '@enotarylog/tag-utils/helpers/parseField';
import { tagTypes } from '@enotarylog/tag-utils/constants/tagTypes';
import getFontSizeToFit from '@enotarylog/tag-utils/templatingActions/fitText';
import { InstanceObject } from '../../viewer';

const createTextField = ({ name, annotClassName }: any) => ({ instance, annotClasses }: InstanceObject) => {
  const { Annotations, docViewer } = instance;
  const annotManager = instance.docViewer.getAnnotationManager();
  const checkIfDateNeeded = (type: string) => {
    if (type in tagTypes.dateTags) {
      return tagTypes.dateTags[type].dateFormat;
    }

    return undefined;
  };

  const checkIfStateNeeded = (type: string) => {
    if (type in tagTypes.addressTags) {
      return tagTypes.addressTags[type].stateFormat;
    }
  };
  const checkIfAddressNeeded = (type: string) => {
    if (type in tagTypes.addressTags) {
      return tagTypes.addressTags[type].addressFormat;
    }
  };

  interface CustomObj {
    type?: string;
    value?: string;
    flags?: any;
  }

  return async (rectAnnot: any, custom: CustomObj = {}) => {
    const {
      type = _.toUpper(name),
      value = '',
      flags = {
        readOnly: true,
        multiline: false,
        edit: true,
        required: true,
        dateFormat: checkIfDateNeeded(type),
        stateFormat: checkIfStateNeeded(type),
        addressFormat: checkIfAddressNeeded(type),
      },
    } = custom;

    // console.debug('type', type);
    const pageIndex = (rectAnnot?.PageNumber) ? rectAnnot.PageNumber - 1 : docViewer.getCurrentPage() - 1;


    // const zoom = docViewer.getZoom();
    const CustomAnnot = annotClasses[annotClassName];
    const textAnnot = new CustomAnnot();

    const rotation = docViewer.getCompleteRotation(pageIndex + 1) * 90;

    textAnnot.Rotation = rotation;
    textAnnot.X = rectAnnot.X;
    textAnnot.Y = rectAnnot.Y;
    textAnnot.Width = rectAnnot.Width;
    textAnnot.Height = rectAnnot.Height;
    textAnnot.setPadding(new Annotations.Rect(0, 0, 0, 0));
    textAnnot.Author = instance.annotManager.getCurrentUser();

    // get currently selected signer.
    const signerId = instance.getSigner();
    const signer = instance.getSignerById(signerId);

    // NOTE: This isn't the finalized logic
    if (!signer || !signer.id) {
      await annotManager.deleteAnnotation(rectAnnot, true);

      return annotManager.deselectAllAnnotations();
    }

    textAnnot.custom = {
      ...custom,
      ...rectAnnot.CustomData,
      type: type ? type : 'SIGNATURE',
      value,
      flags,
      fieldType: type ? type : 'SIGNATURE',
      signerId: signer ? signer.id : 'Notary',
      id: rectAnnot.Id,
      color: signer ? [signer.color.R, signer.color.G, signer.color.B, signer.color.A] : [17, 8, 236, 0.5],
      author: annotManager.getCurrentUser(),
      name: signer ? parseName(signer) : 'Notary',
    };

    textAnnot.custom.fullName = toFullName(textAnnot.custom.name);

    textAnnot.CustomData = textAnnot.custom;

    let contents = createContent(textAnnot.CustomData, signer);
    const contentEmpty = R.isNil(contents) || R.isEmpty(contents);

    if (R.not(type in tagTypes.imageTags) && R.not(type in tagTypes.fieldTags) && R.not(contentEmpty)) {
      textAnnot.setRichTextStyle({
        0: {
          'text-decoration': 'underline',
          color: '#000000',
        }
      });
    } else {
      textAnnot.setRichTextStyle({
        0: {
          color: '#000000',
        }
      });
    }

    textAnnot.FontSize = '11pt';
    textAnnot.FillColor = rectAnnot.FillColor;
    textAnnot.TextColor = new Annotations.Color(0, 0, 0, 1);
    textAnnot.FillColor = signer ? new Annotations.Color(signer.color.R, signer.color.G, signer.color.B, signer.color.A) : new Annotations.Color(17, 8, 236, 0.5);
    textAnnot.StrokeThickness = 0;
    textAnnot.StrokeColor = new Annotations.Color(0, 165, 228, 0);
    textAnnot.TextAlign = 'left';
    textAnnot.PageNumber = rectAnnot?.PageNumber || (pageIndex + 1);
    textAnnot.LockedContents = true;

    if (R.isNil(contents) || R.isEmpty(contents)) {
      // Nil or empty give no field entered
      contents = parseField(type);
    }

    if (R.isEmpty(contents) && (type === 'SIGNATURE' || type === 'INITIALS' || type === 'CHECK' || type === 'TEXT')) {
      const signer = instance.getSignerById(textAnnot.CustomData.signerId) || {};
      const fullName = parseName(signer);
      const lbl = _.upperFirst(_.lowerCase(type));

      contents = `${lbl}: ${fullName}`;
    }

    textAnnot.setContents(contents);
    const newFontSize = getFontSizeToFit(instance, textAnnot);
    textAnnot.FontSize = `${newFontSize}pt`;
    // NOTE: for form fields use orange color.
    if (type in tagTypes.fieldTags) {
      //textAnnot.FillColor = new Annotations.Color(255, 141, 0, 0.5);
      textAnnot.custom.color = [255, 141, 0, 0.5];
    }

    // Resize the text annotation to fit its new content.
    const doc = instance.docViewer.getDocument();
    const pageInfo = doc.getPageInfo(textAnnot.PageNumber - 1);
    const pageMatrix = doc.getPageMatrix(textAnnot.PageNumber - 1);

    const [prevX, prevY] = [textAnnot.X, textAnnot.Y];
    const [prevWidth, prevHeight] = [textAnnot.Width, textAnnot.Height];
    textAnnot.fitText(pageInfo, pageMatrix);

    // Move it to its new position to be cursor centered upon placement.
    // The setPadding forces the redraw of the content upon correct placement.
    textAnnot.X = prevX + (prevWidth / 2) - (textAnnot.Width / 2);
    textAnnot.Y = prevY + (prevHeight / 2) - (textAnnot.Height / 2);
    textAnnot.setPadding(new Annotations.Rect(0, 0, 0, 0));

    instance.annotManager.on('annotationChanged', async (annotations, action) => {
      if (action && (annotations[0]?.qq < 25 || annotations[0]?.rq < 25)) {
        annotations[0].MaintainAspectRatio = false;
        if (annotations[0].ToolName === 'AnnotationCreateCallout') {
          annotations[0].Author = instance.annotManager.getCurrentUser();
        }
      }
    })
    await annotManager.deleteAnnotation(rectAnnot, true);
    await annotManager.addAnnotation(textAnnot, false);
    await annotManager.deselectAllAnnotations();
    await annotManager.redrawAnnotation(textAnnot);
    await annotManager.selectAnnotation(textAnnot);
  };
};

export default createTextField;
