import _ from 'lodash';
import license from '@enotarylog/pdftron-webviewer/license';
// import * as R from '@enotarylog/ramda';
import saveAs from 'save-as'
import moment from 'moment';
import { WebViewerInstance } from '@pdftron/webviewer';
const cert_file_path = '/static/pdf/certificate.pfx';



/**
 * Seals pdf using example certificate
 * @param pdfnet PDFNet instance
 * @param buffer pdf file buffer
 */
const initialize = (instance: WebViewerInstance) => {
  if (!instance) {
    return {};
  }

  const pdfnet = instance.PDFNet;

  return {
    sealPDF: (buffer) => pdfnet.runWithCleanup(async () => {
      const doc = await pdfnet.PDFDoc.createFromBuffer(buffer);
      await doc.flattenAnnotations(false);

      // lock the document before a write operation
      // runWithCleanup will auto unlock when complete
      doc.lock();


      // Add an StdSignatureHandler instance to PDFDoc, making sure to keep track of it using the ID returned.
      const sigHandlerId = await doc.addStdSignatureHandlerFromURL(cert_file_path, 'password');
      const sigField = await doc.fieldCreate('Signature1', pdfnet.Field.Type.e_signature);
      const page1 = await doc.getPage(1);
      const widgetAnnot = await pdfnet.WidgetAnnot.create((await doc.getSDFDoc()), (await pdfnet.Rect.init(0, 0, 0, 0)), sigField);
      page1.annotPushBack(widgetAnnot);
      widgetAnnot.setPage(page1);
      const widgetObj = await widgetAnnot.getSDFObj();
      widgetObj.putNumber('F', 132);
      widgetObj.putName('Type', 'Annot');


      const sigDict = await sigField.useSignatureHandler(sigHandlerId);
      const year = moment().format('YYYY');
      const month = moment().format('MM');
      const dd = moment().format('DD');
      const hr = moment().format('HH');
      const mins = moment().format('mm');
      const secs = moment().format('ss');

      // TODO: timezone offset is hardcoded to florida. figure out how to determine the offset of the server
      const date = new pdfnet.Date(parseInt(year), parseInt(month), parseInt(dd), parseInt(hr), parseInt(mins), parseInt(secs));
      const date_obj = await sigDict.putString('M', `D:${year}${month}${dd}${secs}${hr}${secs}-00'00'`);
      await date.update(date_obj);

      sigDict.putName('SubFilter', 'adbe.pkcs7.detached');
      sigDict.putString('Name', 'eNotaryLog');
      sigDict.putString('Organization', 'eNotaryLog, LLC');
      sigDict.putString('Location', 'Tampa, Florida');
      sigDict.putString('Reason', 'Document verification');

      const root = await doc.getRoot();
      const perms = await root.putDict('Perms');
      perms.put('DocMDP', sigDict);

      const refObj = await sigDict.putArray('Reference');
      const transform = await refObj.pushBackDict();
      transform.putName('TransformMethod', 'DocMDP');
      transform.putName('Type', 'SigRef');
      const transformParams = await transform.putDict('TransformParams');
      transformParams.putNumber('P', 1); // Set permissions as necessary.
      transformParams.putName('Type', 'TransformParams');
      transformParams.putName('V', '1.2');


      return doc.saveMemoryBuffer(0);
    }, license),
    getWithAnnots: (buffer: ArrayBuffer, xfdfString: string, linearize = false) => pdfnet.runWithCleanup(async () => {
      const pdfDoc = await pdfnet.PDFDoc.createFromBuffer(buffer);

      if (xfdfString) {
        const fdfDoc = await pdfnet.FDFDoc.createFromXFDF(xfdfString);

        await pdfDoc.fdfMerge(fdfDoc);
      }

      await pdfDoc.flattenAnnotations(false);

      return pdfDoc.saveMemoryBuffer(linearize ? pdfnet.SDFDoc.SaveOptions.e_linearized : 0);
    }, license),
    getPersistData: async () => {
      const annots = await instance.annotManager.getAnnotationsList();

      // annots that have tags associated with them
      const annotsToFlatten = _.filter(annots, (annot) => !_.isNil(annot.CustomData.corrId));
      const toExcludeIds = [..._.map(annotsToFlatten, 'Id'), ..._.map(annotsToFlatten, 'CustomData.corrId')]
      const annotsToKeep = _.filter(annots, (a) => _.indexOf(toExcludeIds, a.Id) === -1);

      // tags ids that have been applied
      _.chain(annotsToFlatten)
        .map((a) => instance.annotManager.getAnnotationById(a.CustomData.corrId))
        .filter((a) => !_.isNil(a))
        .value();

      const flattenXfdf = await instance.annotManager.exportAnnotations({
        annotList: [...annotsToFlatten],
        links: false,
        fields: false,
        useDisplayAuthor: false // Setting this to true caused an issue where the xfdf would have title="" imported annotations, even if those annotations were imported with something in their title attribute
      });

      const preserveXfdf = await instance.annotManager.exportAnnotations({
        annotList: [...annotsToKeep],
        links: false,
        fields: false,
        useDisplayAuthor: false // Setting this to true caused an issue where the xfdf would have title="" imported annotations, even if those annotations were imported with something in their title attribute
      });

      const doc = instance.docViewer.getDocument();
      if (!doc) {
        return;
      }

      const data = await doc.getFileData({
        xfdfString: flattenXfdf,
        flatten: true
      });

      const blob = new Blob([data], { type: 'application/pdf' });
      // utils.saveBufferAsPDFDoc(data);

      return {
        fileData: blob,
        xfdf: preserveXfdf,
        allXfdf: await instance.annotManager.exportAnnotations()
      }
    },
    saveBufferAsPDFDoc: (buf: ArrayBuffer) => {
      const blob = new Blob([buf], { type: 'application/pdf' });
      saveAs(blob, 'signed_doc.pdf');
    }
  }
}


export default initialize;
