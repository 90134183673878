import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    margin: 0,
  },
  flex: {
    flex: 1,
    display: 'flex',
    [theme.breakpoints.between(902,1010)]: {
      maxWidth: '180px',
    }
  }
}));
