import { Accordion, AccordionSummary, AccordionDetails } from './index';
import { withStyles } from '@material-ui/core/styles';

export const FlexAccordion = withStyles({
  expanded: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    '& > .MuiCollapse-entered': {
      flex: '1',
      display: 'flex',
      '& > .MuiCollapse-wrapper': {
        flex: '1',
        '& > .MuiCollapse-wrapperInner': {
          display: 'flex',
          '& > [role="region"]': {
            flex: '1',
            display: 'flex',
          },
        },
      },
    },
  },
})(Accordion);

export const FlexAccordionDetails = withStyles({
  root: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
  },
})(AccordionDetails);

export const FlexAccordionSummary = AccordionSummary;
