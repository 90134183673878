/* eslint-disable max-lines */
import React, { Component } from 'react';
import _ from 'lodash';
import bPromise from 'bluebird';
import initWv, { WebViewerInstance, Annotations, CoreControls } from '@pdftron/webviewer';
import * as R from 'ramda';
import { handleCreateAddressAnnotation } from '@enotarylog/tag-utils/templatingActions/addressActions/createAnnotation';
import { tagTypes } from '@enotarylog/tag-utils/constants/tagTypes';
import { createNotaryVerbiageFreeText } from '@enotarylog/tag-utils/templatingActions/notaryVerbiageActions/createAnnotation';
import moment from 'moment';
import { states } from '@enotarylog/tag-utils/constants/states';
import registerTools from './lib';
import handleAuditTrail from './lib/eventListeners/auditTrails';
import * as pathToImg from './lib/helpers/pathToImage';
import CertPdfModal from './components/CertPdfModal';
import ConfirmationModal from './components/ConfirmationModal';
import VADisclaimerModal from './components/VADisclaimerModal';
import DocSelector from './components/DocSelector';
import defConfig from './lib/configs/wv';
import callIfDefined from './lib/helpers/callIfDefined';
import AddressPromptModal from './components/templateModals/addressPromptModal';
import { bufferStringToJson } from './utils/bufferStringToJson';
import { isMobileDevice } from './utils/getUserAgent';
import stripSpecialAnnots from './lib/helpers/stripSpecialAnnots';
import stripNonSpecialAnnots from './lib/helpers/stripNonSpecialAnnots';
import license from '../../license';
import { DebouncedFunc } from "lodash";
import extractFileExtension from '@enotarylog/utils/extractFileExtension';
import axios, { AxiosError } from 'axios';

const handleError = (instance: Instance) => (error: any) => {
  if (error.serverResponse && error.serverResponse.rawResponse) {
    const decodedError = bufferStringToJson(error.serverResponse.rawResponse);

    return instance.showErrorMessage(decodedError.message || error.message);
  }

  return instance.showErrorMessage(error.message);
};

export interface Annot extends Annotations.Annotation {
  FillColor?: Annotations.Color;
  authorId?: string;
  StrokeThickness?: number;
  type?: any;
  name?: any;
  signerId?: any;
}

export interface AnnotManager extends CoreControls.AnnotationManager {
  handleDeleteDuplicateWidgets: DebouncedFunc<() => Promise<void>>;
  getWidgetById: (getWidgetById: string) => Annotations.Annotation | undefined;
  getWidgetsById: (getWidgetById: string) => Annotations.Annotation[];
}

export interface DocViewer extends CoreControls.DocumentViewer {
  lockWebviewer: (disabled: boolean) => void;
}

export interface WebViewerConfig {
  enableLogRocket?: boolean;
  viewOnly?: boolean;
  isAdminUser?: boolean;
  taggingMode?: boolean;
  taggingRoom?: boolean;
  disableElements?: string[];
  enableAnnotations?: boolean;
  l?: string;
  css?: string;
  fullAPI?: boolean;
  flattenClientSide?: boolean;
  customTags?: Record<string, string>;
  isMobile?: boolean;
}

export interface WebviewerProps {
  insertLuckyOrange?: boolean;
  isAdminUser?: boolean;
  isEsign?: boolean;
  taggingMode?: boolean;
  isReadOnly?: boolean;
  config: WebViewerConfig;
  customStyle?: string;
  pdftronServer?: any;
  docs?: any;
  selectedDoc?: any;
  showDocSelector?: any;
  currentUser?: any;
  xfdf?: any;
  loadedDocs?: any[];
  signatures?: any;
  images?: any;
  signers?: any;
  runId?: any;
  toolConfig?: any;
  nsId?: any;
  authenticate?: any;
  onDocumentChanged?: any;
  notary?: any;
  countyName?: string;
  onAuditTrail?: any;
  onLockChanged?: any;
  bindEvents?: any;
  onRemoveFormFields?: any;
  onSelectedSignerChanged?: any;
  onRemoveSignatures?: any;
  bindDocEvents?: any;
  onAddDocLoaded?: any;
  onBlankPagesAdded?: any;
  onBlankPagesRemoved?: any;
  onRemoveAllAnnots?: any;
  onVaDisclaimerChanged?: any;
  onAnnotationAdded?: any;
  onAnnotationUpdated?: any;
  onAnnotationDeleted?: any;
  onWidgetAdded?: any;
  onWidgetUpdated?: any;
  onWidgetDeleted?: any;
  onFieldUpdated?: (field: any) => any;
  onDocumentLoaded?: any;
  onDocumentUnloaded?: any;
  onFinishedRendering?: any;
  getLoadedDocs?: any;
  selectedSigner?: any;
  flattenSpecialFields?: any;
  token?: any;
  onInit?: any;
  unbindEvents?: any;
  unbindDocEvents?: any;
  onSaveSignature?: any;
  showVaDisclaimer?: any;
  createAnnotation?: any;
  height?: string;
  onVADisclaimerSubmit?: any;
  userPinAuthModalOpen?: boolean;
  blankPages?: any;
  onError?: any;
  onBeforeLoadDocument?: any;
  onAnnotationsImported?: any;
  onDocumentConvertCompleted?: any;
}

export interface WebViewerState {
  certModal: {
    show: boolean
    pdf?: any;
    type?: string;
  };
  confirmationModal: {
    show: boolean;
    message?: string;
    onConfirm?: any;
  };
  vaDisclaimer: {
    show: boolean;
  };
  promptModal: {
    isOpen: boolean;
    annotId?: any;
    customData?: any;
  }
  pages?: number;
  docWidth?: number;
  screenWidth?: number;
  fitWidthZoom?: number;
  isMobile?: boolean;
  isZooming?: boolean;
}

// export interface CoreControlsExtends {
//   office2PDFBuffer?: any;
//   createDocument?: any;
// }

export interface Instance extends WebViewerInstance {
  // hotkeys: typeof WebViewerInstance.Hotkeys & {
  //   Keys: typeof WebViewerInstance.Hotkeys.Keys & {
  //     [key: string]: string;
  //   }
  // };
  setDocs: (docs: any, license: string) => void;
  setXfdfs: (xfdfs: any[], license: string) => void;
  setRunId: (runId: any) => void;
  getDocId: () => string;
  loadSignatures: (signatures: any) => Promise<void>;
  getSelectedSigner: () => any;
  getSigner: () => any;
  getSigners: () => any;
  hideMessage: (str?: string) => void;
  setNotary: (notary: any) => void;
  saveSignatures: (user: any) => Promise<any>;
  clearSignatures: () => Promise<void>;
  getRunId: () => any;
  setCertPdf: (pdf: any) => void;
  getSignerById: (signer: any) => any;
  loadDocument: (doc: any, options: any) => any;
  showMessage: (message: string) => void;
  toggleTools: (toggle: boolean) => void;
  getOriginalPageCount: () => number;
  selectedDoc: any;
  annotManager: AnnotManager;
  docViewer: DocViewer;
  iframeContainer: HTMLElement;
  store: any;
  // CoreControls.office2PDFBuffer is giving TS error
  CoreControls: any;
}

export interface InstanceObject {
  instance: Instance;
  [key: string]: any;
}

declare global {
  interface Window {
    winstance?: WebViewerInstance;
    instance?: Instance;
    pathToImg?: any;
    annotManager?: CoreControls.AnnotationManager;
    docViewer?: CoreControls.DocumentViewer;
    getInstance?: (el: HTMLElement) => WebViewerInstance;
  }
}

export class Webviewer extends Component<WebviewerProps, WebViewerState> {
  public viewerRef: React.RefObject<HTMLDivElement>;
  public targetRef: React.RefObject<HTMLElement>;
  public containerRef: React.RefObject<HTMLElement>;
  public notarialCertUploadRef: React.RefObject<HTMLInputElement>;
  public instance?: Instance;
  public winstance?: WebViewerInstance;
  public annotManager?: CoreControls.AnnotationManager;
  public docViewer?: CoreControls.DocumentViewer;
  public state: WebViewerState;

  static defaultProps = {
    showDocSelector: true,
    loadedDocs: [],
    // config: defConfig(),
    signatures: {},
    images: {},
    xfdf: {},
  }

  constructor(props: WebviewerProps) {
    super(props);
    this.viewerRef = React.createRef();
    this.targetRef = React.createRef();
    this.containerRef = React.createRef();
    this.state = {
      certModal: { show: false },
      confirmationModal: { show: false },
      vaDisclaimer: { show: false },
      promptModal: {
        isOpen: false,
        customData: null,
      },
      pages: 0,
      docWidth: 0,
      screenWidth: 0,
      fitWidthZoom: 0,
      isMobile: false,
      isZooming: false,
    };
    this.notarialCertUploadRef = React.createRef();
  }

  componentDidMount = async () => {
    window.pathToImg = pathToImg;

    const config = {
      ...defConfig({
        viewOnly: false,
        isAdminUser: this.props.isAdminUser,
        taggingMode: this.props.taggingMode || false,
      }),
      ..._.omit(this.props.config, ['flattenClientSide']),
    };

    let fileExtension = 'pdf';

    if (this.props.docs[this.props.selectedDoc] && this.props.docs[this.props.selectedDoc].file) {
      // const loadDocFrom = this.props.docs[this.props.selectedDoc].url || this.props.docs[this.props.selectedDoc].file;
      fileExtension = extractFileExtension(this.props.docs[this.props.selectedDoc].file);
    }

    const winstance = await initWv({
      css: this.props.customStyle || '/wv-configs/webviewer-styles/webviewer.css',
      ...config,
      // @ts-expect-error
      l: license,
      pdftronServer: this.props.pdftronServer,
      custom: JSON.stringify({
        ...(config?.custom || {}),
        license: config.l || license,
        l: config.l || license,
        flattenSpecialFields: this.props.flattenSpecialFields,
        insertLuckyOrange: this.props.insertLuckyOrange,
      }),
      config: '/wv-configs/vanilla-config.js',
      isAdminUser: this.props.isAdminUser,
      preloadWorker: fileExtension,
    }, this.viewerRef.current!);

    winstance.iframeWindow.addEventListener('loaderror', (err) => {
      // Do something with error. eg. instance.showErrorMessage('An error has occurred')
      console.log('an error has occurred', err);


      if (this.props.onError) {
        return this.props.onError(err);
      }
    });

    winstance.iframeWindow.addEventListener('error', (err) => {
      // Do something with error. eg. instance.showErrorMessage('An error has occurred')
      console.log('an error has occurred', err);

      if (this.props.onError) {
        return this.props.onError(err);
      }
    });

    const {
      docs = {},
      selectedDoc,
      currentUser,
      isAdminUser,
      isReadOnly,
    } = this.props;

    // when ready is fired from public/wv-configs/config.js
    winstance.docViewer.one('ready', async (instance: Instance) => {
      // if already initialized, then return;
      if (this.instance) {
        return;
      }

      instance.iframeContainer = this.viewerRef.current as HTMLElement;

      if (isReadOnly) {
        instance.annotManager.setReadOnly(isReadOnly);
      }

      instance.annotManager.on('toggledTools', (disable) => {
        if (!disable && this.props.isReadOnly) {
          instance.annotManager.setReadOnly(true);
        }
      });

      // instance.annotManager.trigger('setSigners', this.props.signers);
      this.instance = window.instance = instance;
      this.winstance = window.winstance = winstance;

      const annotManager = this.annotManager = window.annotManager = instance.annotManager;
      const docViewer = this.docViewer = window.docViewer = instance.docViewer;
      const viewerElement = this.viewerRef.current as HTMLElement;
      const mobileDeviceOrientation = window.matchMedia('(orientation: portrait)');

      this.setState({ isMobile: isMobileDevice() });

      if (instance.setDocs) {
        await instance.setDocs(this.props.docs, config.l!);
      }

      if (instance.setXfdfs) {
        await instance.setXfdfs(this.props.xfdf, config.l!);
      }

      instance.annotManager.trigger('setSigners', this.props.signers);

      instance.setRunId(this.props.runId);

      // initialize custom annotation tools
      await registerTools({
        instance,
        config: {
          ...this.props.toolConfig,
          customTags: this.props.config.customTags || {},
          signatures: this.props.signatures,
          images: this.props.images,
          nsId: this.props.nsId,
          docs: this.props.docs,
        },
      });

      annotManager.setCurrentUser(currentUser);
      annotManager.setIsAdminUser(!!isAdminUser);

      if (_.isFunction(this.props.authenticate)) {
        await this.props.authenticate();
      }

      // Overwrite client-side permission check method on the annotation manager
      // The default was set to compare the authorName
      // Instead of the authorName, we will compare authorId created from the server
      annotManager.setPermissionCheckCallback((author: any, annotation: Annotations.Annotation) => {
        const currentUser = annotManager.getCurrentUser();
        const isAdmin = annotManager.getIsAdminUser();

        // @ts-expect-error authorId doesn't exist
        return annotation.authorId === currentUser || annotation.Author === currentUser || isAdmin;
      });

      instance.annotManager.on('annotationsImported', (annotations) => {
        if (this.props.onAnnotationsImported) {
          this.props.onAnnotationsImported(instance, annotations);
        }
      });

      // when cert modal clicked
      instance.docViewer.on('certModal', this.handleCertModal);

      instance.docViewer.on('confirmationModal', this.handleConfirmationModal);

      // when text prompt annot is clicked
      instance.docViewer.on('openPromptModal', ({ id, CustomData }: any) => {
        this.setState({
          promptModal: {
            isOpen: true,
            annotId: id,
            customData: CustomData,
          },
        });
      });

      instance.docViewer.on('createNotaryVerbiageAnnotation', (tag: any) => {
        // @ts-expect-error type can be undefined
        const tagType = tagTypes.notaryVerbiageTags[tag.CustomData.type as keyof typeof tagTypes.notaryVerbiageTags].type;
        let notaryVerbiage: string | undefined = '';

        if (tagType === 'docId') {
          notaryVerbiage = `Document ID: ${instance.getDocId()}`;
        } else if (tagType === 'nsId') {
          notaryVerbiage = this.props.nsId;
        } else if (tagType === 'commissionId') {
          // NOTE: notary is being passed in differently between the org and consumer room
          notaryVerbiage = this.props.notary?.commissionId;
        } else if (tagType === 'commissionExpiration') {
          notaryVerbiage = moment(this.props.notary?.notarySeal?.expirationDate).format('MM/DD/YYYY');
        } else if (tagType === 'notaryCounty') {
          notaryVerbiage = this.props.countyName;
        } else if (tagType === 'notaryState') {
          let formattedState = this.props.notary?.notaryStateId;

          if (tag.CustomData.flags.stateFormat === 'full') {
            formattedState = states.abbrToFull[this.props.notary?.notaryStateId as keyof typeof states.abbrToFull];
          }

          notaryVerbiage = formattedState;
        } else {
          notaryVerbiage = tagTypes.notaryVerbiageTags[tag.CustomData.type as keyof typeof tagTypes.notaryVerbiageTags].text;
        }

        createNotaryVerbiageFreeText(instance, notaryVerbiage, tag);
      });

      // setup listeners which apply no matter which document is loaded
      instance.docViewer.one('documentLoaded', async () => {
        instance.annotManager.on('auditEvent', this.props.onAuditTrail);

        // attach event listeners to docViewer
        docViewer.on('lockChanged', callIfDefined(this.props.onLockChanged));

        // bind events that should be bound once
        if (_.isFunction(this.props.bindEvents)) {
          await this.props.bindEvents({
            ...instance,
            selectedDoc: instance.getDocId(),
          });
        }

        docViewer.on('removeFormFields', callIfDefined(this.props.onRemoveFormFields));
        docViewer.on('deleteOtherDocSignatureItems', (userId: string, docId: string, type: string) => callIfDefined(this.props.onRemoveSignatures(userId, docId, type)));

        annotManager.on('selectedSignerChanged', this.props.onSelectedSignerChanged);


        // load signatures if available
        if (this.props.signatures?.[this.props.currentUser]) {
          await instance.loadSignatures(this.props.signatures[this.props.currentUser]);
        }
      });

      // setup listeners which listen for events fired from webviewer
      instance.docViewer.on('documentLoaded', async () => {
        // eslint-disable-next-line no-new
        new bPromise((res) => instance.docViewer.trigger('setBlankPages', [this.props.blankPages, () => res()]));

        // if docid not loaded yet then load its xfdf
        if (this.props.isAdminUser) {
          if (this.props.loadedDocs!.indexOf(this.props.selectedDoc) === -1) {
            if (this.props.xfdf && this.props.xfdf[this.props.selectedDoc]) {
              console.log('%cLoading XFDF', 'font-size: 20px; color: red;');
              await this.loadXfdf(this.props.selectedDoc, this.props.xfdf[this.props.selectedDoc]);
            }
          } else {
            console.log('%cNot Loading XFDF', 'font-size: 20px; color: red;');
          }
        }

        // bind events for receiving downstream updates
        if (_.isFunction(this.props.bindDocEvents)) {
          await this.props.bindDocEvents({
            ...instance,
            selectedDoc: instance.getDocId(),
          });
        }

        if (_.isFunction(this.props.onAddDocLoaded)) {
          await this.props.onAddDocLoaded(this.props.selectedDoc);
        }

        docViewer.on('blankPagesAdded', callIfDefined(this.props.onBlankPagesAdded));
        docViewer.on('blankPagesRemoved', callIfDefined(this.props.onBlankPagesRemoved));
        docViewer.on('removeAllAnnots', callIfDefined(this.props.onRemoveAllAnnots));

        annotManager.on('vaDisclaimerChanged', (show: boolean) => {
          if (!this.props.onVaDisclaimerChanged) {
            return;
          }

          return this.props.onVaDisclaimerChanged(instance.getSelectedSigner(), show);
        });

        // add event listeners on annotManager for sending annots upstream
        annotManager.on('annotationAdded', this.props.onAnnotationAdded);
        annotManager.on('annotationUpdated', this.props.onAnnotationUpdated);
        annotManager.on('annotationDeleted', this.props.onAnnotationDeleted);
        annotManager.on('widgetAdded', this.props.onWidgetAdded);
        annotManager.on('widgetUpdated', this.props.onWidgetUpdated);
        annotManager.on('widgetDeleted', this.props.onWidgetDeleted);
        annotManager.on('fieldUpdated', this.props.onFieldUpdated!);

        handleAuditTrail()({
          instance,
          config: {
            ...this.props.toolConfig,
            signatures: this.props.signatures,
            images: this.props.images,
            nsId: this.props.nsId,
            docs: this.props.docs,
          },
        });

        instance.hideMessage('Loading...');

        if (_.isFunction(this.props.onDocumentLoaded)) {
          return this.props.onDocumentLoaded(instance);
        }

        this.saveMobileScreenWidth(docViewer, viewerElement);

        if (this.state.isMobile) {
          mobileDeviceOrientation.addEventListener(
            'change',
            this.handleMobileDeviceRotation.bind(this, docViewer, viewerElement)
          );
        }
      });

      // detach all listeners
      instance.docViewer.on('documentUnloaded', () => {
        docViewer.off('removeAllAnnots');
        docViewer.off('blankPagesAdded');
        docViewer.off('blankPagesRemoved');
        annotManager.off('annotationAdded');
        annotManager.off('annotationUpdated');
        annotManager.off('annotationDeleted');
        annotManager.off('widgetAdded');
        annotManager.off('widgetUpdated');
        annotManager.off('widgetDeleted');
        annotManager.off('fieldUpdated');
        annotManager.off('vaDisclaimerChanged');

        if (this.props.onDocumentUnloaded) {
          return this.props.onDocumentUnloaded();
        }

        if (this.state.isMobile) {
          mobileDeviceOrientation.removeEventListener(
            'change',
            this.handleMobileDeviceRotation.bind(this, docViewer, viewerElement)
          );
        }
      });

      instance.docViewer.one('annotationsLoaded', () => {
        instance.annotManager.trigger('setSelectedSigner', this.props.selectedSigner);
      });

      instance.docViewer.on('annotationsLoaded', async () => {
        console.log('%cAnnotations Loaded', 'font-size:20px; color: red;');
        // Set the list of signers to assign template fields for.
        instance.annotManager.trigger('setSigners', this.props.signers);

        // Set the selected signer
        // instance.annotManager.trigger('setSelectedSigner', this.props.selectedSigner);

        // Set the currentUser
        // instance.annotManager.trigger('setCurrentUser', this.props.currentUser || this.props.userId);
        instance.annotManager.trigger('updateAnnotationPermission');
      });

      instance.docViewer.on('zoomUpdated', () => {
        if (this.state.isZooming) return;

        this.handleMobileZoomUpdate(docViewer);
      });

      if (_.isFunction(this.props.onFinishedRendering)) {
        instance.docViewer.on('finishedRendering', () => this.props.onFinishedRendering(instance));
      }

      if (docs[selectedDoc]) {
        let strippedXfdf;

        if (this.props.flattenSpecialFields) {
          strippedXfdf = await stripSpecialAnnots(this.props.xfdf[this.props.selectedDoc]);
        }

        const loadDocFrom = docs[selectedDoc].url || docs[this.props.selectedDoc].file;
        let blob;

        instance.showMessage('Loading...');

        let fileExtension = 'pdf';
        if (typeof loadDocFrom !== 'string') fileExtension = extractFileExtension(loadDocFrom);

        if (fileExtension !== 'pdf') {
          // convert the non pdf document
          const fileNameSplit = loadDocFrom?.name?.split('.') || [];
          fileNameSplit.pop();
          let docBuffer;
          if (fileExtension === 'doc') {
            try {
              const formData = new FormData();
              formData.append('files', loadDocFrom)
              const config = {
                headers: {
                  'content-type': 'multipart/form-data',
                  // 'authorization': `Bearer ${this.props.token}`
                  'authorization': 'Bearer myAccessToken'
                }
              }
              const apiResponse: any = await axios.post(`${process.env.NX_PUBLIC_API_URL}/documents/convert-to-pdf`, formData, config);
              docBuffer = new Uint8Array(apiResponse?.data[0]?.data || []);
            } catch (err: unknown) {
              const error = (err as AxiosError);
              console.error(error);
              alert('.doc file conversion error');
              docBuffer = new Uint8Array([]);
            }
          } else if (fileExtension === 'docx') {
            docBuffer = await instance.CoreControls.office2PDFBuffer(loadDocFrom, {
              l: license,
              extension: fileExtension,
              customHeaders: { Authorization: `Bearer ${this.props.token}` },
            });
          } else {
            const doc = await instance.CoreControls.createDocument(loadDocFrom, {
              l: license,
              extension: fileExtension,
              customHeaders: { Authorization: `Bearer ${this.props.token}` },
            });
            const data = await doc.getFileData({});
            docBuffer = new Uint8Array(data);
          }
          blob = new File([docBuffer], `${fileNameSplit.join('.')}.pdf`, {
            type: 'application/pdf',
          });
          fileExtension = 'pdf';
          if (this.props.onDocumentConvertCompleted) this.props.onDocumentConvertCompleted(this.props.selectedDoc, blob);
        }

        if (strippedXfdf) {
          const doc = await instance.CoreControls.createDocument(blob || loadDocFrom, {
            l: this.props.config.l,
            extension: fileExtension,
            customHeaders: { Authorization: `Bearer ${this.props.token}` },
          });

          let options = {};

          options = { xfdfString: strippedXfdf, flatten: true };
          const data = await doc.getFileData(options);
          const arr = new Uint8Array(data);

          blob = new File([arr], 'name', { type: 'application/pdf' });
        }

        const removeUndefined = R.compose(JSON.parse, JSON.stringify);

        const loadConfig = removeUndefined({
          l: this.props.config.l,
          xfdf: strippedXfdf
            || this.props.xfdf[this.props.selectedDoc]
            // eslint-disable-next-line max-len
            || '<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><pdf-info xmlns="http://www.pdftron.com/pdfinfo" version="2" import-version="3" /><fields /><annots /><pages><defmtx matrix="1,0,0,-1,0,842" /></pages></xfdf>',
          docId: selectedDoc,
          filename: selectedDoc,
          fullAPI: config.fullAPI,
          pdftronServer: this.props.pdftronServer,
          extension: fileExtension,
          flattenSpecialFields: this.props.flattenSpecialFields,
          customHeaders: this.props.token ? { Authorization: `Bearer ${this.props.token}` } : {},
          onError: (error: any) => {
            if (error.serverResponse && error.serverResponse.rawResponse) {
              const decodedError = bufferStringToJson(error.serverResponse.rawResponse);

              if (this.props.onError) {
                console.error('document failed to load', error);

                return this.props.onError({
                  message: decodedError,
                });
              }

              return instance.showErrorMessage(decodedError.message || error.message);
            }

            if (this.props.onError) {
              console.error('document failed to load', error);

              return this.props.onError(error);
            }

            return instance.showErrorMessage(error.message);
          },
        } as WebViewerConfig);

        if (this.props.onBeforeLoadDocument) {
          await this.props.onBeforeLoadDocument(instance);
        }

        await instance.loadDocument(blob || loadDocFrom, loadConfig);
      }

      if (this.props.onInit) {
        this.props.onInit(instance);
      }
    });

    return winstance.docViewer.trigger('initReady');
  }

  handleConfirmationModal = ({ message, onConfirm }: any) => {
    return this.setState({
      confirmationModal: {
        show: true,
        message,
        onConfirm,
      },
    });
  }

  handleCertModal = ({ type, pdf }: any) => {
    if (type === 'file') {
      return this.notarialCertUploadRef.current!.click();
    }

    return this.setState({ certModal: { show: true, pdf } });
  }

  componentWillUnmount = async () => {
    if (_.isFunction(this.props.unbindEvents)) {
      await this.props.unbindEvents();
    }

    if (window.getInstance && this.viewerRef.current) {
      // console.log(this.viewerRef);
      const instance = window.getInstance(this.viewerRef.current);


      if (instance) {
        instance?.docViewer?.trigger('documentUnloaded');
        instance.dispose();
      }
    }
  }

  setVaDisclaimer = (show: boolean) => this.setState({ vaDisclaimer: { show } })

  componentDidUpdate = async (prevProps: WebviewerProps) => {
    if (!this.instance) {
      return;
    }

    if (this.props.signers !== prevProps.signers) {
      this.instance.annotManager.trigger('setSigners', this.props.signers);
    }

    if (this.props.notary) {
      this.instance.setNotary(this.props.notary);
    }


    // selected document has changed. close doc and open new
    if (prevProps.selectedDoc !== this.props.selectedDoc) {
      if (!this.props.selectedDoc && _.isEmpty(this.props.docs)) {
        const doc = this.instance.docViewer.getDocument();

        if (doc) {
          this.instance.docViewer.closeDocument();
        }
      }

      if (this.props.docs[this.props.selectedDoc]) {
        // unBind any external listeners
        if (_.isFunction(this.props.unbindDocEvents)) {
          await this.props.unbindDocEvents({
            ...this.instance,
            selectedDoc: this.instance.getDocId(),
          });
        }

        // NOTE: uncomment if regression
        // await this.instance.closeDocument();
        let strippedXfdf;

        if (this.props.flattenSpecialFields) {
          strippedXfdf = await stripSpecialAnnots(this.props.xfdf[this.props.selectedDoc]);
        }

        const config = {
          ...defConfig({
            viewOnly: false,
            isAdminUser: this.props.isAdminUser,
            taggingMode: this.props.taggingMode || false,
          }),
          ..._.omit(this.props.config, ['flattenClientSide']),
        };

        const loadDocFrom = this.props.docs[this.props.selectedDoc].url || this.props.docs[this.props.selectedDoc].file;
        let blob;

        this.instance.showMessage('Loading...');

        // if (strippedXfdf) {
        //   const doc = await this.instance.CoreControls.createDocument(loadDocFrom, {
        //     l: this.props.config.l,
        //     extension: 'pdf',
        //     customHeaders: { Authorization: `Bearer ${this.props.token}` },
        //   });

        //   let options = {};

        //   options = { xfdfString: strippedXfdf, flatten: true };

        //   const data = await doc.getFileData(options);
        //   const arr = new Uint8Array(data);

        //   blob = new File([arr], { type: 'application/pdf' });
        // }

        if (this.props.onBeforeLoadDocument) {
          await this.props.onBeforeLoadDocument(this.instance, prevProps.selectedDoc);
        }

        this.instance.loadDocument(blob || loadDocFrom, {
          docId: this.props.selectedDoc,
          xfdf: strippedXfdf || this.props.xfdf[this.props.selectedDoc],
          fullAPI: config.fullAPI,
          pdftronServer: this.props.pdftronServer,
          filename: this.props.selectedDoc,
          flattenSpecialFields: this.props.flattenSpecialFields,
          l: this.props.config.l,
          extension: 'pdf',
          customHeaders: { Authorization: `Bearer ${this.props.token}` },
          onError: handleError(this.instance),
          isMobile: config.isMobile,
        });

        if (prevProps.isReadOnly !== this.props.isReadOnly) {
          this.instance.docViewer.one('documentLoaded', async () => {
            this.instance!.annotManager.setReadOnly(!!this.props.isReadOnly);
          });
        }

        return;

      }

      // NOTE: uncomment if regression
      // return this.instance.closeDocument();
    }

    // signers list has been updated
    if (prevProps.signers !== this.props.signers) {
      this.instance.annotManager.trigger('setSigners', this.props.signers);
    }

    // selected signer has been changed
    // TODO: move to bindEvents
    if (prevProps.selectedSigner !== this.props.selectedSigner) {
      this.instance.annotManager.trigger('setSelectedSigner', this.props.selectedSigner);
    }

    // if current user changed and not an admin, export consumers signatures
    // load currently selected signer's signature if it exists
    if (prevProps.currentUser !== this.props.currentUser) {
      if (!this.props.isAdminUser) {
        if (prevProps.currentUser !== '-1') {
          const sigs = await this.instance.saveSignatures(prevProps.currentUser);


          if (sigs && this.props.onSaveSignature) {
            await this.props.onSaveSignature(prevProps.currentUser, sigs);
          } else if (!sigs && this.props.onSaveSignature) {
            await this.props.onSaveSignature(prevProps.currentUser, {});
          }

          if (!this.props.isEsign) {
            await this.instance.clearSignatures();
          }
        }

        this.instance.annotManager.setCurrentUser(this.props.currentUser);
      }

      if (this.props.signatures?.[this.props.currentUser]) {
        await this.instance.loadSignatures(this.props.signatures[this.props.currentUser]);
      }
    }

    if (prevProps.showVaDisclaimer !== this.props.showVaDisclaimer) {
      if (this.props.showVaDisclaimer !== false) {
        this.instance.annotManager.trigger('vaModalOpen', []);
      } else {
        this.instance.annotManager.trigger('vaModalDismissed', []);
      }

      if (this.props.showVaDisclaimer === this.instance.getRunId()) {
        this.setVaDisclaimer(this.props.showVaDisclaimer === this.instance.getRunId());
      } else if (this.state.vaDisclaimer.show === true) {
        this.setVaDisclaimer(false);
      }
    }


    // load non-admin user signatures
    if (prevProps.signatures !== this.props.signatures && !this.props.isAdminUser) {
      if (this.props.signatures?.[this.props.currentUser]) {
        // console.log('currentUser loading signature');
        await this.instance.loadSignatures(this.props.signatures[this.props.currentUser]);
      }
    }

    // admin user status changed
    if (prevProps.isAdminUser !== this.props.isAdminUser) {
      this.instance.annotManager.setIsAdminUser(!!this.props.isAdminUser);
    }

    if (prevProps.xfdf !== this.props.xfdf) {
      if (this.props.xfdf && this.props.xfdf[this.props.selectedDoc] && this.props.isAdminUser) {
        await this.loadXfdf(this.props.selectedDoc, this.props.xfdf[this.props.selectedDoc]);
      }
    }
  }

  loadXfdf = async (docId: string, xfdf: string) => {
    if (xfdf && this.props.createAnnotation) {
      const strippedXfdf = await stripNonSpecialAnnots(xfdf);
      const annotList = await this.annotManager!.importAnnotations(strippedXfdf);

      await bPromise.mapSeries(annotList, async (annot: Annotations.Annotation) => {
        await annot.resourcesLoaded();
        annot.setModified(true);
        const xfdf = await this.annotManager!.exportAnnotCommand();

        return this.props.createAnnotation(annot.Id, {
          id: annot.Id,
          authorId: this.props.notary?.id || this.props.currentUser,
          docId: docId || this.props.selectedDoc,
          type: 'annotation',
          xfdf,
        });
      });
    }
  }

  saveMobileScreenWidth = (docViewer: DocViewer, viewerElement: HTMLElement) => {
    this.state.pages = docViewer.getPageCount();

    _.range(0, this.state.pages).forEach((pageNum) => {
      if (docViewer.getPageWidth(pageNum) > this.state.docWidth!) {
        this.state.docWidth = docViewer.getPageWidth(pageNum);
      }
    });

    this.state.screenWidth = viewerElement.offsetWidth;
    this.state.fitWidthZoom = this.state.screenWidth / this.state.docWidth!;
  }

  handleMobileZoomUpdate = (docViewer: DocViewer, hasDeviceRotated = false) => {
    const zoom = docViewer.getZoom();

    if (hasDeviceRotated || zoom < 1 && this.state.isMobile) {
      this.state.isZooming = true;
      docViewer.zoomTo(this.state.fitWidthZoom!);
      this.state.isZooming = false;
    }
  }

  handleMobileDeviceRotation = (docViewer: DocViewer, viewerElement: HTMLElement) => {
    setTimeout(() => {
      this.saveMobileScreenWidth(docViewer, viewerElement);
      this.handleMobileZoomUpdate(docViewer, true);
    }, 150);
  }

  render() {
    const config = {
      ...defConfig({
        viewOnly: false,
        isAdminUser: this.props.isAdminUser,
        taggingMode: this.props.taggingMode || false,
      }),
      ..._.omit(this.props.config, ['flattenClientSide']),
    };

    return (
      <>
        {
          this.props.isAdminUser && this.props.onDocumentChanged && this.props.showDocSelector !== false && (
            <DocSelector
              docs={this.props.docs}
              selectedDoc={this.props.selectedDoc}
              onChange={(newDocId) => {
                if (!this.instance) return;

                return this.props.onDocumentChanged({
                  ...this.instance,
                  selectedDoc: this.instance.getDocId(),
                  newDocId,
                });
              }}
            />
          )
        }

        <div
          style={{
            height: this.props.height || '100%',
            visibility: 'visible',
          }}
          data-testid='webviewer-container'
          className='webviewer-container'
          ref={this.viewerRef}
        />

        <input
          ref={this.notarialCertUploadRef}
          type='file'
          style={{ display: 'none' }}
          accept='image/*,.pdf'
          onChange={(e) => this.setState({ certModal: { show: true, type: 'file', pdf: e.target.files?.[0] } })}
        />

        <ConfirmationModal
          show={this.state.confirmationModal.show}
          message={this.state.confirmationModal.message}
          onHide={() => this.setState({ confirmationModal: { show: false } })}
          onSubmit={() => {
            this.instance!.docViewer.trigger(this.state.confirmationModal.onConfirm);

            return this.setState({ confirmationModal: { show: false } });
          }}
        />
        <CertPdfModal
          show={this.state.certModal.show}
          pdf={this.state.certModal.pdf}
          onHide={() => {
            this.notarialCertUploadRef.current!.value = '';// clear input so onchange always fires
            this.setState({ certModal: { show: false } });
          }}
          pdftronServer={this.props.pdftronServer}
          pdftronLicense={config.l}
          currentUser={this.props.currentUser}
          signatures={this.props.signatures}
          onSubmit={({ img, dataUrl }: any) => {
            this.instance!.setCertPdf({ img, dataUrl });
            this.instance!.setToolMode('NotaryCertTool');
            this.notarialCertUploadRef.current!.value = '';// clear input so onchange always fires
            this.setState({ certModal: { show: false } });
          }}
        />
        <VADisclaimerModal
          show={this.state.vaDisclaimer.show && !this.props.userPinAuthModalOpen}
          onAccept={() => this.props.onVADisclaimerSubmit(true)}
          onReject={() => this.props.onVADisclaimerSubmit(false)}
        />
        {this.state.promptModal.isOpen && R.includes(this.state.promptModal.customData.type, R.keys(tagTypes.addressTags)) && (
          <AddressPromptModal
            show={this.state.promptModal.isOpen}
            notary={this.props.notary}
            signerId={this.props.selectedSigner}
            onHide={() => this.setState({ promptModal: { isOpen: false } })}
            annotCustomData={this.state.promptModal.customData}
            currentUser={this.instance!.getSignerById(this.props.selectedSigner)}
            onSubmit={async (addressInfo) => {
              handleCreateAddressAnnotation(this.instance!,
                addressInfo,
                await this.instance!.annotManager.getAnnotationById(this.state.promptModal.annotId) as Annotations.FreeTextAnnotation,
                this.state.promptModal.customData.type);
              this.setState({ promptModal: { isOpen: false, customData: null } });
            }}
          />
        )}
      </>
    );
  }
}


export default Webviewer;
