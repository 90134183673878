import { Annotations, CoreControls, WebViewerInstance } from '@pdftron/webviewer';
import React, { ReactElement } from 'react'

import { createContext, useContext } from 'react';
import { loadSetSignatureInitials } from 'document-viewer/src/utils/pdftron/handleSignatures';

import { withParticipants } from './useParticipants';
import initUtils from 'document-viewer/src/utils/pdftron/sealPDF';
import _ from 'lodash';
import { withAuth } from './useAuth';
import { withAnnots } from './useAnnots';
import { withDocs } from './useDocs';
import initThumbnailUtils from 'document-viewer/src/utils/pdftron/renderThumbnails';
import { DocThumbnails } from 'document-viewer/src/slices/documents';
import bPromise from 'bluebird';

export interface EnlWebViewerInstance extends WebViewerInstance {
  core: any;
  getDocId: () => string;
  CoreControls: typeof CoreControls,
  setNotary: (n: any) => any;
  getSigners: (n: any) => any;
  // TODO: finish defining this. reference: vanilla-config.js:1198
}

interface ISaveDoc {
  doc: ArrayBuffer,
  xfdf: string
}

export type WebViewerContextType = {
  setInstance: (instance) => Promise<any>;
  instance?: EnlWebViewerInstance;
  annotManager?: CoreControls.AnnotationManager;
  docViewer?: CoreControls.DocumentViewer;
  coreControls?: typeof CoreControls;
  persistDoc: () => ISaveDoc | any;
  applyTag: (annot: Annotations.Annotation) => any;
  myUnreviewedTags: any[];
  setMyUnreviewedTags: (annots: any[]) => any;
  utils?: ReturnType<typeof initUtils>,
  zoomLevel: number;
  currentPage: number;
  numberOfPages: number;
};

export const WebViewerContext = createContext<WebViewerContextType>({
  async setInstance() { },
  setMyUnreviewedTags() { },
  persistDoc() { },
  applyTag() { },
  myUnreviewedTags: [],
  zoomLevel: 100,
  currentPage: 0,
  numberOfPages: 0,
});

export const THUMBNAIL_SIZE = 150;

export const hasBlueText = (annot) => annot.TextColor && annot.TextColor.B > 200 && annot.TextColor.R < 200 && annot.TextColor.G < 200;


interface Props {
  sessionId: string;
  participants: any;
  selectedParticipantId: string | null;
  lockDocument: (val: boolean) => void;
  checkAuth: (sessionId: string, pId: string) => void;
  setPendingAnnot: (annot: Annotations.Annotation) => void;
  clearPendingAnnot: () => void;
  addThumbnail: (docId: string, pageIndex: number, page?: HTMLCanvasElement, annot?: HTMLCanvasElement) => void;
  currentDocThumbnails: DocThumbnails;
  children: ReactElement;
}

class _WebViewerProvider extends React.Component<Props> {
  state = {
    instance: null,
    zoomLevel: 100,
    currentPage: 0,
    numberOfPages: 0,
  }

  utils: ReturnType<typeof initUtils>
  instance: WebViewerInstance;
  currentPage: number;
  numberOfPages: number;

  setCurrentPage = (page: number): void => {
    this.setState({
      ...this.state,
      currentPage: page,
    });
  };

  setNumberOfPages = (num: number): void => {
    this.setState({
      ...this.state,
      numberOfPages: num,
    });
  };

  applyTag = async (annot) => {
    const inst = this.state.instance || (window as unknown as any).instance as WebViewerInstance;

    if (!inst || !inst.annotManager) {
      return this.props.checkAuth(this.props.sessionId, annot.CustomData.signerId)
    }

    const selectedParticipantId = inst.annotManager.getCurrentUser();

    // if there is no signer selected
    if (!selectedParticipantId) {
      this.props.setPendingAnnot(annot)
      return this.props.checkAuth(this.props.sessionId, annot.CustomData.signerId)
    }

    // if tag doesnt belong to current signer
    if (!this.props.participants[annot.CustomData.signerId] || selectedParticipantId !== annot.CustomData.signerId) {
      // set as pending annot so when auth modal is dismissed, we can apply the tag
      this.props.setPendingAnnot(annot)
      return this.props.checkAuth(this.props.sessionId, annot.CustomData.signerId)
    }

    const p = this.props.participants[selectedParticipantId];

    // make sure signature is loaded
    await loadSetSignatureInitials(p, this.state.instance);

    if (annot.CustomData?.flags?.required) annot.setCustomData('applied', true);

    annot.setCustomData('reviewed', true);
    inst.annotManager.trigger('applyTag', [annot, {
      color: hasBlueText(annot)
        ? new inst.Annotations.Color(0, 0, 255, 1)
        : new inst.Annotations.Color(0, 0, 0, 1),
      forceNoResize: false,
    }]);

    this.props.clearPendingAnnot();
  };


  persistDoc = async () => {
    if (!this.instance && !this.state.instance) {
      console.info('instance is not defined')
      return;
    }

    return this.utils.getPersistData();
  }

  thumbnailUtils: { renderAnnot: (pageIndex: any) => any; generateThumbnail: (pageIndex: any) => Promise<unknown>; };



  componentDidUpdate(prevProps) {

    this.utils = initUtils(this.state.instance)

    if (this.state.instance) {

      // if select participant changed
      if (prevProps.selectedParticipantId !== this.props.selectedParticipantId) {
        this.state.instance.annotManager.setCurrentUser(this.props.selectedParticipantId);

        const p = this.props.participants[this.props.selectedParticipantId] || {};
        const { signature, initials } = p;
        if (signature && initials) {

          // if document not loaded yet, then load sig/initials when document loads
          if (!this.state.instance.docViewer.getDocument()) {
            this.state.instance.docViewer.one('documentLoaded', () => {
              loadSetSignatureInitials({ signature, initials }, this.state.instance);
            })
          } else {
            loadSetSignatureInitials({ signature, initials }, this.state.instance);
          }
        }
      }

    }

  }


  setInstance = async (instance: EnlWebViewerInstance) => {
    this.instance = instance;
    this.utils = initUtils(instance)
    this.thumbnailUtils = initThumbnailUtils(instance.core, this.props.currentDocThumbnails);

    return this.setState({ instance }, () => {
      const onUserChanged = async () => {
        const p = this.props.participants[this.props.selectedParticipantId] || {};
        const { signature, initials } = p;
        if (signature && initials) {
          if (!this.state.instance.docViewer.getDocument()) {
            this.state.instance.docViewer.one('documentLoaded', () => {
              loadSetSignatureInitials({ signature, initials }, this.state.instance);
            });
          } else {
            loadSetSignatureInitials({ signature, initials }, this.state.instance);
          }
        }
      }

      this.state.instance.docViewer.one('documentLoaded', () => {
        instance.docViewer.on('zoomUpdated', (num) => {
          this.setState({ zoomLevel: num })
        });
      });

      instance.annotManager.on('currentUserChanged', _.debounce(onUserChanged, 50, { trailing: true }))


      // load document thumbnails when document is loaded
      instance.core.addEventListener('documentLoaded', async () => {
        this.setCurrentPage(1);
        this.setNumberOfPages(instance.docViewer.getPageCount());
        this.props.lockDocument(true);
        instance.docViewer.on('pageNumberUpdated', (pageNum: number): void => {
          this.setCurrentPage(pageNum);
        });

        const pgCount = instance.docViewer.getPageCount();
        const util = initThumbnailUtils(instance.core, this.props.currentDocThumbnails);
        const docId = instance.getDocId()
        const rng = _.range(0, pgCount);

        bPromise.map(rng, async (pgIndex) => {
          if (this.props.currentDocThumbnails[pgIndex] && this.props.currentDocThumbnails[pgIndex].page) {
            return this.props.currentDocThumbnails[pgIndex];
          }
          const thumb = await util.generateThumbnail(pgIndex);
          return this.props.addThumbnail(docId, pgIndex, thumb.page, thumb.annot)
        });

        this.props.lockDocument(false);

      });

    })
  }


  render() {
    return (
      <WebViewerContext.Provider
        value={{
          setInstance: (instance) => this.setInstance(instance),
          instance: this.state.instance,
          myUnreviewedTags: [],
          persistDoc: this.persistDoc,
          setMyUnreviewedTags: () => { },
          applyTag: this.applyTag,
          annotManager: this.state.instance?.annotManager,
          docViewer: this.state.instance?.docViewer,
          coreControls: this.state.instance?.CoreControls,
          utils: initUtils(this.state.instance),
          zoomLevel: this.state.zoomLevel,
          currentPage: this.state.currentPage ?? 0,
          numberOfPages: this.state.numberOfPages ?? 0,
        }}
      >
        {this.props.children}
      </WebViewerContext.Provider>
    )
  }
}


export const useWebViewer = () => useContext(WebViewerContext);
export const WebViewerProvider = withDocs(withAnnots(withAuth(withParticipants(_WebViewerProvider))));
