import React, { createContext, useContext } from 'react';
import firebase from 'document-viewer/src/lib/clients/firebase';

const FirebaseContext = createContext(firebase);

export const useFirebase = () => useContext(FirebaseContext);

export function FirebaseProvider({ children }) {
  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
}

