import { useTheme, useMediaQuery } from '@material-ui/core';

/**
 * This custom hook is used to determine if the screen is mobile or not.
 *
 * @returns {boolean} Whether the screen is mobile or not.
 */
const useIsMobileScreen = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  return isMobileScreen;
};

export default useIsMobileScreen;
