import React, { useCallback } from 'react';
import Doc from './Doc';
import { sortBy } from '@enotarylog/ramda';
import { FlexAccordion, FlexAccordionSummary, FlexAccordionDetails } from 'document-viewer/src/components/Accordion/flex';
import { useStyles } from './styles';
import Tooltip from 'document-viewer/src/components/Tooltip';
import clsx from 'clsx';

export default function DocListSection({
  collapsedSection,
  setCollapsedSection,
  currentDocIsCompleted,
  collapsedCurrentDoc,
  setCollapsedCurrentDoc,
  currentDocId,
  docs,
  docsInSection,
  handleExpand,
  currentPageIndex,
  numPages,
  onPageSelected,
  title,
  toolTipTitle,
  summaryClass,
  summaryTextClass,
  completedNoTagDocList,
  setLoadingThumbnails,
  isCompletedSection = false
}) {
  const classes = useStyles({ collapsedSection });

  const sortDocs = useCallback((docs) => {
    const sorted = sortBy((doc) => doc.order, docs || []);

    const hasCurrentDocId = docs.some((doc) => doc.id === currentDocId);
    // If there is a doc open, it MUST be at the top, so force that to happen
    if (currentDocId && !collapsedCurrentDoc && hasCurrentDocId) {
      const [currentDoc] = sorted.splice(sorted.findIndex(({ id }) => id === currentDocId), 1);
      sorted.unshift(currentDoc);
    }
    return sorted;
  }, [collapsedCurrentDoc, currentDocId]);

  const currentDocIsInThisSection = currentDocIsCompleted === isCompletedSection;

  const sortedDocsInSection = sortDocs(docsInSection);
  const docsThatAreNotOpened = !collapsedCurrentDoc && currentDocIsInThisSection
    ? sortedDocsInSection.slice(1)
    : sortedDocsInSection;

  const commonDocProps = { handleExpand, docs, currentPageIndex, numPages, onPageSelected, currentDocId };

  return (
    <section>
      <FlexAccordion
        square
        expanded={!collapsedSection}
        onChange={(_, isExpanded) => {
          setCollapsedSection(!isExpanded);
          currentDocIsInThisSection && setCollapsedCurrentDoc(true);
        }}
        className={clsx(classes.grow, classes.clipOverflow, classes.docListSectionAccordion)}
      >
        <Tooltip title={toolTipTitle}>
          <div>
            <FlexAccordionSummary
              className={clsx(classes.docHeader, summaryClass, isCompletedSection)}
              data-testid={`DocListSectionSummary: ${isCompletedSection ? 'Completed' : 'To Sign'}`}
            >
              <h2
                className={clsx(classes.docHeaderText, summaryTextClass)}
              >
                {title}
              </h2>
            </FlexAccordionSummary>
          </div>
        </Tooltip>
        <FlexAccordionDetails classes={{ root: classes.thumbnailsAccordion }}>
          {!collapsedCurrentDoc && currentDocIsInThisSection && (
            <div className={classes.accordionDetailsDiv}>
              <Doc
                completedNoTagDocList={completedNoTagDocList}
                setLoadingThumbnails={setLoadingThumbnails}
                isExpanded={currentDocId === sortedDocsInSection[0].id && !collapsedCurrentDoc}
                key={sortedDocsInSection[0].id}
                doc={sortedDocsInSection[0]}
                {...commonDocProps}
                hiddenHeader={false}
              />
            </div>
          )}
          {!collapsedSection && docsThatAreNotOpened.length > 0 && (
            <div className={clsx(classes.noScrollbarCorner, classes.restOfDocs)}>
              {docsThatAreNotOpened.map((doc: any) => (
                <Doc
                  completedNoTagDocList={completedNoTagDocList}    
                  setLoadingThumbnails={setLoadingThumbnails}
                  isExpanded={currentDocId === doc.id && !collapsedCurrentDoc}
                  key={doc.id}
                  doc={doc}
                  {...commonDocProps}
                  hiddenHeader={false}
                  inactive={true}
                />
              ))}
            </div>
          )}

        </FlexAccordionDetails>
      </FlexAccordion>
    </section>
  );
}
