import { ApolloClient } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GET_STEP_PAGE } from 'document-viewer/src/lib/gql/step-page';

import * as _ from 'lodash';
import { AppThunk, RootState } from 'document-viewer/src/store';
import { NotarySessionUser, User } from 'document-viewer/src/utils/types/notary-session-user';


export interface StepPageData {
  type: string;
  page: string;
  step?: string;
  participant?: NotarySessionUser;
  witnesses: number;
}

export interface StepPageState {
  loading: boolean;
  flowType?: string;
  page?: string;
  step?: string;
  participant?: Omit<NotarySessionUser, 'user'>;
  user?: User;
}



export const initialState: StepPageState = {
  loading: false,
  page: undefined,
  flowType: undefined,
  step: undefined,
  participant: undefined,
  user: undefined,
};


const slice = createSlice({
  name: 'stepPage',
  initialState,
  reducers: {
    setLoading(state: StepPageState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setStepPageData(state: StepPageState, action: PayloadAction<StepPageData>) {
      state.page = action.payload.page;
      state.step = action.payload.step;
      state.flowType = action.payload.type;
      state.participant = _.omit(action.payload.participant, ['user']);
      state.user = action.payload.participant?.user;
    },
  },
});

export const { setStepPageData } = slice.actions;

export const getStepPageData = (id: string, sessionId?: string): AppThunk => async (dispatch, __, client: ApolloClient<any>) => {
  dispatch(slice.actions.setLoading(true));
  const { data } = await client.query({
    query: GET_STEP_PAGE,
    variables: { transactionId: id, sessionId },
    fetchPolicy: 'no-cache'
  });
  dispatch(slice.actions.setStepPageData(data.getStepPage))
  dispatch(slice.actions.setLoading(false));
};



export const reducer = slice.reducer;
export const selector = (state: RootState): StepPageState => state.stepPage
export default slice;

