
exports.__esModule = true;
exports.defaultTheme = exports.muiColorPalettes = exports.ENABLE_REDUX_DEV_TOOLS = void 0;
exports.ENABLE_REDUX_DEV_TOOLS = true;
exports.muiColorPalettes = ['primary', 'secondary', 'error', 'warning', 'info', 'success'];
exports.defaultTheme = {
  primaryColor: '#ed5f42',
  secondaryColor: '#507f91',
  errorColor: '#eb5757',
  warningColor: '#ff9800',
  infoColor: '#2196f3',
  successColor: '#4caf50',
  tonalOffset: 0.2,
  contrastThreshold: 3,
  borderColor: '#4461FB',
  headerTextColor: '#000000',
  background: '#EEF3F4',
  secondaryBackground: '#f0f0f0',
  icons: {},
  textColor: '#2d2d30',
  tabTextColor: '#000000',
  iconColor: '#57a4ff',
  textButtonColor: '#57a4ff',
  fontType: 'Poppins',
  onComplete: ['toast', 'confetti'],
  dialogTitle: '#F4F6F8',
  dialogSecondaryText: '#546E7A',
  headerBackground:'#fff',
  dialogTextColor: '#263238',
};

exports.ENL_LOGO = './static/logo.png';


exports.defaultData = {
  notaryAutoScrollEnabled: true,
  sigFonts: [],
  searchEnabled: true,
  toolTipsEnabled: true,
  zoomLabelEnabled: true,
  toolsLabelEnabled: true,
  deletableAnnotsEnabled: true,
  sideBarOpenCloseStyling: 'icon',
  signerAutoScrollEnabled: true,
  toastNotificationsEnabled: true,
  signatureAndInitialsInOneStep: false,
}

exports.stateStepMapping = {
  equipment_check: {
    tabName: 'Equipment Check',
    explanation: {
      title: 'Let\'s make sure your system is ready to go',
      description: 'For the best experience we will need to check a few things on your end including camera, microphone, and speakers.',
    },
  },
  pending_kba_start: {
    tabName: 'Personal Information',
    explanation: {
      title: 'Complete Your Personal Information',
      description: 'Fill in your personal information below so that we can verify your identity',
    },
  },
  pending_kba_verify: {
    tabName: 'Personal Quiz',
    explanation: {
      title: 'Personal Quiz',
      description: `Complete the quiz below so we can identify you before your session. You have ${+process.env.NX_MILLISECONDS_TO_ANSWER_KBA / 60000} minutes to complete the quiz and must pass to move forward.`,
    },
  },

  pending_doc_verify: {

    tabName: 'ID Check',
    explanation: {
      title: 'ID Check',
      description: 'Show us proof of your identity so that we can have proof of who you legally are on record.',
    },
  }

}
