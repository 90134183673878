import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction, Action } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Participant } from 'document-viewer/src/utils/types/participant';
import { AppThunk, RootState } from 'document-viewer/src/store';
import { checkAuth } from './authentication';
import { UPDATE_PARTICIPANT, AGREE_TO_LEGAL_STUFF } from 'document-viewer/src/lib/gql/participant';
import { clearPendingAnnot } from './annotations';



export interface ParticipantsState {
  loading: boolean;
  participants: {
    byId: Record<string, Participant>,
    allIds: string[]
  };
  selectedParticipant: null | string;
  pendingSelection: null | string;
  promptPinCode: boolean;
  showSwitch: boolean;
}

const initialState: ParticipantsState = {
  loading: false,
  participants: {
    byId: {},
    allIds: []
  },
  selectedParticipant: null,
  pendingSelection: null,
  promptPinCode: false,
  showSwitch: false
};



export interface ParticipantPinCodes {
  [key: string]: string
}


const slice = createSlice({
  name: 'participants',
  initialState,
  reducers: {
    setSelectedParticipant(state: ParticipantsState, action: PayloadAction<{ id: string }>) {
      const pId = _.find(state.participants.allIds, (pId) => _.isEqual(pId, action.payload.id));
      if (!pId) {
        return;
      }
      state.pendingSelection = null;
      state.promptPinCode = false;
      state.selectedParticipant = action.payload.id;
    },
    setPendingSelection(state: ParticipantsState, action: PayloadAction<{ id: string | null }>) {
      if (!action.payload.id) {
        state.pendingSelection = null;
        state.promptPinCode = false;
        return;
      }

      const pId = _.find(state.participants.allIds, (pId) => _.isEqual(pId, action.payload.id));
      if (!pId) {
        return;
      }
      state.pendingSelection = action.payload.id;
    },
    setParticipants(state: ParticipantsState, action: PayloadAction<{ participants: Participant[] }>) {
      const { payload } = action;
      const pinCodes = JSON.parse(localStorage.getItem('pinCodes') || '{}');

      state.participants.byId = _.reduce(payload.participants, (acc, p) => ({
        ...acc,
        [p.id]: { ...p, pinCode: pinCodes[p.id] }
      }), state.participants.byId);

      const participants = _.chain(payload.participants)
        .sortBy('order')
        .value();

      state.participants.allIds = _.uniq([...state.participants.allIds, ..._.map(participants, 'id')]);
    },
    setParticipantPinCodes(state: ParticipantsState, action: PayloadAction<ParticipantPinCodes>) {
      const { payload } = action;
      state.participants.byId = _.reduce(payload, (participants, pinCode, pId) => (!participants[pId])
        ? participants
        : {
          ...participants,
          [pId]: {
            ...participants[pId],
            pinCode
          }
        },
      state.participants.byId,
      );
    },
    updateParticipantSigInitials(state: ParticipantsState, action: PayloadAction<{ id: string, signature: string, initials: string, textColor: string }>) {
      const { payload } = action;
      const { id, signature, initials, textColor } = payload;

      state.participants.byId[id] = {
        ...state.participants.byId[payload.id],
        signature,
        initials,
        textColor,

      };
    },
    updateParticipantStatus(state: ParticipantsState, action: PayloadAction<{ id: string, participant: Participant }>) {
      const { id, participant } = action.payload;
      if (state.participants.byId[id]){
        state.participants.byId[id] = {
          ...state.participants.byId[id],
          ...participant
        };
      }
    },
    setLoading: (state: ParticipantsState, action: PayloadAction<boolean>) => {
      state.loading = !!action.payload;
    },
    setPromptPinCode: (state: ParticipantsState, action: PayloadAction<boolean>) => {
      state.promptPinCode = !!action.payload;
    },
    setShowSwitch: (state: ParticipantsState, action: PayloadAction<boolean>) => {
      state.showSwitch = !!action.payload;
    },
  }
});

export const reducer = slice.reducer;
export const selector = (state: RootState) => state.participants;


export const setParticipants = (participants: Participant[]): AppThunk => (dispatch, getState) => {
  dispatch(slice.actions.setParticipants({ participants }) as Action)

  // if there is only one participant, then immediately make them authenticate
  if (participants.length === 1) {
    const state = getState();
    dispatch(checkAuth(state.transaction.esignId, participants[0].id))
  }
};

export const setSelectedParticipant = (pId: string): Action => slice.actions.setSelectedParticipant({ id: pId }) as Action;

export const setPendingSelection = (pId: string): AppThunk => (dispatch, getState) => {
  // clear if called with null
  if (!pId || pId === null) {
    dispatch(clearPendingAnnot());
    return dispatch(slice.actions.setPendingSelection({ id: null }) as Action);
  }
  const { pinCode, signature, initials } = getState().participants.participants.byId[pId]
  const allParticipantIds = getState().participants.participants.allIds

  if (allParticipantIds.length === 1) {
    if (!signature || !initials) {
      dispatch(slice.actions.setPendingSelection({ id: pId }) as Action);
    } else {
      dispatch(slice.actions.setSelectedParticipant({ id: pId }) as Action);
    }
  } else {
    // make the sigpad modal show up
    if (!pinCode || !signature || !initials) {
      dispatch(slice.actions.setPendingSelection({ id: pId }) as Action);
    } else {
      dispatch(slice.actions.setPendingSelection({ id: pId }) as Action);
      dispatch(slice.actions.setPromptPinCode(true))
    }
  }
  return null;
}


export const setParticipantPinCodes = (pinCodes: ParticipantPinCodes) => slice.actions.setParticipantPinCodes(pinCodes);

export const setShowSwitch = (val: boolean) => slice.actions.setShowSwitch(val);


export const updateParticipantSignatureInitials = (applyTag) => (
  pId: string,
  signature: string,
  initials: string,
  textColor: string,
  pinCode: string,
  setSelected = true,
  agreeToLegalStuff = false,
): AppThunk => async (dispatch, getState, client) => {

  const transactionId = getState().transaction.id;
  const pendingAnnot = getState().annotations.pendingAnnot;

  dispatch(slice.actions.setLoading(true))
  const {
    data: { updateParticipant }
  } = await client.mutate({
    mutation: UPDATE_PARTICIPANT,
    variables: {
      participant: {
        id: pId,
        signature,
        initials,
        textColor
      },
      transactionId,
      agreeToLegalStuff,
    }
  });

  dispatch(slice.actions.updateParticipantSigInitials({ ...updateParticipant, pinCode }))
  dispatch(slice.actions.setParticipantPinCodes({ [pId]: pinCode }))

  dispatch(slice.actions.setLoading(false));
  if (setSelected) {
    dispatch(slice.actions.setSelectedParticipant({ id: pId }));
    if (pendingAnnot && applyTag) {
      await applyTag(pendingAnnot);
    }
  }
};

export const updateParticipantStatus = (id: string, participant): AppThunk => (dispatch) => dispatch(slice.actions.updateParticipantStatus({ id, participant }))

export const agreeToLegalStuff = (participantId: string, cb: () => void): AppThunk => async (dispatch, getState, client) => {
  const transactionId = getState().transaction.id;
  dispatch(slice.actions.setLoading(true));
  await client.mutate({
    mutation: AGREE_TO_LEGAL_STUFF,
    variables: {
      participantId,
      transactionId,
    },
  });
  dispatch(slice.actions.setLoading(false));
  return await cb();
};

export default slice;
