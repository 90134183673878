import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/styles/makeStyles';
import { divideLength } from 'document-viewer/src/utils/styles/lengthMath';

const CENTERED_SPINNER_SIZE = '40px';

const useStyles = makeStyles(() => ({
  centeredSpinner: {
    flexGrow: 1,
    position: 'absolute',
    top: `calc(50% - ${divideLength(CENTERED_SPINNER_SIZE, 2)})`,
    left: `calc(50% - ${divideLength(CENTERED_SPINNER_SIZE, 2)})`,
  },
}));

export const CenteredSpinner = () => {
  const classes = useStyles();

  return (
    <div className={classes.centeredSpinner}>
      <CircularProgress size={CENTERED_SPINNER_SIZE} />
    </div>
  );
};

export default CircularProgress;
