import trimCanvas from 'trim-canvas';
import FontFaceObserver from 'fontfaceobserver';

export const textToCanvas = async (fontName, text, color) => {
  const font = new FontFaceObserver(fontName);
  await font.load();


  const canvas = document.createElement('canvas');

  canvas.width = 2000;
  canvas.height = 2000;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return canvas;
  }
  ctx.font = `96px ${fontName}`;
  ctx.fillStyle = color;

  // NOTE: set a max width to avoid cut off
  ctx.fillText(text, 20, 500, 1000);
  ctx.fillStyle = color;
  ctx.textAlign = 'center';

  trimCanvas(canvas);

  return canvas;
};

export const getInitials = (name) => {
  if (!name) {
    return ''
  }
  let initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  return initials;
}

export function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
