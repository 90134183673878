import { useCallback, useEffect, useRef, useState } from 'react';
import { EnlWebViewerInstance } from './useWebViewer';

export interface MinimalWvCoreSearchResult {
  result_str: string;
  ambient_str: string;
  page_num: number;
  quads: Record<string, number>;
  // There are more things missing from here, but hey, this is a minimal type and I don't have time to add the rest
}

export type DocSearch = {
  onSearch(query: string): void;

  lastSearchQuery: string;
  searchingThroughDocId: string;

  numResults: number;
  resultIndex: number;

  onRequestedNextResult(): void;
  onRequestedPrevResult(): void;
};

export default function useDocSearch(instance?: EnlWebViewerInstance): DocSearch {
  const [results, setResults] = useState<MinimalWvCoreSearchResult[]>([]);
  const [resultIndex, setResultIndex] = useState<number>(0); // An index into results
  const currentSearchQuery = useRef('');
  const core = instance?.core;
  const docId = instance && instance.getDocId();

  const resetSearchTo = useCallback((newQuery) => {
    if (!core) return;
    core.clearSearchResults();
    // Overwrite the last query so that if search results are found for it while this search happens, it won't run setResults
    currentSearchQuery.current = newQuery;
  }, [core]);

  const onSearch = useCallback((searchQuery) => {
    if (!core) return;
    resetSearchTo(searchQuery);

    if (!searchQuery) {
      setResults([]);
      return;
    }

    const { e_whole_word, e_page_stop, e_highlight } = core.getSearchMode();
    const resultsFound: MinimalWvCoreSearchResult[] = [];
    core.textSearchInit(searchQuery, e_page_stop | e_highlight | e_whole_word, {
      fullSearch: true,
      onDocumentEnd() {
        // If nobody started searching while this search was already in progress, then set the things needed in state:
        if (currentSearchQuery.current === searchQuery) {
          if (resultsFound[0]) {
            core.displaySearchResult(resultsFound[0]);
          }
          setResultIndex(0);
          setResults(resultsFound);
        }
      },
      onResult(result: MinimalWvCoreSearchResult) {
        resultsFound.push(result);
      },
    });
  }, [core, resetSearchTo]);

  const increaseResultIndexBy = useCallback((amount) => {
    setResultIndex((index) => {
      let newIndex = (index + amount) % results.length; // % provides wrap around if newIndex is too high for the list of results
      if (newIndex < 0) newIndex = results.length + newIndex; // Wrap around if newIndex is -1
      if(results[newIndex]){
        core.displaySearchResult(results[newIndex]); // Actually highlight the result
      }
      return newIndex;
    });
  }, [core, results]);

  // If the docId changes, reset and stop showing results for the previous doc
  useEffect(() => {
    resetSearchTo('');
    setResults([]);
  }, [core, docId, resetSearchTo]);

  return {
    onSearch,
    numResults: results.length,
    resultIndex,
    lastSearchQuery: currentSearchQuery.current,
    searchingThroughDocId: docId,
    onRequestedNextResult: useCallback(() => increaseResultIndexBy(1), [increaseResultIndexBy]),
    onRequestedPrevResult: useCallback(() => increaseResultIndexBy(-1), [increaseResultIndexBy]),
  }
}
