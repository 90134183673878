import { gql } from '@apollo/client';

// check if user is still authenticated
export const CHECK_AUTH = gql`
  query($auth: String!) {
    checkAuth(auth: $auth)
  }
`;

// mark user as authed in redis
export const SET_AUTH = gql`
  mutation($auth: String!) {
    setAuth(auth: $auth)
  }
`;

// send verification code to user
export const SEND_VERIFICATION_CODE = gql`
  mutation($sessionId: String!, $participantId: String!, $type: VerificationTypeEnum!) {
    sendVerificationCode(sessionId: $sessionId, participantId: $participantId, type: $type)
  }
`;

// check 2fa code
export const VERIFY_CODE = gql`
  mutation($sessionId: String!, $code: String!, $participantId: String!) {
    verifyCode(sessionId: $sessionId, code: $code, participantId: $participantId)
  }
`;

// check personal password
export const VERIFY_PERSONAL_PASSWORD = gql`
  mutation($participantId: String!, $personalPasswordAnswer: String!) {
    verifyPersonalPassword(participantId: $participantId, personalPasswordAnswer: $personalPasswordAnswer)
  }
`;

// mark user.emailVerified as true
export const UPDATE_PARTICIPANT = gql`
  mutation($participant: ParticipantUpdateInput!, $transactionId: String!) {
    updateParticipant(participant: $participant, transactionId: $transactionId) {
      id
      emailVerified
      signature
      initials
      textColor
    }
  }
`;


export const VERIFY_PIN_CODE = gql`
  mutation($participantId: String!, $docPinCode: String!) {
    verifyDocPin(participantId: $participantId, docPinCode: $docPinCode)
  }
`;
