import React, { FC, useState } from 'react';
import {
  Grid,
  TextField,
  Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDebounce } from 'react-use';
import { useSetSigInitStyles, useTypeItInStyles } from './styles';
import { textToCanvas, getInitials } from './utils'
import TextPreview from './TextPreview';
import FontAndColorSelectors from './FontAndColorSelectors';

const convert = async (fontName: string, text: string, color: string) => {
  const canvas = await textToCanvas(fontName, text, color);
  return { image: canvas.toDataURL(), canvas };
};

export interface TypeItInProps {
  type?: 'signature' | 'initials';
  color?: 'blue' | 'black';
  setColor(color: 'blue' | 'black'): void;
  onChange(type: 'signature' | 'initials', payload: { image: string; canvas?: HTMLCanvasElement }): void;
  participantName: string;
  fontFamilies: string[];
  fontFamily: string;
  setFontFamily(font: string): void;
  isVisible: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TypeItIn: FC<TypeItInProps> = ({ type, color, setColor, onChange, participantName, fontFamilies, fontFamily, setFontFamily, isVisible }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useTypeItInStyles();
  const setSigInitClasses = useSetSigInitStyles();
  const bothSigAndInitials = !type;
  const [name, setName] = useState<string>(type === 'signature' || bothSigAndInitials ? participantName : getInitials(participantName));

  useDebounce(async () => {
    if (name) {
      if (bothSigAndInitials) {
        onChange('signature', await convert(fontFamily, name, color));
        onChange('initials', await convert(fontFamily, getInitials(name), color));
      } else {
        onChange(type, await convert(fontFamily, name, color));
      }
    } else {
      const blank = {
        image: '',
        canvas: null,
      };
      if (bothSigAndInitials) {
        onChange('signature', blank);
        onChange('initials', blank);
      } else {
        onChange(type, blank);
      }
    }
  }, 250, [name, color, fontFamily]);

  return (
    <form>
      <Grid
        container
        direction='column'
      >
        {
          !bothSigAndInitials ? (
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              className={styles.topRow}
              spacing={isSmallScreen ? 2 : 5}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  data-testid={`typeitin-${type}`}
                  id={`typeitin-${type}`}
                  label={type === 'signature' ? 'Full Name' : 'Initials'}
                  placeholder={`Confirm your ${type}`}
                  value={name}
                  disabled
                  onChange={(event) => setName(event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FontAndColorSelectors
                  canChooseFont
                  font={fontFamily}
                  setFont={setFontFamily}
                  fontChoices={fontFamilies}
                  canChooseColor={type === 'signature'}
                  color={color}
                  setColor={setColor}
                  showLabels
                />
              </Grid>
            </Grid>
          ) : null
        }
        <Grid
          item
          container
        >
          <Grid
            item
            xs={bothSigAndInitials ? 8 : 12}
            className={bothSigAndInitials ? setSigInitClasses.rightSideSpacingMdUp : undefined}
          >
            <TextPreview
              nameFont={fontFamily}
              name={name}
              color={color}
              type={bothSigAndInitials ? 'signature' : type}
              isVisible={isVisible}
            />
          </Grid>
          {
            bothSigAndInitials ? (
              <Grid
                item
                xs={4}
              >
                <TextPreview
                  nameFont={fontFamily}
                  name={getInitials(name)}
                  color={color}
                  type='initials'
                  isVisible={isVisible}
                />
              </Grid>
            ) : null
          }
        </Grid>
      </Grid>
      {
        bothSigAndInitials ?
          <div />
          :
          <Box mb={2} />
      }

    </form>
  );
};

export default TypeItIn;
