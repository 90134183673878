/* eslint-disable no-param-reassign */
import debug from 'debug';
import bPromise from 'bluebird';
import * as R from 'ramda';
import _ from 'lodash';
import { Instance, Annot } from '../../viewer';
import { AppStateProviderContext } from '@enotarylog/pdftron-webviewer/notarize/lib/hooks/AppState';

const log = debug('vanilla:lib:helpers:import');

/**
 * imports annotations pushed from firebase for non-widget annotations via importAnnotCommand.
 * If `corrId` present, it will hide the annotation with that id
 */
export const importFbaseVal = (instance: Instance) => async ({ val }: any) => {
  const { selectedDoc, annotManager } = instance;

  log('annotation created/modified');

  if (selectedDoc !== val.docId) {
    log('selectedDoc !== val.docId', {
      selectedDoc,
      annotDocId: val.docId,
      annotId: val.id,
    });

    return;
  }

  log(`importing: ${val.id}`);

  // Import the annotation based on xfdf command
  const annots = await annotManager.importAnnotCommand(val.xfdf);
  const currentUser = annotManager.getCurrentUser();
  const isAdmin = annotManager.getIsAdminUser();

  // eslint-disable-next-line no-param-reassign

  if (!annots.length) {
    return;
  }

  await bPromise.mapSeries(annots, async (annot: Annot) => {
    if (annot.Id !== val.id) {
      return;
    }

    if (_.isArray(annot?.CustomData?.color)) {
      annot.FillColor = new instance.Annotations.Color(...annot.CustomData.color as [number, number, number, number]);
    }

    // templates should be locked if user is a signer
    if (annot.Subject.includes('Template') && !isAdmin) {
      annot.Locked = true;
    }


    annot.authorId = annot.Author = annot.Author || val.authorId;

    // Resize text annots after typing
    // TODO: FIX THIS
    // if (annot instanceof instance.Annotations.FreeTextAnnotation && !annot.Subject.includes('Template')) {
    //   log('resizing annot because it is a free text and not a template', annot);
    //   const doc = docViewer.getDocument();
    //   const pageInfo = doc.getPageInfo(annot.PageNumber - 1);
    //   const pageMatrix = doc.getPageMatrix(annot.PageNumber - 1);

    //   annot.fitText(pageInfo, pageMatrix);
    //   annot.Width += 10;
    // }

    // annot.setContents('OMG TIPPY TP SHAPE');
    if (annot instanceof instance.Annotations.FreeTextAnnotation && _.isEmpty(annot.getContents())) {
      if (currentUser !== (annot as Annot).authorId) {
        annot.setContents(annot?.CustomData?.initialText || 'Insert text here');
      }
    }

    await annotManager.redrawAnnotation(annot);
  });

  // hide corresponding annotation if corrId specified.
  if (val.corrId || val.hidden) {
    const annot = annotManager.getAnnotationById(val.corrId || val.id);

    if (annot && !annot.Hidden) {
      await annotManager.hideAnnotation(annot);
      annot.Hidden = true;
      await annotManager.redrawAnnotation(annot);
    }
  }
};

/**
 * deletes annot based on value pushed from firebase
 * if `corrId` specified, then it will find and show annotation with that id if it is hidden
 */
export const delFbaseVal = ({ annotManager }: any) => async ({ key, val }: any) => {
  await annotManager.importAnnotCommand(`<delete><id>${key}</id></delete>`);

  if (val.corrId) {
    const annot = annotManager.getAnnotationById(val.corrId);

    if (annot && annot.Hidden) {
      annotManager.showAnnotation(annot);
    }
  }
};


/**
 * deletes widget annot based on value pushed from firebase if widget exists
 */
export const delWidgetFbaseVal = ({ annotManager }: Instance) => async ({ key }: any) => {
  const widget = annotManager.getWidgetById(key);

  if (widget) {
    annotManager.deleteAnnotation(widget, true, true, false);
  }
};

/**
 * Imports widget annot pushed from firebase into webviewer if it doesn't already exist
 */
export const importWidgetFbaseVal = (instance: Instance) => async ({ val, key }: any) => {
  const { selectedDoc, annotManager } = instance;

  if (selectedDoc !== val.docId) {
    log('selectedDoc !== widgetVal.docId', { selectedDoc, docId: val.docId });

    return;
  }

  const widget = annotManager.getWidgetById(key);

  if (!widget) {
    log(`importing widget: ${val.id}`);
    const [annotation] = await annotManager.importAnnotations(val.xfdf);

    if (annotation) {
      await annotation.resourcesLoaded();
      // Set a custom field authorId to be used in client-side permission check
      annotation.authorId = annotation.Author = annotation.Author || val.authorId;
      annotation.CustomData = { ...annotation.CustomData, ..._.omit(val, ['xfdf']) };


      if (val.fieldName && val.fieldValue && annotation.getField) {
        const field = annotation.getField();

        if (field && field.value !== val) {
          field.setValue(val.fieldValue);
        }
      }

      if (val.hidden === true) {
        instance.annotManager.hideAnnotations([annotation]);
      } else if (val.hidden === false) {
        instance.annotManager.showAnnotations([annotation]);
      }

      annotManager.redrawAnnotation(annotation);
      annotManager.trigger('updateAnnotationPermission', [annotation]);
    }
  } else {
    log(`widget found skipping: ${val.id}`);
    const widget = annotManager.getWidgetById(key);

    if (val.hidden === true) {
      instance.annotManager.hideAnnotations([widget!]);
    } else if (val.hidden === false) {
      instance.annotManager.showAnnotations([widget!]);
    }
  }
};


/**
 * Checks if field exists and if it does will set its value
 */
export const importField = ({ annotManager }: Instance) => async ({ val }: any) => {
  const mgr = annotManager.getFieldManager();
  const field = mgr.getField(val.name);

  if (field) {
    // @ts-expect-error this should have 2 parameters
    field.setValue(val.value);
  }
};


/**
 * Set the number of blank pages
 */
export const setBlankPages = ({ selectedDoc, docViewer }: Instance) => async ({ val, key }: any) => {
  if (selectedDoc !== key) {
    log('setBlankPages: selectedDoc !== val.docId', {});

    return false;
  }

  return docViewer.trigger('setBlankPages', [val]);
};


export const lockWebviewer = (instance: Instance) => async ({ val }: any) => {
  instance.docViewer.trigger('setLockStatus', [val]);

  if (!instance.annotManager.getIsAdminUser()) {
    return instance.docViewer.lockWebviewer(val);
  }
};


export const setSigners = (inst: Instance) => async ({ val }: any) => inst.annotManager.trigger('setSigners', _.values(val));

export const setCurrentUser = (inst: Instance) => async ({ val }: any) => inst.annotManager.setCurrentUser(val);


export const setSelectedSigner = (inst: Instance, appState: AppStateProviderContext) => ({ val }: any) => {
  appState.setSelectedSigner(val);

  if (inst.annotManager.getIsAdminUser()) {
    inst.toggleTools(false);

    return;
  }

  // if signers can be located in different places
  if (appState.signerLocation === 'remote') {
    // if (val === '-1') {
    inst.annotManager.setCurrentUser('-1');
    inst.toggleTools(true);
    appState.setCurrentUser('-1');
    // }

    // if (val === '-1' || (inst.getSignerById(val) && appState.runId !== inst.getSignerById(val).runId)) {
    //   inst.annotManager.setCurrentUser('-1');
    //   inst.toggleTools(true);
    //   appState.setCurrentUser('-1');
    // } else if ((inst.getSignerById(val) && appState.runId === inst.getSignerById(val).runId)) {
    //   inst.annotManager.setCurrentUser(val);
    //   inst.toggleTools(false);
    //   appState.setCurrentUser(val);
    // }
  } else {
    inst.annotManager.setCurrentUser(val);
    appState.setCurrentUser(val);
    inst.toggleTools(val === '-1');
  }

  inst.annotManager.trigger('setSelectedSigner', val);
};


export const setModifiedXfdf = R.curry(async (server, inst) => {
  if (!inst.selectedDoc || inst.selectedDoc === '-1') {
    return false;
  }

  const annotList = _.filter(inst.annotManager.getAnnotationsList(), (annot) => !annot.Subject || !annot.Subject.includes('Template'));
  const xfdf = await inst.annotManager.exportAnnotations({
    annotList,
    widgets: true,
    fields: true,
  });

  return server.saveModifiedXfdf(inst.selectedDoc, xfdf);
});

export const nsCompleting = (inst: Instance) => ({ val }: any) => {
  if (val) {
    inst.hideMessage();

    if (_.isString(val)) {
      inst.showMessage(_.upperFirst(val));
    } else {
      inst.showMessage('Completing...');
    }
  } else {
    inst.hideMessage();
  }
};

export const setCorrAnnot = (server: any, type = 'add') => (val: any) => {
  if (val.widgetId) {
    if (type === 'add') {
      server.deleteWidget(val.widgetId);
    }
  }

  if (val.corrId) {
    server.updateAnnotation(val.corrId, {
      hidden: type === 'add',
    });
  }
};
