// E.g. '3rem' / 2 = '1.5rem'
export function divideLength(dimension: string, divisor: number, decimalPlaces?: number) {
  const number = parseInt(dimension);
  const unit = dimension.replace(/[0-9]+/, '');

  if (typeof decimalPlaces === 'number') {
    return (number / divisor).toFixed(decimalPlaces) + unit;
  } else {
    return number / divisor + unit;
  }
}
