// Strip out the strikeout and whiteout annots.
export default async function stripNonSpecialAnnots(xfdf: string) {
  const doc = new DOMParser().parseFromString(xfdf, 'application/xml');
  const annots = await doc.getElementsByTagName('annots');

  await Promise.all(Array.from(annots[0].children).map(async (xmlNode) => {
    if (xmlNode.getAttribute('subject') === 'Strikeout' || xmlNode.getAttribute('subject') === 'WhiteOutAnnotation'
    ) {
      xmlNode.parentNode!.removeChild(xmlNode);
    }
  }));

  return new XMLSerializer().serializeToString(doc);
}
