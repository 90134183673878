import React from 'react';

import * as R from 'ramda';
import _ from 'lodash';
import { parseName } from '@enotarylog/tag-utils/helpers/parseName';
import { injectTool } from '../initializers/injectors';
import { InstanceObject } from '../../viewer';

export interface SelectSignerProps {
  signers: any;
  instance: any;
}

export interface SelectSignerState {
  signers: any;
  selectedSigner: any;
}

class SelectSigner extends React.Component<SelectSignerProps, SelectSignerState> {
  constructor(props: SelectSignerProps) {
    super(props);

    this.state = {
      signers: props.signers,
      selectedSigner: null,
    };
  }

  componentDidMount() {
    const { instance } = this.props;

    this.setState({
      signers: instance.getSigners(),
      selectedSigner: instance.getSelectedSigner(),
    });
    instance.annotManager.on('signersChanged', () => this.setState({ signers: instance.getSigners() }));
    instance.annotManager.on('selectedSignerChanged', (signer: any) => this.setState({ selectedSigner: signer }));
  }

  render() {
    return (
      <select
        value={this.state.selectedSigner}
        onChange={(evt) => {
          this.props.instance.annotManager.trigger('setSelectedSigner', evt.target.value);
        }}
      >
        <option value='-1'>Not Set</option>
        {
          _.map(this.state.signers, (signer) => {
            if (!signer || signer.type === 'remotesigner') {
              return null;
            }

            return (
              <option
                key={signer.id}
                value={signer.id}
              >
                {parseName(signer)}
              </option>
            );
          })
        }
      </select>

    );
  }
}


const setCurrentSigner = ({ instance }: InstanceObject) => ({
  type: 'customElement',
  title: 'Select Signer',
  dataElement: 'selectSigner',
  render: () => (
    <SelectSigner
      signers={instance.getSigners()}
      instance={instance}
    />
  ),
});


const registerSelectSigner = R.pipeP(
  injectTool('SelectSigner', setCurrentSigner)
);

export default registerSelectSigner;
