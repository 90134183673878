import { createFullDateAnnotation, createMonthAnnotation, createYearAnnotation, createDayAnnotation } from '../templatingActions/dateActions';
import { createFirstNameAnnotation, createLastNameAnnotation, createMiddleNameAnnotation, createFullNameAnnotation } from '../templatingActions/nameActions';
import { createAddressAnnotation, createStateAnnotation, createCityAnnotation, createZipAnnotation } from '../templatingActions/addressActions/createAnnotation';
import { initialHere, signHere, checkBox, freeFormText, radioButton, eSeal, } from '../stamps/stampImages';
import { Annotations, WebViewerInstance } from '@pdftron/webviewer';
import { IAddressInfo, StateAbbr } from '../types';
import { createFreeFormAnnotation } from '../templatingActions/freeFormTextActions';

export const tagTypes = {
  dateTags: {
    DATETEMPLATE: {
      label: 'Date:',
      title: 'Enter a Date',
      showMonthYearPicker: false,
      showYearPicker: false,
      dateFormat: 'MM/dd/yyyy',
      dateFormats: [
        'MM/DD/YYYY',
        'DD/MM/YYYY',
        'MM/DD/YY',
        'DD/MM/YY',
        'MMMM do, yyyy',
      ],
      subject: 'DateTemplateFreeTextAnnot',
      createDateAnnotation: createFullDateAnnotation,
      prefillable: true,
      legallyBinding: true,
    },
    DAYTEMPLATE: {
      label: 'Day:',
      title: 'Pick a Day',
      showMonthYearPicker: false,
      showYearPicker: false,
      dateFormat: 'do',
      subject: 'DayTemplateFreeTextAnnot',
      createDateAnnotation: createDayAnnotation,
      prefillable: true,
      legallyBinding: true,
    },
    MONTHTEMPLATE: {
      label: 'Month:',
      title: 'Pick a Month',
      showMonthYearPicker: true,
      showYearPicker: false,
      dateFormat: 'MMMM',
      subject: 'MonthTemplateFreeTextAnnot',
      createDateAnnotation: createMonthAnnotation,
      prefillable: true,
      legallyBinding: true,
    },
    YEARTEMPLATE: {
      label: 'Year:',
      title: 'Pick a Year',
      showMonthYearPicker: false,
      showYearPicker: true,
      dateFormat: 'yyyy',
      subject: 'YearTemplateFreeTextAnnot',
      createDateAnnotation: createYearAnnotation,
      prefillable: true,
      legallyBinding: true,
    },
  },
  nameTags: {
    FIRSTNAMETEMPLATE: {
      label: 'First Name:',
      title: 'Enter your first name',
      field: 'firstName',
      createNameAnnotation: createFirstNameAnnotation,
      prefillable: true,
      legallyBinding: false,
    },
    MIDDLENAMETEMPLATE: {
      label: 'Middle Name:',
      title: 'Enter your middle name',
      field: 'middleName',
      createNameAnnotation: createMiddleNameAnnotation,
      prefillable: true,
      legallyBinding: false,
    },
    LASTNAMETEMPLATE: {
      label: 'Last Name:',
      title: 'Enter your last name',
      field: 'lastName',
      createNameAnnotation: createLastNameAnnotation,
      prefillable: true,
      legallyBinding: false,
    },
    FULLNAMETEMPLATE: {
      label: 'Full Name:',
      title: 'Enter your full name',
      field: 'fullName',
      subject: 'FullNameTemplateFreeTextAnnot',
      createNameAnnotation: createFullNameAnnotation,
      prefillable: true,
      legallyBinding: false,
    },
  },
  addressTags: {
    ADDRESSTEMPLATE: {
      label: 'Address:',
      title: 'Enter an address',
      field: 'address',
      addressFormat: 'full',
      createAddressFieldAnnotation: function createAddressFieldAnnotation(instance: WebViewerInstance, addressInfo: IAddressInfo, annot: Annotations.FreeTextAnnotation) {
        createAddressAnnotation(instance, addressInfo, annot);
      },
      prefillable: true,
      legallyBinding: false,
    },
    STATETEMPLATE: {
      type: 'STATETEMPLATE',
      label: 'State:',
      title: 'Enter a state',
      stateFormat: 'Abbr',
      field: 'state',
      createAddressFieldAnnotation: function createAddressFieldAnnotation(instance: WebViewerInstance, state: StateAbbr, annot: Annotations.FreeTextAnnotation) {
        createStateAnnotation(instance, state, annot);
      },
      prefillable: true,
      legallyBinding: false,
    },
    CITYTEMPLATE: {
      label: 'City:',
      title: 'Enter a city',
      field: 'city',
      createAddressFieldAnnotation: function createAddressFieldAnnotation(instance: WebViewerInstance, city: string, annot: Annotations.FreeTextAnnotation) {
        createCityAnnotation(instance, city, annot);
      },
      prefillable: true,
      legallyBinding: false,
    },
    ZIPTEMPLATE: {
      label: 'Zip/Postal Code:',
      title: 'Enter a zip',
      field: 'zip',
      createAddressFieldAnnotation: function createAddressFieldAnnotation(instance: WebViewerInstance, zip: string, annot: Annotations.FreeTextAnnotation) {
        createZipAnnotation(instance, zip, annot);
      },
      prefillable: true,
      legallyBinding: false,
    },

  },
  freeFromTag:{
    FREEFORMTEMPLATE: {
      label: 'Free Form:',
      title: 'Free Form Tag',
      field: 'freeForm',
      subject: 'FreeFormTemplateFreeTextAnnot',
      createFreeAnnotation: createFreeFormAnnotation,
      prefillable: false,
      legallyBinding: false,
    }
  },
  notaryVerbiageTags: {
    DOCUMENTIDTEMPLATE: {
      label: 'Choice:',
      title: 'Document\'s unique identified',
      type: 'docId',
      text: 'Document ID: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
      subject: 'DocumentIdTemplateFreeTextAnnot',
      prefillable: true,
      legallyBinding: false,
    },
    NOTARYSESSIONIDTEMPLATE: {
      label: 'Session ID:',
      title: 'Session\'s unique identified',
      type: 'nsId',
      text: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
      subject: 'NotarySessionIdTemplateFreeTextAnnot',
      prefillable: true,
      legallyBinding: false,
    },
    COMMISSIONIDTEMPLATE: {
      label: 'Commission ID:',
      title: 'Notary\'s commision ID',
      type: 'commissionId',
      subject: 'CommissionIdTemplateFreeTextAnnot',
      text: 'GG 123125',
      prefillable: true,
      legallyBinding: false,
    },
    COMMISSIONEXPIRATIONTEMPLATE: {
      label: 'Commission ID:',
      title: 'Notary\'s commision ID',
      type: 'commissionExpiration',
      subject: 'CommissionExpirationTemplateFreeTextAnnot',
      text: 'Notary Commission Expiration Date',
      prefillable: true,
      legallyBinding: false,
    },
    DISCLAIMERTEMPLATE: {
      label: 'Choice:',
      subject: 'DisclaimerTemplateFreeTextAnnot',
      title: 'Choose Notary Verbiage',
      text: 'This Notarization was conducted using online audio/video technology.',
      type: 'disclaimer',
      prefillable: true,
      legallyBinding: false,
    },
    COMMISSIONSTAMPTEMPLATE: {
      text: 'Commission Stamp',
      prefillable: true,
      legallyBinding: false,
    },
    NOTARYTITLETEMPLATE: {
      label: 'Notary Title:',
      title: 'Notary\'s Title',
      type: 'notaryTitle',
      text: 'Notary Public',
      subject: 'NotaryTitleTemplateFreeTextAnnot',
      prefillable: true,
      legallyBinding: false,
    },
    NOTARYCOUNTYTEMPLATE: {
      type: 'notaryCounty',
      text: 'Notary County',
      subject: 'NotaryCountyTemplateFreeTextAnnot',
      prefillable: true,
      legallyBinding: false,
    },
    NOTARYSTATETEMPLATE: {
      type: 'notaryState',
      text: 'Notary State',
      subject: 'NotaryStateTemplateFreeTextAnnot',
      prefillable: true,
      legallyBinding: false,
    },
  },
  imageTags: {
    SIGNATURETEMPLATE: {
      text: 'SIGNATURE',
      subject: 'SignHereTagTemplateAnnotation',
      ratio: 3.8219178082191787,
      prefillable: false,
      legallyBinding: true,
    },
    INITIALSTEMPLATE: {
      text: 'INITIALS',
      subject: 'InitialHereTagTemplateAnnotation',
      ratio: 3.8219178082191787,
      prefillable: false,
      legallyBinding: true,
    },
    SIGNHERETAGTEMPLATEANNOTATION: {
      text: 'SIGNATURE',
      subject: 'SignHereTagTemplateAnnotation',
      ratio: 3.8219178082191787,
      svg: signHere,
      prefillable: false,
      legallyBinding: true,
    },
    INITIALHERETAGTEMPLATEANNOTATION: {
      text: 'Initials',
      subject: 'InitialHereTagTemplateAnnotation',
      ratio: 3.8219178082191787,
      svg: initialHere,
      prefillable: false,
      legallyBinding: true,
    },
    SEALTAGTEMPLATEANNOTATION: {
      text: '',
      type: 'SEALTAGTEMPLATEANNOTATION',
      subject: 'SealTagTemplateAnnotation',
      svg: eSeal,
      ratio: 2.0833333333333335,
      prefillable: true,
      legallyBinding: true,
    },
    COMMISSIONSTAMPTEMPLATE: {
      text: '',
      // ratio = width / height
      subject: 'CommissionStampTemplate',
      ratio: 2.0833333333333335,
      prefillable: true,
      legallyBinding: true,
    },
    CHECKHERETAGTEMPLATEANNOTATION: {
      text: '',
      type: 'CHECKHERETAGTEMPLATEANNOTATION',
      svg: checkBox,
      prefillable: false,
      legallyBinding: false,
    },
    FREEFORMTEXTTAGTEMPLATEANNOTATION: {
      text: '',
      type: 'FREEFORMTEXTTAGTEMPLATEANNOTATION',
      subject: 'FreeFormTextTagTemplateAnnotation',
      ratio: 3.8219178082191787,
      svg: freeFormText,
      prefillable: false,
      legallyBinding: false,
    },
    RADIOBUTTONTAGTEMPLATEANNOTATION: {
      text: '',
      type: 'RADIOBUTTONTAGTEMPLATEANNOTATION',
      svg: radioButton,
      prefillable: false,
      legallyBinding: false,
    },
  },
  fieldTags: {
    SIGNATURE: {},
    INITIALS: {},
    TEXT: {},
    CHECK: {},
  },
};

export default tagTypes;
