// The idea here is that you provide:
// 1. Some bounds (width and height) - usually comes from the original tag
// 2. The size of your source (width and height) - usually comes from the dropped annot
// And you get back the right annot size (width and height) which is as big as possible WITHOUT EXCEEDING THE BOUNDS.
// For the inputs you don't have to worry about casing, but the output will always be { width, height }

interface LBounds {
  width: number;
  height: number;
};

interface UBounds {
  Width: number;
  Height: number;
};

export default function scaleWithinBounds(bounds: LBounds | UBounds, source: LBounds | UBounds) {
  const boundingWidth = (bounds as UBounds).Width || (bounds as LBounds).width;
  const boundingHeight = (bounds as UBounds).Height || (bounds as LBounds).height;
  const sourceWidth = (source as UBounds).Width || (source as LBounds).width;
  const sourceHeight = (source as UBounds).Height || (source as LBounds).height;

  const boundsAspectRatio = boundingWidth / boundingHeight;
  const sourceAspectRatio = sourceWidth / sourceHeight;

  if (boundsAspectRatio > sourceAspectRatio) {
    // The bounds are wider than the source, so the bounding height must be used for the calculations because it's smaller, aspect-ratio-wise
    return {
      height: boundingHeight,
      width: boundingHeight * (sourceWidth / sourceHeight),
    };
  }

  // The bounds aren't wider than the source, so the source is wider than or the same size as the bounds, aspect-ratio-wise
  // Assume that the source is wider than the bounds; if they're the same then this math will work too
  return {
    height: boundingWidth * (sourceHeight / sourceWidth),
    width: boundingWidth,
  };
}
