import { makeStyles } from '@material-ui/core/styles';
import { defaultTheme } from 'document-viewer/src/constants';

const isiOSFirefox = navigator.userAgent.indexOf("FxiOS") > -1;

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .MuiDialogActions-root': {
      marginTop: 0,
    }
  },
  scrollPaper: {
    alignItems: 'flex-start',
  },
  mobileContent: {
    [theme.breakpoints.down("md")]: {
      // due to the way the modal is rendered in firefox, we need to use a different maxHeight
      maxHeight: isiOSFirefox ? 'calc(95% - 80px)' : 'calc(100% - 120px)',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      // due to the way the modal is rendered in firefox, we need to use a different maxHeight
      maxHeight: isiOSFirefox ? 'calc(97% - 80px)' : 'calc(96% - 120px)',
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: 'calc(100% - 120px)',
    },
  },
  dialogTitle: { backgroundColor: defaultTheme.dialogTitle , padding: '32px 24px', color: defaultTheme.dialogTextColor },
  dialogHeader: { fontSize: '1rem', fontWeight: 500 },
}));
