import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { parseName } from '@enotarylog/tag-utils/helpers/parseName';
import { ComponentProps } from './types';
import { Typography, Grid, } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'document-viewer/src/components/Dialog';

export default function SwitchSigner({ participants, currentParticipantId, open, onSelect, onClose, reason, buttonText, autoSelectIfOnlyOne = false }: ComponentProps) {
  const [selectedSignerId, setSelectedSignerId] = useState(null);
  const classes = useStyles();

  useEffect(() => setSelectedSignerId(null), [open]);

  // If there's only one option, auto select them
  useEffect(() => {
    if (!open || !autoSelectIfOnlyOne) return; // No need to do anything if this isn't even being shown right now
    if (participants.length === 1) {
      onSelect(participants[0]); // There's only 1 participant, of course they have to be selected
    } else if (participants.length === 2) {
      // There's only 2 participants.
      // If one of them is currentParticipantId, then because this is called SwitchSigner, that means they want to switch to the other
      const participantsWhoAreNotCurrentParticipant = participants.filter((p) => p.id !== currentParticipantId);
      if (participantsWhoAreNotCurrentParticipant.length === 1) {
        onSelect(participantsWhoAreNotCurrentParticipant[0]); // There's only 1 participant to switch to, so switch to them automatically
      }
    }
  }, [participants, currentParticipantId, onSelect, open, autoSelectIfOnlyOne]);

  const labelId = Math.random().toString();

  const switchOrSelect = currentParticipantId ? 'Switch' : 'Select'

  return (
    <Dialog
      open={open}
      onClose={onClose}
      size='sm'
    >
      <Grid
        className={classes.container}
        container
        alignContent='center'
      >
        <Grid
          item
          xs={12}
        >
          <DialogTitle>
            {switchOrSelect}
            {' '}
            Signer
          </DialogTitle>
          <DialogContent
            className={classes.contentRoot}
          >
            {reason && (
              typeof reason === 'string'
                ? <Typography>{reason}</Typography>
                : reason)}
            <FormControl className={classes.formControlRoot}>
              <InputLabel id={labelId}>Who will be signing now?</InputLabel>
              <Select
                labelId={labelId}
                value={selectedSignerId || ''}
                onChange={({ target }) => setSelectedSignerId(target.value)}
              >
                {open && participants.map((participant) => {
                  if (participant.id === currentParticipantId) return null;
                  return (
                    <MenuItem
                      className={classes.listText}
                      value={participant.id}
                      key={participant.id}
                    >
                      {parseName(participant)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </DialogContent>

        </Grid>
      </Grid>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={() => {
            setSelectedSignerId(null);
            onSelect(participants.find((p) => p.id === selectedSignerId))
          }}
          color='primary'
          variant='contained'
          disabled={!selectedSignerId}
          className={classes.button}
        >
          {buttonText || 'Next'}
        </Button>
      </DialogActions>
    </Dialog >
  );
}
