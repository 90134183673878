import React, { useReducer, useContext, useCallback } from 'react';
import _ from 'lodash';
import * as R from 'ramda';
import debug from 'debug';
import { useSessionStorage, useList } from 'react-use';

const log = debug('hooks:AppState');

export interface AppStateProviderContext {
  setBlankPages: any;
  setSigners: any;
  updateSigners: any;
  setVerifications: any;
  setSelectedSigner: any;
  setTwilio: any;
  setSelectedDoc: any;
  setCurrentUser: any;
  setStatus: any;
  setShowVaDisclaimer: any;
  setSignatures: any;
  setXfdf: any;
  setDocs: any;

  setLoadedDocs: any;
  addLoadedDocs: any;
  setNotarySession: any;
  setNotary: any;
  setPinModal: any;
  setAuthPinModal: any;
  setAuthingNsUserId: any;

  getBlankPages: any;
  getSigners: any;
  getSelectedSigner: any;
  getSelectedDoc: any;
  getCurrentUser: any;
  getRunId: any;
  getStatus: any;
  getUsersOnDevice: any;

  // runId is used to identity which users are on the same machine
  usersOnDevice: any;
  twilio: any;
  status: any;
  runId: any;
  blankPages: any;
  signers: any;
  selectedSigner: any;
  selectedDoc: any;
  currentUser: any;
  userId: any;
  isAdminUser: any;
  config: any;
  docs: any;
  userType: any;
  verifications: any;
  signerLocation: any;
  images: any;
  xfdf: any;
  notary: any;
  signatures: any;
  showVaDisclaimer: any;
  loadedDocs: any[];
  notarySession: any;
  pinModal: any;
  authPinModal: any;
  authingNsUserId: any;
}

const AppStateCtx = React.createContext<AppStateProviderContext | null>(null);


const tapFns = R.pipe(
  R.toPairs,
  R.map(
    R.when(
      R.pipe(R.nth(1), _.isFunction),
      R.converge(R.unapply(R.identity), [R.nth(0), ([key, fn]) => R.pipe(R.tap((args) => log(`${key} called`, args)), fn)])
    )
  ),
  R.fromPairs
);


const buildReducer = (spec: any) => (state: any, action: any) => {
  if (action.type in spec) {
    return spec[action.type](state, action);
  }

  return state;
};


const buildSetter = (prop: any) => (state: any, { payload }: any) => ({
  ...state,
  [prop]: payload,
});

// NOTE: this `runId` is used to determine which users are in same browser tab
const _runId = `${Math.floor(Math.random() * 10000)}`;


const reducer = buildReducer({
  setNotary: buildSetter('notary'),
  setXfdf: R.identity,
  setSigners: buildSetter('signers'),
  setBlankPages: buildSetter('blankPages'),
  setSelectedSigner: buildSetter('selectedSigner'),
  setTwilio: buildSetter('twilio'),
  setSelectedDoc: buildSetter('selectedDoc'),
  setCurrentUser: buildSetter('currentUser'),
  setVerifications: buildSetter('verifications'),
  setStatus: buildSetter('status'),
  setShowVaDisclaimer: buildSetter('showVaDisclaimer'),
  setSignatures: buildSetter('signatures'),
  setNotarySession: buildSetter('notarySession'),
  setPinModal: buildSetter('pinModal'),
  setAuthPinModal: buildSetter('authPinModal'),
  setAuthingNsUserId: buildSetter('authingNsUserId'),
  setDocs: (state: any, { payload }: any) => ({
    ...state,
    docs: payload,
    selectedDoc: state.selectedDoc || Object.keys(payload)[0],
  }),
});

export interface AppStateProviderProps {
  children: React.ReactNode;
  config: any;
  userId: string;
  isAdminUser: boolean;
  runId: string;
  docs: any;
  userType: string;
  signerLocation: any;
  notarySession: any;
  signers: any[];
  twilio: any;
  signatures: any;
  images: any;
  selectedDoc: any;
  verifications: any;
  notary: any;
  showVaDisclaimer: boolean;
  status: string;
}

// Provider
export function AppStateProvider({
  children,
  config,
  userId = '-1',
  isAdminUser,
  runId,
  docs,
  userType,
  signerLocation,
  notarySession,
  signers: _signers,
  twilio,
  signatures,
  images,
  selectedDoc: _selectedDoc,
  verifications,
  notary = {},
  showVaDisclaimer = false,
  // selectedDoc,
  ...rest
}: AppStateProviderProps) {
  const [state, dispatch] = useReducer(reducer, {
    signers: _signers || {},
    selectedSigner: null,
    twilio,
    currentUser: userId,
    status: rest.status,
    verifications,
    selectedDoc: _selectedDoc,
    runId,
    signatures,
    docs,
    images,
    userType,
    isAdminUser,
    notarySession,
    notary,
    signerLocation,
    blankPages: {},
    xfdf: _.mapValues(docs, (val) => {
      return _.get(_.find(val.revisions, { versionNumber: 1 }), 'xmlAnnotation');
    }),
    config,
    showVaDisclaimer,
    // loadedDocs: [],

    // notarization request room
    pinModal: null,
    authPinModal: null,
    authingNsUserId: null,
  });

  const buildDispatch = _.curry((action: any, payload: any) => dispatch({ type: action, payload }));
  const buildGetter = (prop: keyof typeof state) => () => state[prop];
  const [loadedDocs, { set: setLoadedDocs, push: addLoadedDocs }] = useList<any>([]);

  const getUsersOnDevice = useCallback(() => {
    const getUsersOnDevice = R.pipe(
      R.toPairs,
      // @ts-ignore
      R.filter(R.pipe(R.nth(1), R.propEq('runId', state.runId))),
      R.fromPairs
    );

    return getUsersOnDevice(state.signers);
  }, [state.signers, state.runId]);


  const context = {
    setBlankPages: buildDispatch('setBlankPages'),
    setSigners: R.pipe(R.omit([notary?.userId]), buildDispatch('setSigners')),
    updateSigners: (signers: any) => {
      const newSigners = _.map(signers, (s) => ({
        ...(_.find(state.signers, { id: s.id }) || {}),
        ...s,
      }));
      // @ts-expect-error not sure why this has two arguments
      dispatch('setSigners', newSigners);
    },
    setVerifications: buildDispatch('setVerifications'),
    setSelectedSigner: buildDispatch('setSelectedSigner'),
    setTwilio: buildDispatch('setTwilio'),
    setSelectedDoc: buildDispatch('setSelectedDoc'),
    setCurrentUser: buildDispatch('setCurrentUser'),
    setStatus: buildDispatch('setStatus'),
    setShowVaDisclaimer: buildDispatch('setShowVaDisclaimer'),
    setSignatures: buildDispatch('setSignatures'),
    setXfdf: buildDispatch('setXfdf'),
    setDocs: buildDispatch('setDocs'),

    setLoadedDocs: (docs: any) => setLoadedDocs(docs),
    addLoadedDocs: (docId: any) => addLoadedDocs(docId),
    setNotarySession: buildDispatch('setNotarySession'),
    setNotary: buildDispatch('setNotary'),
    setPinModal: buildDispatch('setPinModal'),
    setAuthPinModal: buildDispatch('setAuthPinModal'),
    setAuthingNsUserId: buildDispatch('setAuthingNsUserId'),

    getBlankPages: buildGetter('blankPages'),
    getSigners: buildGetter('signers'),
    getSelectedSigner: buildGetter('selectedSigner'),
    getSelectedDoc: buildGetter('selectedDoc'),
    getCurrentUser: buildGetter('currentUser'),
    getRunId: buildGetter('runId'),
    getStatus: buildGetter('status'),
    getUsersOnDevice,

    // runId is used to identity which users are on the same machine
    usersOnDevice: getUsersOnDevice(),
    twilio: state.twilio,
    status: state.status,
    runId: state.runId,
    blankPages: state.blankPages,
    signers: state.signers,
    selectedSigner: state.selectedSigner,
    selectedDoc: state.selectedDoc,
    currentUser: state.currentUser,
    userId: state.currentUser,
    isAdminUser: state.isAdminUser,
    config: state.config,
    docs: state.docs,
    userType: state.userType,
    verifications: state.verifications,
    signerLocation: state.signerLocation,
    images: state.images,
    xfdf: state.xfdf,
    notary: state.notary,
    signatures: state.signatures,
    showVaDisclaimer: state.showVaDisclaimer,
    loadedDocs: loadedDocs || [],
    notarySession: state.notarySession,
    pinModal: state.pinModal,
    authPinModal: state.authPinModal,
    authingNsUserId: state.authingNsUserId,
  };

  return (
    <AppStateCtx.Provider value={tapFns(context) as unknown as AppStateProviderContext}>
      {children}
    </AppStateCtx.Provider>
  );
}


// hook
export const useAppState = () => {
  const appState = useContext(AppStateCtx);

  if (!appState) {
    throw new Error('useAppState must be used within AppStateProvider');
  }

  return appState;
};

type withAppStateProvider<U = any, C = any, P = any> = (useInitState: U) => (Component: C) => (props: P) => React.ReactNode

// HOC
export const withAppStateProvider: withAppStateProvider = (useInitState) => (Component) => (props) => {
  const [runId] = useSessionStorage('runId', _runId);
  const initialState = useInitState({ ...props, runId: runId || _runId });


  return !initialState.loading ? (
    <AppStateProvider
      {...props}
      runId={runId}
      {...initialState.value}
    >
      <Component
        {...props}
        {...initialState.value}
      />
    </AppStateProvider>
  ) : <></>;
};

type withUseAppState<C = any, P = any> = (Component: C) => (props: P) => C

export const withUseAppState: withUseAppState = (Component) => (props) => {
  const appState = useAppState();

  return (
    <Component
      {...props}
      appState={appState}
    />
  );
};


export default useAppState;
