import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'document-viewer/src/lib/hooks/useTheme';

export const useStyles = makeStyles((theme : Theme) => ({
  formControlRoot: {
    width: '100%',
  },
  contentRoot: {
    minWidth: '400px',
    maxWidth: '80vw',
  },
  container: { height: '100%' },
  dialogActions: { justifyContent: 'center' },
  button: { margin: 8, minWidth: 200 },
  listText: theme.signatureAndInitialsInOneStep ? {
    textTransform: 'uppercase',
  } : undefined,
}));
