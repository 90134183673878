import { ApolloClient } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CRED_ANALYSIS_SUBMIT,
  KBA_GET_QUESTIONS,
  KBA_START,
  KBA_SUBMIT_ANSWERS,
} from 'document-viewer/src/lib/gql/idv';
import { GET_PARTICIPANT } from 'document-viewer/src/lib/gql/participant';
import { updateParticipantStatus } from './participants';

import { AppThunk, RootState } from 'document-viewer/src/store';

export interface IdvState {
  loading: boolean;
  id: number | string | undefined;
  referenceId: number | string | undefined;
  questions: Array<KbaQuestion>;
  idvParticipant: any;
}

export const initialState: IdvState = {
  loading: false,
  id: undefined,
  referenceId: undefined,
  questions: [],
  idvParticipant: null,
};

export enum KbaQuestionTypeEnum {
  singlechoice = 'singlechoice',
  multiplechoice = 'multiplechoice',
}

export interface KbaQuestionChoice {
  id: number | string;
  statement: string;
}

export interface KbaQuestion {
  id: number | string;
  statement: string;
  type: KbaQuestionTypeEnum;
  choices: Array<KbaQuestionChoice>;
}

export interface KbaQuestionsPayload {
  referenceId: number | string;
  id: number | string;
  questions: Array<KbaQuestion>;
}

const slice = createSlice({
  name: 'kba',
  initialState,
  reducers: {
    setLoading(state: IdvState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setQuestions(state: IdvState, action: PayloadAction<KbaQuestionsPayload>) {
      state.referenceId = action.payload.referenceId;
      state.questions = action.payload.questions;
      state.id = action.payload.id;
    },
    setParticipant(state: IdvState, action: PayloadAction<any>) {
      state.idvParticipant = action.payload.participant;
    },
  },
});

export const kbaSetParticipant =
  (participant): AppThunk =>
    async (dispatch, __, client: ApolloClient<any>) => {
      try {
        dispatch(slice.actions.setParticipant(participant));
      } catch (err) {
        console.error(err);
      }
    };

export const kbaReloadParticipant =
  (participantId): AppThunk =>
    async (dispatch, __, client: ApolloClient<any>) => {
      try {
        dispatch(slice.actions.setLoading(true));
        const { data } = await client.query({
          query: GET_PARTICIPANT,
          fetchPolicy: 'no-cache',
          variables: {
            pId: participantId,
          },
        });

        dispatch(slice.actions.setParticipant(data.getParticipant));
        return {
          data,
        };
      } catch (err) {
        console.error(err);
        return null;
      }
    };

export const kbaStart =
  (transactionId, participantId, payload): AppThunk =>
    async (dispatch, __, client: ApolloClient<any>) => {
      try {
        dispatch(slice.actions.setLoading(true));
        const { data } = await client.mutate({
          mutation: KBA_START,
          variables: {
            transactionId,
            participantId,
            payload,
          },
        });
        let idvParticipant = null;

        if (data.kbaStart) {
          const { data } = await client.query({
            query: GET_PARTICIPANT,
            variables: {
              pId: participantId,
            },
          });
          dispatch(slice.actions.setParticipant(data.getParticipant));
          idvParticipant = data.getParticipant;
        }

        dispatch(slice.actions.setQuestions(data.kbaStart));

        return { data, idvParticipant };
      } catch (err) {
        console.error(err);
        return null;
      } finally {
        dispatch(slice.actions.setLoading(false));
      }
    };

export const getKbaQuestions =
  (nsUserId: string): AppThunk =>
    async (dispatch, __, client: ApolloClient<any>) => {
      dispatch(slice.actions.setLoading(true));
      const { data } = await client.query({
        query: KBA_GET_QUESTIONS,
        fetchPolicy: 'no-cache',
        variables: {
          nsUserId,
        },
      });
      dispatch(slice.actions.setQuestions(data.getQuestions));
      dispatch(slice.actions.setLoading(false));
    };

export const submitAnswers =
  (nsUserId: string, answers, isChallengeQuestion?: boolean, isAbruptlyClosed?: boolean): AppThunk =>
    async (dispatch, __, client: ApolloClient<any>) => {
      dispatch(slice.actions.setLoading(true));
      const { data } = await client.mutate({
        mutation: KBA_SUBMIT_ANSWERS,
        variables: {
          nsUserId,
          answers,
          isChallengeQuestion,
          isAbruptlyClosed
        },
      });

      if (
        data.submitAnswers?.passed === false &&
      data.submitAnswers.retry === false
      ) {
        dispatch(slice.actions.setLoading(false));
        return 'NO_MORE_ATTEMPTS';
      }

      dispatch(slice.actions.setLoading(false));
      return data;
    };

export const submitCredAnalysis =
  (nsUserId: string, identityAccessKey: string, hasFailed: boolean): AppThunk =>
    async (dispatch, __, client: ApolloClient<any>) => {
      dispatch(slice.actions.setLoading(true));

      const { data } = await client.mutate({
        mutation: CRED_ANALYSIS_SUBMIT,
        variables: {
          nsUserId,
          identityAccessKey,
          hasFailed,
        },
      });

      const { id, ...participant } = data?.credAnalysisSubmit || {};

      if (id) { dispatch(updateParticipantStatus(id, participant)); }

      dispatch(slice.actions.setLoading(false));
    };

export const reducer = slice.reducer;
export const selector = (state: RootState): IdvState => state.idv;
export default slice;
