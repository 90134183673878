export default {
  panelNames: [],
  toolNames: ['NotaryTools', 'NameTools', 'Divider', 'WhiteOutTool', 'StrikeOutTool'],
  popupNames: [
    'AssignSigner',
    'SetDateFormat',
    'SetRequired',
    'SetStateFormat',
    'SetAddressFormat',
  ],
  hotKeys: [],
};
