import { ReactElement } from 'react';
import type { Participant } from 'document-viewer/src/utils/types/participant';


export enum AuthStates {
  Initial = 'INITIAL',
  CodeSent = 'CODE_SENT',
  CodeValidated = 'CODE_VALIDATED',
  TryAgain = 'TRY_AGAIN',
  Blocked = 'BLOCKED',
  ErrorSendingCode = 'ERROR_SENDING_CODE',
}

export enum TwoFactorCodeTypes {
  Sms = 'sms',
  Email = 'email',
}



export type AuthProps = {
  participants: Participant[],
  sessionId: string,
  children: ReactElement,
  participantsCannotAct: boolean;
}

export type TwoFactorProps = {
  phone: string;
  email: string;
  sendCode: (codeType: TwoFactorCodeTypes) => any;
  codeType: TwoFactorCodeTypes;
  currentProcess: AuthStates;
  checking: boolean;
  checkCodeValid: (string) => any;
  name: string;
  signatureAndInitialsInOneStep: boolean;
  participant: Participant;
  handleAttemptsCallBack: (number) => any;
}

export type PersonalPasswordProps = {
  email: string;
  personalPasswordQuestion: string;
  sendAnswer: (string) => any;
  currentProcess: AuthStates;
  setCurrentProcess: (arg: AuthStates) => any;
  checking: boolean;
  signatureAndInitialsInOneStep: boolean;
  participant: Participant;
}

export type NoVerificationProps = {
  onComplete(): void;
  name: string;
};
