import { gql } from '@apollo/client';

export const KBA_START = gql`
  mutation kbaStart($transactionId: String!, $participantId: String!, $payload: PersonalInfoInput!){
    kbaStart(transactionId: $transactionId, participantId: $participantId, personalInfo: $payload){
      id
      referenceId
      questions {
        id
        statement
        type
        choices {
          id
          statement
        }
      }
    }
  }
`
export const KBA_GET_QUESTIONS = gql`
  query getQuestions($nsUserId: String!) {
    getQuestions(participantId: $nsUserId)
  }
`

export const KBA_SUBMIT_ANSWERS = gql`
  mutation submitAnswers($nsUserId: String!, $answers: JSONObject!, $isChallengeQuestion: Boolean, $isAbruptlyClosed: Boolean) {
    submitAnswers(participantId: $nsUserId, answers: $answers, isChallengeQuestion: $isChallengeQuestion, isAbruptlyClosed: $isAbruptlyClosed)
  }
`

export const CRED_ANALYSIS_SUBMIT = gql`
  mutation ($nsUserId: String!, $identityAccessKey: String!, $hasFailed: Boolean) {
    credAnalysisSubmit(
      participantId: $nsUserId,
      identityAccessKey: $identityAccessKey,
      hasFailed: $hasFailed
    )
  }
`
