import React from 'react';
import { CenteredSpinner } from 'document-viewer/src/components/LoadingSpinner';
import { useDocumentThumbnails } from 'document-viewer/src/lib/hooks/useDocumentThumbnails';
import { useThumbnailStyles } from './styles';
import Tooltip from 'document-viewer/src/components/Tooltip';
import DoneIcon from '@material-ui/icons/DoneRounded';
import SignatureIcon from '@material-ui/icons/Gesture';
import { groupBy } from '@enotarylog/ramda';
import { useWebViewer } from 'document-viewer/src/lib/hooks/useWebViewer';
import useAnnots from 'document-viewer/src/lib/hooks/useAnnots';
import { useParticipants } from 'document-viewer/src/lib/hooks/useParticipants';
import { isUnreviewedAnnot, isUnexecutedRequiredAnnot } from 'document-viewer/src/utils/pdftron/tagHelpers';

export default function Thumbnail({ index, style }) {
  const classes = useThumbnailStyles();
  const { annotManager } = useWebViewer();
  const { annotations = [] } = useAnnots();
  const { participantIdsOnThisDevice } = useParticipants();
  const { thumbnails, highlightedPageNumber, onPageSelected, docId } = useDocumentThumbnails();

  const image = thumbnails[index]?.page;

  if (image) {
    const annotsOnThisPage = annotations.filter((a) => a.PageNumber === index + 1);
    const annotAuthor = (annot) => annot.CustomData?.signerId || annot.Author;
    const tagsByAuthor = groupBy((annot) => {
      const author = annotAuthor(annot);
      if (!author && annot.CustomData?.corrId && annotManager) {
        const correlatedAnnot = annotManager.getAnnotationById(annot.CustomData.corrId);
        if (correlatedAnnot) {
          return annotAuthor(correlatedAnnot);
        }
      }
      return author;
    }, annotsOnThisPage);
    const authors: string[] = Object.keys(tagsByAuthor)
      .sort() // Ensure that dots always appear in the same order on each page
      .filter((id) => id !== 'undefined' && participantIdsOnThisDevice.includes(id)); // Ignore annots without any author, and ones for authors not on this device

    // Ensure that the current user shows at the very end, by ensuring they're at the beginning of the array (flexWrap: 'wrap-reverse' is on)
    const currentUserIdx = authors.indexOf(annotManager.getCurrentUser());
    if (currentUserIdx > 0) {
      authors.splice(currentUserIdx, 1);
      authors.unshift(annotManager.getCurrentUser());
    }

    // DEV: At some point, these two nested loops need to be properly unrolled.
    return (
      <Tooltip title={`Jump to page ${index + 1}`}>
        <div
          style={style}
          className={classes.container}
          onClick={() => onPageSelected(index)}
        >
          <div className={classes.thumbContainer}>
            <div
              className={index === highlightedPageNumber ? classes.activeThumb : classes.inactiveThumb}
              ref={(ref) => {
                if (ref) {
                  ref.innerHTML = '';
                  ref.appendChild(image);
                }
              }}
            />
            <div className={classes.icons}>
              {authors.map((author) => (
                tagsByAuthor[author]?.some(isUnexecutedRequiredAnnot)
                  ? (
                    <SignatureIcon
                      key={`${docId}/${index}/${author}`}
                      className={classes.errorLight}
                    />
                  )
                  : (
                    <DoneIcon
                      key={`${docId}/${index}/${author}`}
                      className={
                        tagsByAuthor[author]?.some(isUnreviewedAnnot)
                          ? classes.warningLight
                          : classes.successLight
                      }
                    />
                  )
              ))}
            </div>
          </div>
        </div>
      </Tooltip>

    );
  }

  return (
    <div style={{ ...style, display: 'flex' }}>
      <CenteredSpinner />
    </div>
  );
}
