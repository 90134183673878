import { gql } from '@apollo/client';

export const GET_ORG_INFO  = gql`
  query getOrgInfo ($organizationId: ID!, $includeSettings: Boolean) {
    getOrganizationInfo(id: $organizationId, includeSettings: $includeSettings) {
      id
      settings {
        key
        value
      }
    }
  }
`

export const GET_SESSION_INFO = gql`
  query getInitialData($organizationId: ID!, $participantIds: [String]!, $sessionId: String!, $iframeId: ID!) {
    getParticipants(ids: $participantIds) {
      id
      firstName
      middleName
      lastName
      kbaAttempts
      email
      phone
      twoFactorContact
      status
      authenticationType
      authenticationFailures
      authenticationPassed
      personalPasswordQuestion
      kbaPassed
      kbaRequired
      credAnalysisRequired
      credAnalysisPassed
      signature
      initials
      textColor
      order
      address1
      address2
      city
      state
      zip
      phone
      twoFactorContact
      order
      skipVerification
      documents {
        documentId
        isCompleted
        readOnly
        visible
      }
    }
    getEsign(id: $sessionId, organizationId: $organizationId, iframeId: $iframeId) {
      id
      status
      transaction {
        id
        status
        auth0OrganizationId
        lockedOut
        failureReason
      }

      documents {
        id
        description
        title
        status
        order
        tags
        revisions {
          url
          gcsRefId
          id
          participantId
          version
          xfdf
          completed
        }
      }
      iframeRequest {
        id
        style
        data
        inUsed
        organizationId
      }
    }
  }

`;

export const GET_SESSION = gql`
  query($sessionId: String!, $organizationId: ID!) {
    getEsign(id: $sessionId, organizationId: $organizationId) {
      status
      transactionId
      sendEmailNotification
      documents {
        id
        description
        title
        status
        tags
        revisions {
          url
          gcsRefId
          id
          participantId
          version
          xfdf
          completed
        }
      }
    }
  }
`;

export const SET_ESIGN_IN_PROGRESS = gql`
  mutation ($id: ID!, $organizationId: ID!) {
    esignIsInProgress(id: $id, organizationId: $organizationId) {
      status
    }
  }
`;
