import { deferredPromise } from './deferredPromise';

export const SMOOTH_SCROLL_FOR_LESS_THAN = 20;
export const MS_TO_PAUSE_AFTER_USER_INTERACTION_FOR = 100;

export type CruiseControlled = {
  promise: Promise<void>,
  cancel: () => void,
};

// Scrolls to a desiredY in an element at a certain speed, allowing the user to manually scroll as well.
// If the user scrolls in the opposite direction or goes past desiredY, cruise control is completely canceled.
// Returns { cancel, promise } where cancel is a function you can call
// When cruise control ends for whatever reason (cancellation or it just ended up in the right spot), a promise will resolve if it ended up in the right spot, or reject otherwise
export default function cruiseControlScroll(desiredY: number, element: HTMLElement, pxPerSec = 400, paddingPx = element.clientHeight / 5): CruiseControlled {
  const { resolve, reject, promise } = deferredPromise<void>();
  let cancel = () => {}; // Used to cancel the promise
  // A negative Y value will never work, and is likely a bug
  if (desiredY < 0) {
    reject(new Error('Negative Y given to cruiseControlScroll ' + desiredY));
    return { promise, cancel };
  }

  // If the padding is too big, then cut it down to the max padding
  const actualPaddingPx = Math.min(paddingPx, element.clientHeight / 2);

  const goingUp = element.scrollTop > desiredY - actualPaddingPx;
  const bottomOfScreen = element.scrollTop + element.clientHeight;

  // If no scrolling is needed because it's already in view (even considering padding), then resolve immediately!
  if (!goingUp && bottomOfScreen >= desiredY + actualPaddingPx) {
    resolve();
    return { promise, cancel };
  }

  const paddedDesiredY = goingUp ? desiredY - actualPaddingPx : desiredY - element.clientHeight + actualPaddingPx;
  const yToScrollTo = Math.round(Math.min(Math.max(0, paddedDesiredY), element.scrollHeight - element.clientHeight)); // Won't exceed any bounds; can actually be scrolled to
  let timer = null;
  let lastFrameAt = new Date();
  let lastYCoord = element.scrollTop;

  const pauseForUserScroll = () => {
    lastFrameAt = new Date();
    lastFrameAt.setTime(lastFrameAt.getTime() + MS_TO_PAUSE_AFTER_USER_INTERACTION_FOR);
  }
  element.addEventListener('wheel', pauseForUserScroll, { passive: true });

  const scroll = () => {
    // If you reach the destination, OR if the user scrolled in the opposite direction, then resolve or reject (depedending on whether the destination was reached) and return, stopping the loop.
    if (goingUp) {
      if (element.scrollTop - yToScrollTo < 1 || lastYCoord < element.scrollTop) {
        if (element.scrollTop - yToScrollTo < 1) {
          resolve();
        } else {
          reject(new Error('Scrolled too far'));
        }
        return
      }
    } else if (element.scrollTop - yToScrollTo > -1 || lastYCoord > element.scrollTop) {
      if (element.scrollTop - yToScrollTo > -1) {
        resolve();
      } else {
        reject(new Error('Scrolled too far'));
      }
      return
    }

    lastYCoord = element.scrollTop;

    timer = setTimeout(() => {
      const elapsedMs = new Date().getTime() - lastFrameAt.getTime();
      const change = Math.floor(pxPerSec * (elapsedMs / 1000));
      if (change > 1) {
        lastFrameAt = new Date();
        const newScrollTop = element.scrollTop + (goingUp ? - change : change);
        element.scrollTo({
          left: element.scrollLeft,
          top: Math[goingUp ? 'max' : 'min'](newScrollTop, yToScrollTo),
          behavior: change > SMOOTH_SCROLL_FOR_LESS_THAN ? 'smooth' : 'auto',
        });
      }
      scroll();
    }, 20);
  }

  scroll();

  cancel = () => {
    if (timer) {
      clearTimeout(timer);
    }

    element.removeEventListener('wheel', pauseForUserScroll);
    reject(new Error('Canceled'));
  }

  return { promise, cancel };
}

export function scrollToBottomOf(element: HTMLElement, pxPerSex?: number, paddingPx?: number) {
  return cruiseControlScroll(element.scrollHeight, element, pxPerSex, paddingPx);
}

export function scrollToTopOf(element: HTMLElement, pxPerSex?: number, paddingPx?: number) {
  return cruiseControlScroll(0, element, pxPerSex, paddingPx);
}

export function scrollToAnnot(docViewer, annot, pxPerSec = 5, paddingPx = docViewer.getScrollViewElement().clientHeight / 5) {
  const displayMode = docViewer.getDisplayModeManager().getDisplayMode()
  const topOfAnnot = displayMode.pageToWindow({ x: 0, y: annot.Y }, annot.PageNumber - 1).y;
  const bottomOfAnnot = displayMode.pageToWindow({ x: 0, y: annot.Y }, annot.PageNumber - 1).y;
  const middleOfAnnot = (topOfAnnot + bottomOfAnnot) / 2;
  // Scroll to the middle of the annot, but add the annot's size to the padding to ensure that the padding is padding AROUND the annot.
  // So paddingPx = 0 would show the entire annot with zero padding
  const totalPadding = (bottomOfAnnot - middleOfAnnot) + paddingPx;

  return cruiseControlScroll(middleOfAnnot, docViewer.getScrollViewElement(), pxPerSec, totalPadding);
}
