import { createSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { User } from 'document-viewer/src/utils/types/notary-session-user';
import { Participant } from 'document-viewer/src/utils/types/participant';
import { selector, getStepPageData, StepPageState } from 'document-viewer/src/slices/step-page';

// selectors
const loadingSel = createSelector(selector, (state: StepPageState) => state.loading);
const pageSel = createSelector(selector, (state: StepPageState) => state.page);
const stepSel = createSelector(selector, (state: StepPageState) => state.step);
const flowTypeSel = createSelector(selector, (state: StepPageState) => state.flowType);
const participantSel = createSelector(selector, (state: StepPageState) => state.participant);
const userSel = createSelector(selector, (state: StepPageState) => state.user);


export default function useStepPage(){
  const dispatch = useDispatch();
  const loading = useSelector(loadingSel);
  const page = useSelector(pageSel);
  const participant = useSelector(participantSel) as Participant;
  const user = useSelector(userSel) as User;
  const step = useSelector(stepSel) ;
  const flowType = useSelector(flowTypeSel);


  return {
    loading,
    page,
    step,
    participant,
    user,
    flowType,
    getStepPageData: (id: string, sessionId?: string) => dispatch(getStepPageData(id, sessionId)),
  }
}
