import _ from 'lodash';
import { InstanceObject } from '../../viewer';

const removeAllUserSignatures = ({ instance }: InstanceObject) => async (signature: any) => {
  if (!signature) {
    return;
  }

  const isNotary = () => instance.annotManager.getIsAdminUser();

  const author = signature.Author;
  const currentUserId = instance.annotManager.getCurrentUser();

  if (isNotary()) {
    return;
  }

  if (author !== currentUserId) {
    return;
  }

  const annotations = [...instance.annotManager.getAnnotationsList()];
  const isSignature = (annot: any) => {
    return annot.Subject === 'Signature' && annot.Author === author && annot.CustomData.type === signature.CustomData.type;
  };
  const annotsToDelete = _.filter(annotations, isSignature);

  await Promise.all([
    instance.annotManager.deleteAnnotations(annotsToDelete),
  ]);
  // @ts-expect-error why does this have 4 arguments?
  instance.docViewer.trigger('deleteOtherDocSignatureItems', currentUserId, instance.getDocId(), signature.CustomData.type);
};

const signatureReset = () => ({ instance, ...rest }: InstanceObject) => {
  const handleReset = removeAllUserSignatures({ instance, ...rest });
  const sigTool = instance.docViewer.getTool('AnnotationCreateSignature');


  sigTool.on('signatureDeleted', handleReset);

  return { instance, ...rest };
};

export default signatureReset;
