import { clearAuthenticating, checkAuth, sendVerificationCode, setCurrentProcess, verifyPersonalPassword, verifyCode, selector as authSelector, AuthenticationState } from 'document-viewer/src/slices/authentication';
import { useDispatch, useSelector } from 'document-viewer/src/store';
import { useQuery } from './useQuery';
import { connect } from 'react-redux';
import { Action, createSelector } from '@reduxjs/toolkit';


export const currenAuthenticationIdSel = createSelector(authSelector, (authenticationState: AuthenticationState) => authenticationState.currentAuthentication);

export default function useAuth() {
  const dispatch = useDispatch();
  const query = useQuery();

  const currenAuthenticationId = useSelector(currenAuthenticationIdSel) as ReturnType<typeof currenAuthenticationIdSel>;

  return {
    currenAuthenticationId,
    clearAuthenticating: () => dispatch(clearAuthenticating()),
    checkAuth: (pId) => dispatch(checkAuth(query.session, pId)),
    sendVerificationCode: (pId, type) => dispatch(sendVerificationCode(query.session, pId, type)),
    setCurrentProcess: (currProcess) => dispatch(setCurrentProcess(currProcess)),
    verifyPersonalPassword: (pId, answer, opts) => dispatch(verifyPersonalPassword(pId, answer, opts)),
    verifyCode: (pId, answer, opts) => dispatch(verifyCode(pId, answer, opts))
  }
}


export const withAuth = connect(() => ({}), (dispatch) => ({
  clearAuthenticating: () => dispatch(clearAuthenticating()),
  checkAuth: (sessionId, pId) => dispatch(checkAuth(sessionId, pId) as unknown as Action<any>),
  sendVerificationCode: (sessionId, pId, type) => dispatch(sendVerificationCode(sessionId, pId, type) as unknown as Action<any>),
  setCurrentProcess: (currProcess) => dispatch(setCurrentProcess(currProcess) as unknown as Action<any>),
  verifyPersonalPassword: (pId, answer, opts) => dispatch(verifyPersonalPassword(pId, answer, opts) as unknown as Action<any>),
  verifyCode: (pId, answer, opts) => dispatch(verifyCode(pId, answer, opts) as unknown as Action<any>)
}))
