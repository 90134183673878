import { FunctionComponent, ReactNode } from 'react';
import { ComponentProps } from './types';
import { Button, Grid, Typography, Dialog, Box, CardHeader } from '@material-ui/core';
import { DialogTitle, DialogContent, DialogContentText, DialogActions } from 'document-viewer/src/components/Dialog';
import { useStyles } from './';


const Component: FunctionComponent<ComponentProps> = ({ open, failureReason, transactionStatus, onClick }) => {
  const classes = useStyles();
  let title: ReactNode = 'Error';
  let content: ReactNode = failureReason;
  let actions: ReactNode = (
    <Button
      data-testid='information-dialogue-close-button'
      onClick={() => {
        window.location.reload()
      }}
      color='primary'
      variant='contained'
      size='large'
    >
      {failureReason === 'Challenge Failed' ? 'Okay' : 'Refresh'}
    </Button>
  );
  if (!failureReason && transactionStatus === 'canceled') {
    // Just going to assume that this is an issue with this being in the tagging queue, just like the code used to.
    // TODO: refactor
    title = 'Error';
    content = (
      <>
        Your session has been canceled, please contact your organization for more details.
      </>
    );
  }
  else if (!failureReason) {
    // Just going to assume that this is an issue with this being in the tagging queue, just like the code used to.
    // TODO: refactor
    title = 'Session not ready';
    content = (
      <>
        Sorry, your session is still being prepared by the organization.
        <br />
        Please contact the organization and press
        {' '}
        <strong>refresh</strong>
        {' '}
        once your session is ready.
      </>
    );
  } else if (failureReason === 'Auth verification failed attempts limit reached') {
    title = 'Identity Verification Failed';
    content = (
      <>
        <br />
        A participant has failed the required authentication.
        <br></br>
        Please contact the organization that created the transaction to continue.
      </>
    )
    actions = null;
  } else if(failureReason === 'Challenge Failed') {
    title = 'Error - Insufficient Info for Questions'
    content = (
      <>
        <br />
        There was Insufficient information returned to validate your identity for online notrization
        at this time.
        <br />
        <br />

        eNotaryLog strictly complies with Florida State Law - see statute below.
        <br />
        <br />
        <i style={{ color: 'rgba(0,0,0,0.7)' }}>
          Prohibits an online Notary from performing an online notarization 
          if the online Notary is unable to verify identity of a 
          principal through remote presentation of an ID credential,
          credential analysis of the ID credential and identity proofing,
          or if the databases consulted for identity proofing do not 
          contain sufficient information to permit authentication.
        </i>
        <br />
        <br />
      </>
    )
  }

  return (
    <Dialog
      open={open}
      aria-labelledby='participant-cannot-act-title'
      aria-describedby='participant-cannot-act-description'
      fullWidth={true}
      maxWidth='sm'
      // style={{ width:'600px' , margin:'auto' }}
      onClose={() => { }}
    >
      <div className={classes.titleAndContentContainer}>
        <div className={classes.titleAndContentInnerContainer}>

          {title ?
            <DialogTitle className={classes.dialogTitle}>
              <Box >
                <Typography
                  variant='h5'
                  className={classes.dialogHeader}
                >
                  {title}

                </Typography>
              </Box>
            </DialogTitle> : null}
          {
            content ? (
              <DialogContent
                className={actions ? classes.dialogContentClass : classes.dialogContentSecondClass}
              >
                <DialogContentText className={classes.dialogContentText}>
                  {content}
                </DialogContentText>
              </DialogContent>
            ) : null
          }
        </div>
        {actions ? <DialogActions>{actions}</DialogActions> : null}
      </div>
    </Dialog>
  );
};

export default Component;
