import _ from 'lodash';
export interface FontDeclaration {
  name: string;
  weight: string;
  style: string;
  url: string;
  css: string;
};

export const parseGoogleFontsCss = (cssString: string): FontDeclaration[] => {

  const fontFaceDeclarations = cssString.split('@font-face')
    // Slicing off the first one because it will just be what came before that font declaration
    .slice(1)
    // Since the split took out @font-face, put it back
    .map((declaration) => `@font-face${declaration}`);

  return _.map(fontFaceDeclarations, (declaration) => {

    const name = declaration.match(/font-family(\s*):(\s*)('|")([a-zA-Z0-9\- .,!@#$%^&*()+=_]+)('|")/);
    const weight = declaration.match(/font-weight(\s*):(\s*)([a-zA-Z0-9\-.]+)/);
    const style = declaration.match(/font-style(\s*):(\s*)('|"|)([a-zA-Z0-9\-.,!@#$%^&*()+=_]+)('|"|)/);
    const url = declaration.match(/src(\s*):(\s*)url\((\s*)('|"|)([a-zA-Z0-9\-.,!@#$%^&()+=_:/?]+)('|"|)(\s*)\)/);

    return _.pickBy({
      name: _.get(name, '4'),
      weight: _.get(weight, '3'),
      style: _.get(style, '4'),
      url: _.get(url, '5'),
      css: declaration,
    }, _.identity) as FontDeclaration
  });
};
