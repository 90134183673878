import React, { createContext, useContext } from 'react';

export const DocumentThumbnailsContext = createContext(null);

export const useDocumentThumbnails = () => useContext(DocumentThumbnailsContext);

export const DocumentThumbnailsProvider = ({
  thumbnails,
  highlightedPageNumber,
  onPageSelected,
  docId,
  children
}) => (
  <DocumentThumbnailsContext.Provider
    value={{
      thumbnails: thumbnails || {},
      highlightedPageNumber,
      onPageSelected,
      docId
    }}
  >
    {children}
  </DocumentThumbnailsContext.Provider>
);
