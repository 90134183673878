import React from 'react'

import {
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import _ from 'lodash';
import { participantColors } from '@enotarylog/tag-utils/constants/colors';
import { useTheme } from 'document-viewer/src/lib/hooks/useTheme';
import SignatureInitialView from 'document-viewer/src/components/SignatureInitialsModal/Component';
import { Accordion } from 'document-viewer/src/components/Accordion';
import { Participant } from 'document-viewer/src/utils/types/participant';
import clsx from 'clsx';
import useEllipses from 'document-viewer/src/utils/styles/useEllipses';

const signerColors = participantColors.map((item) =>
  `rgba(${item[0]}, ${item[1]}, ${item[2]}, ${item[3] - .2})`
);

const useStyles = makeStyles(() => ({
  accordion: { overflowY: 'auto' },
  noBorder: { borderBottom: 'none !important' },
  accDetailsDiv: { width: '100%' },
  accordionDetails: { marginTop: '-20px', padding: '10px 0' },
  summaryHeader: {
    margin: 0,
    padding: 0,
    cursor: 'auto',
    textTransform: 'uppercase'
  },
  participantBox: {
    padding: 16,
    borderRadius: '8px 0 0 8px',
    margin: 16,
    marginRight: 0,
    boxShadow: '0 4px 4px rgba(0,0,0,.2)'
  },
  participantHeader: { margin: 0, textAlign: 'center' },
}))


interface Props {
  onClick: (x: any) => any;
  participants: Record<string, Participant>;
  selectedParticipant: string | null
}

const ParticipantsList = ({
  onClick,
  participants,
  selectedParticipant
}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { ellipses } = useEllipses({ numLines: 2 });

  return (
    <Accordion
      square
      expanded
      className={clsx('mt-0', classes.accordion)}
      classes={{ root: classes.noBorder }}
    >

      <AccordionSummary
        aria-controls='panel1d-content'
        id='panel1d-header'
      >
        <h2 className={classes.summaryHeader}>Signers</h2>
      </AccordionSummary>

      <AccordionDetails className={classes.accordionDetails}>
        <div className={clsx('d-flex flex-column w-100', classes.accDetailsDiv)}>
          {
            _.chain(participants)
              .values()
              .sort((a, b) => a.id !== selectedParticipant ? a.order - b.order : -1)
              .map((participant) => (
                <div
                  key={participant.id}
                  className={classes.participantBox}
                  style={{ backgroundColor: signerColors[participant.order % signerColors.length] }}
                >
                  <Tooltip
                    title={`${participant.firstName} ${participant.lastName}`}
                    aria-label={`${participant.firstName} ${participant.lastName}`}
                  >
                    <h3
                      className={clsx(classes.participantHeader, ellipses)}
                      onClick={() => onClick(participant.id)}
                    >
                      {`${participant.firstName} ${participant.lastName}`}
                      &nbsp;
                    </h3>
                  </Tooltip>
                  {
                    (
                      (theme?.showParticipants ?? true)
                      && (participant.id === selectedParticipant)
                      && (participant.signature)
                      && (participant.initials)
                    ) && (
                      <SignatureInitialView
                        signature={participant?.signature}
                        initials={participant?.initials}
                        onClick={() =>
                          // if (!authedPs.current.includes(participant.id)) {
                          // checkAuth(participant.id)
                          // }
                          onClick(participant.id)}
                      />
                    )
                  }
                </div>
              ))
              .value()
          }



        </div>
      </AccordionDetails>
    </Accordion >
  )
}


export default ParticipantsList;
