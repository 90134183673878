import { AppBar, Tabs, Tab, makeStyles } from "@material-ui/core";
import { Theme } from "document-viewer/src/lib/hooks/useTheme";
import React from "react";
import { a11yProps } from "./utils";


const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    background: theme.palette.background.paper,
  }
}));

export default function SelectInputTypeAppBar({
  type,
  activeTab,
  onActiveTabChanged,
}) {

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    onActiveTabChanged(newValue);
  };

  return (
    <AppBar
      position='static'
      color='default'
    >
      <Tabs
        className={classes.tabs}
        value={activeTab || 0}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
        aria-label='full width tabs example'
      >
        <Tab
          label='Type it in'
          {...a11yProps(0)}
        />
        <Tab
          label='Draw it'
          data-testid={`drawItBtn-${type}`}
          {...a11yProps(1)}
        />
      </Tabs>
    </AppBar>
  )
}
