import { selector as transactionSelector, getTransaction, setShowCompletedNotification, updateTransaction } from 'document-viewer/src/slices/transaction';
import { createSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'document-viewer/src/store';
import * as R from '@enotarylog/ramda';
import { useCallback } from 'react';
import { TransactionStatus } from 'document-viewer/src/utils/types/transaction';

const loadingSel = createSelector(transactionSelector, (t) => t.loading);
const participantsCannotActSel = createSelector(transactionSelector, (t) => t.participantsCannotAct || t.lockedOut || t.status === TransactionStatus.canceled);
const tIdSel = createSelector(transactionSelector, (t) => t.id);
const failureReasonSel = createSelector(transactionSelector, (t) => t.failureReason);
const showCompletedNotificationSel = createSelector(transactionSelector, (t) => t.showCompletedNotification);
const tTransactionStatusSel = createSelector(transactionSelector, (t) => t.status);

export default function useTransaction() {
  const dispatch = useDispatch();
  const loading = useSelector<boolean>(loadingSel);
  const participantsCannotAct = useSelector(participantsCannotActSel);
  const transactionId = useSelector<string>(tIdSel);
  const transactionStatus = useSelector(tTransactionStatusSel);

  return {
    loading,
    participantsCannotAct,
    transactionId,
    getTransaction: R.pipe(getTransaction, dispatch),
    showCompletedNotification: useSelector<boolean>(showCompletedNotificationSel),
    setShowCompletedNotification: useCallback((show: boolean) => dispatch(setShowCompletedNotification({ show })), [dispatch]),
    updateTransaction: useCallback((transaction) => dispatch(updateTransaction(transaction)), [dispatch]),
    failureReason: useSelector(failureReasonSel),
    transactionStatus,
  }
}
