import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import TypeItIn from './typeIn';
import DrawIt from './drawIt';
import clsx from 'clsx';
import { useSetSigInitStyles } from './styles';
import TabPanel from './TabPanel';
import SelectInputTypeAppBar from './SelectInputTypeAppBar';
import { DialogContent } from '@material-ui/core';

export default function SetSigInit({
  color,
  setColor,
  type,
  onChange,
  activeTab,
  onActiveTabChanged,
  open,
  participantName,
  fontFamilies,
  fontFamily,
  setFontFamily
}) {
  const classes = useSetSigInitStyles();
  const theme = useTheme();

  const handleChangeIndex = (index) => {
    onActiveTabChanged(index);
  };

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.hidden]: !open,
      })}
    >
      <SelectInputTypeAppBar
        type={type}
        activeTab={activeTab}
        onActiveTabChanged={onActiveTabChanged}
      />
      <TabPanel
        value={activeTab}
        index={0}
      >
        <DialogContent
          className={classes.typeInDialogContent}
          style={{ paddingBottom: 0 }}
        >
          <TypeItIn
            onChange={(_, data) => onChange('typeitin', data)}
            type={type}
            color={color}
            setColor={setColor}
            participantName={participantName}
            fontFamilies={fontFamilies}
            fontFamily={fontFamily}
            setFontFamily={setFontFamily}
            isVisible={open}
          />
        </DialogContent>
      </TabPanel>
      <TabPanel
        value={activeTab}
        index={1}
      >
        <DialogContent
          className={classes.drawItDialogContent}
          style={{ paddingBottom: 0, overflow: 'hidden' }}
        >
          <DrawIt
            type={type}
            onChange={(data) => onChange('drawit', data)}
            color={color}
            setColor={setColor}
          />
        </DialogContent>
      </TabPanel>
    </div>
  );
}
