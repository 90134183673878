import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { participantColors } from '@enotarylog/tag-utils/constants/colors';
import { Dialog, DialogContent, DialogTitle } from 'document-viewer/src/components/Dialog';
import { Theme } from 'document-viewer/src/lib/hooks/useTheme';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  btnDiv: {
    textAlign: 'center',
  },

  fullHeight: { height: '100%', padding: 16 },

  bold: {
    paddingTop: 8,
    fontWeight: 'bold'
  },
  root: {
    backgroundColor: '#fff'
  },
  autoScrollText: {
    background: '#fff',
    paddingTop: theme.signatureAndInitialsInOneStep ? 0 : undefined,
    paddingBottom: 16,
    overflowY: theme.signatureAndInitialsInOneStep ? 'hidden' : 'auto',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto'
    }
  },
  startButton: {
    minWidth: 200
  },
  title: theme.signatureAndInitialsInOneStep ?
    { paddingBottom: 0, marginBottom: 0 }
    : {},
  customTitle: {
    color: '#263238',
    background: '#F4F6F8',
    marginLeft: '-15px',
    marginRight: '-15px',
    marginTop: '-15px'
  },
  tagImg: {
    maxWidth: 120,
    objectFit: 'contain',
    margin: 10,
    boxShadow: '0 4px 4px rgba(0,0,0,.2)',
  },
}));

const firstParticipantColor = `rgba(${participantColors[0]}, ${participantColors[1]}, ${participantColors[2]}, ${participantColors[3]})`;
export const sampleCustomTag = (svg: string) => {
  const filledInSvg = svg
    .replace(/{{(s|S)ignerName}}/g, 'Sample Signer')
    .replace(/{{(c|C)olor}}/g, firstParticipantColor);
  return `data:image/svg+xml;base64,${btoa(filledInSvg)}`;
}
export const sampleTag = (customSvg: string, defaultImage: string) => customSvg ? sampleCustomTag(customSvg) : defaultImage;

export default function AutoSignDisclaimer({ open, onClose, onAccept, customTags }) {
  const classes = useStyles();
  const tileData = [
    {
      img: sampleTag(customTags.INITIALHERETAGTEMPLATEANNOTATION, '/static/init.png'),
      alt: 'Initial tag',

    },
    {
      img: sampleTag(customTags.SIGNHERETAGTEMPLATEANNOTATION, '/static/sign.png'),
      alt: 'Signature tag',
    },
    {
      img: '/static/date.png',
      alt: 'Date tag',
    }
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      size='sm'
      // style={{ maxWidth: '760px' }}
    >
      <Grid
        container
        justifyContent='center'
        direction='column'
        className={clsx(classes.root, classes.fullHeight)}
      >
        <Grid
          item
        >
          <DialogTitle className={clsx(classes.title, classes.customTitle)}>
            Autoscroll
          </DialogTitle>
          <DialogContent className={classes.autoScrollText}>
            <Typography variant='caption'>To enhance your signing experience, our Autoscroll feature allows you to read documents at your own pace.</Typography>
            <br />

            <div className={classes.root}>
              <div style={{ textAlign: 'center' }}>
                {tileData.map((tile) => (
                  <img
                    className={classes.tagImg}
                    src={tile.img}
                    key={tile.img}
                    alt={tile.alt}
                  />
                ))}
              </div>
              <br />
              <Typography variant='caption'>
                To adjust the speed of the document, use the toolbar feature called “SCROLL SPEED”.
              </Typography>
              <br />
              <br />
              <Typography variant='caption'>
                To stop AutoScroll, or to scroll manually, use the scroll wheel on your mouse or click “STOP” at the top of the page.
              </Typography>
            </div>
          </DialogContent>
        </Grid>
        <Grid
          item
        >
          <div
            className={classes.btnDiv}
          >
            <Button
              data-testid='autoScrollDisclaimerBtn'
              color='primary'
              variant='contained'
              onClick={onAccept}
              className={classes.startButton}
            >
              START SIGNING
            </Button>
          </div>
        </Grid>
      </Grid>
    </Dialog >
  );
}
