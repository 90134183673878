import { FunctionComponent, useEffect, useRef, useState, createRef, LegacyRef } from 'react';
import { ComponentProps } from './types';
import { useStyles } from './styles';
import Passbase from '@passbase/button';
import LoadingScreen from 'document-viewer/src/components/LoadingScreen';
import { useEffectOnce } from 'react-use';
import { CredentialAnalysisProvider } from '../../../utils/types/transaction';

const Component: FunctionComponent<ComponentProps> = ({ onSuccess, onFail, saving, user, provider, transactionId }) => {
  const classes = useStyles();
  // The goal of hasStartedRef is to prevent having the verify button be clicked more than once
  const hasStartedRef = useRef(false);
  // Ideally, the button shouldn't even be visible. But if they close passbase, then we gotta show it to them and explain to them that they need to use passbase
  const [isVisible, setIsVisible] = useState(true);

  const [jumioIframeUrl, setJumioIframeUrl] = useState<string>();
  const [jumioTransactionId, setJumioTransactionId] = useState<string>();
  const [waitingOnJumio, setWaitingOnJumio] = useState<boolean>(false);

  const [hasStartPassbaseFired, setHasStartPassbaseFired] = useState(false);
  const [passbaseBuildStarted, setPassbaseBuildStarted] = useState(false);
  const tagRef = createRef();

  const attemptTriggerModalDelay = 2000;
  const jumioStatusPollDelay = 4000;

  const attemptTriggerModal = () => {

    if(!hasStartPassbaseFired)
    {
      Passbase.start();
    }
    else
    {
      setTimeout(attemptTriggerModal, attemptTriggerModalDelay);
    }
  };

  const getJumioIframeUrl = async (tid: string, pid: string) => {
    await fetch(
      `/transactions/${tid}/participants/${pid}/credential-analysis/url`
    )
      .then((response) => response.json())
      .then((response) => {
        setJumioIframeUrl(response.url);
        setJumioTransactionId(response.credAnalysisTransactionId);
      });
  }

  const getJumioStatus = async () => {
    await fetch(
      `/transactions/credential-analysis/${jumioTransactionId}/status`
    )
      .then((response) => response.json())
      .then((response) => {
        // This is how we are "polling" for a final status. There are better
        //  ways to do this, and going forward we may want to address
        //  this method. Essentially we ping the Jumio API to get the
        //  status of the transaction until something other than
        //  PENDING is returned.
        if (response.status === 'PENDING') {
          setTimeout(() => getJumioStatus(), jumioStatusPollDelay);
          return;
        }
        if (response.status === 'APPROVED_VERIFIED') {
          onSuccess(jumioTransactionId);
          completeJumioListener();
        } else {
          onFail(jumioTransactionId);
          completeJumioListener();
        }
      });
  }

  const onMessageEvent =  async (e) => {
    if ((e.data.source || '').includes('react-devtools')) {
      return;
    }

    try {
      const data = JSON.parse(e.data);
      if (data.payload.value === 'success') {
        setWaitingOnJumio(true);
        getJumioStatus();
      }
      console.info("Message event value: ", data.payload.value);
    } catch (err) {
      console.error(err);
    }
  }

  const setJumioListener = () => {
    window.addEventListener('message', onMessageEvent, false);
  }

  const completeJumioListener = () => {
    window.removeEventListener('message', onMessageEvent);
  }

  useEffect(() => {
    if (provider !== CredentialAnalysisProvider.passbase) {
      return;
    }

    const attemptTriggerBuildDelay = 1000;
    const attemptBuildPassbase = () => {
      if(!passbaseBuildStarted) {
        const element = tagRef.current as HTMLElement;
        if(element) {
          setPassbaseBuildStarted(true);
          Passbase.renderButton(
            element,
            process.env.NX_PASSBASE_API_KEY!,
            {
              onFinish: (identityAccessKey) => {

                if (identityAccessKey) {

                  onSuccess(identityAccessKey)
                }
              },
              onStart: () => {
                setHasStartPassbaseFired(true);
              },
              onError: (...args) => {
                if (args[0] === 'CANCELLED_BY_USER') {
                  setIsVisible(true);
                }
                onFail(args[0]);
              },
            }
          );

          setTimeout(attemptTriggerModal, attemptTriggerModalDelay);

        }
      }

      if(!passbaseBuildStarted) {
        setTimeout(attemptBuildPassbase, attemptTriggerBuildDelay);
      }

    }

    attemptBuildPassbase();
  }, [passbaseBuildStarted]);

  useEffect(() => {
    if (provider !== CredentialAnalysisProvider.jumio) {
      return;
    }
    getJumioIframeUrl(
      transactionId,
      user.id,
    )
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (!jumioTransactionId) {
      return;
    }
    setJumioListener();
  }, [jumioTransactionId]);

  // When saving finishes, assume that this process has not been started
  useEffect(() => {
    if (!saving) {
      hasStartedRef.current = false;
    }
  }, [saving]);

  if (waitingOnJumio) {
    return <LoadingScreen />;
  }

  const passbaseMarkup = (
    <div
      // Hide the button to avoid confusing the user. If they close the modal, then the button will be shown
      className={isVisible ? classes.centered : classes.hidden}
    >
      <div className={classes.centered}>
        You must verify your identity to continue the process. Please click the button below.

        <div ref={tagRef as LegacyRef<HTMLDivElement>}></div>

      </div>
    </div>
  );

  const jumioMarkup = (
    <div className={isVisible ? classes.centered : classes.hidden}>
      <div className={classes.centered}>
        <iframe
          title='document-verification'
          src={jumioIframeUrl}
          allow='camera; microphone'
          className='embed-responsive-item'
          width='100%'
          height='75%'
          style={{ bottom: '0', position: 'absolute' }}
        />
      </div>
    </div>
  );

  return provider === CredentialAnalysisProvider.jumio
    ? jumioMarkup
    : passbaseMarkup;
};

export default Component;
