import { Action, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store'
import _ from 'lodash';
import type { Annotations } from '@pdftron/webviewer';


interface AnnotationsState {
  annotations: {
    byId: Record<string, Annotations.Annotation[]>,
    allIds: string[]
  },
  appliedAnnotIds: {
    byId: Record<string, string[]>,
    allIds: string[]
  },
  reviewedAnnotIds: {
    byId: Record<string, string[]>,
    allIds: string[]
  },
  pendingAnnot: Annotations.Annotation | null
}

const initialState: AnnotationsState = {
  annotations: {
    byId: {},
    allIds: []
  },
  appliedAnnotIds: {
    byId: {},
    allIds: []
  },
  reviewedAnnotIds: {
    byId: {},
    allIds: []
  },
  pendingAnnot: null,

};

const autoPropagateReviewedAnnots = (state: AnnotationsState, docId: string) => {
  state.reviewedAnnotIds.byId[docId] = state.annotations.byId[docId]
    .filter((annot) => annot.CustomData?.reviewed)
    .map((annot) => annot.Id);
}

const slice = createSlice({
  name: 'annotations',
  initialState,
  reducers: {
    setDocAnnots(state: AnnotationsState, action: PayloadAction<{ docId: string, annotations: Annotations.Annotation[] }>) {
      const { payload } = action;
      state.annotations.byId[payload.docId] = payload.annotations;
      state.annotations.allIds = _.uniq([...state.annotations.allIds, payload.docId]);
      // Add reviewed annots to reviewedAnnotIds, otherwise the UI may think that a doc has some unreviewed annots even though it does not
      autoPropagateReviewedAnnots(state, payload.docId);
    },

    addAppliedAnnot(state: AnnotationsState, action: PayloadAction<{ docId: string, annotId: string }>) {
      const { payload } = action;
      const byId = state.appliedAnnotIds.byId[payload.docId] || [];
      state.appliedAnnotIds.byId[payload.docId] = _.uniq([...byId, payload.annotId]);
      (window as unknown as any).hasUnsaved = true;
    },
    clearAppliedAnnots(state: AnnotationsState, action: PayloadAction<{ docId: string }>) {
      state.appliedAnnotIds.byId[action.payload.docId] = [];
      (window as unknown as any).hasUnsaved = false;
    },
    addReviewedAnnot(state: AnnotationsState, action: PayloadAction<{ docId: string, annotId: string }>) {
      const { payload } = action;
      const byId = state.reviewedAnnotIds.byId[payload.docId] || [];
      state.reviewedAnnotIds.byId[payload.docId] = _.uniq([...byId, payload.annotId])
    },
    removeAppliedAnnot(state: AnnotationsState, action: PayloadAction<{ docId: string, annotId: string }>) {
      const { payload } = action;
      state.appliedAnnotIds.byId[payload.docId] = _.filter(state.appliedAnnotIds.byId[payload.docId] || [], (aId) => aId !== payload.annotId);
    },
    setPendingAnnot(state: AnnotationsState, action: PayloadAction<Annotations.Annotation>) {
      state.pendingAnnot = action.payload;
    },
    clearPendingAnnot(state: AnnotationsState) {
      state.pendingAnnot = null
    },
    determineReviewedAnnots(state: AnnotationsState, { payload }: PayloadAction<{ docId: string }>) {
      autoPropagateReviewedAnnots(state, payload.docId);
    }
  }
});

export const reducer = slice.reducer;
export const selector = (state: RootState) => state.annotations;
export const appliedAnnotIdsByIdSel = (state: RootState) => state.annotations.appliedAnnotIds.byId;
export const reviewedAnnotIdsByIdSel = (state: RootState) => state.annotations.reviewedAnnotIds.byId;

export const setDocAnnots = (docId: string, annots: Annotations.Annotation[]): Action => slice.actions.setDocAnnots({ docId, annotations: annots }) as Action;
export const addAppliedAnnot = (docId: string, annotId: string): Action => slice.actions.addAppliedAnnot({ docId, annotId }) as Action;
export const addReviewedAnnot = (docId: string, annotId: string): Action => slice.actions.addReviewedAnnot({ docId, annotId }) as Action;
export const removeAppliedAnnot = (docId: string, annotId: string): Action => slice.actions.removeAppliedAnnot({ docId, annotId }) as Action;
export const clearAppliedAnnots = (docId: string): Action => slice.actions.clearAppliedAnnots({ docId }) as Action;
export const setPendingAnnot = (annot: Annotations.Annotation): Action => slice.actions.setPendingAnnot(annot);
export const clearPendingAnnot = (): Action => slice.actions.clearPendingAnnot();
export const { determineReviewedAnnots } = slice.actions;

export default slice;
