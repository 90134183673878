import React, { FunctionComponent } from 'react';
import { ErrorGetKbaQuestionsDialog } from './types';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from 'document-viewer/src/components/Dialog';
import { Button, PaperProps } from '@material-ui/core';
import { useStyles } from './styles';

const ErrorGetKbaQuestionsWarning: FunctionComponent<ErrorGetKbaQuestionsDialog> = ({ open, onClose }) => {
  const classes = useStyles();
  const dialogPaperProps: PaperProps = {
    classes: {
      root: classes.dialogMain
    }
  }
  
  if (!open) return null;

  return (
    <div data-testid='ErrorGetKbaQuestionsWarning'>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onClose()
          }
        }}
        size='md'
        fullWidth={false}
        PaperProps={dialogPaperProps}
      >
        <DialogTitle className={classes.dialogTitle}>Error - Unable to generate  questions</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogText}>
            We were not able to generate questions using the information provided. Please
            check that you are submiting the correct information and try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            onClick={onClose}
            className={classes.dialogButton}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ErrorGetKbaQuestionsWarning;
