export enum TransactionStatus {
  pendingDEPRECATED = 'pending',
  esignPending = 'esign_pending',
  ronPending = 'ron_pending',
  completed = 'completed',
  canceled = 'canceled',
  taggingPending = 'tagging_pending',
  taggingInProgress = 'tagging_in_progress',
  taggingCompleted = 'tagging_completed'
}

export interface Transaction {
  id: string | null;
  status: TransactionStatus | null;
  lockedOut: boolean;
  failureReason: string | null;
}

export enum CredentialAnalysisProvider {
  jumio = 'jumio',
  passbase = 'passbase',
}
