import React, { createContext, useContext, useState } from 'react';

const AutoScrollSpeedContext = createContext({
  scrollPxPerSec: 0,
  setScrollPxPerSec: (_speed: number) => {},
  isAutoScrolling: false,
  setIsAutoScrolling: (_: boolean) => {},
});

export const useAutoScrollSpeed = () => useContext(AutoScrollSpeedContext);

export const AutoScrollSpeedProvider = ({ children }) => {
  const [scrollPxPerSec, setScrollPxPerSec] = useState(47);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);

  return (
    <AutoScrollSpeedContext.Provider
      value={{
        scrollPxPerSec,
        setScrollPxPerSec,
        isAutoScrolling,
        setIsAutoScrolling,
      }}
    >
      {children}
    </AutoScrollSpeedContext.Provider>
  );
};
