import moment from 'moment';
import { tagTypes, states } from './constants';
import { parseDate, parseName } from './helpers';
import { ImageTagKeys, NotaryVerbiageTagKeys, AddressTagKeys, IAddressInfo, NameTagKeys, INameSpec, IUserSpec, DateTagKeys, AddressInfoKeys, FreeFormTagKeys } from './types';

const { abbrToFull } = states;

type DateFormats = 'MM/dd/yyyy' | 'do' | 'MMMM' | 'yyyy' | 'MM/DD/YYYY' | 'DD/MM/YYYY' | 'MM/DD/YY' | 'DD/MM/YY' | 'MMMM do, yyyy';
type NameFormats = 'firstName' | 'middleName' | 'lastName';
interface IFlags {
  stateFormat?: 'full' | null;
  addressFormat?: 'full' | null;
  dateFormat?: DateFormats;
}


interface ICustomData {
  type: string;
  flags: IFlags
}


export const createContent = (customData: ICustomData, user: INameSpec | IUserSpec | IAddressInfo) => {
  if (customData.type in tagTypes.addressTags) {
    const tagType = tagTypes.addressTags[customData.type as AddressTagKeys];
    const {
      address1,
      address2 = '',
      city,
      state,
      zip,
    } = user as IAddressInfo;

    if (tagType.field === 'address') {
      let formattedAddress = address1;

      if (customData.flags.addressFormat === 'full') {
        formattedAddress = `${address1}, ${address2 ? `${address2}, ` : ''}${city}, ${state}, ${zip}`;
      }

      return formattedAddress;
    }

    if (tagType.field === 'state') {
      if (customData.flags.stateFormat === 'full') {
        return abbrToFull[state];
      }

      return state;
    }

    return (user as IAddressInfo)[tagType.field as AddressInfoKeys];
  }


  if (customData.type in tagTypes.dateTags) {
    // Get dateFormat if it exists otherwise default
    const dateFormat = customData.flags.dateFormat || tagTypes.dateTags[customData.type as DateTagKeys].dateFormat;

    const dateFormats = (customData.type === 'DATETEMPLATE') ? tagTypes.dateTags.DATETEMPLATE.dateFormats : [];
    const { flags = {} } = customData;


    if (flags.dateFormat && dateFormats.length > 0) {
      const foundDateFormat = dateFormats.find((el: string) => el === flags.dateFormat);

      if (foundDateFormat) {
        return moment().format(foundDateFormat);
      }
    }


    return parseDate(new Date(), dateFormat);
  }

  if (customData.type in tagTypes.nameTags) {
    const tagType = tagTypes.nameTags[customData.type as NameTagKeys];

    if (tagType.field === 'fullName') {
      return parseName(user as INameSpec | IUserSpec);
    }

    return (user as INameSpec)[tagType.field as NameFormats];
  }

  if (customData.type in tagTypes.freeFromTag) {
    const tagType = tagTypes.freeFromTag[customData.type as FreeFormTagKeys];

    if (tagType.field === 'freeForm') {
      return 'Text Input For ' + parseName(user as INameSpec | IUserSpec);
    }

  }

  if (customData.type in tagTypes.notaryVerbiageTags) {
    return tagTypes.notaryVerbiageTags[customData.type as NotaryVerbiageTagKeys].text;
  }

  if (customData.type in tagTypes.imageTags) {
    return tagTypes.imageTags[customData.type as ImageTagKeys].text;
  }

  return '';
};
