import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, Button, useMediaQuery } from '@material-ui/core';
import SignatureCanvas from 'react-signature-canvas';
import _ from 'lodash';
import { useDrawItStyles, SIG_CANVAS_HEIGHT, getSigCanvasBorder } from './styles';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useTheme } from 'document-viewer/src/lib/hooks/useTheme';
import FontAndColorSelectors from './FontAndColorSelectors';

function DrawIt({ type, onChange: _onChange, color, setColor, combinedStyle = false }) {
  const sigRef = useRef<any>(null);
  const styles = useDrawItStyles({ combinedStyle });
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [isCanvasBlank, setIsCanvasBlank] = useState(true);
  const onChange = useCallback(({ image, canvas }) => {
    _onChange({ image, canvas });
    setIsCanvasBlank(!image);
  }, [_onChange]);

  const handleColorChange = useCallback((newColor) => {
    if (color !== newColor) {
      setColor(newColor);
    }

    if (sigRef && sigRef.current) {
      const sigPad = sigRef.current.getSignaturePad();
      const newData = _.map(sigPad._data, (d) => _.map(d, (point) => ({ ...point, color: newColor })));

      sigPad.fromData(newData);
      const sigInitsC = sigRef.current.getTrimmedCanvas();
      const sigInits = sigInitsC.toDataURL();
      onChange({
        image: sigInits,
        canvas: sigInitsC,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, setColor]);

  const clearCanvases = useCallback(() => {
    sigRef.current.clear();
    onChange({
      image: '',
      canvas: null,
    });
  }, [onChange]);

  useEffect(() => {
    handleColorChange(color);
  }, [color, handleColorChange]);

  return (
    <>
      {!combinedStyle ? (
        <Grid
          container
          direction='row'
          justifyContent='space-evenly'
          alignItems='center'
          spacing={2}
          style={{ marginBottom: combinedStyle ? 32 :24 }}
        >
          <Grid
            item
            md={5}
            xs={12}
            style={{ textAlign: 'center' }}
          >
            {`Draw your ${type}`}
          </Grid>
          <Grid
            item
            xs={5}
          >
            {type === 'signature' && (
              <FontAndColorSelectors
                canChooseColor
                color={color}
                setColor={handleColorChange}
                showLabels
              />
            )}
          </Grid>
          <Grid
            item
            sm={2}
            xs={6}
          >
            <Button
              type='button'
              onClick={() => clearCanvases()}
              variant='outlined'
              color='secondary'
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      ) : null}
      <Grid
        className={styles.sigCanvas}
      >
        <AutoSizer className={styles.sigCanvasAutoSizer}>
          {({ width }: {width: number}) => (
            <SignatureCanvas
              ref={sigRef}
              penColor={color}
              dotSize={5}
              onBegin={() => setIsCanvasBlank(false)}
              onEnd={() => {
                const sigInitsC = sigRef.current.getTrimmedCanvas();
                const sigInits = sigInitsC.toDataURL();

                onChange({
                  image: sigInits,
                  canvas: sigInitsC,
                });
              }}
              canvasProps={{ height: `${SIG_CANVAS_HEIGHT}px`, width: `${width - getSigCanvasBorder({ isMdUp, combinedStyle }) * 2}px` }}
            />
          )}
        </AutoSizer>
        {isCanvasBlank && combinedStyle ? (
          <div className={styles.placeholder}>
            {type === 'signature' ? 'Sign Here' : 'Initials Here'}
          </div>
        ) : null}
        {combinedStyle ? (
          <Button
            variant='text'
            className={styles.clearBtnOnCanvas}
            onClick={() => clearCanvases()}
          >
            Clear
          </Button>
        ) : null}
      </Grid>
    </>
  );
}

export default DrawIt;
