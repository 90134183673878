import { gql } from '@apollo/client';

export const GET_AUDIT = gql`
  query getAuditTrail($transactionId: ID!){
    getAudits(transactionId: $transactionId){ 
      entity
      event
      entityId
      createdAt
      data
    }
  }
`;

export const ADD_AUDIT = gql`
  mutation addAudit(
    $participantId: String!
    $docId: String!
    $event: String!
    $data: JSONObject!
  ) {
    addAudit(
      participantId: $participantId
      docId: $docId
      event: $event
      data: $data
    )
  }
`
