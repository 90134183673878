import { makeStyles } from '@material-ui/core/styles';
import { defaultTheme } from 'document-viewer/src/constants';
import { Theme } from 'document-viewer/src/lib/hooks/useTheme';

export const useStyles = makeStyles((theme: Theme) => ({
  titleAndContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
    maxWidth:'md',
    background:'#fff'
  },
  titleAndContentInnerContainer: {
    width: theme.signatureAndInitialsInOneStep ? 'unset' : '100%',
  },
  dialogTitle: { backgroundColor: defaultTheme.dialogTitle , padding: '32px 24px', color: defaultTheme.dialogTextColor },

  dialogTitleContent:{
    fontSize: '0.9rem',
    fontWeight: 600,
    padding: '30px 20px 30px 24px'
  },
  dialogContent: { paddingBottom: '50px',
    background:'#fff' }, 
  dialogContentClass: { backgroundColor : defaultTheme.headerBackground } ,
  dialogContentText : { fontSize: '.875rem', lineHeight: '1.4' },
  dialogTitles: { backgroundColor: defaultTheme.dialogTitle , padding: '32px 24px' },
  dialogHeader: { fontSize: '1rem', fontWeight: 500 },

  dialogContentSecondClass: { backgroundColor : defaultTheme.headerBackground ,paddingBottom:'30px' } 

}));
