import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const Accordion = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    marginTop: '0 !important',
    overflow: 'auto',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginBottom: 0,
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    marginTop: '0 !important',
    overflow: 'auto',
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


export class AccordionSummary extends React.Component<Record<any, any>> {
  render() {
    return (
      <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon style={{ margin: 0 }} />}
        {...this.props}
      />
    )
  }
}
