import React, {
  Suspense,
  Fragment,
  lazy,
  useCallback,
  useState,
} from 'react';
import {
  Switch,
  Redirect,
  Route,
  useLocation,
  useHistory,
} from 'react-router-dom';
import LoadingScreen from 'document-viewer/src/components/LoadingScreen';
import NotFound from 'document-viewer/src/pages/errors/NotFoundView';
import EsignGuard from 'document-viewer/src/lib/guards/esign.guard';
import SessionPage from 'document-viewer/src/pages/session';
import NotaryRoom from 'document-viewer/src/pages/notary-room';
import DocumentDelivery from 'document-viewer/src/pages/document-delivery';
import { useQuery } from 'document-viewer/src/lib/hooks/useQuery';
import { useCustomCompareEffect, useDebounce, useEffectOnce, useLocalStorage } from 'react-use';
import EquipmentCheck from 'document-viewer/src/pages/equipment-check';
import { useRequestData } from 'document-viewer/src/lib/hooks/useRequestData';
import * as _ from 'lodash';
import SetupStepper from 'document-viewer/src/components/SetupStepper';
import { SessionProvider } from 'document-viewer/src/lib/hooks/useSessionStatus';
import { WebViewerProvider } from 'document-viewer/src/lib/hooks/useWebViewer';
import * as constants from './constants';
import useStepPage from 'document-viewer/src/lib/hooks/useStepPage';
import { useSubscription } from '@apollo/client';
import { NS_USER_SUBSCRIPTION } from 'document-viewer/src/lib/gql/step-page'
import { PARTICIPANT_SUBSCRIPTION } from 'document-viewer/src/lib/gql/participant';
import sessionCanceled from 'document-viewer/src/pages/sessionCanceled'
import { AxiosProvider } from 'document-viewer/src/lib/context/AxiosContext';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

export const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: () => <NotFound />
  },
  {
    path: '/session/complete',
    exact: true,
    component: DocumentDelivery,
    guard: EsignGuard,
  },
  {
    path:'/session/canceled',
    component:sessionCanceled
  },
  {
    path: '/session/:transactionId',
    guard: function SkipAlreadyFinishedEquipmentCheck({ children }) {
      const [passedEqCheckForSession] = useLocalStorage('passedEqCheckForSession', null);

      const { session, iframe, transactionId, sessionId } = useQuery();
      const history = useHistory();
      const { search, pathname } = useLocation();
      const { getRequestData } = useRequestData();
      const { flowType, participant, step, page, getStepPageData } = useStepPage();
      const [nsUserLastUpdatedAt, setNsUserLastUpdatedAt] = useState<number | null>(null);
      const [participantLastUpdatedAt, setParticipantLastUpdatedAt] = useState<number | null>(null);


      useEffectOnce(() => {
        getStepPageData(transactionId, sessionId);
        getRequestData(iframe, true);
      });

      useDebounce(async () => {
        if (!nsUserLastUpdatedAt && !participantLastUpdatedAt) return;
        getStepPageData(transactionId, sessionId);
      }, 500, [transactionId, nsUserLastUpdatedAt, sessionId, participantLastUpdatedAt, getStepPageData]);

      useSubscription(NS_USER_SUBSCRIPTION, {
        variables: {
          nsUserId: participant?.id
        },
        onSubscriptionData: () => setNsUserLastUpdatedAt(Date.now()),
        shouldResubscribe: () => true
      })

      useSubscription(PARTICIPANT_SUBSCRIPTION, {
        variables: {
          pId: participant?.id
        },
        skip: !participant?.id,
        shouldResubscribe: () => true,
        onSubscriptionData: () => setParticipantLastUpdatedAt(Date.now()),
      });


      useCustomCompareEffect(() => {
        if (flowType) {
          // make sure equipment check is done
          if (passedEqCheckForSession !== session && !pathname.includes('equipment-check') && flowType !== 'esignOnly') {
            return history.push(`/session/${transactionId}/equipment-check${search}`)
          }
          // send them to whereever they need to be
          if (page && !pathname.includes(page) && !pathname.includes('equipment-check')) {
            return history.push(`/session/${transactionId}/${page}${search}`);
          }
        }
      }, [
        { flowType, page, step }
      ], (prevDeps, nextDeps) => {
        const [prev] = prevDeps;
        const [curr] = nextDeps;
        const rtn = (prev.flowType !== curr.flowType) && !_.isEmpty(prev.flowType);
        const rtn2 = (prev.page !== curr.page);
        const rtn3 = (prev.step !== curr.step);
        return !(rtn && rtn2 && rtn3)
      });

      return (children)
    },
    layout: function StepWrapper({ children }) {
      const { step, page } = useStepPage();
      const { search, pathname } = useLocation();
      const { transactionId } = useQuery();
      const history = useHistory();

      const getInfo = useCallback(() => {
        if (step === null && page && !pathname.includes(`/session/${transactionId}/${page}`)) {
          return history.push({
            pathname: `/session/${transactionId}/${page}`,
            search
          })
        }
        return step && (pathname.includes('equipment-check')) ? constants.stateStepMapping.equipment_check : constants.stateStepMapping[step] ? constants.stateStepMapping[step] : null
      }, [history, page, pathname, search, step, transactionId])

      const info = getInfo()

      return (info) ? (
        <SetupStepper
          step={info.tabName}
          explanation={{
            title: info.explanation.title,
            description: info.explanation.description
          }}
        >
          {children}
        </SetupStepper>

      ) : children
    },

    routes: [
      {
        exact: true,
        path: '/session/:transactionId/equipment-check',
        component: function EquipmentCheckPage() {
          const history = useHistory();
          const location = useLocation();
          const query = useQuery();
          const { page } = useStepPage();

          return (
            <EquipmentCheck
              onComplete={() => history.push({
                pathname: `/session/${query.transactionId}/${page}`,
                search: location.search,
                state: {
                  from: location.pathname
                }
              })}
            />
          )
        }
      },
      {
        exact: true,
        path: '/session/:transactionId/verification',
        component: lazy(() => import('./pages/personal-information')),
      },
      {
        exact: true,
        path: '/session/:transactionId/esign',
        guard: EsignGuard,
        component: SessionPage
      },
      {
        path: '/session/:transactionId/notary-room',
        exact: true,
        component: NotaryRoom
      },
    ]
  },
  {
    path: '/esign/doc-retrieval',
    exact: true,
    guard: function EsignGuard({ children }) {
      const query = useQuery()
      return (
        <AxiosProvider>
          <SessionProvider
            organizationId={query.org}
            participantIds={query.participant}
            sessionId={query.session}
            iframeId={query.iframe}
          >
            <WebViewerProvider
            // selectedParticipantId={''}
            // checkAuth={() => { }}
              sessionId={query.session}
            >
              {children}
            </WebViewerProvider>
          </SessionProvider>
        </AxiosProvider>
      )
    },
    component: lazy(() => import('./pages/doc-retrieval'))
  },
  {
    path: '*',
    component: () => <Redirect to='/404' />
  },
  
]
