import { isEmpty } from 'lodash';
import { tagTypes } from '@enotarylog/tag-utils/constants/tagTypes';
import { curryN } from '@enotarylog/ramda';

export const flatTagTypes = Object.fromEntries<any>(Object.values(tagTypes).flatMap((categoryOfTagTypes) => Object.entries(categoryOfTagTypes)));

export const isFlattenedAnnot = (annot) => annot.CustomData?.flattened;
export const isExecutableAnnot = (annot) => !isEmpty(annot.CustomData?.type) && isEmpty(annot.CustomData.corrId) && !isFlattenedAnnot(annot);
export const isUnreviewedAnnot = (annot) => isExecutableAnnot(annot) && !annot.CustomData.reviewed;
export const isUnexecutedRequiredAnnot = (annot) => isExecutableAnnot(annot) && annot.CustomData?.flags?.required && !annot.CustomData?.applied;

export const isLegallyBinding = (annot) => flatTagTypes[annot?.CustomData?.type]?.legallyBinding;
export const isPrefillable = (annot) => flatTagTypes[annot?.CustomData?.type]?.prefillable;

export const annotBelongsTo = curryN(2, (annot, participantId) => {
  if (annot.CustomData?.signerId) {
    return annot.CustomData.signerId === participantId;
  } else if (annot.Author) {
    return annot.Author === participantId;
  }
  return false;
});
