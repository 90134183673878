import _ from 'lodash';
import { InstanceObject } from '../../viewer';

const disableHotkeys = async ({ instance, config = {}, tools, ...rest }: InstanceObject) => {
  _.map(config.hotKeys, (hotKey) => {
    return instance.hotkeys.off(hotKey);
  });

  return {
    instance,
    config,
    tools,
    ...rest,
  };
};

export default disableHotkeys;
