import { TwoFactorCodeTypes } from "document-viewer/src/components/authentication/types";

export enum AuthenticationTypes {
  TwoFactorAuth = 'two-factor-authentication',
  PersonalPassword = 'personal-password',
  None = 'none',
}
export interface Participant {
  id: string;
  status: string;
  pinCode?: string;
  order: number;
  email: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  phone?: string;
  twoFactorContact?: TwoFactorCodeTypes;
  signature?: string;
  initials?: string;
  isAuthenticated?: boolean;
  authenticationType: AuthenticationTypes;
  personalPasswordQuestion?: string;
  textColor?: string;
  kbaAttempts: number;
  documents: ParticipantDocument[];
  updatedAt: Date;
  authenticationFailures: number;
}

export interface ParticipantDocument {
  documentId: string;
  isCompleted: boolean;
  readOnly: boolean;
  visible: boolean;
}
