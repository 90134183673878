import React, { Component } from 'react';
import _ from 'lodash';
import * as R from 'ramda';
import {
  setStateFormat,
} from '../setters';
import annotNameIncludes from '../../helpers/annotNameIncludes';
import { Annotations } from '@pdftron/webviewer'


const getTemplateAnnots = R.filter(
  (annot: Annotations.Annotation) => annotNameIncludes(annot, 'StateTemplate')
);

export interface SetStateFormatProps {
  annotManager?: any;
  signers?: any;
  instance?: any;
}

export interface SetStateFormatState {
  stateFormat: any;
}

export default class SetStateFormat extends Component<SetStateFormatProps, SetStateFormatState> {
  public formatOptions?: any;

  constructor(props: SetStateFormatProps) {
    super(props);
    this.state = { stateFormat: { value: 'Abbr', display: 'Abbreviation' } };
    this.formatOptions = [
      { value: 'Abbr', display: 'Abbreviation' },
      { value: 'full', display: 'Full' },
    ];
  }

  componentDidMount = async () => {
    const selectedAnnots = this.props.annotManager.getSelectedAnnotations();

    const annots = _.filter(selectedAnnots, (a) => {
      return annotNameIncludes(a, 'Template');
    });

    if (annots) {
      const stateFormat = annots[0].CustomData.flags.stateFormat;

      await this.setState({ stateFormat });
    }
  }

  render() {
    const selectedAnnots = this.props.annotManager.getSelectedAnnotations();
    const annots = getTemplateAnnots(selectedAnnots);

    // TODO: set annot.Author and annot.CustomData.type
    if (annots.length === selectedAnnots.length) {
      return (
        <div style={{ paddingRight: 5 }}>
          <label htmlFor='signer'>State Format: </label>
          <select
            value={this.state.stateFormat}
            onChange={(ev) => {
              _.map(annots, (a) => {
                if (_.isNil(a.setStateFormat)) {
                  setStateFormat(a, ev.target.value, _.find(this.props.signers, { id: a.CustomData.signerId }), this.props.instance);
                } else {
                  a.setStateFormat(ev.target.value, _.find(this.props.signers, { id: a.CustomData.signerId }));
                }

                this.setState({ stateFormat: ev.target.value });
              });
            }}
          >
            {
              _.map(this.formatOptions, (format, index) => {
                return (
                  <option
                    key={index}
                    value={format.value}
                  >
                    {format.display}
                  </option>
                );
              })
            }
          </select>
        </div>
      );
    }

    return null;
  }
}
