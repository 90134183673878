import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Grid, Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import clsx from 'clsx';

export default function CreatePasswordPrompt({
  userPassword,
  setUserPassword,
  participantName,
  open
}) {
  const classes = useStyles();
  const [error,setError] = useState(null);

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='center'
      className={clsx({
        [classes.hidden]: !open,
      }, classes.createPwPromptContainer)}
    >
      <Grid
        item
        xs={6}
      >
        <Typography
          className={classes.nameDisplay}
          variant='h2'
        >
          {participantName}
        </Typography>
        <Box
          className={classes.passwordText}
        >
          <p>Set a 4 digit PIN used to identify you as the signer. Each time you need to sign you will need to input this PIN to enable you to sign.</p>
        </Box>
        <TextField
          data-testid='pinInput'
          id='password'
          required
          variant='outlined'
          value={userPassword || ''}
          error={!!error}
          helperText={error}
          onChange={(e) => {
            if(/\d{4}$/.test(e.target.value)){
              setError(null);
            }else{
              setError('PIN may only contain 4 numbers');
            }
            setUserPassword(e);
          }}
          inputProps={{ minLength: 4, maxLength: 4, pattern: '[0-9]{4}' }}
        />
      </Grid>
    </Grid>
  );
}
