import React, { FunctionComponent } from 'react';
import { TextField as formikTextField } from 'formik-material-ui';
import { Button, Grid } from '@material-ui/core';
import { ComponentProps } from './ComponentProps';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from 'document-viewer/src/components/Dialog';
import { Field, Form, Formik } from 'formik';
import { useStyles } from './styles';

interface Values {
  password: string;
}
export const PasswordPrompt: FunctionComponent<ComponentProps> = ({
  participantId,
  participantName,
  open,
  actualPinCode,
  handleClose,
  onSubmit,
}) => {

  const classes = useStyles();

  return (
    <Dialog
      size='sm'
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
    >
      <Formik
        initialValues={{
          password: '',
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validate={(values) => {
          const errors: Partial<Values> = {};
          if (!values.password) {
            errors.password = 'PIN Required';
          }
          else if (!/\d{4}$/.test(values.password)) {
            errors.password = 'PIN may only contain 4 numbers'
          }
          else if (actualPinCode !== values.password && actualPinCode !== null) {
            errors.password = 'Incorrect PIN!';
          }
          return errors;
        }}
        onSubmit={() => {
          onSubmit(participantId)
        }}
      >
        {({ submitForm, errors, touched, isSubmitting }) => (

          <Form className={classes.pwPromptForm}>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              className={classes.pwPromptGrid}
            >
              <Grid
                item
              >
                <DialogTitle
                  id='form-dialog-title'
                  className={classes.dialogTitle}
                >
                  {participantName}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>Enter your PIN to become the active signer.</DialogContentText>
                  <Field
                    autoFocus
                    component={formikTextField}
                    type='password'
                    label='PIN'
                    name='password'
                    variant='outlined'
                    helperText={errors['password']}
                    inputProps={{ maxLength: 4, 'data-testid': 'pincode' }}
                    error={touched['password'] && !!errors['password']}
                    className={classes.pwPromptField}
                  />
                </DialogContent>
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                variant='contained'
                onClick={submitForm}
                color='primary'
                disabled={isSubmitting}
                className={classes.pwPromptButton}
              >
                Activate
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );

};
