import { useState, useCallback, useEffect, useRef } from 'react';

/**
 * This custom hook is used to determine if the screen is in landscape mode or not. It updates the
 * value based on the orientation change event.
 *
 * @returns {boolean} `true` if landscape, `false` if portrait.
 */
const useOrientation = () => {
  const isMobileScreen = window.matchMedia('(max-width: 960px)').matches;
  const landscapeOrientation = window.matchMedia('(orientation: landscape)');

  const isListenerAdded = useRef(false);

  const [isLandscape, setIsLandscape] = useState(landscapeOrientation.matches);

  const handleOrientationChange = useCallback((e: MediaQueryListEvent) => {
    setIsLandscape(e.matches);
  }, []);

  useEffect(() => {
    if (!isListenerAdded.current && isMobileScreen) {
      landscapeOrientation.addEventListener('change', handleOrientationChange);
      isListenerAdded.current = true;
    }

    return () => landscapeOrientation.removeEventListener('change', handleOrientationChange);
  }, []);

  return isLandscape;
};

export default useOrientation;
