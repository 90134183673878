export const participantColors = [
  [153, 215, 114, 0.5],
  [255, 10, 102, 0.5],
  [38, 79, 120, 0.5],
  [239, 106, 70, 0.5],
  [238, 145, 142, 0.5],
  [255, 87, 126, 0.5],
  [238, 224, 86, 0.5],
  [193, 151, 109, 0.5],
  [157, 157, 157, 0.5],
  [160, 146, 236, 0.5],
];
export const notaryColor = [91, 215, 227, 0.5];
