import { hotKeys } from '../constants/hotKeys';

export default {
  panelNames: [],
  toolNames: [
    'LockCheckbox',
    'Divider',
    'SelectSigner',
    'Divider',
    'AddBlankPage',
    'RemoveBlankPage',
    'Divider',
    // 'RemoveFormFields',
    'RemoveAllAnnots',
    'Divider',
    'FormFieldTools',
    'CheckboxTool',
    'RadioButtonTool',

    // 'TemplateTools',
    'Divider',
    'VaDisclaimer',
    'StampTools',
    'CertTool',
  ],
  popupNames: [
    // 'AssignSigner',
    'SetDateFormat',
    'SetRequired',
    'SetStateFormat',
    'SetAddressFormat',
  ],
  contextMenuItems: [
    'panToolButton',
  ],
  hotKeys: [
    hotKeys.F,
    hotKeys.V,
    hotKeys.R,
    hotKeys.B,
    hotKeys.Q,
    hotKeys.W,
    hotKeys.E,
    hotKeys.T,
    hotKeys.S,
    hotKeys.C,
    hotKeys.A,
    hotKeys.Z,
    hotKeys.X,
    hotKeys.O,
    hotKeys.L
  ],
};
