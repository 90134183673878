import { useSubscription, useApolloClient } from '@apollo/client';
import SigPad from 'document-viewer/src/components/SignaturePadModal';
import { GET_PARTICIPANT, PARTICIPANT_SUBSCRIPTION } from 'document-viewer/src/lib/gql/participant';
import { useParticipants } from 'document-viewer/src/lib/hooks/useParticipants';
import { useDebounce, useLocalStorage } from 'react-use';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { useRequestData } from 'document-viewer/src/lib/hooks/useRequestData';
import { selector as themeSelector, ThemeState } from 'document-viewer/src/slices/theme';
import { useSelector } from 'document-viewer/src/store';
import { createSelector } from '@reduxjs/toolkit';
import { useDocs } from 'document-viewer/src/lib/hooks/useDocs';

export const useTheme = (): ThemeState => useSelector(createSelector(themeSelector, (theme: ThemeState) => theme));

export default function SignaturePadModal({ participantsLoading, onLockout, isChallengeQuestion, setIsChallengeQuestion }) {

  const {
    participantIds,
    updateParticipantSignatureInitials,
    pendingSelection,
    pendingSelectionId,
    updateParticipantStatus,
  } = useParticipants();

  const { requestData: { signatureAndInitialsInOneStep } } = useRequestData();

  const [pinCodes, setLocalPinCodes] = useLocalStorage('pinCodes', {});
  const fontFamilies = useTheme().sigFonts;
  const apollo = useApolloClient();
  const [participantLastUpdatedAt, setParticipantLastUpdatedAt] = useState<number | null>(null);

  useDebounce(async () => {
    if (!participantLastUpdatedAt || !pendingSelection?.id) return;

    const participant = _.get(await apollo.query({ query: GET_PARTICIPANT, fetchPolicy: 'no-cache', variables: { pId: pendingSelection?.id } }), 'data.getParticipant', null);

    if (participant && pendingSelection &&
      (pendingSelection.status !== participant?.status || pendingSelection.kbaAttempts !== participant?.kbaAttempts)
    ) {
      updateParticipantStatus(participant.id, participant)
    }
  }, 500, [participantLastUpdatedAt, updateParticipantStatus, pendingSelection]);

  useSubscription(PARTICIPANT_SUBSCRIPTION, {
    variables: {
      pId: pendingSelection?.id
    },
    skip: !pendingSelection?.id,
    onSubscriptionData: () => setParticipantLastUpdatedAt(Date.now()),
  });

  const { docs } = useDocs();
  const someDocHasTags = useMemo(() => Object.values(docs).some((doc) => doc.tags !== 0), [docs]);

  const participantName = pendingSelection && [
    pendingSelection.firstName,
    pendingSelection.middleName,
    pendingSelection.lastName,
  ].join(' ');

  return (
    <SigPad
      onSubmit={(pinCode, sig, initials, color) => {
        if (pinCode) {
          setLocalPinCodes({
            ...pinCodes,
            [pendingSelectionId]: pinCode
          })
        }
        updateParticipantSignatureInitials(pendingSelectionId, sig.image, initials.image, color, pinCode, undefined, signatureAndInitialsInOneStep);
      }}
      onLockout={onLockout}
      open={pendingSelection && ((!pendingSelection.pinCode && participantIds.length > 1) || !pendingSelection.signature || !pendingSelection.initials) && someDocHasTags}
      participantsLoading={participantsLoading}
      participantName={pendingSelection && participantName}
      participant={pendingSelection}
      numParticipants={participantIds.length}
      participantId={pendingSelectionId}
      fontFamilies={fontFamilies}
      signatureAndInitialsInOneStep={signatureAndInitialsInOneStep}
      isChallengeQuestion={isChallengeQuestion}
      setIsChallengeQuestion={setIsChallengeQuestion}
    />
  )
}
