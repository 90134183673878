import { createSlice, PayloadAction, Action } from "@reduxjs/toolkit";
import { defaultTheme } from "../constants";
import * as R from '@enotarylog/ramda';
import { ThemeSettings } from "document-viewer/src/utils/types/theme-settings";
import { AppThunk } from 'document-viewer/src/store';
import WebFont from 'webfontloader';


export interface ThemeState {
  settings: ThemeSettings
  sigFonts: string[];
}


export const initialState: ThemeState = {
  settings: defaultTheme,
  sigFonts: [],
};

const slice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state: ThemeState, action: PayloadAction<{ theme: ThemeSettings }>) => {
      state.settings = R.mergeDeepRight(state.settings, action.payload.theme);
    },
    setSigFonts(state: ThemeState, { payload }: PayloadAction<{ sigFonts: string[] }>) {
      state.sigFonts = payload.sigFonts;
    }
  }
});




export const setTheme = (theme: ThemeSettings): Action => slice.actions.setTheme({ theme }) as Action;
export const setSigFonts = (sigFonts: string[]): AppThunk => (dispatch) => {
  WebFont.load({
    google: {
      families: sigFonts,
    },
  });
  dispatch(slice.actions.setSigFonts({ sigFonts }));
}




export const reducer = slice.reducer;
export const selector = (state: { theme: ThemeState }) => state.theme
export default slice;
