import React from 'react';
import { Grid } from '@material-ui/core';
import Tooltip from 'document-viewer/src/components/Tooltip';
import { FlexAccordionSummary, } from 'document-viewer/src/components/Accordion/flex';
import { useStyles } from './styles';
import { InfoOutlined as InfoIcon, Create as PencilIcon } from '@material-ui/icons/'
import { useFlags } from 'document-viewer/src/lib/hooks/useFlags';
import { useParticipants } from 'document-viewer/src/lib/hooks/useParticipants';
import { docIsCompletedOrParticipantCompletedDoc } from 'document-viewer/src/utils/docIsCompletedOrParticipantCompletedDoc';

export default function DocHeader({ doc, isCurrentDoc, inactive, completedNoTagDocList }) {
  const flags = useFlags();
  const classes = useStyles({});
  const {
    participants,
    selectedParticipantId,
  } = useParticipants();

  return (

    <Tooltip title={doc.title}>
      <FlexAccordionSummary
        classes={{
          expanded: classes.noMinHeight,
          content: classes.marginCorrection
        }}
        className={
          `${docIsCompletedOrParticipantCompletedDoc(doc, participants[selectedParticipantId], completedNoTagDocList) ? classes.completedLight : !inactive ? classes.toSignLight : classes.incompleteDocs}
          ${classes.docSummaryHead}
          ${classes.docHeaderIcon}`
        }
        expandIcon={false}
        data-testid={`DocHeader ${doc.id}`}
      >

        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          wrap='nowrap'
        >
          <h3 className={classes.docTitleText}>
            {doc.title}
          </h3>
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            className={classes.sideIcons}
          >
            {flags?.showGetInformationInDocViewer ?
              <InfoIcon
                onClick={(e) => {
                  e.stopPropagation()
                  alert('No information available at this time')
                }}
              />
              : null}
          </Grid>
        </Grid>

      </FlexAccordionSummary>
    </Tooltip>
  );
}
