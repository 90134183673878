import { createSelector } from '@reduxjs/toolkit';
import {
  updateParticipantSignatureInitials,
  selector as participantsSelector,
  ParticipantsState,
  setSelectedParticipant,
  setPendingSelection,
  setShowSwitch,
  updateParticipantStatus,
  agreeToLegalStuff,
} from 'document-viewer/src/slices/participants';
import { RootState, useDispatch, useSelector } from 'document-viewer/src/store';

import { connect, useStore } from 'react-redux';
import * as R from '@enotarylog/ramda'
import { useWebViewer } from './useWebViewer';
import { useQuery } from 'document-viewer/src/lib/hooks/useQuery';
import { useCallback } from 'react';



export const participantsSel = createSelector(participantsSelector, (state: ParticipantsState) => state.participants.byId);
export const participantLoadingSel = createSelector(participantsSelector, (state: ParticipantsState) => state.loading);
export const showSwitchSel = createSelector(participantsSelector, (state: ParticipantsState) => state.showSwitch);
export const participantsAllIdsSel = createSelector(participantsSelector, (state: ParticipantsState) => state.participants.allIds);
export const selectedParticipantIdSel = createSelector(participantsSelector, (state: ParticipantsState) => state.selectedParticipant);
export const selectedParticipantSel = createSelector(participantsSelector, (participantsState: ParticipantsState) => participantsState.participants.byId[participantsState.selectedParticipant]);
export const pendingSelectionSel = createSelector(participantsSelector, (participantsState: ParticipantsState) => participantsState.participants.byId[participantsState.pendingSelection]);
export const pendingSelectionIdSel = createSelector(participantsSelector, (participantsState: ParticipantsState) => participantsState.pendingSelection);
export const promptPinCodeSel = createSelector(participantsSelector, (participantsState: ParticipantsState) => participantsState.promptPinCode);



export function useParticipants() {

  const dispatch = useDispatch();
  const store = useStore();
  const { applyTag } = useWebViewer();

  const participants = useSelector(participantsSel) as ReturnType<typeof participantsSel>
  const participantIds = useSelector(participantsAllIdsSel) as ReturnType<typeof participantsAllIdsSel>;
  const selectedParticipantId = useSelector(selectedParticipantIdSel) as ReturnType<typeof selectedParticipantIdSel>;
  const selectedParticipant = useSelector(selectedParticipantSel) as ReturnType<typeof selectedParticipantSel>
  const pendingSelection = useSelector(pendingSelectionSel) as ReturnType<typeof selectedParticipantSel>;
  const pendingSelectionId = useSelector(pendingSelectionIdSel) as ReturnType<typeof selectedParticipantIdSel>;
  const promptPinCode = useSelector(promptPinCodeSel) as ReturnType<typeof promptPinCodeSel>;
  const loading = useSelector(participantLoadingSel) as ReturnType<typeof participantLoadingSel>
  const showSwitch = useSelector(showSwitchSel) as ReturnType<typeof showSwitchSel>
  const participantIdsOnThisDevice = R.asArray(useQuery().participant);

  return {
    updateParticipantSignatureInitials: useCallback(
      (
        ...args: Parameters<ReturnType<typeof updateParticipantSignatureInitials>>
      ) => dispatch(updateParticipantSignatureInitials(applyTag)(...args)),
      [applyTag, dispatch]
    ),
    updateParticipantStatus: useCallback(
      (
        ...args: Parameters<typeof updateParticipantStatus>
      ) => dispatch(updateParticipantStatus(...args)),
      [dispatch]
    ),
    setSelectedParticipant: useCallback(async (pId) => {
      dispatch(setSelectedParticipant(pId));
      const pendingAnnot = store.getState().annotations.pendingAnnot;
      if (pendingAnnot && applyTag) {
        await applyTag(pendingAnnot);
      }
    }, [applyTag, dispatch, store]),
    setPendingSelection: useCallback((args) => dispatch(setPendingSelection(args)), [dispatch]),
    setShowSwitch: useCallback((args) => dispatch(setShowSwitch(args)), [dispatch]),
    loading,
    participants,
    participantIds,
    selectedParticipantId,
    selectedParticipant,
    pendingSelection,
    pendingSelectionId,
    promptPinCode,
    showSwitch,
    participantIdsOnThisDevice,
    agreeToLegalStuff: useCallback(
      (...args: Parameters<typeof agreeToLegalStuff>) => dispatch(agreeToLegalStuff(...args)),
      [dispatch],
    ),
  }
}

export const withParticipants = connect((state: RootState) => ({
  loading: participantLoadingSel(state),
  participants: participantsSel(state),
  selectedParticipantId: selectedParticipantIdSel(state),
  selectedParticipant: selectedParticipantSel(state)
}), (dispatch) => ({
  setSelectedParticipant: R.pipe(setSelectedParticipant, dispatch),
  setPendingSelection: R.pipe(setPendingSelection, dispatch),
}))
