import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'document-viewer/src/lib/hooks/useTheme';

const drawerWidth = 240;
const scrollBarWidth = 12;

interface PropTypes {
  collapsedSection?: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({

  textPrimaryContrast: { color: theme.palette.primary.contrastText, },
  textSuccessContrast: { color: theme.palette.success.contrastText, },

  docAccordion: {
    border: 0,
    borderTop: '0 !important',
    borderBottom: '0 !important',
    overflow: 'hidden !important'
  },
  docHeader: {
    maxWidth: drawerWidth - 16,
    borderRadius: '0 8px 8px 0',
    margin: '8px 0',
    boxShadow: '0 4px 4px rgba(0,0,0,.3)',
    '& .Mui-expanded': { marginTop: 0, marginBottom: 0 }
  },
  docSummaryHead: {
    width: drawerWidth - 32,
    borderRadius: '0 8px 8px 0',
    boxShadow: '0 4px 4px rgba(0,0,0,.3)',
    minHeight: 0,
    padding: 8,
  },
  docHeaderText: {
    margin: 0,
    padding: 0,
    cursor: 'pointer',
    textTransform: 'uppercase',
    transition: 'font-size .2s',
    fontSize: ({ collapsedSection }: PropTypes) => collapsedSection ? '1.3rem' : '1.6rem'
  },
  docTitleText: {
    maxWidth: drawerWidth - 80,
    fontSize: '.9rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: 0,
    fontWeight: 400,
  },
  docPaginationText: {
    padding: '10px 0 5px 0',
    marginRight: scrollBarWidth,
    textAlign: 'center',
    color: `${theme.palette.text.primary} !important`
  },
  docHeaderIcon: {
    '& .MuiIconButton-root': {
      padding: '0 !important',
      paddingRight: '12px !important',
    },
    '& .Mui-expanded': { transform: 'rotate(0deg) !important' }
  },

  completed: {
    backgroundColor: theme.palette.success.main,
    '& span': { color: theme.palette.success.contrastText, },
  },
  isExpanded: {
    minHeight: '140px'
  },
  completedLight: {
    backgroundColor: theme.palette.success.light,
    color: `${theme.palette.success.contrastText} !important`,
    '& path': { color: `${theme.palette.success.contrastText} !important` }
  },
  toSign: {
    backgroundColor: theme.palette.primary.main,
    '& span': { color: theme.palette.primary.contrastText, },
  },
  toSignLight: {
    backgroundColor: theme.palette.primary.light,
    color: `${theme.palette.primary.contrastText} !important`,
    '& path': { color: `${theme.palette.primary.contrastText} !important` }
  },

  noPadding: {
    padding: 0,
  },
  marginCorrection: {
    margin: '0 !important',
  },
  noMinHeight: {
    minHeight: '0 !important',
  },
  clipOverflow: {
    overflow: 'clip',
  },

  largeSection: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    background: 'rgba(0,0,0,0)',
  },
  smallSection: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 'min-content',
  },

  sideIcons: {
    width: 'unset',
    marginLeft: theme.spacing(1),
  },
  grow: {
    border: 0,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    background: 'rgba(0,0,0,0)',
  },
  thumbnailsContainer: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    minHeight: '30vh',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    paddingTop: '0 !important',
    paddingBottom: theme.signatureAndInitialsInOneStep ? 0 : undefined,
  },
  docMinHeight: {
    minHeight: '50px',
  },
  successDark: { color: theme.palette.success.dark },
  flexSection: { display: 'flex', flex: 1 },
  noScrollbarCorner: {
    '&::-webkit-scrollbar-corner': { background: theme.palette.background.paper },
    background: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
  },
  thumbnailsAccordion: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  restOfDocs: {
    overflow: 'scroll',
    flexShrink: 1,
    minHeight: '65px',
  },
  incompleteDocs: {
    backgroundColor: 'gray !important'
  },
  docListSectionAccordion: {
    paddingRight: 0,
    border: 0,
    overflowX: 'hidden'
  },

  docDetailsDiv: { display: 'flex', flex: 1 },

  accordionDetailsDiv: { flexGrow: 2, display: 'flex' },

  docListBox: {
    overflowX: 'hidden',
    overflowY: 'scroll',
  },

}));

